import React, { useEffect, useState } from "react";
import CustomDropDown from "../../../../Components/CustomDropDown/CustomDropDown";
// import ApexAreaChartsFacebookPost from "./Charts/ApexAreaChartsFacebookPost";
import "./FbAnalytics.css";
import ApexAreaChartsFacebookPage from "../../DeepAnalytics/Charts/ApexAreaChartsFacebookPage";

function FbAnalytics(props) {
  const facebookChartsData = [
    "page_impressions",
    "page_engaged_users",
    "page_total_actions",
    "page_consumptions",
    "page_consumptions_unique",
    "page_impressions_unique",
    "page_tab_views_logout_top",
    "page_impressions_paid*",
  ];
  const [AllPages, setAllPages] = useState(props.pagesDetails);
  const [IsPageSelected, setIsPageSelected] = useState(false);
  const [SelectedPage, setSelectedPage] = useState("Select Page");
  const [pageData, setPageData] = useState();
  useEffect(() => {
    setAllPages(props.pagesDetails);
  }, [props.pagesDetails]);

  const Networkpages = [];
  AllPages?.map((item) => {
    if (item.account_type === "facebook") {
      Networkpages.push(item.name);
    }
  });
  console.log(AllPages);
  console.log(Networkpages);

  AllPages?.map((item) => {
    if (item.name === SelectedPage && item.account_type === "facebook") {
      setPageData(item);
    }
  });

  return (
    <div className="fbAnalyticBlock">
      <div className="mediaFilterBlock">
        <p>Facebook</p>
        <CustomDropDown
          isActive={IsPageSelected}
          setIsActive={setIsPageSelected}
          selected={SelectedPage}
          setIsSelected={setSelectedPage}
          list={Networkpages}
          type={props.type}
        />
      </div>

      <div className="mediaKPIBlock">
        <div className="mediaKPI cardSpace">
          <p>Post Engagements</p>
          <p className="kpiValue">80%</p>
        </div>
        <div className="mediaKPI cardSpace">
          <p>Post Engagements</p>
          <p className="kpiValue">80%</p>
        </div>
        <div className="mediaKPI cardSpace">
          <p>Post Engagements</p>
          <p className="kpiValue">80%</p>
        </div>
        <div className="mediaKPI cardSpace">
          <p>Post Engagements</p>
          <p className="kpiValue">80%</p>
        </div>
        <div className="mediaKPI cardSpace">
          <p>Post Engagements</p>
          <p className="kpiValue">80%</p>
        </div>
        <div className="mediaKPI ">
          <p>Post Engagements</p>
          <p className="kpiValue">80%</p>
        </div>
      </div>
      <div className="mediaLineChart"></div>
    </div>
  );
}

export default FbAnalytics;
