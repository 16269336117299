import React, { Fragment } from "react";
import myprofile_icon from '../../assets/myprofile_icon.png'

const Avatar = ({ imageUrl }) => {
  return (
    <Fragment>
      {/* <div >
        {imageUrl && (
          <img className="avatarImage" alt="avatar" src={imageUrl} />
        )}
        {imageUrl === undefined && name !== "" && (
          <div className={classes.avatatext}>{name.substring(0, 2)}</div>
        )}


      </div> */}

      <div className="profileContainer">
        {imageUrl ?  <img src={imageUrl} className="uploadedImg" alt="profile" /> : <img src={myprofile_icon} alt="profile" />}
        
      </div>
    </Fragment>
  );
};

export default Avatar;