import React, { useState, useEffect } from "react";
import FormInput from "../../Components/FormInput/FormInput";
import { useLocation, useNavigate } from "react-router-dom";
import warning from "../../assets/warning.png";
import { ScaleLoader } from "react-spinners";
import "./ForgotPassword.css";
import AuthAPI from "../../Utils/AuthAPI";
import Logo from "../../../src/assets/logo1.png";
import Digi from "../../../src/assets/digi.png";
import login_left_img from "../../../src/assets/login_left_img.png";
import underLine from "../../assets/underline.svg";
import { ChevronRight } from "react-feather";
import {GeneratePasswordResetToken} from "../../Utils/api";
import { toast, ToastContainer } from "react-toastify";

function ForgotPassword({ loginState, setLoginState, setUserInfo, userInfo }) {
  const location = useLocation();
  const authApi = React.useContext(AuthAPI);
  const [emailError, setEmailError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [timer, setTimer] = useState();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const checkLogin = (params) => {
    if (localStorage.getItem("token") || sessionStorage.getItem("token")) {
      navigate("/dashboard");
      authApi.setAuth(true);
    }
  };


const  handleSendEmail = () => {
    if (email.length === 0) {
        setLoginError("Please fill all the Input");
        return;
    }
    GeneratePasswordResetToken(email).then((res) => {
        console.log(res);
        if (res.status === 200) {
            console.log('sent '+email.email)
            localStorage.setItem('ResetTime', Date.now())
            setIsDisabled(true)
             toast.success("Email Sent Successfully"+ email.email, {
                 position: "top-right",
                 autoClose: 2000,
                 hideProgressBar: false,
                 closeOnClick: true,
                 pauseOnHover: true,
                 draggable: true,
                 progress: undefined,
                 });
             
         setInterval(() => {
             const secondsLeft = Math.round((Date.now() - localStorage.getItem('ResetTime')) / 1000);
             if (secondsLeft >= 60) {
                 setIsDisabled(false)
                 clearInterval(timer);
             } else {
                 setTimer(60 - secondsLeft);
             }
         }, 1000);
        }
       
    }).catch((err) => {
         if(err.response.status === 404){
            toast.error("email not found", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        } else if(err.response.status === 500){
            toast.error("Internal Server Error", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }
        else{
            toast.error("Something went wrong", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }
    })

  

}

  useEffect(() => {
    if (location.pathname !== "/link-account") {
      checkLogin(userInfo);
    }
  }, []);

//  useEffect(() => {
// console.log('timer '+ Date.now())
//  },[Date.now()]);
  
  return (
    <div>
        <ToastContainer />
      <div className="mainLayout">
        <div className="layout1">
          <div className="leftLayout">
            <img src={Logo} alt="logo" />
            <p className="regular text">
              Get one stop solution for all
              <br />
              your <span>Digital Marketing</span> needs
            </p>
          </div>
          <div>
            <img src={login_left_img} alt="login" className="img3" />
          </div>
        </div>



        <div className="layout2">
          <section className="mainBlock">
         <div style={{marginTop: '70px'}}>
         <div className="boldText" >
              <h3>Reset Password</h3>
              {loginError.length !== 0 && (
                <p className="errorcase">
                  {loginError}
                  <img src={warning} alt="error" />
                </p>
              )}
              <div className="field">
                <p className="regular">Email</p>
                <FormInput
                    inputType="email"
                    name="email"
                    setState={setEmail}
                    setError={setEmailError}
                />
              </div>
              {emailError.length !== 0 && (
                <p className="errorcase">
                  {emailError} <img src={warning} alt="error" />
                </p>
              )}
             {/* {/* {localStorage.getItem('ResetTime') - Date.now() > 60000 ? (setIsDisabled === true) : (setIsDisabled === false)} 
             {localStorage.getItem('ResetTime') - Date.now() > 60000 ? (localStorage.getItem('ResetTime') - Date.now()) : null}
             {console.log(localStorage.getItem('ResetTime') - Date.now())} */}
{timer> 2? ("Please wait for "+timer+ " Seconds"): null}
          
              <button
                type="submit"
                className="login-btn"
                disabled={isDisabled}
                onClick={handleSendEmail}
              >
                <div className="loginBtn-Container">
                  <p className="logintxt">
                    Send Code &nbsp;&nbsp;&nbsp;
                    <ChevronRight className="arrow" />
                  </p>
                  <ScaleLoader
                    style={{ paddingRight: "20px" }}
                    width={2}
                    height={20}
                    size={20}
                    color="#ffffff"
                    loading={isLoading}
                  />
                  {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                </div>
              </button>
            </div>
         </div>
            
          </section>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
