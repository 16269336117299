import React from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom'

if ((window.location.pathname === "/linkedin" || window.location.pathname === "/dashboard")) {
  const query = new URLSearchParams(window.location.search);
  if (query.get("code") !== null && query.get('state') === "123456") {
    console.log(query.get("code"));
    let code = query.get("code");
    const res = axios.post(
      `${process.env.REACT_APP_SERVER_URI}/api/crud/linkedin`, {
      jwToken: sessionStorage.getItem('token'),
      code
    }
    );
    console.log(res);
  }
}

export default function LinkedIn(userInfo) {

  const navigate = useNavigate()

  setTimeout(() => {
    navigate('/login')
  }, 1500);
  return <div> Successfully Connected to LinkedIn</div>;
}