import React, { useState } from "react";
import { useNavigate } from "react-router";
import Logout from "../../../assets/logoutIcon.png";
import { useQuery } from "react-query";
import axios from "axios";
import { Button, Collapse, Spinner } from "reactstrap";
import "./viewUsersQuestion.css";
import dropArrowDown from "../../../assets/dropDownArrow.png";

const ViewUsersQuestions = () => {
  const [page, setPage] = useState(0);
  const [nameQuery, setNameQuery] = useState("");
  const [emailQuery, setEmailQuery] = useState("");
  const PAGE_SIZE = 10;
  const navigate = useNavigate();
  const {
    data: users,
    isLoading: usersIsLoading,
    isError: usersIsError,
    error: usersError,
  } = useQuery(["fetchUsers", page, PAGE_SIZE], fetchUsers);

  const [isOpen, setIsOpen] = useState({});

  const toggle = (userId) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [userId]: !prevState[userId],
    }));
  };

  if (usersIsLoading) {
    return (
      <div className="center">
        <Spinner className="loader" />
      </div>
    );
  }
  if (usersIsError) {
    return (
      <div className="center">
        <h1>{usersError.message}</h1>
      </div>
    );
  }

  // Filter the users data based on name and email search queries
  const filteredUsers = users?.data?.filter((user) => {
    try {
      const nameMatch = user?.fName
        .toLowerCase()
        .includes(nameQuery?.toLowerCase());
      const emailMatch = user?.email
        .toLowerCase()
        .includes(emailQuery?.toLowerCase());
      return nameMatch && emailMatch;
    } catch (err) {
      return "" && "";
    }
  });

  console.log(filteredUsers);
  return (
    <div className="userOuterLayout">
      <div className="headSection regular">
        <span>User Questions</span>
        <img
          src={Logout}
          alt="logout"
          onClick={() => {
            localStorage.clear();
            sessionStorage.clear();
            return navigate("/admin-login");
          }}
        />
      </div>
      <div className="searchSection">
        <input
          type="text"
          placeholder="Search by name"
          value={nameQuery}
          onChange={(e) => setNameQuery(e.target.value)}
        />
        <input
          type="text"
          placeholder="Search by email"
          value={emailQuery}
          onChange={(e) => setEmailQuery(e.target.value)}
        />
      </div>
      <div className="dataSection">
        <table>
          <thead>
            <tr
              style={{
                backgroundColor: "red",
                padding: "40px",
              }}
            >
              <th>
                <h3>First Name</h3>
              </th>
              <th>
                <h3>Email</h3>
              </th>
              <th>
                <h3>Questions</h3>
              </th>
            </tr>
          </thead>
          <tbody>
            {nameQuery || emailQuery
              ? filteredUsers.map((user) => (
                  <tr key={user?._id}>
                    <td>{user?.fName}</td>
                    <td>{user?.email}</td>

                    <td>
                      <label for={user?._id}>
                        <span className="btn">
                          {isOpen[user?._id] ? (
                            <p style={{ fontSize: "30px" }}>⨯</p>
                          ) : (
                            <img
                              src={dropArrowDown}
                              alt=""
                              style={{ width: "15px" }}
                            />
                          )}
                        </span>
                      </label>
                      <button class="buttonForViewUsers" 

                        id={user?._id}
                        onClick={() => toggle(user?._id)}
                        hidden
                      ></button>
                      <Collapse isOpen={isOpen[user?._id]}>
                        {user?.userQuestionDetails?.map((item) => (
                          <ul key={item?._id}>
                            <li>
                              {item?.questionText}
                              <p>
                                <b>Answer :</b> {item?.answerText}
                              </p>
                            </li>
                          </ul>
                        ))}
                      </Collapse>
                    </td>
                  </tr>
                ))
              : filteredUsers
                  .slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE)
                  .map((user) => (
                    <tr key={user?._id}>
                      <td>{user?.fName}</td>
                      <td>{user?.email}</td>

                      <td>
                        <label for={user?._id}>
                          <span className="btn">
                            {isOpen[user?._id] ? (
                              <p style={{ fontSize: "30px" }}>⨯</p>
                            ) : (
                              <img
                                src={dropArrowDown}
                                alt=""
                                style={{ width: "15px" }}
                              />
                            )}
                          </span>
                        </label>
                        <button class="buttonForViewUsers" 

                          id={user?._id}
                          onClick={() => toggle(user?._id)}
                          hidden
                        ></button>
                        <Collapse isOpen={isOpen[user?._id]}>
                          {user?.userQuestionDetails?.map((item) => (
                            <ul key={item?._id}>
                              <li>
                                {item?.questionText}
                                <p>
                                  <b>Answer :</b> {item?.answerText}
                                </p>
                              </li>
                            </ul>
                          ))}
                        </Collapse>
                      </td>
                    </tr>
                  ))}
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td className="paginate">
                <button class="buttonForViewUsers" 

                  disabled={page === 0}
                  onClick={() => setPage((prev) => prev - 1)}
                >
                  Previous
                </button>
                <span>
                  <b>Page {page + 1}</b>
                </span>
                <button class="buttonForViewUsers" 

                  disabled={
                    usersIsLoading ||
                    filteredUsers?.length === 0 ||
                    page === Math.floor(filteredUsers?.length / 10)
                  }
                  onClick={() => setPage(page + 1)}
                >
                  Next
                </button>
              </td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

function fetchUsers({ queryKey }) {
  const [_, page, pageSize] = queryKey;
  const offset = page * pageSize;
  const url = `${process.env.REACT_APP_SERVER_URI}/api/users/getUsers?offset=${offset}&limit=${pageSize}`;
  return axios.get(url);
}
export default ViewUsersQuestions;
