
import React from 'react';
import { Container, Row, Col, CardBody , Card, Button} from 'reactstrap';
import { useEffect, useState } from 'react';

const MediaLibrary = () => {
    // Define an array to store the media assets


    const [mediaAssets, setMediaAssets] = useState([])


    useEffect(() => {
        // Fetch the media assets from the server
        fetchMediaAssets();
    }, []);


    const fetchMediaAssets = async () => {
        // Fetch the media assets from the server
        const response = await fetch(process.env.REACT_APP_SERVER_URI + '/api/user-media');
        const data = await response.json();

        // Set the media assets in the state
        setMediaAssets(data);
    };



 

    return (
  <div >
   <Card className="calenderModuleCard">
        <CardBody className="calendarBody">

            <div>
                <Button className="btn btn-primary" style={{float: "right", width:'145px', borderRadius:'0px'}}>Add Media</Button>
            </div>


        </CardBody>

        </Card>



</div>
    );
};

export default MediaLibrary;