import Chart from 'react-apexcharts'
import axios from 'axios'
import React, { useState, useEffect } from 'react'

import { Card, CardHeader, CardTitle, CardBody, CardSubtitle, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown } from 'reactstrap'
import { async } from 'emoji-mart'
import capitalize from 'capitalize'

const areaColors = {
  series3: '#a4f8cd',
  series2: '#60f2ca',
  series1: '#2bdac7'
}

const SmallGraphs = (props) => {
  console.log(props)

  console.log(new Date(props.startDate).toISOString().slice(0, 10))
  console.log(Date.parse(new Date(props.endDate).toISOString()))
  //convert Wed Sep 07 2022 00:00:00 GMT+0530 in yyyy-mm-dd format



  const [metricF, setMetricF] = useState(props.metric)
  const [graphData, setGraphData] = useState([])
  const getGraphData = async () => {
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/crud/getAnalytics/${metricF}`, {
        
      "dimension": "day",
      "startDate": new Date(props.startDate).toISOString().slice(0, 10),
      "endDate": new Date(props.endDate).toISOString().slice(0, 10),
      "jwToken": localStorage.getItem("token"),
      "pageId": props.selectedPageID.pageId
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': localStorage.getItem('token')
      }
    })
    setGraphData(response.data)
  };
  useEffect(() => {
    getGraphData()
  }, [metricF, props.startDate, props.endDate,props.page, props.selectedPageID])



  const series = [{
    name: graphData.metric,
    data: graphData.data
  }];
  let percent = graphData.data && graphData.data.length > 1 ? (graphData.data[graphData.data.length - 1] * 100 / graphData.data[graphData.data.length - 2] - 100).toFixed(0) : 0;
  percent = parseInt(percent)
  console.log(typeof (percent))
  //convert Nan to 0
  if (isNaN(percent)) {
    percent = 0
  }
  //create color for percent
  let color = percent >= 0 ? "green" : "red"

  if (percent >= 0) {
    percent = " " + "+" + percent + "%" + "▴"
  } else if (percent < 0) {
    percent = " " + " " + percent + "%" + "▾"
  }
  const options = {

    chart: {
      type: 'area',

      height: 150,
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      },
      tooltip: {
        enabled: false,
        show: false
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        title: {
          text: '',
          align: 'left',
          // offsetX: 14
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: undefined,
    },
    stroke: {
      curve: 'smooth',
      width: 0.8
    },
    grid: {
      show: false,
    },
    title: {
      text: '',
      align: 'left'
    },
    subtitle: {
      text: '',
      align: 'left'
    },
    labels: graphData.categories,
    xaxis: {
      labels: {
        show: false
      }
    },

    yaxis: {
      opposite: false,
      labels: {
        show: false
      }
    },

    title: {
      text: percent,
      align: 'right',
      style: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: color
      }
    },
    subtitle: {
      text: graphData.metric + "  ",
      align: 'left',
      style: {
        fontSize: '10px',
      }
    },
    legend: {
      horizontalAlign: 'left'
    }
  };

  const [dropdownOpen, setOpen] = useState(false);
  const toSentenceCase = camelCase => {
    if (camelCase) {
        const result = camelCase.replace(/([A-Z])/g, ' $1');
        return result[0].toUpperCase() + result.substring(1).toLowerCase();
    }
    return '';
 };

  return (
    <div className='mediaKPI cardSpace'>
      <p>{toSentenceCase(graphData.metric)}</p>
      <p className='kpiValue'>{(graphData.data && graphData.data.reduce((partialSum, a) => partialSum + a, 0))?.toFixed(0)}</p>

    </div>
    //   <Card style={{ border: 'none', marginTop: '10px', marginBottom: '10px', boxShadow: '0 4px 24px 0 rgb(34 41 47 / 31%)', height: '18rem' }}>
    //
    //     <CardBody >  <div style={{ marginBottom: '10px' }}>
    //
    //       {/* hi */}
    //       {/* {JSON.stringify(graphData)} */}
    //       <h1 style={{ float: 'left' }}>  {(graphData.data && graphData.data[graphData.data.length - 1])?.toFixed(0)}</h1>
    //       <ButtonDropdown toggle={() => { setOpen(!dropdownOpen) }}
    //         isOpen={dropdownOpen} style={{ float: 'right' }}>
    //         <DropdownToggle className="bg-transparent" style={{ border: 'none' }} >
    //
    //           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.479 2l-7.479 12.543v5.924l-1-.6v-5.324l-7.479-12.543h15.958zm3.521-2h-23l9 15.094v5.906l5 3v-8.906l9-15.094z" /></svg>
    //         </DropdownToggle>
    //         <DropdownMenu>
    //           <DropdownItem header>Filters
    //           </DropdownItem>
    //           {props.filters.map((filter) => {
    //             return (
    //               <DropdownItem onClick={() => { setMetricF(filter) }}>{filter}</DropdownItem>
    //             )
    //           })
    //           }
    //
    //         </DropdownMenu>
    //       </ButtonDropdown>
    //     </div>
    //
    //       <Chart options={options} series={series} type='area' height={200} width={220} />
    //     </CardBody>
    //   </Card>
  )
}
export default SmallGraphs
