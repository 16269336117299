import React, { useState, useEffect, useRef } from "react";
import "../Analytics/FbAnalytics/FbAnalytics.css";
import axios from "axios";
// import Reactwordcloud from 'react-wordcloud';
import ReactionChart from "./ReacttionChart";
import { CardMedia, CardContent, Typography, CardActions, Button } from "@mui/material";
import {AiOutlineLike,AiOutlineComment, AiOutlineShareAlt} from 'react-icons/ai'


import ApexAreaChartsFacebookPage from "./Charts/ApexAreaChartsFacebookPage";
import ApexDonutChart from "./Charts/ApexDonutChart";
import ApexLineChart from "./Charts/ApexLineChart";
import FacebookPageLineChart from "./Charts/FacebookPageLineChart";
import Posts from "./Charts/posts/Posts";
// import "flatpickr/dist/themes/light.css";
// import Flatpickr from 'react-flatpickr';
import ApexAreaChartsFacebookPost from "./Charts/ApexAreaChartsFacebookPost";
import CustomDropDown from "../../../Components/CustomDropDown/CustomDropDown";
import "../../../Components/CustomDropDown/CustomDropDown.css";
import { set } from "react-ga";
import { Card, CardBody, CardFooter } from "reactstrap";
import FacebookPostAnalyticsModal from "./FacebookPostAnalyticsModal";
// import ImpressionChart from "./ImpressionChart";
import ImpressionChart from "./impressionChart";
import InstagramPostAnalyticsModal from "./InstagramPOstAnalyticsModal";
import InstagramAccountChart1 from "./InstagramAccountChart1";
import InstagramAccountChart2 from "./InstagramAccountChart2";
import InstagramAccountChart3 from "./InstagramAccountChart3";
import { BsInstagram } from "react-icons/bs";
const InstagramAnalytics = (props) => {
  console.log(props);

  const [pageData, setPageData] = useState(
    (props?.pagesDetails.pagesDetails?.filter(
      (item) => item.account_type === "instagram"
    ))[0]
  );


 



 

  const [AllPages, setAllPages] = useState(props.pagesDetails);
  const [IsPageSelected, setIsPageSelected] = useState(false);
  const [SelectedPage, setSelectedPage] = useState("Select Page");
  const [networkPages, setNetworkPages] = useState([]);
  const [allPosts, setAllPosts] = useState([]);

  useEffect(() => {
    setAllPages(props.pagesDetails);
  }, [props.pagesDetails]);



  useEffect(() => {
    const Networkpages = [];
    props.pagesDetails.pagesDetails?.map((item) => {
      if (item.account_type === "instagram") {
        Networkpages.push(item.name);
      }
    });
    setSelectedPage(Networkpages[0]);
    console.log(props.pagesDetails.pagesDetails);
    setNetworkPages(Networkpages);
  }, [props.pagesDetails]);

  useEffect(() => {
    console.log(SelectedPage);
    setPageData(
      props.pagesDetails.pagesDetails.filter(
        (item) => item.name === SelectedPage
      )[0]
    );
  }, [SelectedPage]);


  const options = {
    rotations: 2,
    rotationAngles: [-90, 0],
    fontFamily: 'Arial',
    fontSizes: [20, 60], // Set the range of font sizes for the words
 
  };
  const size = [1000, 400];

  const words = []

const [wordCloudWords,setWordCloudWords]=useState(words)
const [reactionData,setReactionData]=useState([])
const [headData,setHeadData]=useState([])
const wordcloudData = async()=>{

await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/deepAnalytics/instagramWordCloudData`,{
  pageId:pageData?.pageId,
  startDate:props?.startDate?.pindate,
  endDate:props?.endDate?.pindate,
  jwToken: sessionStorage?.getItem("token"),
}).then((res)=>{
  console.log(res.data);
  setWordCloudWords(res.data);
  return res.data;
}).catch((err)=>{
  console.log(err);
})




}



// const [Most_positive_and_negative_post,setMost_positive_and_negative_post]=useState([])
const [isPostAnalytics,setIsPostAnalytics]=useState(false)
const [selectedPostAnalyticsId,setSelectedPostAnalyticsId]=useState("")
const [id,setId]=useState("")
const [pageAnalytics,setPageAnalytics]=useState()
const [impressionChartData,setImpressionChartData]=useState([])


const getAccountAnanlytics=async()=>{
  await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/deepAnalytics/instagramAccountAnalytics`,{
    pageId:pageData?.pageId,
    startDate:props?.startDate?.pindate,
    endDate:props?.endDate?.pindate,
    jwToken: sessionStorage?.getItem("token"),
  }).then((res)=>{
    console.log(res.data);
    setPageAnalytics(res.data);
    return res.data;
  }
  ).catch((err)=>{
    console.log(err);
  }
  )
}



const getAllPosts=async()=>{
  await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/deepAnalytics/instagramAllPosts`,{
    pageId:pageData?.pageId,
    startDate:props?.startDate?.pindate,
    endDate:props?.endDate?.pindate,
    jwToken: sessionStorage?.getItem("token"),
  }).then((res)=>{
    console.log(res.data);
    setAllPosts(res.data);
    return res.data;
  }
  ).catch((err)=>{
    console.log(err);
  }
  )
}




const [isWordCloudData,setIsWordCloudData]=useState(false)
const [isAccountAnalytics,setIsAccountAnalytics]=useState(false)
const [isAllPosts,setIsAllPosts]=useState(false)







useEffect(() => {
  pageData?.pageId && isWordCloudData && wordcloudData()
  pageData?.pageId && isAccountAnalytics && getAccountAnanlytics()
 pageData?.pageId && isAccountAnalytics && getAllPosts()
  // console.log(pageData)
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.startDate,props.endDate,pageData?.pageId,isWordCloudData,isAccountAnalytics,isAllPosts])




const openPostAnalyticsModal=(id)=>{
  setIsPostAnalytics(true)
  setSelectedPostAnalyticsId(id)
  // setId(id)
  console.log(id)
}

const handleChangeVisibility=(type)=>{
  if(type==="wordCloud"){
    setIsWordCloudData(true)
    setIsAccountAnalytics(false)
    setIsAllPosts(false)
  }
  else if(type==="accountAnalytics"){
    setIsAccountAnalytics(true)
    setIsWordCloudData(false)
    setIsAllPosts(false)
  }
  else if(type==="allPosts"){
    setIsAllPosts(true)
    setIsAccountAnalytics(false)
    setIsWordCloudData(false)
  }

}



  return (

    
    <div className="fbAnalyticBlock">




            <InstagramPostAnalyticsModal SelectedPageId={pageData?.pageId}  selectedPostAnalyticsId={selectedPostAnalyticsId}  isPostAnalytics={isPostAnalytics} setIsPostAnalytics={setIsPostAnalytics}  />

     
      {/* </div> */}
      <div className="mediaFilterBlock">
        <p><BsInstagram />  {SelectedPage}</p>
        {/* <CustomDropDown
          isActive={IsPageSelected}
          setIsActive={setIsPageSelected}
          selected={SelectedPage}
          setIsSelected={setSelectedPage}
          list={networkPages}
          type="mediaFilter"
        /> */}
      </div>




    <div style={{display:'flex',width:'100%', height:'auto', marginBottom:'10px', flexWrap:'wrap'}}>


    {
      pageAnalytics ?  pageAnalytics[0]?.map((item)=>{
        return(
            <div className="tiles__" >
          <p>{item.name}</p>
          <h2 className="val_ues">{item.value}</h2>
          </div> 
        )
      }
      ) : (
        <div className="click_notify_wrp" 
        onClick={() => handleChangeVisibility("accountAnalytics")}

      >
  <p>Stay updated with your audience! <br/>Click here to view your Instagram Posts,
              Engaged Users, Impressions, Total Users, Location wise Traffic and other engagement metrics.</p>
              <h2>Click to View</h2>
      </div>
      
      )
    }
    
    
    </div>


    





      {/* <div className="mediaFilterBlock" style={{marginTop:'40px'}}>

<p> Most Frequent words </p>
</div>


<Card className="fbAnalyticsCard">

<div style={{ width: "100%", height: "100%", display:'flex', flexDirection:'row',justifyContent:'center', alignItems:'center', flexWrap:'wrap' }}>
      <ReactWordcloud words={wordCloudWords} options={options} size={size}  />
</div>

</Card> */}



<div className="mediaFilterBlock" style={{marginTop:'30px'}}>

<p> Impression over Time </p>
</div>
{
  pageAnalytics ? (
    <Card className="fbAnalyticsCard" style={{marginTop:'20px', padding:'30px'}}>



    <InstagramAccountChart1 data={pageAnalytics &&  pageAnalytics[1]} />
  
      </Card>
  ) : (
    <div className="click_notify_wrp" 
      onClick={() => handleChangeVisibility("accountAnalytics")} 
    >

    <p>Stay updated with your audience! <br/>Click here to view your Instagram insights, 
                Impressions, Total Users and other engagement metrics.</p>
                <h2>Click to View</h2> 
    </div>

  )

}
 


    <div className="mediaFilterBlock" style={{marginTop:'30px'}}>

<p> Reach over Time </p>
</div>
{
  pageAnalytics ? (
    <Card className="fbAnalyticsCard" style={{marginTop:'20px', padding:'30px'}}>



    <InstagramAccountChart2 data={pageAnalytics && pageAnalytics[1]} />
  
      </Card>
  ) : (
    <div className="click_notify_wrp" 
      onClick={() => handleChangeVisibility("accountAnalytics")}
      
    >
      
       <p>Stay updated with your audience! <br/>Click here to view your Instagram Reach over time and other engagement metrics.</p>
    <h2>Click to View</h2> 
    </div>

  )
}
 


    <div className="mediaFilterBlock" style={{marginTop:'30px'}}>

<p>Profile views over Time</p>
</div>

{
  pageAnalytics ? (
    <Card className="fbAnalyticsCard" style={{marginTop:'20px', padding:'30px'}}>



    <InstagramAccountChart3 data={pageAnalytics && pageAnalytics[1]} />
  
      </Card>
  ) : (

    <div className="click_notify_wrp" 
      onClick={() => handleChangeVisibility("accountAnalytics")}
     >
      <p>Stay updated with your audience! <br/>Click here to view your Instagram profile views over time 
      and other engagement metrics.</p>
    <h2>Click to View</h2> 
    </div>







  )
} 
<div className="mediaFilterBlock" style={{marginTop:'30px'}}>

<p> Your Posts </p>
</div>

{
  isAllPosts ? (
    <Card className="fbAnalyticsCard" style={{marginTop:'20px', padding:'30px', display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
    {  allPosts.length > 1 &&                               
      allPosts?.map((item)=>{
    return(
      <Card sx={{ maxWidth: 345 }} style={{width:'250px', margin:'10px'}}>
      <CardMedia
            sx={{ height: 150 }}
            image={item?.media_url}
            title="green iguana"
          />
          <CardContent>
            {/* <Typography gutterBottom variant="h5" component="div">
              {item?.message?.slice(0,20)}
            </Typography>
           */}
          </CardContent>
    
          <CardFooter style={{display:'flex', 'justifyContent':'space-between'}}>
            <Button size="large"><AiOutlineLike /> {item?.like_count|| 0} </Button>
            <Button size="large"><AiOutlineComment /> {item?.comments_count || 0} </Button>
            <Button size="large"><AiOutlineShareAlt /> {item?.shares?.summary?.total_count || 0} </Button>
    
            </CardFooter>
          <CardActions>
            <Button size="small"   href={item.permalink} target='_blank'>View Post</Button>
            <Button size="small" onClick={()=>openPostAnalyticsModal(item?.id)}>See analytics</Button>
          </CardActions>
    
      </Card>
    )
    
    
    
      })
    }
    
    </Card>
  ) : (
    <div className="click_notify_wrp" 
      onClick={() => handleChangeVisibility("allPosts")}
      
    > 
    <p>Stay updated with your audience! <br/>Click here to view your Instagram Posts
    and other engagement metrics.</p>
  <h2>Click to View</h2> 
    </div>

  
  )
}


   



  
    </div>
  );
};

export default InstagramAnalytics;
