import axios from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

const baseURL = `${process.env.REACT_APP_SERVER_URI}/api/`;
const generatePasswordResetToken = "forgetPassword/genToken";
const ResetPassword = "forgetPassword";
const signUpPath = "users/register";
const loginPath = "users/signin";
const updateUserData = "users";
const DeleteAccount = "users/DeleteAccount";
const updateUserActivity = "users/updateActivity";
const questionsDetailsPath = "questions";
const packageDetailspath = "packages";
const getPagesDetails = "crud/getPagesDetail";
const saveConnectedAccount = "users/saveConnectedAccounts";
const savePagesDetails = "crud/pages";
const FacebookText = "crud/FaceBookText";
const facebookScheduleMessage = "crud/publishSheduledMessage";
const facebookScheduleVideo = "crud/ScheduleFacebookVideo";
const facebookPostImage = "crud/FaceBookImage";
const facebookAllPosts = "crud/facebookAllPosts";
const facebookAllCommentsForPost = "crud/facebookAllCommentsForPost";
const facebookPostCommentReply = "crud/facebookPostCommentReply";
// const FaceBookImage = "http://localhost:8080/api/crud/FaceBookImage";
const draftPost = "crud/postDrafts";
const draftSchedule = "crud/ScheduleDrafts";
const createPins = "crud/createPin";

const createBoards = "crud/createBoard";
const schedulePosts = "crud/schedulePost";
const upload_image = "crud/upload_image";

//----------------user data--------------------
const updateProfile = "users/updateProfile";
const upload_Profileimage = "crud/upload";
const getAll_Users = "users/getUsers";

//---------------------------------------------

//----------------emailValidation--------------------
const get_verifiedEmail = "emailValidation/uploadEmailList";
const get_verifiedEmailsList = "emailValidation/multipleEmail";
const get_UserEmailsList = "emailValidation/getEmailList";
const createNewList = "emailValidation/createNewEmailList";
const addToEmaiNew = "emailValidation/addToEmailList";
const deleteEmail = "emailValidation/removeEmail";

//---------------------------------------------

//------------------------------LINKED IN---------------------------------------------------
const linkedinMessage = "crud/messageLinkedIn";
const linkedinimage = "crud/linkedInImage";
const linkedinVideo = "crud/linkedInVideo"
const ScheduleLinkedinVideo = "crud/scheduleLinkedinVideo"

const linkedInImageSchedule = "crud/scheduleLinkedInImage";
const linkedInTextSchedule = "crud/scheduleLinkedInText";
const linkedInRefreshToken = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&scope=r_liteprofile%20r_emailaddress%20w_member_social&state=123456&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}/linkedin`;
const instagram_schedule = "crud/insta_post_schedule";

//---------------------------++ LINKED IN ++-----------------------------------------------
//----------------------------Medium--------------------------------

const medium = "crud/postMedium";

// <------------------------------ Youtube ------------------------------------------------>

const youtubePostVideo = "crud/uploadVideo";
const youtubeSchedulePostVideo = "crud/schedule_Youtube_Video";

const getAnalytic = "crud/getAnalytics";

// <------------------------------ Youtube ------------------------------------------------>

const scheduleBoards = "crud/scheduleBoard";
const schedulePins = "crud/schedulePin";

//---------------------------TWITTER-------------------------------------------------------
const twitterMessage = "crud/twitterText";
const twitterImage = "crud/twitterImage";
const twitterVideo = "crud/twitterVideo"
const scheduleTwitterText = "crud/scheduleTwitterText";
const scheduleTwitterImage = "crud/scheduleTwitterImage";
const scheduleTwitterVideo = "crud/schedule_Twitter_Video";
const twitterAllTweets = "crud/twitterAllTweets";
const twitterAllComments = "crud/twitterAllComments";
const twitterReplyToComment = "crud/twitterReplyToComment";
const twitterMoreTweetResults = "crud/twitterMoreTweetResults";
//-------------------------++twitter++----------------------------------------------------
export const signUpApi = (variables) => {
  return axios.post(baseURL + signUpPath, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// <--------------------------------------Schedule API's ----------------------------------------------->

const getPost = "crud/getSchedulePosts";

// <--------------------------------------Schedule API's ----------------------------------------------->

// <-------------------------------------Admin Question Module ---------------------------------->

export const DeteteUser = (variables) => {
  return axios.post(baseURL + DeleteAccount, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// <-------------------------------------Admin Question Module ---------------------------------->

export const loginApi = (variables) => {
  return axios.post(baseURL + loginPath, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    // withCredentials: true,
  })
};

export const CheckAllConnectedPagesHealth = (variables) => {
  return axios.post(baseURL + 'crud/CheckAllConnectedPagesHealth', variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    // withCredentials: true,
  })
};

export const getQuestionsApi = () => {
  return axios.get(baseURL + questionsDetailsPath);
};

export const deleteQuestionsAPI = (id) => {
  return axios.delete(baseURL + questionsDetailsPath, {
    data: {
      id,
    },
  });
};

export const createQuestion = (data) => {
  return axios.post(baseURL + questionsDetailsPath, {
    answerOptions: data.answerOptions,
    questionText: data.questionText,
    questionType: data.questionType,
  });
};

export const updateQuestion = (id, data) => {
  return axios.put(baseURL + questionsDetailsPath, {
    id: id,
    updateQuery: data,
  });
};

//------------------------------------------ package api------------------------------------//
export const getPackageApi = () => {
  return axios.get(baseURL + packageDetailspath);
};

export const deletePackageAPI = (id) => {
  return axios.delete(baseURL + packageDetailspath, {
    data: {
      id,
    },
  });
};

export const createPackages = (data) => {
  console.log(data);
  return axios.post(baseURL + packageDetailspath, {
    packageText: data.packageText,
    packageDays: data.packageDays,
    packageAmount: data.packageAmount,
    packagePlanId: data.packagePlanId,
  });
};

export const updatePackages = (id, data) => {
  console.log(id);
  console.log(data);
  return axios.put(baseURL + packageDetailspath, {
    id: id,
    updateQuery: data,
  });
};

//------------------------------------------------------------------------------//

export const getPageDetailsApi = (params) => {
  console.log(params);
  return axios.post(
    baseURL + getPagesDetails,
    {
      jwToken: params.jwToken,
    },
    {
      headers: {
        "Content-Type": "application/json",
        token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
        userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
      },
    }
  );
};

export const saveACcountsLinked = (variables) => {
  return axios.post(baseURL + saveConnectedAccount, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const savePageDetails = (variables) => {
  return axios.post(baseURL + savePagesDetails, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
      userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
    },
  });
};

export const facebookScheduleMessages = (variables) => {
  return axios.post(baseURL + facebookScheduleMessage, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const facebookScheduleVideos = (variables) => {
  return axios.post(baseURL + facebookScheduleVideo, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => {
    toast.success("Schedule Successfully on Facebook");
  }).catch((err) => {
    // console.log(err + '_____________________________________---------------------------------------------------------------------------');
    toast.error(err?.response?.data?.slice(0, 25))
  });
};

export const FaceBookTextPost = (variables) => {
  return axios.post(baseURL + FacebookText, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => {
    toast.success("Post Successfully on Facebook");
  }).catch((err) => {
    // console.log(err + '_____________________________________---------------------------------------------------------------------------');
    toast.error(err?.response?.data?.slice(0, 25))
  });
};

export const FaceBookImagePost = (variables) => {
  return axios.post(baseURL + facebookPostImage, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => {
    toast.success("Post Successfully on Facebook");
  }).catch((err) => {
    // console.log(err + '_____________________________________---------------------------------------------------------------------------');
    toast.error(err?.response?.data?.slice(0, 25))
  });
};

export const FacebookVideoPost = (variables)=>{
  return axios.post(baseURL + "crud/FacebookVideo", variables, {
    headers:{
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => {
    toast.success("Post Successfully on Facebook");
  }).catch((err) => {
    // console.log(err + '_____________________________________---------------------------------------------------------------------------');
    toast.error(err?.response?.data?.slice(0, 25))
  });
}

export const FacebookAllPosts = (variables) => {
  // console.log(variables);
  return axios.post(baseURL + facebookAllPosts, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
      userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
    },
  });
};

export const FacebookAllCommentsForPost = (variables) => {
  return axios.post(baseURL + facebookAllCommentsForPost, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
      userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
    },
  });
};

export const FacebookPostCommentReply = (variables) => {
  return axios.post(baseURL + facebookPostCommentReply, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
    },
  });
};

export const schedulePost = (variables) => {
  return axios.post(baseURL + schedulePosts, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const getPhotoUrl = (variables) => {
  return axios.post(baseURL + upload_image, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// export const postDrafts = (variables) => {
//   return axios.post(draftPost, variables, {
//     headers:{
//       Accept: "application/json",
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": "*",
//     },
//   });
// };

export const postDrafts = (variables) => {
  return axios.post(baseURL + draftPost, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const scheduleDrafts = (variables) => {
  return axios.post(baseURL + draftSchedule, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

//========================================================LINKED IN====================================================
export const postLinkedInMessage = (variables) => {
  return axios.post(baseURL + linkedinMessage, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => {
    toast.success("Post Successfully on Linkedin");
  }).catch((err) => {
    // console.log(err + '_____________________________________---------------------------------------------------------------------------');
    toast.error(err?.response?.data?.slice(0, 25))
  });
};

export const postLinkedInImage = (variables) => {
  return axios.post(baseURL + linkedinimage, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => {
    toast.success("Post Successfully on Linkedin");
  }).catch((err) => {
    // console.log(err + '_____________________________________---------------------------------------------------------------------------');
    toast.error(err?.response?.data?.slice(0, 25))
  });
};
export const postLinkedInVideo = (variables) => {
  return axios.post(baseURL + linkedinVideo, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => {
    toast.success("Post Successfully on Linkedin");
  }).catch((err) => {
    // console.log(err + '_____________________________________---------------------------------------------------------------------------');
    toast.error(err?.response?.data?.slice(0, 25))
  });
};

export const LinkedinScheduleVideo = (variables) => {
  return axios.post(baseURL + ScheduleLinkedinVideo, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => {
    toast.success("Schedule Successfully on Linkedin");
  }).catch((err) => {
    // console.log(err + '_____________________________________---------------------------------------------------------------------------');
    toast.error(err?.response?.data?.slice(0, 25))
  });
};

export const linkedInImageSchedules = (variables) => {
  return axios.post(baseURL + linkedInImageSchedule, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => {
    toast.success("Schedule Successfully on Linkedin");
  }).catch((err) => {
    // console.log(err + '_____________________________________---------------------------------------------------------------------------');
    toast.error(err?.response?.data?.slice(0, 25))
  });
};

export const linkedInTextSchedules = (variables) => {
  return axios.post(baseURL + linkedInTextSchedule, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const linkedInRefreshTokens = () => {
  return axios.get(baseURL + linkedInRefreshToken, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
  });
};

//========================================================LINKED IN====================================================

//========================================================TWITTER=======================================================
export const postTwitterMessage = (variables) => {
  return axios.post(baseURL + twitterMessage, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => {
    toast.success("Post Successfully on Twitter");
  }).catch((err) => {
    // console.log(err + '_____________________________________---------------------------------------------------------------------------');
    toast.error(err?.response?.data?.slice(0, 25))
  });
};

export const postTwitterImage = (variables) => {
  return axios.post(baseURL + twitterImage, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => {
    toast.success("Post Successfully on Twitter");
  }).catch((err) => {
    console.log(err?.response?.data + '_____________________________________---------------------------------------------------------------------------');
    toast.error(err?.response?.data?.slice(0,25))
  });
};

export const postTwitterVideo = (variables) => {
  return axios.post(baseURL + twitterVideo, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => {
    toast.success("Post Successfully on Twitter");
  }).catch((err) => {
    // console.log(err + '_____________________________________---------------------------------------------------------------------------');
    toast.error(err?.response?.data?.slice(0, 25))
  });
};




export const scheduleTwitterTexts = (variables) => {
  return axios.post(baseURL + scheduleTwitterText, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => {
    toast.success("Schedule Successfully on Twitter");
  }).catch((err) => {
    // console.log(err + '_____________________________________---------------------------------------------------------------------------');
    toast.error(err?.response?.data?.slice(0, 25))
  });
};

export const scheduleTwitterImages = (variables) => {
  return axios.post(baseURL + scheduleTwitterImage, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => {
    toast.success("Schedule Successfully on Twitter");
  }).catch((err) => {
    // console.log(err + '_____________________________________---------------------------------------------------------------------------');
    toast.error(err?.response?.data?.slice(0, 25))
  });
};
export const scheduleTwiterVideo = (variables) => {
  return axios.post(baseURL + scheduleTwitterVideo, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => {
    toast.success("Schedule Successfully on Twitter");
  }).catch((err) => {
    // console.log(err + '_____________________________________---------------------------------------------------------------------------');
    toast.error(err?.response?.data?.slice(0, 25))
  });
};




export const TwitterAllTweets = (variables) => {
  return axios.post(baseURL + twitterAllTweets, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
      userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
    },
  });
};

export const TwitterAllComments = (variables) => {
  return axios.post(baseURL + twitterAllComments, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const TwitterReplyToComment = (variables) => {
  return axios.post(baseURL + twitterReplyToComment, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const TwitterMoreTweetResults = (variables) => {
  return axios.post(baseURL + twitterMoreTweetResults, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

//========================================================TWITTER=========================================================

export const instaPost = (variables) => {
  return axios.post(`${baseURL}crud/insta_post`, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => {
    toast.success("Post Successfully on instagram");
  }).catch((err) => {
    console.log(err + '_____________________________________---------------------------------------------------------------------------');
    toast.error(err?.response?.data?.slice(0, 25))
  });
};

export const instaReelPost = (variables) => {
  return axios.post(`${baseURL}crud/insta_reel`, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => {
    toast.success("Post Successfully on instagram");
  }).catch((err) => {
    // console.log(err + '_____________________________________---------------------------------------------------------------------------');
    toast.error(err?.response?.data?.slice(0, 25))
  });
};

//=========================================================Medium================================================================

export const postMedium = (variables) => {
  return axios.post(baseURL + medium, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// <-------------------------------------------------------Pinterest------------------------------------------------------------------>
export const createPin = (variables) => {
  return axios.post(baseURL + createPins, variables);
};

export const createImagePin = (variables) => {
  return axios.post(baseURL + 'crud/Imagepin', variables);
};

export const ScheduleImagePin = (variables) => {
  return axios.post(baseURL + 'crud/scheduleImagePin', variables);
}


export const createBoard = (variables) => {
  return axios.post(baseURL + createBoards, variables);
};

export const scheduleBoard = (variables) => {
  return axios.post(baseURL + scheduleBoards, variables);
};

export const schedulePin = (variables) => {
  return axios.post(baseURL + schedulePins, variables);
};

// <-------------------------------------- Youtube ------------------------------------------>
export const youtubePostVideos = (variables) => {
  return axios.post(baseURL + youtubePostVideo, variables);
};

export const youtubeSchedulePostVideos = (variables) => {
  return axios.post(baseURL + youtubeSchedulePostVideo, variables);
};

export const getSchedulePosts = () => {
  return axios.post(baseURL + getPost, {
    jwToken: sessionStorage.getItem("token"),
  });
};

export const getAnalytics = (params) => {
  return axios.post(baseURL + getAnalytic, params, {
    headers: {
      token: sessionStorage.getItem("token"),
    },
  });
};

// <-------------------------------------------------------Instagram Schedule ---------------------------------------------->

export const instagramScheduleAPI = (params) => {
  return axios.post(baseURL + instagram_schedule, params);
};

// Auth
const isLogged = "users/isLoggedIn";
export const isLoggedIn = (params) => {
  return axios.post(baseURL + isLogged, params);
};

// <-------------------------------------------------------User Profile API ---------------------------------------------->

export const getAllUserApi = () => {
  return axios.get(baseURL + getAll_Users);
};

export const getUserProfileInfo = (id) => {
  const userProfileInfo = `users/${id}`;
  return axios.get(baseURL + userProfileInfo, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
  });
};

export const updateUserProfileInfo = (info) => {
  return axios.put(baseURL + updateProfile, info, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const getProfileUrl = (variables) => {
  return axios.post(baseURL + upload_Profileimage, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const getverifiedEmail = (variables) => {
  return axios.post(baseURL + get_verifiedEmail, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const getverifiedEmailsList = (variables) => {
  return axios.post(baseURL + get_verifiedEmailsList, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const getUserEmailsList = (variables) => {
  return axios.post(baseURL + get_UserEmailsList, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const createNewListAPI = (variables) => {
  return axios.post(baseURL + createNewList, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const addeNewEmailAPI = (variables) => {
  return axios.post(baseURL + addToEmaiNew, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const DeleteEmail = (variables) => {
  return axios.put(baseURL + deleteEmail, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const getPaymentInfoAPI = (variables) => {
  return axios.post(baseURL + "paymentInfo", variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const getPaymentsListAPI = (variables) => {
  return axios.post(baseURL + "getOrders", variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const updateActivity = (variables) => {
  return axios.post(baseURL + updateUserActivity, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const updateUser = (_id, data) => {
  return axios.post(
    baseURL + updateUserData,
    {
      _id,
      userData: data,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
};

export const GeneratePasswordResetToken = (variables) => {
  return axios.post(baseURL + generatePasswordResetToken, variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const ResetForgottenPassword = (variables) => {
  return axios.post(
    baseURL + ResetPassword,
    {
      password: Object.values(variables.password)[0],
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${variables.token}`,
      },
    }
  );
};




//-------------------------------------------------------------
export const Facebook_Image_Schedule = (variables) => {
  return axios.post(baseURL + 'crud/Facebook_Image_Schedule', variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const Linkedin_Image_Schedule = (variables) => {
  return axios.post(baseURL + 'crud/schedule_LinkedIn_Image', variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const Linkedin_Video_Schedule = (variables) => {
  return axios.post(baseURL + 'crud/schedule_LinkedIn_Video', variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const Twitter_Image_Schedule = (variables) => {
  return axios.post(baseURL + 'crud/schedule_Twitter_Image', variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const Instagram_Image_Schedule = (variables) => {
  return axios.post(baseURL + 'crud/Schedule_instagram_image', variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};


export const Instagram_Video_Schedule = (variables) => {
  return axios.post(baseURL + 'crud/schedule_Instagram_Video', variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};


export const GETALLAGENDAPOSTS = (variables) => {
  return axios.post(baseURL + 'crud/GetALLScheduledPosts', variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const DELETEAGENDAPOSTS = (variables) => {
  return axios.post(baseURL + 'crud/deleteScheduledAgendaPosts', variables, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};





//-------------------------------------------------------------












export const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        const response = await axios.get(url, { cancelToken: source.token });
        setData(response.data);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request cancelled", error.message);
        } else {
          setError(error);
          setLoading(false);
        }
      }
    };
    fetchData();
    return () => {
      source.cancel();
    };
  }, [url]);

  return { data, loading, error };
};




export const PageIdToName = (id) => {
  

  const data = axios.post(baseURL + 'crud/PageIdToName', {pageId:id, jwToken:  localStorage.getItem("token")}, {
    headers: {
      "Content-Type": "application/json",
      "jwToken": localStorage.getItem("token"),
      },    
      })
      .then((res) => {
        return res.data;
      }
      )



}   



export const getSentimentAnalysis = async (text) => {
  const response = await axios.post('https://api.openai.com/v1/engines/davinci-codex/completions', {
    prompt: text,
    max_tokens: 60,
  }, {
    headers: {
      'Authorization': `Bearer ${process.env.OPENAI_API_KEY}`,
      'Content-Type': 'application/json'
    }
  });

  return response.data;
};


export const instagramStoryAPI = async (params) => {
  const response = await axios.post(baseURL + 'crud/instaStory', params);
  return response.data;
}

export const facebookReelAPI = async (params) => {
  const response = await axios.post(baseURL + 'crud/Facebook_Reel', params);
  return response.data;
}

export const facebookScheduleReelAPI = async (params) => {
  const response = await axios.post(baseURL + 'crud/schedule_Facebook_Reel', params);
  return response.data;
}

