
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

                







export default function ImpressionChart(props) {
    let structuredData = props?.data[0]?.values?.map((data, index) => ({
        dateData: data.end_time.slice(0, 10),
        page_views_total: props?.data[0]?.values[index]?.value,
        page_impressions: props?.data[1]?.values[index]?.value,
        page_consumptions: props?.data[2]?.values[index]?.value,
        page_engaged_users: props?.data[3]?.values[index]?.value
      }));
    
      console.log(structuredData)

    // dateData: item.values[0].end_time.slice(0, 10),
    //         page_views_total: item.values[0].value,
    //         page_impressions: item.values[1].value,
    //         page_consumptions: item.values[2].value,
    //         page_engaged_users: item.values[3].value,
    console.log(structuredData)

    return(
        <div>
            <LineChart width={1200} height={400} data={structuredData} margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
                {/* <CartesianGrid strokeDasharray="" aria-hidden={true} /> */}
                <XAxis dataKey="dateData" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="page_views_total" name="Page views" stroke="#8884d8"  strokeWidth={3}  width={3}/>
                <Line type="monotone" dataKey="page_impressions" name="page Impressions" stroke="#82ca9d"   strokeWidth={3}/>
                <Line type="monotone" dataKey="page_consumptions" name="Page Consumptions" stroke="#ffc658"  strokeWidth={3} />
                <Line type="monotone" dataKey="page_engaged_users" name="Users engaged" stroke="#00ff00" strokeWidth={3} />
            </LineChart>

        </div>  )

}