import React, { useState, useEffect } from "react";
import FormInput from "../../Components/FormInput/FormInput";
import { useNavigate } from "react-router-dom";
import "./SignUp.css";
import warning from "../../assets/warning.png";
import Logo from "../../../src/assets/logo1.png";
import Digi from "../../../src/assets/digi.png";
import login_left_img from "../../../src/assets/login_left_img.png";
import TagManager from 'react-gtm-module'

import { ChevronRight } from "react-feather";
import axios from "axios";
import { toast } from "react-toastify";



// const gg = TagManager.initialize({ gtmId: 'GTM-TLZ8D53' })

// console.log(gg, 'hjhvhuvuvuvuvujv')

 function SignUp({ signUpState, setSignUpState }) {
  const [fNameError, setFNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [validationError, setValidationError] = useState("");

  let navigate = useNavigate();

  const handleClickRedirectToLogin = () => {
    navigate("/login");
  };

  const handleClickSignUp = async() => {
    console.log('singupppppppppppp')


    // check if user already exists

  

     

      console.log(window.dataLayer, 'window.dataLayer')

    if (signUpState.fName.length === 0) {

  


      setFNameError("Please enter Full Name");
      return;
    }

    if (signUpState.email.length === 0) {
      setEmailError("Please enter Email");
      return;
    }

    if (signUpState.password.length === 0) {
      setPasswordError("Please enter Password");
      return;
    }


    let userExists = false;
    await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/users/IsEmailExist`,{
       email: signUpState.email
     }).then((res) => {
       console.log(res, 'res')
       if(res.data.status === true){
         userExists = true;
         setEmailError(" User already exists");
         toast.error("User already exists");
         return;
 
       } else {
        if (emailError == "" && fNameError == "" && passwordError == "") {
          navigate("/setup-account");
        }
       }
     }).catch((err) => {
       console.log(err, 'err')
     });


 
  };



  // window.dataLayer.push({'event': 'signup'});





  return (
    <div>
      <div className="mainLayout">
        <div className="layout1">
          <div className="leftLayout">
            <img src={Logo} alt="logo" />
            <p className="regular text">
              Get one stop solution for all
              <br />
              your <span>Digital Marketing</span> needs
            </p>
          </div>
          <div>
            <img src={login_left_img} alt="login" className="img3" />
          </div>
        </div>
        <div className="layout2">
          <section className="mainBlock">
            <div className="boldText">
              <h3>Create your toowe account</h3>
              <h6 className="mb-5">Get Started using below details</h6>

              <div className="field">
                <p className="regular">Full Name</p>
                <FormInput
                  name="fName"
                  setState={setSignUpState}
                  setError={setFNameError}
                  setValidationError={setValidationError}
                />
              </div>
              {fNameError.length !== 0 && (
                <p className="errorcase">
                  {fNameError} <img src={warning} alt="error" />
                </p>
              )}
              <div className="field">
                <p className="regular">Email</p>
                <FormInput
                  inputType="email"
                  name="email"
                  setState={setSignUpState}
                  setError={setEmailError}
                  setValidationError={setValidationError}
                />
              </div>
              {emailError.length !== 0 && (
                <p className="errorcase">
                  {emailError} <img src={warning} alt="error" />
                </p>
              )}

              <div className="field">
                <p className="regular">Password</p>
                <FormInput
                  inputType="password"
                  name="password"
                  setState={setSignUpState}
                  setError={setPasswordError}
                  setValidationError={setValidationError}
                />
              </div>

              {passwordError.length !== 0 && (
                <p className="errorcase">
                  {passwordError} <img src={warning} alt="error" />
                </p>
              )}

              <button
                id="signup"
                type="submit"
                className="signup-btn"
                onClick={()=>{  handleClickSignUp() }}
              >
                <text className="regular signuptxt">
                  CREATE ACCOUNT&nbsp;&nbsp;&nbsp;
                  <ChevronRight className="arrow" />
                </text>
              </button>
            </div>
            <p className="btmtext links_clr">
              Already have an account? <a href="login">Login</a> ,
              <br /><br />  By clicking 'CREATE ACCOUNT' You
              agree to our toowe's &nbsp;
              <a href="https://www.toowe.io/terms.html" target="_blank">
                Terms & Conditions
              </a>
              , 
               
              <a
                href="https://www.toowe.io/privacy-policy.html"
                target="_blank"
              >
                 &nbsp;Privacy Policy
              </a>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
