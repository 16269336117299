"use client";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./Redux/store";
import AppLogout from "./Components/AutoLogout/AutoLogout";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";


import { QueryClientProvider, QueryClient } from "react-query";
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));

const tagManagerArgs = {
  gtmId: "GTM-TLZ8D53",
  // gtmId:'GTM-TWJCVVK'
};

TagManager.initialize(tagManagerArgs);


Sentry.init({
  dsn: "https://bb7c5df954965d5b71728dd134d6e489@o4506431761219584.ingest.sentry.io/4506431762333696",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/app.toowe.io\.io/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});



// load the adobe express sdk and initialize it
// loadAdobeExpressSDK().then(() => {
//   initializeAdobeExpressSDK();
// });




root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <BrowserRouter>
        <AppLogout>
          <App />
        </AppLogout>
      </BrowserRouter>
    </Provider>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();