import React, { useState, useEffect } from "react";
import "./question.css";
import Select, { StylesConfig } from "react-select";
import Logout from "../../../assets/logoutIcon.png";
import Edit from "../../../assets/icons8-edit-64.png";
import Delete from "../../../assets/icons8-trash-30.png";
import RadioOption from "../../../Components/RadioOption/RadioOption";
import { getQuestionsApi, deleteQuestionsAPI, createQuestion, updateQuestion } from "../../../Utils/api";
import { useNavigate } from 'react-router-dom'

const AdminQuestion = () => {
  const navigate = useNavigate();
  const [questionType, setQuestionType] = useState("");
  const [question, setQuestion] = useState("");
  const [questionsRecord, setQuestionsRecord] = useState([]);
  const [editClicked, setEditClicked] = useState(false);
  const [beforeUpdateValue, setBeforeUpdateValue] = useState();
  const [qTypeId, setQTypeId] = useState("");
  const [questionValue, setQuestionValue] = useState("");
  const [questionTypeValue, setQuestionTypeValue] = useState("");

  const [radioOption, setRadioOption] = useState("");
  const [radioOptionRecord, setRadioOptionRecord] = useState([]);

  // console.log(qTypeId);
  // console.log(questionType);
  // console.log(question);

  const options = [
    { value: "TextBox", label: "TextBox", idx: 0 },
    { value: "Radio Type", label: "Radio Type", idx: 1 },
  ];

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#f7f8fa",
      borderRadius: "0px",
      border: "1px solid #919192",
    }),
    // input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles }),
  };

  const editHandle = (record, idx) => {
    setEditClicked(true);
    setBeforeUpdateValue({ record: record, idx: idx });
    setQuestionValue(record.question);
    setQuestionType(record.questionType === "Radio Type" || record.questionType === "Radio" ? "Radio Type" : "TextBox");
    setQTypeId(record.qTypeId)
    setQuestionTypeValue(record.questionType === "Radio Type" || record.questionType === "Radio" ? "Radio Type" : "Text Box");
    if (record.questionType === "Radio") {
      setRadioOptionRecord(record.answerOptions)
      console.log(record)
    } else {
      setRadioOptionRecord("")
    }
  };

  const deleteHandle = async (record, index) => {
    console.log("Record deleted...", record);
    await deleteQuestionsAPI(record.qId)
    setQuestionsRecord(questionsRecord.filter((el, i) => i !== index));
    setQuestion("")
    setRadioOptionRecord([])
    setQuestionType("")
  };

  const createQuestionSet = async () => {
    if (
      questionType === "" ||
      question === "" ||
      (questionType === "Radio Type" ? radioOptionRecord.length <= 0 : "")
    ) {
      return alert("All fields are mandatory to create a package");
    } else {
      const newQuestion = await createQuestion({
        questionText: question,
        answerOptions: radioOptionRecord.length === 0 ? " " : radioOptionRecord,
        questionType: qTypeId === 0 ? "Text" : "Radio"
      })
      setQuestionsRecord((prev) => [
        ...prev,
        {
          qId: newQuestion.data.id,
          questionType: questionType === "Radio Type" ? "Radio" : "Text",
          question: question,
          qTypeId: qTypeId,
          answerOptions: radioOptionRecord.length === 0 ? " " : radioOptionRecord,
        },
      ]);
      setQuestion("");
    }
  };

  const updateQuestionSet = async () => {
    if (questionType === "" || questionValue === "") {
      return alert("All fields are mandatory to create a package");
    } else {
      if (questionType === "TextBox") {
        setQuestionTypeValue("Text Box")
        const data = {
          questionType: "Text",
          questionText: questionValue,
        }
        setQuestionsRecord((current) =>
          current.map((obj) => {
            if (obj.qTypeId === beforeUpdateValue.record.qTypeId) {
              return {
                ...obj,
                questionType: questionType,
                question: questionValue,
                qTypeId: qTypeId,
              };
            }

            return obj;
          })
        );
        await updateQuestion(beforeUpdateValue.record.qId, data)
      }
      else {
        setQuestionTypeValue("Radio Type")
        const data = {
          questionType: "Radio",
          questionText: questionValue,
          answerOptions: radioOptionRecord
        }
        setQuestionsRecord((current) =>
          current.map((obj) => {
            if (obj.qTypeId === beforeUpdateValue.record.qTypeId) {
              return {
                ...obj,
                questionType: questionType,
                question: questionValue,
                qTypeId: qTypeId,
              };
            }

            return obj;
          })
        );
        await updateQuestion(beforeUpdateValue.record.qId, data)
      }


      setEditClicked(false);
      setQTypeId("");
      setQuestionValue("");
      setQuestionTypeValue("");
      setRadioOptionRecord([])
    }
  };

  const getQuestionAPI = async () => {
    const data = await getQuestionsApi()
    console.log(data.data)
    const reqTypeData = data.data.map((dat) => {
      return {
        qId: dat._id,
        qTypeId: dat.questionType === "Radio" ? 1 : 0,
        question: dat.questionText,
        questionType: dat.questionType,
        answerOptions: Array.isArray(dat.answerOptions) ? dat.answerOptions : ""
      }
    })
    setQuestionsRecord(reqTypeData)
  }

  useEffect(() => {
    getQuestionAPI()
  }, [])

  return (
    <div className="userOuterLayout regular">
      <div className="headSection regular">
        <span>Questions</span>

        <img
          src={Logout}
          alt="logout"
          onClick={() => {
            localStorage.clear()
            sessionStorage.clear()
            return navigate("/admin-login")
          }}
        />
      </div>
      <div className="QuestionFunctionArea ">
        {editClicked ? (
          <>
            {" "}
            <div className="topQuestionFunctionSection">
              <div className="itemSection-questionType">
                <label>Select question Type</label>
                <br />
                <Select
                  className="select"
                  options={options}
                  styles={colourStyles}
                  defaultValue={options[beforeUpdateValue?.record.qTypeId]}
                  onChange={(e) => {
                    setQuestionType(e.value.trim());
                    setQTypeId(e.idx);
                  }}
                />
              </div>
              <div className="itemSection-enterQuestion">
                <label>Enter Question</label> <br />
                <input
                  type="text"
                  value={questionValue}
                  onChange={(e) => {
                    setQuestionValue(e.target.value);
                  }}
                />
              </div>
            </div>
            {beforeUpdateValue.record.questionType === "Radio" ? (
              <RadioOption
                update={true}
                radioOption={radioOption}
                setRadioOption={setRadioOption}
                radioOptionRecord={radioOptionRecord}
                setRadioOptionRecord={setRadioOptionRecord}
              />
            ) : (
              ""
            )}
            <div className="createBtn">
              <button onClick={updateQuestionSet}>Update</button>
            </div>
          </>
        ) : (
          <>
            <div className="topQuestionFunctionSection">
              <div className="itemSection-questionType">
                <label>Select question Type</label>
                <br />
                <Select
                  className="select"
                  options={options}
                  styles={colourStyles}
                  onChange={(e) => {
                    setQTypeId(e.idx);
                    setQuestionType(e.value.trim());
                  }}
                />
              </div>
              <div className="itemSection-enterQuestion">
                <label>Enter Question</label> <br />
                <input
                  type="text"
                  placeholder="Enter Questions here"
                  value={question}
                  onChange={(e) => {
                    setQuestion(e.target.value);
                  }}
                />
              </div>
            </div>
            {questionType === "Radio Type" ? (
              <RadioOption
                update={true}
                radioOption={radioOption}
                setRadioOption={setRadioOption}
                radioOptionRecord={radioOptionRecord}
                setRadioOptionRecord={setRadioOptionRecord}
              />
            ) : (
              ""
            )}
            <div className="createBtn">
              <button onClick={createQuestionSet}>Create</button>
            </div>
          </>
        )}

        <div
          className={
            questionType === "Radio Type"
              ? "On_Active_Radio_bottomQuestionFunctionArea"
              : "bottomQuestionFunctionArea"
          }
        >
          <table>
            <tr>
              <th>S.No.</th>
              <th>Question Name</th>
              <th>Type</th>
              <th>Action</th>
            </tr>

            {questionsRecord.map((record, idx) => {
              return (
                <>
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{record.question}</td>
                    <td>{record.questionType === "Radio" || record.questionType === "Radio Type" ? "Radio Type" : "TextBox"}</td>

                    <td>
                      <span>
                        <img
                          src={Edit}
                          alt="edit"
                          height="25px"
                          width="25px"
                          onClick={() => {
                            editHandle(record, idx);
                          }}
                        />
                        <img
                          src={Delete}
                          alt="delete"
                          height="20px"
                          width="20px"
                          onClick={() => {
                            deleteHandle(record, idx);
                          }}
                        />
                      </span>
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
          {questionsRecord.length === 0 ? (
            <div className="noFound">No Data Available</div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminQuestion;
