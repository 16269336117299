import React, { useEffect } from "react";
import "./TagsInput.css";
import validator from "validator";
const TagsInput = ({ TagData, setEmail, email, setArray }) => {
  console.log(email);
  const removeTag = (index) => {
    setArray(TagData.filter((el, i) => i !== index));
  };

  useEffect(() => {
    document.getElementById("slide").scrollLeft += 500;
  }, [TagData]);

  const handleClickEmail = () => {
    if (email != "") {
      setArray((element) => [...element, email]);
      setEmail("");
    }
  };

  return (
    <div className="tags-input-container" id="slide">
      {TagData.map((tag, index) => (
        <>
          {tag != "" ? (
            <>
              <div className="tag-item" key={index}>
                <span className="text">{tag}</span>
                <span
                  className="close"
                  onClick={(e) => {
                    removeTag(index);
                  }}
                >
                  &times;
                </span>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      ))}
      <input
        id="myInput"
        value={email}
        type="text"
        className="tags-input"
        placeholder="Enter email address"
        onKeyPress={(e) => {
          if (e.key === "Enter" || e.code === "Space") {
            handleClickEmail();
          }
        }}
        onChange={(e) => {
          setEmail(e.target.value.trim());
        }}
      />
    </div>
  );
};

export default TagsInput;
