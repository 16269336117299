
import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

                







export default function LikeUnlikeOverTime(props) {


const [structuredData, setStructuredData] = useState([
    {
        "dateData": "2021-05-01",
        "impression": 0
    }

])


console.log(props)


/* The `useEffect` hook is used to perform side effects in a functional component. In this case, the
`useEffect` hook is being used to update the `structuredData` state variable whenever the `props`
object changes. */
useEffect(() => {



// let date = [
//     "2023-06-20",
//     "2023-06-21",
//     "2023-06-22",
//     "2023-06-23",
//     "2023-06-24",
//     "2023-06-25"
//   ]

//   let data = [
//     0,
//     0,
//     0,
//     1,
//     19,
//     0
//   ]


  let structuredData = props.data &&   props.date.map((item, index) => ({
    dateData: item,
    like: props.data[2].values[index],
    dislike: props.data[3].values[index]
    }))


  props.date &&  setStructuredData(structuredData)


         

}, [props])

    

 



    //   console.log(structuredData)

    // dateData: item.values[0].end_time.slice(0, 10),
    //         page_views_total: item.values[0].value,
    //         page_impressions: item.values[1].value,
    //         page_consumptions: item.values[2].value,
    //         page_engaged_users: item.values[3].value,
    // console.log(structuredData)

    return(
        <div>
            <LineChart width={1200} height={400} data={structuredData} margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
                <XAxis dataKey="dateData" />
                <YAxis />
                <Tooltip />
                <Legend />
              
              
                <Line type="monotone" dataKey="like" name="Like" stroke="#8884d8"  strokeWidth={3}  width={3}/>
                <Line type="monotone" dataKey="dislike" name="Dislike" stroke="red"  strokeWidth={3}  width={3}/>

            </LineChart>

          

        </div>  )

}