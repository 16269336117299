import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Await, json } from "react-router";
import { Alert } from "reactstrap";
import SpinnerReact from "../Spinner/Spinner";
import axios from "axios";

function InstaImageDropzone({ setPaths, setImgName, setImgUrl, imgUrl, selected, paths, isLoading, setIsLoading, FileArr, setFileArr }) {
 
    const [images, setImages] = useState([]);

 

  const onDrop = useCallback(
    async (acceptedFiles) => {
      console.log("Accepted Files: ", acceptedFiles);


      const filteredImages = [];
  
      for (const file of acceptedFiles) {
        const image = new Image();
        image.src = URL.createObjectURL(file);
  
image.addEventListener("load", async() => {
  // Check if the file type is JPEG or JPG
  if (file.type === "image/jpeg" || file.type === "image/jpg") {
    if (image.width === image.height) {
      filteredImages.push(image);
      let ff = new FormData();
      ff.append("file", file);
      await fetch(`${process.env.REACT_APP_SERVER_URI}/api/crud/upload`, {
        method: "POST",
        body: ff,
        headers: {
          token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
          userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
        }
      },{
        headers: {
          token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
          userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
        }
      }).then((response) => response.json()).then((data) => {
          paths.instagram = [...paths.instagram, data.imgurl]
          setPaths({ ...paths })
          setIsLoading(false)
          console.log(paths.instagram, "paths.instagram")
      }).catch(err => console.log(err));    
    } else{
      alert("Please upload square images only")
    }
  } else {
    alert("Please upload JPEG or JPG images only")
  }
});
        
      }
  
      setImages(filteredImages);





    //   for(let i=0; i<acceptedFiles.length; i++){

    //     let ff = new FormData();
    //     ff.append("file", acceptedFiles[i]);
    //     await fetch(`${process.env.REACT_APP_SERVER_URI}/api/crud/upload`, {
    //       method: "POST",
    //       body: ff,
    //     }).then((response) => response.json()).then((data) => {
    //       console.log(data.imgurl);

    //       paths.FileArr = [...paths.FileArr, data.imgurl]

    //       setPaths({ ...paths })
          
    //       setIsLoading(false)
    //     }).catch(err => console.log(err));




    //   }



  


   


  


    },
    [paths, selected]
  );        
  const { getRootProps, getInputProps } = useDropzone({ onDrop });




  return (
    <div className="upload-from-device media-border" {...getRootProps()}>
 
      <input {...getInputProps()} />
      <svg width="16" height="16" viewBox="0 0 20 20" fill="#2f3638">
        <path d="M16.25,8.25 C16.25,8.25 16.25,8.12 16.25,8.12 C16.25,4.62 13.5,1.87 10,1.87 C6.87,1.87 4.25,4.12 3.87,7.25 C1.62,8 0,10 0,12.5 C0,15.62 2.5,18.12 5.62,18.12 C7.75,18.12 13,18.12 15,18.12 C17.75,18.12 20,15.87 20,13.12 C20,10.75 18.37,8.87 16.25,8.25 Z M11.25,11.87 L11.25,15.62 L8.75,15.62 L8.75,11.87 L5,11.87 L10,6.87 L15,11.87 L11.25,11.87 Z"></path>
      </svg>
   

      <p className="uploadBtn2-text1">Upload Instagram Images Here</p>
      {/* <ul>
        {   paths.instagram &&    paths.instagram.map((image) => (
         
            <img height='20px' width='20px' alt="img" src={image} />
        ))}
      </ul> */}
    </div>
  )
}

export default InstaImageDropzone;
