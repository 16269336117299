import React from 'react';
import './CustomDropDown.css';
import dropDownArrow from '../../assets/dropDownArrow.png'
import ClickAwayListener from 'react-click-away-listener';

function CustomDropDown({isActive,setIsActive,selected,setIsSelected,list,type}) {



    return (
        <ClickAwayListener onClickAway={(evt) => { if (isActive)  setIsActive(!isActive); }}>
        <div className={type}>
            <div
                onClick={(e) => {
                    setIsActive(!isActive);
                }}
                className="dropdown-btn"
            >
                {selected}

                <img src={dropDownArrow} className='arrowIcon' alt='dropDown Arrow' />

            </div>
            <div
                className="dropdown-content"
                style={{ display: isActive ? "block" : "none" }}
            >
                {
                    list.map((item,i) =>{
                        return(
                            <div
                            onClick={(e) => {
                                setIsSelected(e.target.textContent);
                                setIsActive(!isActive);
                            }}
                            key={i}
                            className="item"
                        >
                            {item}
                        </div>
                        )
                    })
                }
   

            </div>
        </div>
        </ClickAwayListener>
    );
}

export default CustomDropDown;