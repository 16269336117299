import React, { useState, useEffect, useRef } from "react";
import "./FbAnalytics/FbAnalytics.css";
import axios from "axios";

import ApexAreaChartsFacebookPage from "./Charts/ApexAreaChartsFacebookPage";

import FacebookPageLineChart from "./Charts/FacebookPageLineChart";

import CustomDropDown from "../../../Components/CustomDropDown/CustomDropDown";
import "../../../Components/CustomDropDown/CustomDropDown.css";
const FaceBookAnalytics = (props) => {
  console.log(props);
 
  const [pageData, setPageData] = useState(
    (props?.pagesDetails.pagesDetails?.filter(
      (item) => item.account_type === "facebook"
    ))[0]
  );


  const [smallGraphs, setSmallGraphs] = useState([
    "page_impressions",
    "page_engaged_users",
    "page_total_actions",
    "page_consumptions",
  ]);

  const [SelectedPostAnalytics, setSelectedPostAnalytics] = useState(
    "103191482473211_122362340556125"
  );
  const [analyticsStartDate, setAnalyticsStartDate] = useState(
    props.startDate.date
  );
  const [analyticsEndDate, setAnalyticsEndDate] = useState(props.endDate.date);




  const [allAnalytics, setAllAnalytics] = useState(null)

  const GetAllAnalytics = async () => {


    const response = await axios.post( `${process.env.REACT_APP_SERVER_URI}/api/crud/FacebookAllAnalytics`, {
      token: pageData?.token,
      pageID: pageData?.pageId,
      startDate: Date?.parse(new Date(props?.startDate?.date)) / 1000,
      endDate: Date?.parse(new Date(props?.endDate?.date)) / 1000,
      jwToken: localStorage?.getItem("token"),
    })

    setAllAnalytics(response.data)

    console.log(response.data)
  }



  useEffect(() => {
  
    pageData?.pageId &&  GetAllAnalytics()

  }, [
    analyticsStartDate,
    analyticsEndDate,
    SelectedPostAnalytics,
    smallGraphs,
    pageData,
    props.startDate,
    props.endDate,
  ]);





  const [AllPages, setAllPages] = useState(props.pagesDetails);
  const [IsPageSelected, setIsPageSelected] = useState(false);
  const [SelectedPage, setSelectedPage] = useState("Select Page");
  const [networkPages, setNetworkPages] = useState([]);

  useEffect(() => {
    setAllPages(props.pagesDetails);
  }, [props.pagesDetails]);



  useEffect(() => {
    const Networkpages = [];
    props.pagesDetails.pagesDetails?.map((item) => {
      if (item.account_type === "facebook") {
        Networkpages.push(item.name);
      }
    });
    setSelectedPage(Networkpages[0]);
    console.log(props.pagesDetails.pagesDetails);
    setNetworkPages(Networkpages);
  }, [props.pagesDetails]);

  useEffect(() => {
    console.log(SelectedPage);
    setPageData(
      props.pagesDetails.pagesDetails.filter(
        (item) => item.name === SelectedPage
      )[0]
    );
  }, [SelectedPage, props.pagesDetails.pagesDetails]);

  // useEffect(() => {
  //   setSmallGraphs([...smallGraphs, "post_impressions","post_impressions_unique", "post_impressions_organic"])

  //    console.log(smallGraphs)
  // }, [])
  return (
    <div className="fbAnalyticBlock">
      {/* {JSON.stringify(PageData)} */}
      {/* <div className="date-picker"> */}

      {/* </div> */}
      <div className="mediaFilterBlock">
        <p>Facebook</p>
        <CustomDropDown
          isActive={IsPageSelected}
          setIsActive={setIsPageSelected}
          selected={SelectedPage}
          setIsSelected={setSelectedPage}
          list={networkPages}
          type="mediaFilter"
        />
      </div>

      {/*<div style={{ margin: '10px', display: 'flex', flexDirection: 'start' }}>*/}
      {/*    <ButtonDropdown toggle={() => { setGraphdropdownOpen(!GraphdropdownOpen) }}*/}
      {/*        isOpen={GraphdropdownOpen} >*/}
      {/*        <DropdownToggle caret color="transparent" style={{ boxShadow: '0 4px 24px 0 rgb(34 41 47 / 31%)' }}>*/}
      {/*            Add Graph*/}
      {/*        </DropdownToggle>*/}
      {/*        <DropdownMenu>*/}
      {/*            <DropdownItem header>Numeric Characters*/}
      {/*            </DropdownItem>*/}
      {/*            {facebookChartsData.map((filter) => {*/}
      {/*                return (*/}
      {/*                    <DropdownItem onClick={() => { handleAdd(filter) }}>{filter}</DropdownItem>*/}
      {/*                )*/}
      {/*            })*/}
      {/*            }*/}

      {/*        </DropdownMenu>*/}
      {/*    </ButtonDropdown>*/}
      {/*</div>*/}

      <div className="mediaKPIBlock">
        {allAnalytics &&  allAnalytics[0].map((chart) => (
          <ApexAreaChartsFacebookPage
            metric={chart.title}
            analyticsData ={chart.Total}
        
            PageData={pageData}
            type={"facebookPage"}
            startDate={ Date.parse(new Date(props.startDate.date)) / 1000}
            endDate={Date.parse(new Date(props.endDate.date)) / 1000}
          />
        ))}
      </div>
      <div className="mediaLineChart">
        {allAnalytics !== null ? (
          // <ApexLineChart categories={chartOptionMetaPageImpression[0].xaxis} data={chartOptionMetaPageImpression} />
          <FacebookPageLineChart
            categories={allAnalytics[1][0]}
            impression={allAnalytics[1][1]}
            engagement={allAnalytics[1][2]}
            analyticsData ={allAnalytics}
          />
        ) : null}
      </div>

   
    </div>
  );
};

export default FaceBookAnalytics;
