import React from "react";
import "./NewlistPop.css";
import Cancel from "../../assets/icons8-cancel-64.png";
import ListCard from "../Listcard/Listcard";

const EmailListPop = ({
  userInfo,
  selectedEmail,
  setisAddToListPopup,
  setListArray,
  listArray,
}) => {
  // const [listItem, setListItem] = useState([
  //   "Sep Marketing List",
  //   "March Marketing List",
  //   "Corporate List",
  //   "Sep Marketing List",
  //   "March Marketing List",
  //   "Corporate List",
  //   "Sep Marketing List",
  //   "March Marketing List",
  //   "Corporate List",
  // ]);

  const HandleClose = () => {
    setisAddToListPopup(false);
  };

  return (
    <div className="PopBody regular">
      <div className="popOuterLayout">
        <div className="popHead">
          My Email List
          <img src={Cancel} alt="close" onClick={HandleClose} />
        </div>
        <div className="emailList">
          <ListCard
            userInfo={userInfo}
            HandleClose={HandleClose}
            selectedEmail={selectedEmail}
            listArray={listArray}
            addToList={true}
          />
        </div>
      </div>
    </div>
  );
};

export default EmailListPop;
