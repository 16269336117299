import React from "react";
// import "./styles.css";
import Chart from 'chart.js/auto';
import { Line } from "react-chartjs-2";
import { useState, useEffect } from "react";
import axios from "axios";


export default function InstagramLineChart(props) {
  const [impression, setImpression] = useState([]);
  const [Follow, setFollow] = useState([]);
  const [lables, setLables] = useState([]);
  //["9-16", "9-17", "9-18", "9-19", "9-20", "9-21"]
  const [dat, setDat] = useState(props.PageData);
  async function fetchDataImpression() {
    try {
      // if(type === "facebookPage"){
      const result = await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/crud/analyticsFacebook`, {
        pageId: props.PageData.pageId,
        startDate: props.startDate.date,
        endDate: props.endDate.date,
        metric: 'impressions',
        token: props.PageData.token,
        type: "instagram",
        jwToken: localStorage.getItem("token")
      })
      setImpression(result.data)
      setLables(result.data.labels)
      console.log(result.data)

    } catch (err) {
      console.log(err)
    }
  };
  async function fetchDataFollow() {
    try {
      // if(type === "facebookPage"){
      const result = await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/crud/analyticsFacebook`, {
        pageId: props.PageData.pageId,
        startDate: props.startDate.date,
        endDate: props.endDate.date,
        metric: 'follower_count',
        token: props.PageData.token,
        type: "instagram",
        jwToken: localStorage.getItem("token")
      })
      setFollow(result.data)
      console.log(result.data)

    } catch (err) {
      console.log(err)
    }
  };

  useEffect(() => {
    fetchDataImpression();
    fetchDataFollow()
  }, [props.PageData, props.startDate, props.endDate]);
  const datas = {
    labels: impression.lables,

    datasets: [
      {
        label: "impressions",
        data: impression.data,
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        grid: {
          display: false
        },
        tension: 0.5,
      },
      {
        label: "Follow",
        data: Follow.data,
        fill: true,
        borderColor: "#742774",
        tension: 0.5,
      },

    ],

    tension: 1
  };
  const options = {
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        }
      }
    },
    maintainAspectRatio: false

  }





  return (

    <div style={{ margin: 'auto', position: "relative", "height": "400px", width: "100%" }} >

{Follow.data !== undefined ? null: "NO Data Available for follow"}
      <Line data={datas} options={options} />

    </div>

  );
}
