import Chart from 'react-apexcharts'
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import "../FbAnalytics/FbAnalytics.css"
import { Card, CardHeader, CardTitle, CardBody, CardSubtitle, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown } from 'reactstrap'
import { async } from 'emoji-mart'
import capitalize from 'capitalize'

const areaColors = {
  series3: '#a4f8cd',
  series2: '#60f2ca',
  series1: '#2bdac7'
}

const InstagramSmallCharts = ({ metric, startDate, endDate, filters, type, PageData }) => {
  const [dataset, setDataset] = useState({
    "name": "page",
    "title": "Weekly Total ",
    "lables": [
      "09-03",
      "09-04",
      "09-05",
      "09-06",
      "09-07"
    ],
    "data": [
      0,
      0,
      0,
      0,
      0
    ]
  })
  const [errs, setErrs] = useState()
  async function fetchData() {

    try {
      // if(type === "facebookPage"){
      const result = await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/crud/analyticsFacebook`, {
        pageId: PageData.pageId,
        startDate: new Date(startDate.date).toISOString().slice(0, 10),
        endDate: new Date(endDate.date).toISOString().slice(0, 10),
        metric: metricF,
        token: PageData.token,
        type: "instagram",
        jwToken: localStorage.getItem("token")
      })
      setDataset(result.data)
      console.log(result.data)

      // }else if(type === "facebookPost"){
      //   const result = await  axios.post('${process.env.REACT_APP_SERVER_URI}/api/crud/analyticsFacebookPost',{
      //     postId: "103191482473211_104573479001678",
      //     startDate: startDate,
      //     endDate: endDate,
      // metric: "page_impressions",

      // })
      // setDataset(result.data)
      // console.log(result.data)
      // }



    } catch (err) {
      console.log(err)
      setErrs("No Data")
    }

  };

  const [metricF, setMetricF] = useState(metric);
  useEffect(() => {
    fetchData();

  }, [startDate, endDate, metric, metricF, PageData]);



  const series = [{
    name: dataset.name !== undefined ? dataset.name : "No Data",
    data: dataset.data
  }];
  let percent = dataset.data && dataset.data.length > 1 ? (dataset.data[dataset.data.length - 1] * 100 / dataset.data[dataset.data.length - 2] - 100).toFixed(0) : 0;
  percent = parseInt(percent)
  console.log(typeof (percent))
  //convert Nan to 0
  if (isNaN(percent)) {
    percent = 0
  }
  //create color for percent
  let color = percent >= 0 ? "green" : "red"

  if (percent >= 0) {
    percent = " " + "+" + percent + "%" + "▴"
  } else if (percent < 0) {
    percent = " " + " " + percent + "%" + "▾"
  }
  const options = {

    chart: {
      type: 'area',

      height: 150,
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      },
      tooltip: {
        enabled: false,
        show: false
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        title: {
          text: '',
          align: 'left',
          // offsetX: 14
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: undefined,
    },
    stroke: {
      curve: 'smooth',
      width: 0.8
    },
    grid: {
      show: false,
    },
    title: {
      text: '',
      align: 'left'
    },
    subtitle: {
      text: '',
      align: 'left'
    },
    labels: dataset.lables,
    xaxis: {
      labels: {
        show: false
      }
    },

    yaxis: {
      opposite: false,
      labels: {
        show: false
      }
    },

    title: {
      text: percent,
      align: 'right',
      style: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: color
      }
    },
    subtitle: {
      text: dataset.title ? dataset.title + "  " : "No Data for" + " " + metric,
      align: 'left',
      style: {
        fontSize: '10px',
      }
    },
    legend: {
      horizontalAlign: 'left'
    }
  };

  const [dropdownOpen, setOpen] = useState(false);

  return (


    <div className='mediaKPI cardSpace'>
      <p>{capitalize.words(metric.replaceAll("_", " "))}</p>
      <p className='kpiValue'>{dataset.data && dataset.data.reduce((partialSum, a) => partialSum + a, 0)}</p>

    </div>
    //     <Card style={{ border: 'none', marginTop: '5px', marginBottom: '5px', marginRight: '10px', boxShadow: '0 4px 24px 0 rgb(34 41 47 / 31%)', height: '18rem' }}>
    //
    //      <CardBody >
    //      <div style={{ marginBottom: '50px' }}> <h1 style={{ float: 'left' }}>  {dataset.data && dataset.data[dataset.data.length - 1]}</h1>
    //        <ButtonDropdown toggle={() => { setOpen(!dropdownOpen) }}
    //          isOpen={dropdownOpen} style={{ float: 'right' }}>
    //          <DropdownToggle className="bg-transparent" style={{ border: 'none' }} >
    //
    //            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.479 2l-7.479 12.543v5.924l-1-.6v-5.324l-7.479-12.543h15.958zm3.521-2h-23l9 15.094v5.906l5 3v-8.906l9-15.094z" /></svg>
    //          </DropdownToggle>
    //          <DropdownMenu>
    //            <DropdownItem header>Filters
    //            </DropdownItem>
    //            {filters.map((filter) => {
    //              return (
    //                <DropdownItem onClick={() => { setMetricF(filter) }}>{filter}</DropdownItem>
    //              )
    //            })
    //            }
    //
    //          </DropdownMenu>
    //        </ButtonDropdown>
    //      </div>
    //
    //        <Chart options={options} series={series} type='area' height={200} width={220} />
    //      </CardBody>
    //    </Card>
  )
}
export default InstagramSmallCharts;
