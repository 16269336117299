import React, { useState, useEffect, useRef } from "react";
import "../Analytics/FbAnalytics/FbAnalytics.css";
import axios from "axios";
import ReactWordcloud from "react-wordcloud";
import ReactionChart from "./ReacttionChart";
import { CardMedia, CardContent, Typography, CardActions, Button, Skeleton } from "@mui/material";
import {AiOutlineLike,AiOutlineComment, AiOutlineShareAlt, AiOutlineFundView} from 'react-icons/ai'


import ApexAreaChartsFacebookPage from "./Charts/ApexAreaChartsFacebookPage";
import ApexDonutChart from "./Charts/ApexDonutChart";
import ApexLineChart from "./Charts/ApexLineChart";
import FacebookPageLineChart from "./Charts/FacebookPageLineChart";
import Posts from "./Charts/posts/Posts";
// import "flatpickr/dist/themes/light.css";
// import Flatpickr from 'react-flatpickr';
import ApexAreaChartsFacebookPost from "./Charts/ApexAreaChartsFacebookPost";
import CustomDropDown from "../../../Components/CustomDropDown/CustomDropDown";
import "../../../Components/CustomDropDown/CustomDropDown.css";
import { set } from "react-ga";
import { Card, CardBody, CardFooter } from "reactstrap";
import FacebookPostAnalyticsModal from "./FacebookPostAnalyticsModal";
// import ImpressionChart from "./ImpressionChart";
import ImpressionChart from "./impressionChart";
import InstagramPostAnalyticsModal from "./InstagramPOstAnalyticsModal";


import ViewsOverTime from "./YoutubeGraphs/ViewsOverTime";
import LikeUnlikeOverTime from "./YoutubeGraphs/LikesUnlikeOverTime";
import Subscribers from "./YoutubeGraphs/Subscribers";
import MinutesWatchedOverTime from "./YoutubeGraphs/MinutesWatchedOverTime";
import YoutubePostAnalytics from "./YoutubePostAnalytics";
import { BsYoutube } from "react-icons/bs";
import DataTable from "react-data-table-component";
const YoutubeAnalytics = (props) => {
  console.log(props);

  const [pageData, setPageData] = useState(
    (props?.pagesDetails?.pagesDetails?.filter(
      (item) => item.account_type === "youtube"
    ))[0]
  );


 



 

  const [AllPages, setAllPages] = useState(props.pagesDetails.pagesDetails);
  const [IsPageSelected, setIsPageSelected] = useState(false);
  const [SelectedPage, setSelectedPage] = useState("Select Page");
  const [networkPages, setNetworkPages] = useState([]);
  const [allPosts, setAllPosts] = useState([]);

  useEffect(() => {
    setAllPages(props.pagesDetails.pagesDetails);
  }, [props.pagesDetails]);

  function convertDuration(duration) {
    const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
    
    const hours = (parseInt(match[1]) || 0);
    const minutes = (parseInt(match[2]) || 0);
    const seconds = (parseInt(match[3]) || 0);

    let durationStr = '';
    if (hours > 0) durationStr += `${hours} hours `;
    if (minutes > 0) durationStr += `${minutes} minutes `;
    if (seconds > 0) durationStr += `${seconds} seconds`;

    return durationStr.trim();
  }

  useEffect(() => {
    const Networkpages = [];
    props.pagesDetails.pagesDetails?.map((item) => {
      if (item.account_type === "youtube") {
        Networkpages.push(item.name);
      }
    });
    setSelectedPage(Networkpages[0]);
    console.log(props.pagesDetails.pagesDetails);
    setNetworkPages(Networkpages);
  }, [props.pagesDetails]);

  useEffect(() => {
    console.log(SelectedPage);
    setPageData(
      props.pagesDetails.pagesDetails.filter(
        (item) => item.name === SelectedPage
      )[0]
    );
  }, [SelectedPage]);


  const options = {
    rotations: 2,
    rotationAngles: [-90, 0],
    fontFamily: 'Arial',
    fontSizes: [20, 60], // Set the range of font sizes for the words
 
  };
  const size = [1000, 400];

  const words = []

// const [wordCloudWords,setWordCloudWords]=useState(words)
const [reactionData,setReactionData]=useState([])
const [headData,setHeadData]=useState([])


// const wordcloudData = async()=>{

// await axios.post( process.env.REACT_APP_SERVER_URI + `/api/deepAnalytics/YoutubeWordCloudData`,{
//   pageId:pageData?.pageId,
//   startDate:props?.startDate?.pindate,
//   endDate:props?.endDate?.pindate,
//   jwToken: sessionStorage?.getItem("token"),
// }).then((res)=>{
//   console.log(res.data);
//   setWordCloudWords(res.data);
//   return res.data;
// }).catch((err)=>{
//   console.log(err);
// })
 
// }
 

// const [Most_positive_and_negative_post,setMost_positive_and_negative_post]=useState([])
const [isPostAnalytics,setIsPostAnalytics]=useState(false)
const [selectedPostAnalyticsId,setSelectedPostAnalyticsId]=useState("")
const [id,setId]=useState("")
const [pageAnalytics,setPageAnalytics]=useState()
const [impressionChartData,setImpressionChartData]=useState([])

const [isAccountAnalytics,setIsAccountAnalytics]=useState(false)
const [isWordCloludData,setIsWordCloludData]=useState(false)
const [isPostsVisible,setIsPostsVisible]=useState(false)



const getAccountAnanlytics=async()=>{
 
 
 pageData && await axios.post(process.env.REACT_APP_SERVER_URI + '/api/deepAnalytics/YoutubeChannelAnalytics',{
    pageId:pageData?.pageId,
    startDate:props?.startDate?.pindate,
    endDate:props?.endDate?.pindate,
    jwToken: sessionStorage?.getItem("token"),
    pageData:pageData
  }).then((res)=>{
    console.log(res.data);
    setPageAnalytics(res.data);
    return res.data;
  }
  ).catch((err)=>{
    console.log(err);
  }
  )
}



const getAllPosts=async()=>{
  await axios.post(process.env.REACT_APP_SERVER_URI + '/api/deepAnalytics/YoutubeAllVideos',{
    pageId:pageData?.pageId,
    startDate:props?.startDate?.pindate,
    endDate:props?.endDate?.pindate,
    jwToken: sessionStorage?.getItem("token"),
  }).then((res)=>{
    console.log(res.data);
    setAllPosts(res.data);
    return res.data;
  }
  ).catch((err)=>{
    console.log(err);
  }
  )
}
 
const handleChangeVisibility = (data) => {
  if (data === "wordCloud") {
    setIsWordCloludData(true)
    
  } else if (data === "accountData") {
    setIsAccountAnalytics(true)

  } else if (data === "posts") {
    setIsPostsVisible(true)

  }
};


const [isAllPosts,setIsAllPosts]=useState(false)

useEffect(() => {
  // Check if the functions are defined before calling them
  if (isAccountAnalytics && typeof getAccountAnanlytics === 'function') {
    try {
      getAccountAnanlytics();
    } catch (error) {
      console.error('Failed to get account analytics:', error);
    }
  }

  if (isPostsVisible && typeof getAllPosts === 'function') {
    try {
      getAllPosts();
    } catch (error) {
      console.error('Failed to get all posts:', error);
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.startDate, props.endDate, pageData?.pageId, isAccountAnalytics, isPostsVisible]);

const [selectedPostAnalytics,setSelectedPostAnalytics]=useState()


const openPostAnalyticsModal=(id,post)=>{
  setIsPostAnalytics(true)
  setSelectedPostAnalyticsId(id)
  setSelectedPostAnalytics(post)

  // setId(id)
  console.log(id)
} 

  return (
    <div className="fbAnalyticBlock">
            <YoutubePostAnalytics SelectedPageId={pageData?.pageId}  selectedPostAnalyticsId={selectedPostAnalyticsId} startDate={props?.startDate?.pindate} endDate={props?.endDate?.pindate}  post={selectedPostAnalytics}  isPostAnalytics={isPostAnalytics} setIsPostAnalytics={setIsPostAnalytics}  />

     
      {/* </div> */}
      <div className="mediaFilterBlock">
        <p><BsYoutube color="red" style={{ width: '40px', height:'40px'}} width={40}  height={40}/>  {  " " + SelectedPage}</p>
  
      </div>




{
  isAccountAnalytics ? (
    <div style={{display:'flex',width:'100%', height:'auto', marginBottom:'10px', flexWrap:'wrap'}}>


{
  pageAnalytics &&
  pageAnalytics[0]?.map((item)=>{
    return(
      <div className="youtube youtube_wrp_blocks" >
      <h4>{item.name}</h4>
      <h2>{Number.isInteger(item.value) ? item.value : item.value.toFixed(2)}</h2>
      </div> 
    )
  }
  )
}


</div>
  ) : (
    <div className="click_notify_wrp" 
    onClick={() => handleChangeVisibility("accountData")}
  >
 
  <p>Stay updated with your audience! <br/>Click here to view your YouTube Account Insights,
              Engaged Users, Impressions, Total Users and other engagement metrics.</p>
              <h2>Click to View</h2>
  </div>
  )
}
      


{/* <div className="mediaFilterBlock" style={{marginTop:'40px'}}>

<p>Most Frequent words</p>
</div>
{
  isWordCloludData ? (
    <Card className="fbAnalyticsCard">

<div style={{ width: "100%", height: "100%", display:'flex', flexDirection:'row',justifyContent:'center', alignItems:'center', flexWrap:'wrap' }}>
      <ReactWordcloud words={wordCloudWords} options={options} size={size}  />
</div>

</Card>
  ) : (
    <div
    onClick={() => handleChangeVisibility("wordCloud")}
    style={{
      width: "100%",
      height: "250px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#b19d9dcc",
      backdropFilter: "blur(5px)",
      border: "1px solid #d2d2d2",
      cursor: "pointer",
    }}
  >
    <h2><h2> click to view </h2></h2>
  </div>
  )
} */}

  





<div className="mediaFilterBlock" style={{marginTop:'40px'}}>

<p>Views over Time</p>
</div>
{
  isAccountAnalytics ? (
    <Card className="fbAnalyticsCard" style={{marginTop:'20px', padding:'30px'}}>



    {/* <InstagramAccountChart1 data={pageAnalytics &&  pageAnalytics[1]} /> */}
    <ViewsOverTime data={pageAnalytics &&  pageAnalytics[1]}  date={pageAnalytics && pageAnalytics[2]} />
  
      </Card>):
      (
        <div className="click_notify_wrp" 
      onClick={() => handleChangeVisibility("accountData")}
      >  
      <p>Stay updated with your audience! <br/>Click here to view your YouTube Video Insights, Views, Total Views and other engagement metrics.</p>
                  <h2>Click to View</h2>
      </div>
      )
}
 



    <div className="mediaFilterBlock" style={{marginTop:'40px'}}>

<p>Like / Dislikes over Time</p>
</div>
{   isAccountAnalytics ? (
  <Card className="fbAnalyticsCard" style={{marginTop:'20px', padding:'30px'}}>

<LikeUnlikeOverTime data={pageAnalytics &&  pageAnalytics[1]}  date={pageAnalytics && pageAnalytics[2]} />


    </Card>
    ):
      (
        <div className="click_notify_wrp" 
      onClick={() => handleChangeVisibility("accountData")}
           > 
          <p>Stay updated with your audience! <br/>Click here to view your YouTube Videos likes, dislikes, 
          Total Views and other engagement metrics.</p>
                  <h2>Click to View</h2>
      </div>
      )
}

    <div className="mediaFilterBlock" style={{marginTop:'40px'}}>

<p>Subscribers Gains and Lost Over Time</p>
</div> 

{isAccountAnalytics ? (

  <Card className="fbAnalyticsCard" style={{marginTop:'20px', padding:'30px'}}>


<Subscribers data={pageAnalytics &&  pageAnalytics[1]}  date={pageAnalytics && pageAnalytics[2]} />

    </Card>
    ):
      (
        <div className="click_notify_wrp" 
      onClick={() => handleChangeVisibility("accountData")} 
      >  
      <p>Stay updated with your audience! 
        <br/>
        Click here to view your YouTube Subscribers over time and other engagement metrics.</p>
                  <h2>Click to View</h2>
      </div>
      )
}



    <div className="mediaFilterBlock" style={{marginTop:'40px'}}>

<p>Minutes Watched Over Time</p>
</div>
{isAccountAnalytics ? (

    <Card className="fbAnalyticsCard" style={{marginTop:'20px', padding:'30px'}}>


<MinutesWatchedOverTime data={pageAnalytics &&  pageAnalytics[1]}  date={pageAnalytics && pageAnalytics[2]} />

    </Card>
    ):
      (
        <div className="click_notify_wrp" 
      onClick={() => handleChangeVisibility("accountData")}>

          <p>Stay updated with your audience! 
        <br/>
        Click here to view your YouTube Subscribers over time and other engagement metrics.</p>
                  <h2>Click to View</h2>
      </div>
      )
}


<div className="mediaFilterBlock" style={{marginTop:'30px'}}>

<p>YouTube Videos</p>
</div>
{
  isPostsVisible ? (
    <Card className="fbAnalyticsCard" style={{marginTop:'10px', padding:'10px', display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
    {     
    
    
    // if( allPosts.length===0){
    //   return(
    //     <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
    //       <h2>No Posts</h2>
    //     </div>
    //   )
    // }

  allPosts.length > 0 ?
       (

                <DataTable
            columns={[

              {
                name: 'Thumbnail',
                cell: row => <img src={row.thumbnail} alt="thumbnail" style={{width: '50px', height: '50px'}} />,
                sortable: true,
              },
              {
                name: 'Title',
                selector: row => <a href={`https://www.youtube.com/watch?v=${row.id}`} target="_blank">{row.title}</a>,
                sortable: true,
            },
            {
                name: 'Duration',
                selector: row => convertDuration(row.contentDetails.duration),
                sortable: true,
            },
            {
               name: 'PublishedAt',
               selector: row => row.publishedAt,
               sortable: true,
            },{
              name: 'Likes',
              selector: row => row.statistics.likeCount,
              sortable: true,
            },{
              name: 'Comments',
              selector: row => row.statistics.commentCount,
              sortable: true,
            },{
              name: 'Views',
              selector: row => row.statistics.viewCount,
              sortable: true,
            }
            ]}
            data={allPosts}
            direction="auto"
            fixedHeaderScrollHeight="300px"
            pagination
            responsive
            subHeaderAlign="right"
            subHeaderWrap
        />
    
           
    
       
    
      
        
    ) : (
       
        <Card  style={{marginTop:'10px', padding:'10px', display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
        {
  Array.from({ length: 8 }).map((_, index) => (
    <div style={{margin:'5px'}}>
    <Skeleton key={index} variant="rectangular" width={210} height={118} />

    </div>
  ))
}
            </Card>
      )
    }
    
    </Card>):(
      <div className="click_notify_wrp" 
      onClick={() => handleChangeVisibility("posts")} 
      >  
     
      <p>Stay updated with your audience! 
        <br/>
        Click here to view your YouTube videos and other engagement metrics.</p>
      <h2>Click to View</h2>
      
      </div>
    )
}


{/* ---------------------------NEW POSTS YOUTUBE--------------------------- */}






  

  



  
    </div>
  );
};

export default YoutubeAnalytics;
