import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import SpinnerReact from "../Spinner/Spinner";
import axios from "axios";

function VideoDropzonearea({ VideoUrl, setVideoUrl }) {
  const onDrop = useCallback(
    async (acceptedFiles) => {
      console.log("Accepted Files: ", acceptedFiles);
      const formData = new FormData();
      console.log(formData)
      const [file] = acceptedFiles;
      formData.append("file", file);


      

 await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/crud/uploadReel`,{
        filename:file.name
      },{
        headers: {
          token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
          userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
        }
      }).then(async(res)=>{


  await axios.put(res.data.uploadurl, file,{
    headers: {
      "Content-Type": "application/octet-stream",
      "Accept": "*/*",
      "Access-Control-Allow-Origin": "*",
  },
  mode: 'no-cors',

})

// await  axios(res.data.uploadurl, {
//   method: "PUT",
//   body: file,
  
//   headers: {
//     'Content-Type': 'application/octet-stream',
//     "Accept": "*/*",
//     "Access-Control-Allow-Origin": "*",
//     "Content-Length": file.size,
//     "Content-Type": "video/mp4"

//   }})
 

setVideoUrl(res.data.publicurl)

      }).catch((err)=>{
        console.log(err);
      })


        // setReelUrl(data.vidurl)



      // accounts.map(account => console.log(account))





    },
    [VideoUrl]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className="upload-from-device media-border" {...getRootProps()} style={{ width: "100%", marginTop: "10px" }}>
      <input {...getInputProps()} />
      <svg width="16" height="16" viewBox="0 0 20 20" fill="#2f3638">
        <path d="M16.25,8.25 C16.25,8.25 16.25,8.12 16.25,8.12 C16.25,4.62 13.5,1.87 10,1.87 C6.87,1.87 4.25,4.12 3.87,7.25 C1.62,8 0,10 0,12.5 C0,15.62 2.5,18.12 5.62,18.12 C7.75,18.12 13,18.12 15,18.12 C17.75,18.12 20,15.87 20,13.12 C20,10.75 18.37,8.87 16.25,8.25 Z M11.25,11.87 L11.25,15.62 L8.75,15.62 L8.75,11.87 L5,11.87 L10,6.87 L15,11.87 L11.25,11.87 Z"></path>
      </svg>

      {/* <p className="uploadBtn2-text1">Drag files here</p> */}
      <p className="uploadBtn2-text2">select Video to upload</p>
    </div>
  )
}

export default VideoDropzonearea;
