import React from "react";
import { LoadingBar } from "react-redux-loading-bar";

const Loader = () => {
  return (
    <>
      <header>
        <LoadingBar style={{ backgroundColor: "red", height: "50px" }} />
      </header>
    </>
  );
};

export default Loader;
