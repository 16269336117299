import React from "react";
// import "./styles.css";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

export default function FacebookPageLineChart(props) {


  console.log(props)
  const datas = {
    labels: props.categories,
    datasets: [
      {
        label: "Impressions",
        data: props.impression || [0,0,0,0,0,0],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        grid: {
          display: false,
        },
        tension: 0.5,
      },
      {
        label: "Engagement",
        data: props.engagement || [0,0,0,0,0,0],
        fill: true,
        borderColor: "#742774",
        tension: 0.5,
      },
    ],

    tension: 1,
  };
  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <centre>
      <div
        style={{
          margin: "auto",
          position: "relative",
          height: "400px",
          width: "100%",
        }}
      >
        <Line data={datas} options={options} />



      </div>
    </centre>
  );
}
