import React from "react";
import "./NoSideBarLayout.css";

function NoSideBarLayout(props) {
  return (
    <div>
      <div className="noSideBarHeader">
        <h1 className="logoText">toowe</h1>
      </div>
      {props.children}
    </div>
  );
}

export default NoSideBarLayout;
