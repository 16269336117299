import React, { useEffect, useState } from "react";
import { getQuestionsApi, signUpApi } from "../../Utils/api";
import "./QuestionsPage.css";
import Logo from "../../assets/logo1.png";
import Digi from "../../assets/digi.png";
import login_left_img from "../../assets/login_left_img.png";
// import { RadioGroup, Radio } from "react-radio-group";
import { ChevronRight, ChevronLeft } from "react-feather";
import { useNavigate } from "react-router-dom";

function QuestionsPage({ setUserInfo, signUpState, setSignUpState, onclick }) {
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);

  useEffect(() => {
    getNewUserQuestion();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getNewUserQuestion() {
    try {
      const resData = await getQuestionsApi();
      let questionsData = resData.data.map((item) => ({
        ...item,
        answerText: "",
      }));

      questionsData.forEach((element) => {
        delete element._id;
        delete element.__v;
      });

      setSignUpState((current) => {
        return {
          ...current,
          userQuestionDetails: [...questionsData],
        };
      });
    } catch (error) {
      console.log(error);
    }
  }

  const handleClickBackBtn = () => {
    if (currentQuestion === 0) {
      return;
    }

    setCurrentQuestion((prev) => {
      return prev - 1;
    });
  };

  const handleClickContinueBtn = () => {


    if (currentQuestion === signUpState.userQuestionDetails.length - 1) {
      CreateUser();
      return;
    }

    setCurrentQuestion((prev) => {
      return prev + 1;
    });
  };

  const handleClickSelected = (item) => {
    let updateuserQuestionDetails = [...signUpState.userQuestionDetails];
    updateuserQuestionDetails[currentQuestion].answerText = item;

    setSignUpState((current) => {
      return {
        ...current,
        userQuestionDetails: [...updateuserQuestionDetails],
      };
    });
  };

  const handleTextBoxRes = (e) => {
    let updateuserQuestionDetails = [...signUpState.userQuestionDetails];
    updateuserQuestionDetails[currentQuestion].answerText = e.target.value;

    setSignUpState((current) => {
      return {
        ...current,
        userQuestionDetails: [...updateuserQuestionDetails],
      };
    });
  };

  async function CreateUser() {
    try {
      const res = await signUpApi(signUpState);
      if (res.status === 200) {
        console.log(res.data);
        setUserInfo(res.data);
        sessionStorage.setItem("token", res.data.token);
        localStorage.setItem("token", res.data.token);
        sessionStorage.setItem("userProfileInfo", JSON.stringify(res.data));
        localStorage.setItem("userProfileInfo", JSON.stringify(res.data));
        navigate("/likn-account");
        onclick();
      } else{
        alert("Something went wrong")
      }
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }

  console.log(signUpState);
  return (
    <div>
      {/* {signUpState.userQuestionDetails.length !== 0 && (
        <div className="questionPage-container">
          <h1>
            {signUpState.userQuestionDetails[currentQuestion].questionText}
          </h1>
          <div className="questionContainer">
            <div className="questionInnerContainer">
              <h3>
                QUESTION {currentQuestion + 1} OF{" "}
                {signUpState.userQuestionDetails.length}
              </h3>
              <p>Choose one of the below</p>

              {signUpState.userQuestionDetails[
                currentQuestion
              ].answerOptions.map((item, i) => (
                <div
                  className="answerContainer"
                  key={i}
                  onClick={() => handleClickSelected(item)}
                >
                  <h3
                    className={
                      item ===
                        signUpState.userQuestionDetails[currentQuestion]
                          .answerText && "selected"
                    }
                  >
                    {item}
                  </h3>
                </div>
              ))}
            </div>
            <div className="continue-btn-container">
              <button
                className={`back-btn ${
                  currentQuestion === 0 ? "disableBtn" : ""
                }`}
                disabled={currentQuestion === 0}
                onClick={handleClickBackBtn}
              >
                Back
              </button>
              <button
                className={`continue-btn 
                            ${
                              (currentQuestion ===
                                signUpState.userQuestionDetails.length - 1 &&
                                signUpState.userQuestionDetails[currentQuestion]
                                  .answerText === "" &&
                                "disableBtn") ||
                              (signUpState.userQuestionDetails[currentQuestion]
                                .answerText === "" &&
                                "disableBtn")
                            }`}
                onClick={handleClickContinueBtn}
                disabled={
                  signUpState.userQuestionDetails[currentQuestion]
                    .answerText === ""
                }
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )} */}

      {signUpState.userQuestionDetails.length !== 0 && (
        <div className="mainLayout">
          <div className="layout1">
            <div className="leftLayout">
              <img src={Logo} alt="logo" />
              <p className="regular text">
                Get one stop solution for all
                <br />
                your <span>Digital Marketing</span> needs
              </p>
            </div>
            <div>
              <img src={login_left_img} alt="login" className="img3" />
            </div>
          </div>
          <div className="layout2">
            <section className="mainBlock">
              <h2 className="bold">Give us some info about you!</h2>
              <p className="regular">Answer the below questions</p>

              <h2 className="regular">
                {signUpState.userQuestionDetails[currentQuestion].questionText}
              </h2>
              {/*<div className="radiobtnsection">*/}
              {/*  {signUpState.userQuestionDetails[*/}
              {/*    currentQuestion*/}
              {/*  ].answerOptions.map((item, i) => (*/}
              {/*    // <div*/}
              {/*    //   className="answerContainer"*/}
              {/*    //   key={i}*/}
              {/*    //   onClick={() => handleClickSelected(item)}*/}
              {/*    // >*/}
              {/*    //   <h3*/}
              {/*    //     className={*/}
              {/*    //       item ===*/}
              {/*    //         signUpState.userQuestionDetails[currentQuestion]*/}
              {/*    //           .answerText && "selected"*/}
              {/*    //     }*/}
              {/*    //   >*/}
              {/*    //     {item}*/}
              {/*    //   </h3>*/}
              {/*    // </div>*/}
              {/*    <div*/}
              {/*      style={{*/}
              {/*        display: "flex",*/}
              {/*        width: "100%",*/}
              {/*        marginTop: "10px",*/}
              {/*      }}*/}
              {/*      key={i}*/}
              {/*    >*/}
              {/*      <input*/}
              {/*        type="radio"*/}
              {/*        value="option1"*/}
              {/*        style={{ width: "4%" }}*/}
              {/*        name="question"*/}
              {/*        onClick={() => handleClickSelected(item)}*/}
              {/*      />*/}
              {/*      <p*/}
              {/*        style={{*/}
              {/*          width: "96%",*/}
              {/*          paddingLeft: "20px",*/}
              {/*          marginTop: "5px",*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        {item}*/}
              {/*      </p>*/}
              {/*    </div>*/}
              {/*  ))}*/}
              {/*</div>*/}
              {signUpState.userQuestionDetails.length !== 0 && (
                <div className="questionPage-container">
                  {/*<h1>{signUpState.userQuestionDetails[currentQuestion].questionText}</h1>*/}
                  {/*<div className='questionContainer'>*/}
                  {/*<div className='questionInnerContainer'>*/}
                  {/*<h3>QUESTION {currentQuestion + 1} OF {signUpState.userQuestionDetails.length}</h3>*/}
                  {/*<p>Choose one of the below</p>*/}

                  {signUpState.userQuestionDetails[currentQuestion]
                    .questionType === "Radio" ? (
                    signUpState.userQuestionDetails[
                      currentQuestion
                    ].answerOptions.map((item, i) => (
                      <div
                        className=""
                        key={i}
                        onClick={() => handleClickSelected(item)}
                      >
                        <div className="answerandButton">
                          <div className="outer">
                            <div
                              className={
                                item ===
                                signUpState.userQuestionDetails[currentQuestion]
                                  .answerText
                                  ? "inner-selected"
                                  : "inner"
                              }
                            ></div>
                          </div>
                          <p
                            style={{
                              width: "96%",

                              marginTop: "7px",
                            }}
                          >
                            {item}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <input
                    id="inp"
                      className="input"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        handleTextBoxRes(e);
                       
                      }}

                      value={(signUpState.userQuestionDetails[currentQuestion].answerText)}
                      type={"text"}
                      placeholder={"Enter your response here"}
                    ></input>
                  )}

                  {/*</div>*/}
                  {/*<div className='continue-btn-container'>*/}
                  {/*  <button className={`back-btn ${currentQuestion === 0 ? "disableBtn" : '' }`} disabled={currentQuestion === 0}  onClick={handleClickBackBtn}>Back</button>*/}
                  {/*  <button className={`continue-btn */}
                  {/*      ${((currentQuestion === signUpState.userQuestionDetails.length - 1 && signUpState.userQuestionDetails[currentQuestion].answerText === '' ) && "disableBtn") || (signUpState.userQuestionDetails[currentQuestion].answerText === ''  && "disableBtn")}`} onClick={handleClickContinueBtn}  disabled={signUpState.userQuestionDetails[currentQuestion].answerText === ''}>Continue</button>*/}
                  {/*</div>*/}

                  {/*</div>*/}
                </div>
              )}
              <div className="qesnumber">
                <span>{currentQuestion + 1}</span> <span>out of</span>{" "}
                <span className="bold" style={{ color: "rgb(242, 139, 21)" }}>
                  {signUpState.userQuestionDetails.length}
                </span>
              </div>     
              {/* <button classNam                                                                                                                                        e="nextbtn" onClick={handleClickBackBtn}>
                <p className="regular">
                  NEXT{" "}
                  <span style={{ marginLeft: "130%" }}>
                    <ChevronRight />
                  </span>
                </p>
              </button> */}
              <div className="continue-btn-container">
                <button
                  className={`back-btn ${
                    currentQuestion === 0 ? "disableBtn" : ""
                  }`}
                  disabled={currentQuestion === 0}
                  onClick={handleClickBackBtn}
                >
                  <p className="backIcon">
                    <ChevronLeft />
                  </p>
                </button>
                <button
                  className={`continue-btn 
                            ${
                              (currentQuestion ===
                                signUpState.userQuestionDetails.length - 1 &&
                                signUpState.userQuestionDetails[currentQuestion]
                                  .answerText === "" &&
                                "disableBtn") ||
                              (signUpState.userQuestionDetails[currentQuestion]
                                .answerText === "" &&
                                "disableBtn")
                            }`}
                  onClick={handleClickContinueBtn}
                  disabled={
                    signUpState.userQuestionDetails[currentQuestion]
                      .answerText === ""
                  }
                >
                  <p className="regular">
                    NEXT
                    <span style={{ marginLeft: "130%", color: "#ffffff" }}>
                      <ChevronRight />
                    </span>
                  </p>
                </button>
              </div>
            </section>
          </div>
        </div>
      )}
    </div>
  );
}

export default QuestionsPage;
