import { CREATE_BOARD, CREATE_MENU, CREATE_PIN } from "../Constants"

export const showcreatePostMenu = ()=>{
    return{
        type:CREATE_MENU,
    }
}

export const showCreatePinMenu = () => {
    return {
        type:CREATE_PIN,
    }
}

export const showCreateBoardMenu = () => {
    return {
        type: CREATE_BOARD
    }
}