import React, { useState } from "react";
import { useNavigate } from "react-router";
import Logout from "../../../assets/logoutIcon.png";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from "reactstrap";
import "./upgradeSubscription.css";
import dropArrowDown from "../../../assets/dropDownArrow.png";
import { toast, ToastContainer } from "react-toastify";

const toastSetting = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
const UpgradeSubscription = () => {
  const [page, setPage] = useState(0);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [nameQuery, setNameQuery] = useState("");
  const [emailQuery, setEmailQuery] = useState("");
  const [isOpen, setIsOpen] = useState({});

  const PAGE_SIZE = 10;
  const navigate = useNavigate();
  const {
    data: users,
    isLoading: usersIsLoading,
    isError: usersIsError,
    error: usersError,
  } = useQuery(["fetchUsers", page, PAGE_SIZE], fetchUsers);
  const {
    data: packages,
    isLoading: packagesIsLoading,
    isError: packagesIsError,
    error: packagesError,
  } = useQuery(["fetchPackages", page, PAGE_SIZE], fetchPackages);

  const { mutate, isLoading, isError, error } = useMutation(updateUserPlan, {
    onSuccess: (successData) => {
      console.log(successData);
      toast.success("Plan Upgraded");
    },
    onError: (error) => {
      toast.error(error.message, toastSetting);
    },
  });

  const toggle = (userId) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [userId]: !prevState[userId],
    }));
  };

  if (usersIsLoading || packagesIsLoading) {
    return (
      <div className="center">
        <Spinner className="loader" />
      </div>
    );
  }
  if (usersIsError || packagesIsError) {
    return (
      <div className="center">
        {usersIsError && <h1>{usersError.message}</h1>}
        {packagesIsError && <h1>{packagesError.message}</h1>}
      </div>
    );
  }
  if (isError) {
    console.log(error.message);
  }
  console.log("package", packages.data);
  // Filter the users data based on name and email search queries
  const filteredUsers = users?.data?.filter((user) => {
    try {
      const nameMatch = user?.fName
        .toLowerCase()
        .includes(nameQuery?.toLowerCase());
      const emailMatch = user?.email
        .toLowerCase()
        .includes(emailQuery?.toLowerCase());
      return nameMatch && emailMatch;
    } catch (err) {
      return "" && "";
    }
  });

  function handleSelectPlan({
    id,
    subscription,
    days,
  }) {
    setSelectedPlans((prevSelectedPlans) => {
      // Check if the user already has a plan selected, and update it if they do
      const updatedPlans = prevSelectedPlans.map((selectedPlan) => {
        if (selectedPlan._id === id) {
          return {
            _id: id,
            subscription: subscription,
            days: Number(days),
            planExpiry: new Date().getTime() + Number(days) * 86400000,
          };
        }
        return selectedPlan;
      });

      // If the user doesn't have a plan selected, add the new plan to the state
      if (!updatedPlans.some((selectedPlan) => selectedPlan._id === id)) {
        updatedPlans.push({
          _id: id,
          subscription: subscription,
          planExpiry: new Date().getTime() + Number(days) * 86400000,
        });
      }

      return updatedPlans;
    });
  }

  console.log(selectedPlans);
  console.log(filteredUsers);


  function updateUserPlan(user) {
    if (user.subscription === "") throw new Error("Please select a plan");
    const data = {
      _id: user._id,
      userData: {
        subscription: user.subscription.replaceAll(",", ""),
        planExpiry: selectedPlans[0]?.planExpiry,
      },
    };
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER_URI}/api/users`,
      headers: {
        Accept: "application/ecmascript",
        "Content-Type": "application/json",
      },
      data: data,
    };
    const response = axios(config);
    return response;
  }


  return (
    <div className="userOuterLayout">
      <ToastContainer />
      <div className="headSection regular">
        <span>Upgrade Subscription</span>
        <img
          src={Logout}
          alt="logout"
          onClick={() => {
            localStorage.clear();
            sessionStorage.clear();
            return navigate("/admin-login");
          }}
        />
      </div>
      <div className="searchSection">
        <input
          type="text"
          placeholder="Search by name"
          value={nameQuery}
          onChange={(e) => setNameQuery(e.target.value)}
        />
        <input
          type="text"
          placeholder="Search by email"
          value={emailQuery}
          onChange={(e) => setEmailQuery(e.target.value)}
        />
      </div>
      <div className="dataSection">
        <table>
          <thead>
            <tr
              style={{
                backgroundColor: "red",
                padding: "40px",
                textAlign: "center",
              }}
            >
              <th>
                <span>First Name</span>
              </th>
              <th>
                <span>Email</span>
              </th>
              <th>
                <span>Current Plan</span>
              </th>
              <th>
                <span>Payment Status</span>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {nameQuery || emailQuery
              ? filteredUsers.map((user) => (
                  <tr key={user?._id}>
                    <td>{user?.fName}</td>
                    <td>{user?.email}</td>
                    <td>{user?.subscription}</td>
                    <td>
                      <Dropdown
                        isOpen={isOpen[user?._id]}
                        toggle={() => {
                          toggle(user?._id);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              width: "80%",
                              height: "100%",
                              textAlign: "center",

                              fontWeight: "bold",
                            }}
                          >
                            {selectedPlans.some(
                              (obj) => obj._id === user?._id
                            ) ? (
                              selectedPlans.map((item) =>
                                item?._id === user?._id
                                  ? item?.subscription
                                  : ""
                              )
                            ) : (
                              <span style={{ color: "gray" }}>
                                Select a plan
                              </span>
                            )}
                          </span>
                          <div>
                            <DropdownToggle caret outline>
                              <img
                                src={dropArrowDown}
                                alt=""
                                style={{ width: "15px" }}
                              />
                            </DropdownToggle>
                          </div>
                        </div>

                        <DropdownMenu>
                          <DropdownItem header style={{ textAlign: "center" }}>
                            <span>Subscription plan</span>
                          </DropdownItem>
                          {packages?.data?.map((item) => (
                            <DropdownItem
                              key={item.packageText}
                              onClick={() => {
                                handleSelectPlan({
                                  id: user?._id,
                                  subscription: item.packageText,
                                  days: item.packageDays,
                                });
                              }}
                            >
                              {item.packageText}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </td>
                    <td>
                      <button
                      className="upgradenavButton"
                        onClick={() =>
                          mutate({
                            _id: user?._id,
                            subscription: selectedPlans
                              .map((item) => {
                                if (item?._id === user?._id) {
                                  return item?.subscription;
                                } else {
                                  return "";
                                }
                              })
                              .toString(),
                          })
                        }
                      >
                        Upgrade
                      </button>
                    </td>
                  </tr>
                ))
              : filteredUsers
                  .slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE)
                  .map((user) => (
                    <tr key={user?._id}>
                      <td>{user?.fName}</td>
                      <td>{user?.email}</td>
                      <td>
                        <span
                          className={`${
                            user?.subscription === "free" ? "free" : "beginner"
                          }`}
                        >
                          {user?.subscription}
                        </span>
                      </td>
                      <td>
                        <Dropdown
                          isOpen={isOpen[user?._id]}
                          toggle={() => {
                            toggle(user?._id);
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                width: "80%",
                                height: "100%",
                                textAlign: "center",
                                fontWeight: "bold",
                              }}
                            >
                              {selectedPlans.some(
                                (obj) => obj._id === user?._id
                              ) ? (
                                selectedPlans.map((item) =>
                                  item?._id === user?._id
                                    ? item?.subscription
                                    : ""
                                )
                              ) : (
                                <span style={{ color: "gray" }}>
                                  Select a plan
                                </span>
                              )}
                            </span>
                            <div>
                              <DropdownToggle caret outline>
                                <img
                                  src={dropArrowDown}
                                  alt=""
                                  style={{ width: "15px", zIndex: "0" }}
                                />
                              </DropdownToggle>
                            </div>
                          </div>

                          <DropdownMenu>
                            <DropdownItem
                              header
                              style={{ textAlign: "center" }}
                            >
                              <span>Subscription plan</span>
                            </DropdownItem>
                            <DropdownItem divider />
                            {packages?.data?.map((item) => (
                              <DropdownItem
                                key={item.packageText}
                                onClick={() => {
                                  handleSelectPlan({
                                    id: user?._id,
                                    subscription: item.packageText,
                                    days: item.packageDays,
                                  });
                                }}
                              >
                                {item.packageText}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </td>
                      <td>
                        <button
                          onClick={() =>
                            mutate({
                              _id: user?._id,
                              subscription: selectedPlans
                                .map((item) => {
                                  if (item?._id === user?._id) {
                                    return item?.subscription;
                                  } else {
                                    return "";
                                  }
                                })
                                .toString(),
                            })
                          }
                        >
                          Upgrade
                        </button>
                      </td>
                    </tr>
                  ))}
          </tbody>
        </table>
        <div className="paginate">
          <button
            disabled={page === 0}
            onClick={() => setPage((prev) => prev - 1)}
          >
            Previous
          </button>
          <span>
            <b>Page {page + 1}</b>
          </span>
          <button
            disabled={
              usersIsLoading ||
              filteredUsers?.length === 0 ||
              page === Math.floor(filteredUsers?.length / 10)
            }
            onClick={() => setPage(page + 1)}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

function fetchUsers({ queryKey }) {
  const [_, page, pageSize] = queryKey;
  const offset = page * pageSize;
  const url = `${process.env.REACT_APP_SERVER_URI}/api/users/getUsers?offset=${offset}&limit=${pageSize}`;
  return axios.get(url);
}
function fetchPackages() {
  const url = `${process.env.REACT_APP_SERVER_URI}/api/packages`;
  return axios.get(url);
}



export default UpgradeSubscription;
