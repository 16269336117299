import React, { useState, useEffect } from "react";
import FormInput from "../../Components/FormInput/FormInput";
import { useLocation, useNavigate } from "react-router-dom";
import warning from "../../assets/warning.png";
import { loginApi } from "../../Utils/api";
import { ScaleLoader } from "react-spinners";
import "./LoginPage.css";
import AuthAPI from "../../Utils/AuthAPI";
import Logo from "../../../src/assets/logo1.png";
import Digi from "../../../src/assets/digi.png";
import login_left_img from "../../../src/assets/login_left_img.png";
import underLine from "../../assets/underline.svg";
import { ChevronRight } from "react-feather";
import { ToastContainer, toast } from "react-toastify";

function LoginPage({ loginState, setLoginState, setUserInfo, userInfo }) {
  const location = useLocation();
  const authApi = React.useContext(AuthAPI);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const checkLogin = (params) => {
    if (localStorage.getItem("token") || sessionStorage.getItem("token")) {
      navigate("/dashboard");
      authApi.setAuth(true);
    }
  };

  useEffect(() => {
    if (location.pathname !== "/link-account") {
      checkLogin(userInfo);
    }
  }, []);

  console.log(emailError);
  console.log(passwordError);

  const handleClickLogin = () => {
    if (loginState.email.length === 0 && loginState.password.length === 0) {
      setLoginError("Please fill all the Input");
      return;
    }

    if (loginError?.length !== 0) {
      setLoginError("");
    }
    if (emailError == "" && passwordError == "") {
      setIsLoading(true);
      loginUser();
      return;
    }
  };

  const handleClickRedirect = () => {
    navigate("/create-account");
  };

  async function loginUser() {
    try {
      const res = await loginApi(loginState);
      if (res.status === 200) {
        setIsLoading(false);
        setUserInfo(res.data);
        sessionStorage.setItem("token", res.data.token);
        localStorage.setItem("token", res.data.token);
        sessionStorage.setItem("userProfileInfo", JSON.stringify(res.data));
        localStorage.setItem("userProfileInfo", JSON.stringify(res.data));
        if(res.data.plan === "free" && res.data.createdAt < new new Date().getDate() - 7){
          navigate("/upgrade-plan");
        }else
        if (res.data.role === "user") {
          navigate("/dashboard");
        } else if (res.data.role === "admin") {
          navigate("/admin-dashboard");
        }
        authApi.setAuth(true);
      }
    } catch (error) {

      console.log(error)
      if(error.response.status === 429) {
        toast.error('Too many requests')
      }
      console.log(error);
      setLoginError(error.response.data.message);
      setIsLoading(false);
    }
  }

  const [showPassword, setShowPassword] = useState(false);


  return (
    <div>
      <div className="mainLayout">

        <ToastContainer />
        <div className="layout1">
          <div className="leftLayout">
            <img src={Logo} alt="logo" />
            <p className="regular text">
              Get one stop solution for all
              <br />
              your <span>Digital Marketing</span> needs
            </p>
          </div>
          <div>
            <img src={login_left_img} alt="login" className="img3" />
          </div>
        </div>
        <div className="layout2">
          <section className="mainBlock">
            <div className="boldText">
              <h3>Login into toowe account</h3>
              <h6 className="mb-5">Get Started using below details</h6>
              {loginError?.length !== 0 && (
                <p className="errorcase">
                  {loginError}
                  <img src={warning} alt="error" />
                </p>
              )}
              <div className="field">
                <p className="regular">Email</p>
                <FormInput
                  inputType="email"
                  name="email"
                  setState={setLoginState}
                  setError={setEmailError}
                  setValidationError={setLoginError}
                />
              </div>
              {emailError.length !== 0 && (
                <p className="errorcase">
                  {emailError} <img src={warning} alt="error" />
                </p>
              )}
           <div className="field">
    <p className="regular">Password</p>
    <FormInput
        inputType={showPassword ? "text" : "password"}
        name="password"
        setState={setLoginState}
        setError={setPasswordError}
        setValidationError={setPasswordError}
    />
    <div style={{marginTop:'10px', display:'flex', alignItems:'center'}}>

    <input style={{width:'20px', height: '20px', marginRight:'5px'}} type="checkbox" onClick={() => setShowPassword(!showPassword)} /> Show Password
    </div>
</div>
              {passwordError.length !== 0 && (
                <p className="errorcase">
                  {passwordError} <img src={warning} alt="error" />
                </p>
              )}
              <button
                type="submit"
                className="login-btn"
                onClick={handleClickLogin}
              >
                <div className="loginBtn-Container">
                  <p className="logintxt regular">
                    LOGIN &nbsp;&nbsp;&nbsp;
                    <ChevronRight className="arrow" />
                  </p>
                  <ScaleLoader
                    style={{ paddingRight: "20px" }}
                    width={2}
                    height={20}
                    size={20}
                    color="#ffffff"
                    loading={isLoading}
                  />
                  {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                </div>
              </button>
            </div>
            <p
              className="regular blwText links_clr"
              // onClick={handleClickRedirect}
              style={
                {
                  // marginTop: "10px",
                  // cursor: "pointer",
                }
              }
            >
              <a
                href="ForgotPassword"
                style={{ textDecoration: "none",display:"inline-block", marginBottom: "20px" }}
              >
                Forgot password?
              </a>
              <br />
              Don't have an account?{" "}
              <a href="create-account" style={{ textDecoration: "none" }}>
                Sign Up Now
              </a>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
