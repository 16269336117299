import { useState, useEffect, useCallback, useRef } from "react";
import "./CreateNewPost.css";
import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import { date, time } from "./Modal/Modal";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { showcreatePostMenu } from "../../Redux/Actions/CreateNewPostAction";
import DropzoneArea from "../../Components/Dropzone/Dropzone";
import ReelsDropzoneArea from "../../Components/Dropzone/ReelsDropzone";
import YoutubeDropzoneArea from "../../Components/Dropzone/YoutubeDropzone";
import Alert from "../../Components/Alert/Alert";
import CreatableSelect from "react-select/creatable";
import { InstaGramUsersSearch } from "../../Utils/InstaGramUsersSearch";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";
import { SiAdobe } from "react-icons/si";

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Button,
} from "reactstrap";

// import InputEmoji from "react-input-emoji";
import emojiesIcon from "../../assets/emojiesIcon.png";
import "react-toastify/dist/ReactToastify.css";
import { MoonLoader, ScaleLoader } from "react-spinners";
import "./createPost.css";
import Modal from "./Modal/Modal";
import { useQuery } from "react-query";
import { BsTrash } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import {
  schedulePost,
  FaceBookImagePost,
  FaceBookTextPost,
  FacebookVideoPost,
  facebookScheduleVideos,
  instaPost,
  instaReelPost,
  postDrafts,
  scheduleDrafts,
  postLinkedInMessage,
  postLinkedInImage,
  postLinkedInVideo,
  LinkedinScheduleVideo,
  GETALLAGENDAPOSTS,
  postTwitterMessage,
  scheduleTwitterTexts,
  facebookScheduleMessages,
  linkedInTextSchedules,
  linkedInImageSchedules,
  Twitter_Image_Schedule,
  scheduleTwiterVideo,
  DELETEAGENDAPOSTS,
  postTwitterImage,
  postTwitterVideo,
  scheduleTwitterImages,
  linkedInRefreshTokens,
  youtubePostVideos,
  youtubeSchedulePostVideos,
  getPageDetailsApi,
  instagramScheduleAPI,
  Instagram_Video_Schedule,
  Facebook_Image_Schedule,
  Linkedin_Image_Schedule,
  Linkedin_Video_Schedule,
  Instagram_Image_Schedule,
  instagramStoryAPI,
  facebookReelAPI,
  createImagePin,
  ScheduleImagePin,
  facebookScheduleReelAPI,
} from "../../Utils/api";
import { BarLoader } from "react-spinners";
import Pixabay from "../../Components/Pixabay/Pixabay.js";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import axios from "axios";
import { json, useNavigate } from "react-router-dom";
import { Video } from "react-feather";
import { toast, ToastContainer } from "react-toastify";
import { async } from "q";
import { InputGroup, Tooltip } from "reactstrap";
import { Autocomplete } from "../../Components/AutoComplete/AutoComplete";
import { Mention, MentionsInput } from "react-mentions";
import defaultStyle from "../../Utils/defaultStyle";
import defaultMentionStyle from "../../Utils/defaultMentionStyle";
import { Input } from "@mui/material";
import { Switch } from "@material-ui/core";
import SelectInput from "@mui/material/Select/SelectInput";
import InstaImageDropzone from "../../Components/Dropzone/InstaDropzone";
import { set } from "react-ga";
import CustomContent from "../../Components/Customcontent/Customcontent";
import { toBeRequired } from "@testing-library/jest-dom/matchers";
import VideoDropzonearea from "../../Components/Dropzone/VideoDropzone.js";
import ReactSelect from "react-select";

// async =()=>{
//     const data = await getPageDetailsApi()
//     console.log(data)

// }

const ImageCarousel = ({ imageUrls }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === imageUrls.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? imageUrls.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = imageUrls?.map((imageUrl, index) => (
    <CarouselItem
      key={imageUrl}
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
    >
      <img src={imageUrl} style={{ width: "100%" }} alt={`Slide ${index}`} />
    </CarouselItem>
  ));

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators
        items={imageUrls}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
};

function NewPost({ postContent, paths, customText, selectedPreview }) {
  return (
    <>
      <div></div>
      {customText?.length > 0 &&
      customText?.find((item) => item?.id === selectedPreview.id) ? (
        customText?.find((item) => item?.id === selectedPreview.id).value
          .length > 0 && (
          <div
            dangerouslySetInnerHTML={{
              __html: customText
                ?.find((item) => item?.id === selectedPreview.id)
                .value?.replace(/(https?:\/\/[^\s]+)/g, function (url) {
                  return '<a target="_blank" href=' + url + ">" + url + "</a>";
                }),
            }}
          />
        )
      ) : postContent.length >= 0 ? (
        <div
          dangerouslySetInnerHTML={{
            __html: postContent?.replace(
              /(https?:\/\/[^\s]+)/g,
              function (url) {
                return '<a target="_blank" href=' + url + ">" + url + "</a>";
              }
            ),
          }}
        />
      ) : null}
      {paths?.FileArr?.length > 0 && (
        <ImageCarousel imageUrls={paths?.FileArr} />
      )}

      {/* <img src={paths?.facebook} alt="" style={{ width: "100%" }} /> */}
    </>
  );
}

function ImgDetails({ paths, imgName, handleClickDeleteImage }) {
  return (
    <>
      <div
        style={{
          border: "1px solid rgb(208, 208, 208)",
          padding: "15px",
          display: "flex",
        }}
      >
        <span>
          {" "}
          <img src={paths.facebook} alt="" style={{ maxHeight: "100px" }} />
        </span>
        <div>
          <p style={{ marginLeft: "10px", fontSize: "12px" }}>{imgName}</p>
          <p style={{ marginLeft: "10px", fontSize: "12px" }}>
            Source: Uploads
          </p>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <button
            className="delBtn"
            width="auto"
            height="28"
            type="button"
            onClick={handleClickDeleteImage}
          >
            <svg width="16" height="16" viewBox="0 0 20 20" fill="#ef6927">
              <path d="M15,1.25 L15,3.75 L20,3.75 L20,6.25 L18.75,6.25 L18.75,18.75 C18.75,19.5 18.25,20 17.5,20 L2.5,20 C1.75,20 1.25,19.5 1.25,18.75 L1.25,6.25 L0,6.25 L0,3.75 L5,3.75 L5,1.25 C5,0.5 5.5,0 6.25,0 L13.75,0 C14.5,0 15,0.5 15,1.25 Z M7.5,2.5 L7.5,3.75 L12.5,3.75 L12.5,2.5 L7.5,2.5 Z M16.25,6.25 L3.75,6.25 L3.75,17.5 L16.25,17.5 L16.25,6.25 Z M6.25,8.75 L8.75,8.75 L8.75,16.25 L6.25,16.25 L6.25,8.75 Z M11.25,8.75 L13.75,8.75 L13.75,16.25 L11.25,16.25 L11.25,8.75 Z"></path>
            </svg>
          </button>
        </div>
      </div>
    </>
  );
}

function ImageUploadSpinner() {
  return (
    <>
      <div style={{ border: "2px solid #000", padding: "15px" }}>
        <BarLoader color="#143059" height={9} speedMultiplier={2} width={400} />

        {/* <div style={{ marginLeft: "auto" }}>
        <button
          className="delBtn"
          width="auto"
          height="28"
          type="button"
          onClick={handleClickDeleteImage}
        >
          <svg width="16" height="16" viewBox="0 0 20 20" fill="#2f3638">
            <path d="M15,1.25 L15,3.75 L20,3.75 L20,6.25 L18.75,6.25 L18.75,18.75 C18.75,19.5 18.25,20 17.5,20 L2.5,20 C1.75,20 1.25,19.5 1.25,18.75 L1.25,6.25 L0,6.25 L0,3.75 L5,3.75 L5,1.25 C5,0.5 5.5,0 6.25,0 L13.75,0 C14.5,0 15,0.5 15,1.25 Z M7.5,2.5 L7.5,3.75 L12.5,3.75 L12.5,2.5 L7.5,2.5 Z M16.25,6.25 L3.75,6.25 L3.75,17.5 L16.25,17.5 L16.25,6.25 Z M6.25,8.75 L8.75,8.75 L8.75,16.25 L6.25,16.25 L6.25,8.75 Z M11.25,8.75 L13.75,8.75 L13.75,16.25 L11.25,16.25 L11.25,8.75 Z"></path>
          </svg>
        </button>
      </div> */}
      </div>
    </>
  );
}

const randomStringId = () => {
  return Math.random().toString(36).substr(2, 15);
};

function CreateNewPost({
  pagesDetails,
  userInfo,
  isLoading,
  setIsLoading,
  eventData,
  setPagesDetails,
  accountsSelectedDefault,
}) {
  console.log("eventData", eventData);
  const [searchQuery, setSearchQuery] = useState("");
  const [tooltipToggle, setTooltipToggle] = useState(false);
  const toggle = () => setTooltipToggle(!tooltipToggle);
  const [customText, setCustomText] = useState("");
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [instaUsers, setInstaUsers] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchInstaUsers = async () => {
      const users = await InstaGramUsersSearch(searchText);
      setInstaUsers(users);
    };

    fetchInstaUsers();
  }, [searchText]);

  const [youtubeIsEmbaddable, setYoutubeIsEmbaddable] = useState(true);
  const [youtubeKeywords, setYoutubeKeywords] = useState([]);
  const [youtubeIsNotifySubscribers, setYoutubeIsNotifySubscribers] =
    useState(true);

  useEffect(() => {
    console.log("gemless", youtubeKeywords);
  }, [youtubeKeywords]);

  useEffect(() => {
    if (pagesDetails?.length === 0 && !eventData) {
      getPageDetailsApi({ jwToken: localStorage.getItem("token") }).then(
        (res) => setPagesDetails(res.data)
      );
    }
  }, [selectedVendor]);

  const [ReelUrl, setReelUrl] = useState(eventData?.extra?.vidurl || null);
  const [VideoUrl, setVideoUrl] = useState(eventData?.extra?.vidurl || null);
  //autocomplete
  const {
    data: hashtagData,
    isLoading: hashtagIsLoading,
    isError: hashtagIsError,
    error: hashtagError,
    refetch,
  } = useQuery(["fetchHashtags", `${searchQuery}`], fetchHashtags, {
    enabled: false,
  });

  function searchHashtag(search, callback) {
    if (!search) return;
    setSearchQuery(search);
    // refetch();
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URI}/api/hashtags/autoComplete/${search}`
      )
      .then((res) => {
        const data = res.data.map((item) => ({ id: item, display: item }));
        callback(data);
      })
      .catch((err) => console.log("Err", err.message));
    // if (!hashtagIsLoading && !hashtagIsError) {
    //   const data = hashtagData.data.map((item) => ({
    //     id: item,
    //     display: item,
    //   }));
    //   callback(data);
    // } else return;
  }
  //
  function ReelsDetails({ videoUrl, setVideoUrl }) {
    return (
      <>
        <div
          style={{ border: "2px solid #000", padding: "15px", display: "flex" }}
        >
          <span>
            {" "}
            <video src={videoUrl} controls width={"220px"} height={"340px"} />
          </span>
          <div>
            <p style={{ marginLeft: "10px", fontSize: "12px" }}>
              Source: Uploads
            </p>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <button
              className="delBtn"
              width="auto"
              height="28"
              type="button"
              onClick={() => {
                setVideoUrl(null);
              }}
            >
              <svg width="16" height="16" viewBox="0 0 20 20" fill="#2f3638">
                <path d="M15,1.25 L15,3.75 L20,3.75 L20,6.25 L18.75,6.25 L18.75,18.75 C18.75,19.5 18.25,20 17.5,20 L2.5,20 C1.75,20 1.25,19.5 1.25,18.75 L1.25,6.25 L0,6.25 L0,3.75 L5,3.75 L5,1.25 C5,0.5 5.5,0 6.25,0 L13.75,0 C14.5,0 15,0.5 15,1.25 Z M7.5,2.5 L7.5,3.75 L12.5,3.75 L12.5,2.5 L7.5,2.5 Z M16.25,6.25 L3.75,6.25 L3.75,17.5 L16.25,17.5 L16.25,6.25 Z M6.25,8.75 L8.75,8.75 L8.75,16.25 L6.25,16.25 L6.25,8.75 Z M11.25,8.75 L13.75,8.75 L13.75,16.25 L11.25,16.25 L11.25,8.75 Z"></path>
              </svg>
            </button>
          </div>
        </div>
      </>
    );
  }

  const [youtubeUrl, setYoutubeUrl] = useState();
  function YoutubeDetails({ youtubeUrl, setYoutubeUrl }) {
    return (
      <>
        <div
          style={{ border: "2px solid #000", padding: "15px", display: "flex" }}
        >
          <span>
            {" "}
            <video
              src={youtubeUrl}
              controls
              autoPlay
              width={"220px"}
              height={"340px"}
            />
          </span>
          <div>
            <p style={{ marginLeft: "10px", fontSize: "12px" }}>
              Source: Uploads
            </p>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <button
              className="delBtn"
              width="auto"
              height="28"
              type="button"
              onClick={() => {
                setYoutubeUrl(null);
              }}
            >
              <svg width="16" height="16" viewBox="0 0 20 20" fill="#2f3638">
                <path d="M15,1.25 L15,3.75 L20,3.75 L20,6.25 L18.75,6.25 L18.75,18.75 C18.75,19.5 18.25,20 17.5,20 L2.5,20 C1.75,20 1.25,19.5 1.25,18.75 L1.25,6.25 L0,6.25 L0,3.75 L5,3.75 L5,1.25 C5,0.5 5.5,0 6.25,0 L13.75,0 C14.5,0 15,0.5 15,1.25 Z M7.5,2.5 L7.5,3.75 L12.5,3.75 L12.5,2.5 L7.5,2.5 Z M16.25,6.25 L3.75,6.25 L3.75,17.5 L16.25,17.5 L16.25,6.25 Z M6.25,8.75 L8.75,8.75 L8.75,16.25 L6.25,16.25 L6.25,8.75 Z M11.25,8.75 L13.75,8.75 L13.75,16.25 L11.25,16.25 L11.25,8.75 Z"></path>
              </svg>
            </button>
          </div>
        </div>
      </>
    );
  }

  const socials = {
    facebook: {
      name: "Facebook",
      icon: `
       M20 10.0001C19.9997 8.08916 19.4518 6.21827 18.4213 4.60898C17.3908 2.99968 15.9207 1.71936 14.1851 0.919594C12.4495 0.119828 10.5211 -0.165895 8.62821 0.0962525C6.73529 0.3584 4.95712 1.15744 3.50421 2.39877C2.0513 3.64011 0.984494 5.27176 0.430087 7.10055C-0.124321 8.92935 -0.143113 10.8787 0.375935 12.7178C0.894983 14.557 1.93014 16.2089 3.35884 17.478C4.78755 18.7471 6.54999 19.5803 8.4375 19.8789V12.8908H5.89875V10.0001H8.4375V7.79702C8.4375 5.29077 9.93062 3.90639 12.2144 3.90639C12.9644 3.91679 13.7126 3.98197 14.4531 4.10139V6.56264H13.1919C12.9769 6.5341 12.7584 6.55428 12.5523 6.6217C12.3462 6.68912 12.158 6.80205 12.0015 6.95211C11.845 7.10218 11.7243 7.28553 11.6483 7.48859C11.5723 7.69165 11.543 7.90921 11.5625 8.12514V10.0001H14.3362L13.8925 12.8908H11.5625V19.8789C13.9149 19.5068 16.0572 18.3073 17.604 16.4963C19.1507 14.6853 20.0004 12.3818 20 10.0001V10.0001Z
     `,
      TextFunction: (params) => {
        return FaceBookTextPost(params);
      },
      ImageFunction: (params) => {
        return FaceBookImagePost(params);
      },
      VideoFunction: (params) => {
        return FacebookVideoPost(params);
      },
      scheduleImageFunction: (params) => {
        return Facebook_Image_Schedule(params);
      },
      scheduleVideoFunction: (params) => {
        return facebookScheduleVideos(params);
      },
      ReelFunction: (params) => {
        return facebookReelAPI(params);
      },
      scheduleReelFunction: (params) => {
        return facebookScheduleReelAPI(params);
      },
      buttonBottom: {
        like: `https://i.hootsuite.com/assets/plancreate/fb-like.svg`,
        share: `https://i.hootsuite.com/assets/plancreate/fb-share.svg`,
        comment: `https://i.hootsuite.com/assets/plancreate/fb-comment.svg`,
      },
      color: "#3b5998",
    },
    instagram: {
      name: "Instagram",
      icon: `M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z
       `,
      TextFunction: (params) => {
        console.log(params);
      },
      ImageFunction: (params) => {
        return instaPost(params);
      },
      VideoFunction: (params) => {
        return instaReelPost(params);
      },
      scheduleImageFunction: (params) => {
        return Instagram_Image_Schedule(params);
      },
      scheduleVideoFunction: (params) => {
        return Instagram_Video_Schedule(params);
      },

      StoryFunction: (params) => {
        return instagramStoryAPI(params);
      },
      ReelFunction: (params) => {
        return instaReelPost(params);
      },
      scheduleReelFunction: (params) => {
        return Instagram_Video_Schedule(params);
      },

      buttonBottom: {
        like: `https://i.hootsuite.com/assets/plancreate/fb-like.svg`,
        share: `https://i.hootsuite.com/assets/plancreate/fb-share.svg`,
        comment: `https://i.hootsuite.com/assets/plancreate/fb-comment.svg`,
      },
      color: "#3b5998",
    },
    twitter: {
      name: "Twitter",
      icon: `
       M16.134 6.188C15.806 6.699 15.404 7.133 14.934 7.492C14.943 7.566 14.943 7.676 14.943 7.82C14.9401 9.22326 14.5464 10.598 13.806 11.79C13.4322 12.4001 12.9733 12.9537 12.443 13.434C11.8821 13.9286 11.2346 14.3152 10.533 14.574C9.76895 14.8618 8.95839 15.0062 8.14197 15C6.83072 15.008 5.54876 14.6124 4.46997 13.867C4.64197 13.887 4.83297 13.898 5.04397 13.898C6.13107 13.9074 7.18515 13.5247 8.01297 12.82C7.50642 12.8116 7.01719 12.6342 6.62297 12.316C6.21843 11.999 5.9233 11.5633 5.77897 11.07C5.94297 11.098 6.09097 11.11 6.23197 11.11C6.44197 11.11 6.65397 11.082 6.86097 11.023C6.31332 10.9068 5.82547 10.5981 5.48597 10.153C5.12478 9.69652 4.9329 9.129 4.94297 8.547V8.516C5.27096 8.71203 5.64312 8.8221 6.02497 8.836C5.69559 8.60494 5.42835 8.29614 5.24697 7.937C5.05272 7.56604 4.95343 7.15272 4.95797 6.734C4.95797 6.277 5.06797 5.852 5.28197 5.461C5.8689 6.229 6.61188 6.86394 7.46197 7.324C8.30816 7.78759 9.24833 8.05358 10.212 8.102C10.174 7.91133 10.1545 7.71742 10.154 7.523C10.154 6.824 10.388 6.23 10.853 5.738C11.322 5.246 11.884 5 12.544 5C13.236 5 13.818 5.266 14.29 5.797C14.8295 5.68765 15.3445 5.48094 15.81 5.187C15.6435 5.76458 15.2691 6.26008 14.759 6.578C15.216 6.528 15.677 6.395 16.134 6.188Z"
         `,
      TextFunction: (params) => {
        return postTwitterMessage(params)
          .then(() => console.log("Twitter Auth Token is valid"))
          .catch(async () => {
            // window.alert("Invalid/Expired Auth Token Twitter");
            const res = await axios.post(
              `${process.env.REACT_APP_SERVER_URI}/api/crud/getTwitter`
            );
            window.location.href = res.data.url;
          });
      },
      ImageFunction: (params) => {
        return postTwitterImage(params)
          .then(() => console.log("Twitter Auth Token is valid"))
          .catch(async () => {
            // window.alert("Invalid/Expired Auth Token Twitter");
            // const res = await axios.post(
            //   `${process.env.REACT_APP_SERVER_URI}/api/crud/getTwitter`
            // );
            // window.location.href = res.data.url;
          });
      },
      VideoFunction: (params) => {
        return postTwitterVideo(params);
      },
      scheduleImageFunction: (params) => {
        return Twitter_Image_Schedule(params);
      },
      scheduleVideoFunction: (params) => {
        return scheduleTwiterVideo(params);
      },
      ReelFunction: (params) => {
        return postTwitterVideo(params);
      },
      scheduleReelFunction: (params) => {
        return scheduleTwiterVideo(params);
      },
      buttonBottom: {
        like: `https://i.hootsuite.com/assets/plancreate/tw-reply.svg`,
        share: `https://i.hootsuite.com/assets/plancreate/tw-retweet.svg`,
        comment: `https://i.hootsuite.com/assets/plancreate/tw-like.svg`,
      },
      color: "#1da1f2",
    },
    linkedin: {
      name: "Linkedin",
      icon: `  
      M6.97347 5.39759C6.97878 5.79554 6.84294 6.1277 6.56703 6.39512C6.29112 6.66254 5.92819 6.79519 5.47825 6.79519H5.46233C5.02299 6.79519 4.66961 6.66254 4.40113 6.39512C4.13371 6.1277 4 5.79554 4 5.39759C4 4.99434 4.13796 4.66112 4.41387 4.39689C4.68978 4.13159 5.05058 4 5.49416 4C5.93987 4 6.29537 4.13265 6.56279 4.39689C6.83127 4.66219 6.96604 4.99434 6.97241 5.39759H6.97347ZM6.80368 7.89883V16H4.15387V7.89883H6.80368ZM10.9126 9.04676C11.0138 8.88166 11.1171 8.7416 11.2236 8.61514C11.336 8.48461 11.4889 8.34241 11.6778 8.19066C11.8688 8.03785 12.1022 7.919 12.3781 7.83516C12.654 7.75027 12.9597 7.70782 13.2971 7.70782C14.2129 7.70782 14.9494 8.01769 15.5065 8.6353C16.0637 9.25398 16.3428 10.1602 16.3428 11.3541V15.9968H13.6983V11.664C13.6983 11.0909 13.59 10.6452 13.3735 10.3194C13.156 9.99576 12.8175 9.83339 12.3569 9.83339C12.0195 9.83339 11.7372 9.92572 11.508 10.1146C11.2809 10.3024 11.1111 10.5348 10.9986 10.8129C10.9402 10.9763 10.9105 11.197 10.9105 11.4751V15.9958H8.26813C8.27874 13.8203 8.28299 12.0587 8.28299 10.7068C8.28299 9.35585 8.28299 8.54935 8.27768 8.28723L8.27025 7.89459H10.9126V9.04676Z"
      
    `,
      TextFunction: (params) => {
        console.log("Hitted Text Function");
        return (
          postLinkedInMessage(params)
            // .then(() => window.alert("Text Message Posted on Linkedin"))
            .catch((err) => {
              window.alert(
                "Invalid/Expired Auth Token. Try Again after login."
              );
              window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&scope=r_liteprofile%20r_emailaddress%20w_member_social&state=123456&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}/linkedin`;
            })
        );
      },
      ImageFunction: (params) => {
        console.log("Hitted Image Function");
        return (
          postLinkedInImage(params)
            // .then(() => window.alert("Text Message Posted on Linkedin"))
            .catch((err) =>
              linkedInRefreshTokens()
                .then(() => postLinkedInMessage(params))
                .catch(() => console.log(err))
            )
        );
      },
      scheduleImageFunction: (params) => {
        return Linkedin_Image_Schedule(params);
      },
      VideoFunction: (params) => {
        postLinkedInVideo(params);
      },
      ReelFunction: (params) => {
        postLinkedInVideo(params);
      },
      scheduleVideoFunction: (params) => {
        return Linkedin_Video_Schedule(params);
      },
      scheduleReelFunction: (params) => {
        return Linkedin_Video_Schedule(params);
      },
      buttonBottom: {
        like: `https://i.hootsuite.com/assets/plancreate/li-like.svg`,
        share: `https://i.hootsuite.com/assets/plancreate/li-share.svg`,
        comment: `https://i.hootsuite.com/assets/plancreate/li-comment.svg`,
      },
      color: "#0077B5",
    },
    youtube: {
      name: "Youtube",
      icon: `M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z`,
      TextFunction: (params) => {
        return youtubePostVideos(params);
      },
      ImageFunction: (params) => {
        // return youtubePostVideos(params);
        return console.log("no Image Function");
      },
      VideoFunction: (params) => {
        return youtubePostVideos(params);
      },
      scheduleImageFunction: (params) => {
        // return FacebookScheduleImagePost(params);
      },
      scheduleVideoFunction: (params) => {
        return youtubeSchedulePostVideos(params);
      },
      ReelFunction: (params) => {
        return youtubePostVideos(params);
      },
      scheduleReelFunction: (params) => {
        return youtubeSchedulePostVideos(params);
      },
      buttonBottom: {
        like: `https://i.hootsuite.com/assets/plancreate/fb-like.svg`,
        share: `https://i.hootsuite.com/assets/plancreate/fb-share.svg`,
        comment: `https://i.hootsuite.com/assets/plancreate/fb-comment.svg`,
      },
      color: "#FF0000",
    },
    pinterest: {
      name: "Pinterest",
      icon: "M10.8097 14.2306C10.4323 14.06 9.98797 14.2276 9.81733 14.605C9.64668 14.9824 9.81431 15.4267 10.1917 15.5974L10.8097 14.2306ZM12.5007 15.344L12.5015 16.0941L12.5136 16.0939L12.5007 15.344ZM17.5007 10.172L16.7508 10.1595C16.7507 10.1678 16.7507 10.1762 16.7508 10.1845L17.5007 10.172ZM12.5007 5L12.5136 4.25011C12.505 4.24996 12.4964 4.24996 12.4879 4.25011L12.5007 5ZM7.50072 10.172L8.25072 10.1741C8.25073 10.1692 8.2507 10.1644 8.25062 10.1595L7.50072 10.172ZM7.68386 13.4079C7.90528 13.758 8.36855 13.8623 8.71862 13.6409C9.06869 13.4194 9.17299 12.9562 8.95158 12.6061L7.68386 13.4079ZM11.2292 15.0923C11.3277 14.69 11.0814 14.284 10.679 14.1855C10.2767 14.087 9.87069 14.3334 9.77222 14.7357L11.2292 15.0923ZM8.77222 18.8217C8.67375 19.224 8.92009 19.63 9.32243 19.7285C9.72477 19.827 10.1308 19.5806 10.2292 19.1783L8.77222 18.8217ZM9.76171 14.7861C9.69105 15.1942 9.96463 15.5823 10.3728 15.653C10.7809 15.7237 11.1691 15.4501 11.2397 15.0419L9.76171 14.7861ZM12.2397 9.26594C12.3104 8.8578 12.0368 8.46966 11.6287 8.39899C11.2205 8.32833 10.8324 8.60191 10.7617 9.01006L12.2397 9.26594ZM10.1917 15.5974C10.9175 15.9255 11.705 16.0949 12.5015 16.094L12.4999 14.594C11.9171 14.5946 11.3408 14.4707 10.8097 14.2306L10.1917 15.5974ZM12.5136 16.0939C15.7362 16.0386 18.3043 13.3822 18.2506 10.1595L16.7508 10.1845C16.7907 12.5791 14.8825 14.553 12.4879 14.5941L12.5136 16.0939ZM18.2506 10.1845C18.3043 6.96184 15.7362 4.30541 12.5136 4.25011L12.4879 5.74989C14.8825 5.79098 16.7907 7.76488 16.7508 10.1595L18.2506 10.1845ZM12.4879 4.25011C9.26523 4.30541 6.69714 6.96184 6.75082 10.1845L8.25062 10.1595C8.21072 7.76488 10.119 5.79098 12.5136 5.74989L12.4879 4.25011ZM6.75072 10.1699C6.74751 11.316 7.07122 12.4393 7.68386 13.4079L8.95158 12.6061C8.49143 11.8786 8.24831 11.0349 8.25072 10.1741L6.75072 10.1699ZM9.77222 14.7357L8.77222 18.8217L10.2292 19.1783L11.2292 15.0923L9.77222 14.7357ZM11.2397 15.0419L12.2397 9.26594L10.7617 9.01006L9.76171 14.7861L11.2397 15.0419Z",

      ImageFunction: (params) => {
        return createImagePin(params);
      },
      scheduleImageFunction: (params) => {
        ScheduleImagePin(params);
      },
      // VideoFunction: (params) => {
      //   return pinterestPost(params);
      // },
      color: "#FF0000",
    },
  };

  function VideoDetails({ paths, imgName, handleClickDeleteImage }) {
    return (
      <>
        <div
          style={{ border: "2px solid #000", padding: "15px", display: "flex" }}
        >
          <span>
            {" "}
            <video
              loop
              autoPlay
              src={paths}
              alt=""
              style={{ maxHeight: "100px" }}
            />
          </span>
          <div>
            <p style={{ marginLeft: "10px", fontSize: "12px" }}>{imgName}</p>
            <p style={{ marginLeft: "10px", fontSize: "12px" }}>
              Source: Uploads
            </p>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <button
              className="delBtn"
              width="auto"
              height="28"
              type="button"
              onClick={handleClickDeleteImage}
            >
              <svg width="16" height="16" viewBox="0 0 20 20" fill="#2f3638">
                <path d="M15,1.25 L15,3.75 L20,3.75 L20,6.25 L18.75,6.25 L18.75,18.75 C18.75,19.5 18.25,20 17.5,20 L2.5,20 C1.75,20 1.25,19.5 1.25,18.75 L1.25,6.25 L0,6.25 L0,3.75 L5,3.75 L5,1.25 C5,0.5 5.5,0 6.25,0 L13.75,0 C14.5,0 15,0.5 15,1.25 Z M7.5,2.5 L7.5,3.75 L12.5,3.75 L12.5,2.5 L7.5,2.5 Z M16.25,6.25 L3.75,6.25 L3.75,17.5 L16.25,17.5 L16.25,6.25 Z M6.25,8.75 L8.75,8.75 L8.75,16.25 L6.25,16.25 L6.25,8.75 Z M11.25,8.75 L13.75,8.75 L13.75,16.25 L11.25,16.25 L11.25,8.75 Z"></path>
              </svg>
            </button>
          </div>
        </div>
      </>
    );
  }

  const navigate = useNavigate();
  let options = [];
  const [showEmojis, setShowEmojis] = useState(false);
  const [postType, setPostType] = useState(eventData?.postType || "image");
  const [privacyStatus, setPrivacyStatus] = useState("public");

  useEffect(() => {
    pagesDetails?.forEach((element) => {
      // console.log()
      if (
        element.account_type !== "BUSINESS" &&
        //  &&  element.account_type !== "pinterest"
        element.account_type !== "googleAnalytics" &&
        !(postType === "image" && element.account_type === "youtube") &&
        !(postType === "video" && element.account_type === "instagram")
      ) {
        options.push({
          value: {
            id: element.pageId,
            token: element.token,
            picture: element.picture,
            account_type: element.account_type,
            secrate: element.auth || element.token_secrate,
            name: element.name,
          },
          key: element.pageId,

          label: (
            <>
              <div>
                {" "}
                <svg
                  width="38"
                  height="45"
                  viewBox="0 0 20 20"
                  fill={
                    socials[element.account_type]?.color
                      ? socials[element.account_type]?.color
                      : "#000"
                  }
                  className="sc-jMGUhp hVdFLT"
                >
                  <g>
                    <path d={socials[element.account_type]?.icon}></path>
                  </g>
                </svg>
                <span style={{ marginLeft: "5px" }}>{element?.name}</span>
              </div>
            </>
          ),
        });
      }
    });
  }, [options, pagesDetails, postType, socials]);

  const ConvertDateFormat = (inputDateStr) => {
    // Input date string
    // const inputDateStr = date;

    console.log(inputDateStr);

    // Create a Date object from the input string
    const inputDate = new Date(inputDateStr);

    console.log(inputDate);

    // Extract year, month, and day components
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Adding 1 to month because months are zero-based
    const day = String(inputDate.getDate()).padStart(2, "0");

    // Format the date as yyyy-mm-dd
    const formattedDate = `${year}-${month}-${day}`;
    return new Date(formattedDate).getDate;
  };

  // console.log(options, "options");
  const [postTitle, setPostTitle] = useState(eventData?.postTitle || "");
  const [postContent, setPostContent] = useState(eventData?.desc || " ");
  // eslint-disable-next-line no-unused-vars
  const [paths, setPaths] = useState({
    facebook: eventData?.url || eventData?.imgUrl,
    youtube: "",
    linkedin: "",
    twitter: "",
    pinterest: "",
    FileArr: eventData?.url?.FileArr || [],
    instagram: [],
  });
  const [FileArr, setFileArr] = useState(eventData?.url?.FileArr || []);

  // To convert the date getting from the props to the required Format -> Starts
  const propDate = `${String(eventData?.start).split(" ")[3]}-${
    new Date(
      Date.parse(
        String(eventData?.start).split(" ")[1] +
          " " +
          String(eventData?.start).split(" ")[2] +
          ", " +
          String(eventData?.start).split(" ")[3]
      )
    ).getMonth() +
      1 <
    10
      ? `0${
          new Date(
            Date.parse(
              String(eventData?.start).split(" ")[1] +
                " " +
                String(eventData?.start).split(" ")[2] +
                ", " +
                String(eventData?.start).split(" ")[3]
            )
          ).getMonth() + 1
        }`
      : new Date(
          Date.parse(
            String(eventData?.start).split(" ")[1] +
              " " +
              String(eventData?.start).split(" ")[2] +
              ", " +
              String(eventData?.start).split(" ")[3]
          )
        ).getMonth() + 1
  }-${String(eventData?.start).split(" ")[2]}`;

  // To convert the date getting from the props to the required Format -> Ends

  const [imgUrl, setImgUrl] = useState("");
  const [imgName, setImgName] = useState("");
  const eventAccount = eventData?.account_type;
  const eventAccountId = eventData?.id;
  const [selected, setSelected] = useState(
    //   [
    //   {
    //     eventAccount: eventData.id,
    //   }
    // ] ||
    []
  );

  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState(
    !propDate.includes("undefined") ? propDate : ""
  );
  const [time, setTime] = useState(
    String(eventData?.start).split(" ")[4]?.slice(0, 5) || ""
  );
  const [border, setBorder] = useState("");
  const [Gallery, setGallery] = useState(false);
  const [minimized, setMinimized] = useState(false);
  const [postLink, setPostLink] = useState([]);
  const [selectedSocials, setSelectedSocials] = useState(
    eventData?.accountsSelectedDefault || []
  );
  const [selectedPreview, setSelectedPreview] = useState();
  const [titleRequired, setTitleRequired] = useState(false);
  const [customContentSelected, setCustomContentSelected] = useState(false);
  const [InstaStory, setInstaStory] = useState(false);
  const dispatch = useDispatch();
  // function handleOnEnter(postContentt) {
  //     console.log('enter', postContent)
  // }

  const handleInstaStory = () => {
    setInstaStory(!InstaStory);
  };

  useEffect(() => {
    eventData?.date &&
      setDate(ConvertDateFormat(eventData?.date?.slice(0, 10)));
  }, [eventData?.date]);

  // console.log(selected);
  // console.log(date, time);
  // useEffect(() => {
  //   const newData = postContent.split(" ");
  //   const newLink = newData.filter((data) => data.includes("http" || "https"));
  //   const link = newLink.map(async (data) => {
  //     const res = await axios(`https://api.shrtco.de/v2/shorten?url=${data}`);
  //     const newLine = res.data.result.full_short_link.toString();
  //     setPostLink(newLine);
  //   });
  // }, [postContent]);

  const [isWebsiteRequired, setIsWebsiteRequired] = useState(false);
  const [PinintrestWebURL, setPinintrestWebURL] = useState("");
  const [isOnlyInstagram, setIsOnlyInstagram] = useState(false);
  useEffect(() => {
    setSelectedSocials(selected);
    setTitleRequired(false);
    setReels(false);
    setIsWebsiteRequired(false);
    selected.map((sel) => {
      if (sel.value.account_type === "youtube") {
        return setTitleRequired(true);
      }
      if (sel.value.account_type === "instagram") {
        return setReels(true);
      }

      if (sel.value.account_type === "pinterest") {
        return setIsWebsiteRequired(true);
      }
    });

    const allInstagram = selected.every(
      (sel) => sel.value.account_type === "instagram"
    );

    if (allInstagram) {
      setIsOnlyInstagram(true);
    } else {
      setIsOnlyInstagram(false);
    }
  }, [selected]);

  const [reels, setReels] = useState(false);

  const timexoneOffset = new Date().toTimeString();
  const newTimeZone = timexoneOffset.split(" ")[1];
  const newZone = newTimeZone.split(" ")[0];
  const zone = newZone[0] + newZone[1] + ":" + newZone[2] + newZone[3];
  // console.log(zone);
  const timezone = date + "T" + time + ":00" + "+" + zone;
  // console.log(timezone);

  // Selected Date TimeZone
  let selectedDate = date + " " + time;

  // if(eventData?.date){
  //  selectedDate = eventData?.date
  // }

  const [schedule, setSchedule] = useState({
    message: "",
    page_token: "",
    pageId: "",
    time: "",
  });

  const handleClickCreatePost = () => {
    dispatch(showcreatePostMenu());
  };

  const handleClickDeleteImage = () => {
    setImgUrl("");
    setPaths([]);
    setImgName("");
  };

  // const handleSelectAccount =async (event) =>{
  //  await setSelected(event)
  //   console.log(selected)
  // }

  const scheduleAndReuse = () => {
    if (paths?.length > 5) {
      let params = {
        time: selectedDate,
        message: postContent,
        token: selected.value.token,
        id: selected.value.id,
        url: paths,
      };
      if (selected?.length !== 0 && postContent?.length !== 0) {
        schedulePost(params);
        setPaths("");
        setPostContent("");
      }
    } else {
      let params = {
        time: selectedDate,
        message: postContent,
        token: selected.value.token,
        id: selected.value.id,
      };
      if (selected?.length !== 0 && postContent?.length !== 0) {
        facebookScheduleMessages(params);
        setPaths("");
        setPostContent("");
        setDate("");
      }
    }
  };

  const handleSchedulePost = () => {
    var { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    console.log(timeZone);

    if (paths?.facebook?.length > 0) {
      selectedSocials.map((social) => {
        console.log(social);
        switch (social.value.account_type) {
          case "facebook":
            let params = {
              time: new Date(selectedDate).getTime(),
              message: postContent,
              token: social.value.token,
              id: social.value.id,
              taskId: eventData?.taskId,
              url: paths.facebook,
              altUrl: paths.facebook,
              jwToken: sessionStorage.getItem("token"),
              timezone: timeZone,
            };
            if (selected?.length !== 0 && postContent?.length !== 0) {
              // console.log(params)
              schedulePost(params);
              setPaths("");
              setPostContent("");
            }
            break;
          case "twitter":
            let payload = {
              time: new Date(selectedDate).getTime(),
              postContent: postContent,
              token: social.value.token,
              secrate: social.value.secrate,
              url: paths.twitter,
              altUrl: paths.facebook,
              taskId: eventData?.taskId,
              id: social.value.id,
              jwToken: sessionStorage.getItem("token"),
              timezone: timeZone,
            };
            if (selected?.length !== 0 && postContent?.length !== 0) {
              console.log(payload);
              return scheduleTwitterImages(payload)
                .then(() => console.log("Twitter Auth Token is valid"))
                .catch(async () => {
                  // window.alert("Invalid/Expired Auth Token Twitter");
                  const res = await axios.post(
                    `${process.env.REACT_APP_SERVER_URI}/api/crud/getTwitter`
                  );
                  window.location.href = res.data.url;
                });
            }
            break;
          case "linkedin":
            let parameters = {
              time: new Date(selectedDate).getTime(),
              postContent: postContent,
              token: social.value.token,
              secrate: social.value.secrate,
              id: social.value.id,
              taskId: eventData?.taskId,
              url: paths.linkedin,
              altUrl: paths.facebook,
              jwToken: sessionStorage.getItem("token"),
              timezone: timeZone,
            };
            if (selected?.length !== 0 && postContent?.length !== 0) {
              // console.log(parameters)
              return linkedInImageSchedules(parameters)
                .then(() => console.log("Image Message Scheduled on Linkedin"))
                .catch((err) => {
                  window.alert(
                    "Invalid/Expired Auth Token. Try Again after login."
                  );
                  window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&scope=r_liteprofile%20r_emailaddress%20w_member_social&state=123456&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}/linkedin`;
                });
            }
            break;
          case "youtube":
            let paramsYoutube = {
              title: new Date(selectedDate).getTime(),
              time: selectedDate,
              message: postContent,
              token: social.value.token,
              id: social.value.id,
              url: paths.youtube,
              taskId: eventData?.taskId,
              altUrl: paths.facebook,
              secrate: social.value.secrate,
              jwToken: sessionStorage.getItem("token"),
              timezone: timeZone,
            };
            youtubeSchedulePostVideos(paramsYoutube);
            break;
          case "instagram":
            let instaPayload = {
              time: new Date(selectedDate).getTime(),
              message: postContent,
              url: paths.facebook,
              jwToken: localStorage.getItem("token"),
              altUrl: paths.facebook,
              taskId: eventData?.taskId,
              token: social.value.token,
              timezone: timeZone,
              id: social.value.id,
            };
            instagramScheduleAPI(instaPayload);
            break;
          default:
            console.log(null);
        }
      });
    } else {
      selectedSocials.map((social) => {
        switch (social.value.account_type) {
          case "facebook":
            let params = {
              time: new Date(selectedDate).getTime(),
              message: postContent,
              token: social.value.token,
              taskId: eventData?.taskId,
              id: social.value.id,
              jwToken: sessionStorage.getItem("token"),
              timezone: timeZone,
            };
            if (selected?.length !== 0 && postContent?.length !== 0) {
              facebookScheduleMessages(params);
              setPaths("");
              setPostContent("");
            }
            break;
          case "twitter":
            let parameters = {
              time: new Date(selectedDate).getTime(),
              postContent: postContent,
              token: social.value.token,
              taskId: eventData?.taskId,
              secrate: social.value.secrate,
              id: social.value.id,
              jwToken: sessionStorage.getItem("token"),
              timezone: timeZone,
            };
            if (selected?.length !== 0 && postContent?.length !== 0) {
              return scheduleTwitterTexts(parameters)
                .then(() => console.log("Twitter Auth Token is valid"))
                .catch(async () => {
                  // window.alert("Invalid/Expired Auth Token Twitter");
                  const res = await axios.post(
                    `${process.env.REACT_APP_SERVER_URI}/api/crud/getTwitter`
                  );
                  window.location.href = res.data.url;
                });
            }
            break;
          case "linkedin":
            let payload = {
              time: new Date(selectedDate).getTime(),
              postContent: postContent,
              token: social.value.token,
              taskId: eventData?.taskId,
              secrate: social.value.secrate,
              id: social.value.id,
              jwToken: sessionStorage.getItem("token"),
              timezone: timeZone,
            };
            if (selected?.length !== 0 && postContent?.length !== 0) {
              return linkedInTextSchedules(payload)
                .then(() => window.alert("Text Message Scheduled for Linkedin"))
                .catch((err) => {
                  window.alert(
                    "Invalid/Expired Auth Token. Try Again after login."
                  );
                  window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&scope=r_liteprofile%20r_emailaddress%20w_member_social&state=123456&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}/linkedin`;
                });
            }
            break;
          default:
            console.log(null);
        }
      });
    }
  };

  const handleScheduleAndDuplicate = () => {
    if (paths?.length > 5) {
      let params = {
        time: selectedDate,
        message: postContent,
        token: selected.value.token,
        id: selected.value.id,
        url: paths,
      };
      if (selected?.length !== 0 && postContent?.length !== 0) {
        schedulePost(params);
      }
    } else {
      let params = {
        time: selectedDate,
        message: postContent,
        token: selected.value.token,
        id: selected.value.id,
      };
      if (selected?.length !== 0 && postContent?.length !== 0) {
        facebookScheduleMessages(params);
      }
    }
  };

  const [postOptions, setPostOPtions] = useState(false);
  const [scheduleOptions, setScheduleOptions] = useState(false);
  const [posting, setPosting] = useState(false);
  const [selectedUserTags, setselectedUserTags] = useState("");
  const [selectedCollaborators, setselectedCollaborators] = useState("");

  const [postingLoader, setpostingLoader] = useState(false);

  //----------------- multi post -----------------
  // const handleMultiPost = () => {
  //   setpostingLoader(true);
  //   setPosting(true);
  //   if (selected?.length < 0) {
  //     toast.error(`Please Select Account`, {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //     });
  //     toast.warning(`Please Select Account`, {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //     });
  //     return 0;
  //   } else if (postContent?.length < 0) {
  //     toast.error(`Please Enter Post Content`, {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //     });
  //     return 0;
  //   }
  //   console.log(paths);

  //   if (postType === "image") {
  //     selected.map(async (element, key) => {
  //       let params = {
  //         title: postTitle,
  //         PinintrestWebURL: PinintrestWebURL,
  //         selectedUserTags,
  //         selectedCollaborators,
  //         message:
  //           customText &&
  //           customText?.find((item) => {
  //             console.log(item);
  //             return item?.id === element?.value?.id;
  //           })
  //             ? customText?.find((item) => {
  //                 console.log(item);
  //                 return item?.id === element?.value?.id;
  //               })?.value
  //             : postContent || postContent,
  //         token: element.value.token,
  //         secrate: element.value.auth || element.value.secrate,
  //         id: element.value.id,
  //         vidurl: ReelUrl,
  //         youtubeUrl: youtubeUrl,
  //         url: paths,
  //         allFiles: paths["FileArr"],
  //         privacyStatus: privacyStatus,
  //         jwToken: sessionStorage.getItem("token"),
  //         type:'image'
  //       };

  //       await socials[element.value.account_type]
  //         .ImageFunction(params)
  //         // .catch((err) => {
  //         //   console.log(err);
  //         //   toast.error(err);
  //         // })
  //         // .then(() => {
  //         //   toast.success(`image posted on ${element.value.name}`);
  //         // });

  //         if(InstaStory && element.value.account_type === "instagram"){
  //           await socials[element.value.account_type]
  //           .StoryFunction(params)
  //           .catch((err) => {
  //             console.log(err);
  //             toast.error(`Something Went Wrong` + err.data.message);
  //           })
  //           .then(() => {
  //             toast.success(`image posted on ${element.value.name}`);
  //           });
  //         }

  //       console.log(params);
  //     });
  //     setpostingLoader(false);
  //     setPosting(false);
  //     setpostingLoader(false);
  //   } else if (postType === "video") {
  //     selected.map(async (element, key) => {
  //       let params = {
          // title: postTitle,
          // selectedUserTags,
          // selectedCollaborators,
          // youtubeIsEmbaddable,
          // youtubeIsNotifySubscribers,
          // youtubeKeywords,
  //         message:
  //           customText &&
  //           customText?.find((item) => {
  //             console.log(item);
  //             return item?.id === element?.value?.id;
  //           })
  //             ? customText?.find((item) => {
  //                 console.log(item);
  //                 return item?.id === element?.value?.id;
  //               })?.value
  //             : postContent || postContent,
  //         token: element.value.token,
  //         secrate: element.value.auth || element.value.secrate,
  //         id: element.value.id,
  //         vidurl: VideoUrl,
  //         ReelUrl: ReelUrl,
  //         youtubeUrl: ReelUrl,
  //         url: paths["facebook"],
  //         privacyStatus: privacyStatus,
  //         jwToken: sessionStorage.getItem("token"),
  //         paths: paths,
  //         type:'video'
  //       };

  //       // let isposted = await socials[element.value.account_type]?.VideoFunction(params)

  //       // if(isposted){
  //       //   setpostingLoader(false);
  //       //   setPosting(false);
  //       //   setpostingLoader(false);
  //       //   // toast.success(`video posted on ${element.value.name}`);
  //       // } else {
  //       //   setpostingLoader(false);
  //       //   setPosting(false);
  //       //   setpostingLoader(false);
  //       //   // toast.error(`Something Went Wrong`);
  //       // }

  //       try{
  //         await socials[element.value.account_type]?.VideoFunction(params)
  //         setpostingLoader(false);
  //         setPosting(false);
  //         setpostingLoader(false);
  //         // toast.success(`video posted on ${element.value.name}`);

  //       } catch(err){
  //         setpostingLoader(false);
  //         setPosting(false);
  //         setpostingLoader(false);

  //         // toast.error(err);
  //       }

  //        if(InstaStory && element.value.account_type === "instagram"){
  //           await socials[element.value.account_type]
  //           .StoryFunction(params)
  //           .catch((err) => {
  //             console.log(err);
  //             // toast.error(`Something Went Wrong` + err.data.message);
  //           })
  //           .then(() => {
  //             // toast.success(`image posted on ${element.value.name}`);
  //           });
  //         }

  //       console.log(params);
  //     });

  //   } else if (postType === "reel") {
  //     selected.map(async (element, key) => {
  //       let params = {
  //         title: postTitle,
  //         message:
  //           customText &&
  //           customText?.find((item) => {
  //             console.log(item);
  //             return item?.id === element?.value?.id;
  //           })
  //             ? customText?.find((item) => {
  //                 console.log(item);
  //                 return item?.id === element?.value?.id;
  //               })?.value
  //             : postContent || postContent,
  //         token: element.value.token,
  //         secrate: element.value.auth || element.value.secrate,
  //         id: element.value.id,
  //         vidurl: VideoUrl,
  //         ReelUrl: ReelUrl,
  //         youtubeUrl: ReelUrl,
  //         url: paths["facebook"],
  //         privacyStatus: privacyStatus,
  //         jwToken: sessionStorage.getItem("token"),
  //         paths: paths,
  //       };
  //      try{
  //         await socials[element.value.account_type]?.VideoFunction(params)
  //         setpostingLoader(false);
  //         setPosting(false);
  //         setpostingLoader(false);
  //         // toast.success(`video posted on ${element.value.name}`);

  //       } catch(err){
  //         setpostingLoader(false);
  //         setPosting(false);
  //         setpostingLoader(false);

  //         // toast.error(err);
  //       }

  //        if(InstaStory && element.value.account_type === "instagram"){
  //           await socials[element.value.account_type]
  //           .StoryFunction(params)
  //           .catch((err) => {
  //             console.log(err);
  //             // toast.error(`Something Went Wrong` + err.data.message);
  //           })
  //           .then(() => {
  //             // toast.success(`image posted on ${element.value.name}`);
  //           });
  //         }

  //       console.log(params);
  //     });

  //   }

  //   //-----------------------------------------------------TESTING-----------------------------------------------------//

  //   setpostingLoader(false);
  //   handleClickCreatePost();
  //   // setMinimized(true)
  //   // handleClickCreatePost()
  // };

  const handleMultiPost = async () => {
    setpostingLoader(true); // Set loading once at the start
    setPosting(true);

    // Check for minimum necessary input
    if (!selected || selected.length === 0 || !postContent) {
      const errorMessage =
        !selected || selected.length === 0
          ? "Please Select Account"
          : "Please Enter Post Content";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setpostingLoader(false);
      setPosting(false);
      return;
    }

    // Process all selected accounts
    try {
      await Promise.all(selected.map((element) => postToAccount(element)));
    } catch (error) {
      console.error("Error in posting: ", error);
      toast.error("Something Went Wrong", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setpostingLoader(false);
      setPosting(false);
    }

    handleClickCreatePost(); // Assuming this is required at the end of all operations
  };

  const postToAccount = async (element) => {
    const customMessage = Array.isArray(customText)
      ? customText.find((item) => item?.id === element?.value?.id)?.value
      : postContent;

    const params = {
      title: postTitle,
      message: customMessage,
      token: element.value.token,
      secrate: element.value.auth || element.value.secrate,
      id: element.value.id,
      url: paths,
      selectedUserTags,
      selectedCollaborators,
      youtubeIsEmbaddable,
      youtubeIsNotifySubscribers,
      youtubeKeywords,
      allFiles: paths["FileArr"],
      privacyStatus: privacyStatus,
      jwToken: sessionStorage.getItem("token"),
      type: postType,
      vidurl: postType === "video" ? VideoUrl : undefined,
      ReelUrl: postType === "reel" ? ReelUrl : VideoUrl,
      youtubeUrl: postType === "video" ? ReelUrl : undefined,
    };

    let postTypeFirstUpper =
      postType.charAt(0).toUpperCase() + postType.slice(1);
    // Ensure the method exists for the account type and post type
    const postFunction =
      socials[element.value.account_type][`${postTypeFirstUpper}Function`];
    if (typeof postFunction !== "function") {
      console.error(
        `No valid posting function found for ${postTypeFirstUpper} on ${element.value.account_type}`
      );
      throw new Error(
        `No valid posting function found for ${postTypeFirstUpper} on ${element.value.account_type}`
      );
    }

    if (element.value.account_type === "instagram" && InstaStory) {
      const storyFunction = socials[element.value.account_type].StoryFunction;
      if (typeof storyFunction !== "function") {
        console.error("Story function not available for Instagram");
        throw new Error("Story function not available for Instagram");
      }

      // Run the post and story functions at the same time
      await Promise.all([postFunction(params), storyFunction(params)]);
    } else {
      // If it's not Instagram or there's no story, just run the post function
      await postFunction(params);
    }
  };

  const handleMultiSchedule = () => {
    var { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    console.log(timeZone);
    if (postType === "image") {
      selected.map(async (element, key) => {
        let params = {
          title: postTitle,
          message:
            customText &&
            customText?.find((item) => {
              console.log(item);
              return item?.id === element?.value?.id;
            })
              ? customText?.find((item) => {
                  console.log(item);
                  return item?.id === element?.value?.id;
                })?.value
              : postContent || postContent,
          timeToPost: new Date(selectedDate).getTime(),
          token: element.value.token,
          secrate: element.value.auth || element.value.secrate,
          account_type: element.value.account_type || "linkedin",
          id: element.value.id || eventData?.id,
          vidurl: ReelUrl,
          selectedUserTags,
          selectedCollaborators,
          youtubeIsEmbaddable,
          youtubeIsNotifySubscribers,
          youtubeKeywords,
          youtubeUrl: youtubeUrl,
          url: paths,
          allFiles: paths["FileArr"],
          privacyStatus: privacyStatus,
          jwToken: sessionStorage.getItem("token"),
          timezone: timeZone,
          taskId: eventData?.taskId,
          vendorId: selectedVendor?._id,
          PostSetId: randomStringId(),
        };

        await socials[element.value.account_type].scheduleImageFunction(params);
        setpostingLoader(false);
        setPosting(false);
        setpostingLoader(false);
        toast.success(`image posted on ${element.value.name}`);
        console.log(params);
      });
    } else if (postType === "video") {
      selected.map(async (element, key) => {
        let params = {
          title: postTitle,
          message:
            customText &&
            customText?.find((item) => {
              console.log(item);
              return item?.id === element?.value?.id;
            })
              ? customText?.find((item) => {
                  console.log(item);
                  return item?.id === element?.value?.id;
                })?.value
              : postContent || postContent,
          timeToPost: new Date(selectedDate).getTime(),
          token: element.value.token,
          secrate: element.value.auth || element.value.secrate,
          id: element.value.id || eventData?.id,
          account_type: element.value.account_type || "linkedin",
          vidurl: VideoUrl,
          ReelUrl: ReelUrl,
          youtubeUrl: ReelUrl,
          selectedUserTags,
          selectedCollaborators,
          youtubeIsEmbaddable,
          youtubeIsNotifySubscribers,
          youtubeKeywords,
          url: paths["facebook"],
          privacyStatus: privacyStatus,
          jwToken: sessionStorage.getItem("token"),
          timezone: timeZone,
          taskId: eventData?.taskId,
        };

        await socials[element.value.account_type].scheduleVideoFunction(params);

        toast.success(`image posted on ${element.value.name}`);
        console.log(params);
      });

      setpostingLoader(false);
      setPosting(false);
      setpostingLoader(false);
    } else if (postType === "reel") {
      selected.map(async (element, key) => {
        let params = {
          title: postTitle,
          message:
            customText &&
            customText?.find((item) => {
              console.log(item);
              return item?.id === element?.value?.id;
            })
              ? customText?.find((item) => {
                  console.log(item);
                  return item?.id === element?.value?.id;
                })?.value
              : postContent || postContent,
          timeToPost: new Date(selectedDate).getTime(),
          token: element.value.token,
          secrate: element.value.auth || element.value.secrate,
          id: element.value.id || eventData?.id,
          account_type: element.value.account_type || "linkedin",
          vidurl: VideoUrl,
          ReelUrl: ReelUrl,
          youtubeUrl: ReelUrl,
          selectedUserTags,
          selectedCollaborators,
          youtubeIsEmbaddable,
          youtubeIsNotifySubscribers,
          youtubeKeywords,
          url: paths["facebook"],
          privacyStatus: privacyStatus,
          jwToken: sessionStorage.getItem("token"),
          timezone: timeZone,
          taskId: eventData?.taskId,
        };

        console.log(
          element.value.account_type,
          "332qw45er6t7t45======================================="
        );
        await socials[element.value.account_type].scheduleReelFunction(params);

        toast.success(`image posted on ${element.value.name}`);
        console.log(params);
      });

      setpostingLoader(false);
      setPosting(false);
      setpostingLoader(false);
    }
    setpostingLoader(false);
    setPosting(false);
    setpostingLoader(false);

    handleClickCreatePost();
  };

  //----------------- multi post -----------------

  const postAndReuse = () => {
    console.log("post and reuse");
    if (paths?.length > 5) {
      let params = {
        message: postContent,
        token: selected.value.token,
        token_secrate: selected.value.token_secrate,
        id: selected.value.id,
        url: paths,
      };
      console.log(params);

      if (selected?.length !== 0 && postContent?.length !== 0) {
        FaceBookImagePost(params);
        setPaths("");
        setPostContent("");
      }
    } else {
      let params = {
        message: postContent,
        token: selected.value.token,
        id: selected.value.id,
        secrate: selected.value.token_secrate,
      };
      console.log(params);

      if (selected?.length !== 0 && postContent?.length !== 0) {
        if (selected.value.account_type === "twitter") {
          postTwitterMessage(params);
        } else {
          FaceBookTextPost(params);
          setPostContent("");
        }
      }
    }
  };

  //

  // const handlePost = () => {
  //   if (Object.keys()) {
  //     let params = {
  //       message: postContent,
  //       token: selected.value.token,
  //       secrate: selected.value.secrate,
  //       id: selected.value.id,
  //       url: paths,
  //     };
  //     console.log(params);

  //     if (selected?.length !== 0 && postContent?.length !== 0) {
  //       if (selected.value.account_type === "linkedin") {
  //         params.url = paths.linkedinURN
  //         console.log(params)
  //         postLinkedInImage(params);
  //       } else if (selected.value.account_type === "facebook") {
  //         FaceBookImagePost(params);
  //       } else if (selected.value.account_type === "twitter") {
  //         postTwitterMessage(params);
  //       } else if (selected.value.account_type === "medium") {
  //         postMedium(params);
  //       }
  //     }
  //   } else {
  //     console.log("its me");
  //     let params = {
  //       message: postContent,
  //       token: selected.value.token,
  //       id: selected.value.id,
  //       secrate: selected.value.secrate,
  //     };
  //     console.log(params);

  //     if (selected?.length !== 0 && postContent?.length !== 0) {
  //       console.log("Its ");
  //       if (selected.value.account_type === "linkedin") {
  //         console.log("hit Linkedin");
  //         postLinkedInMessage(params);
  //       } else if (selected.value.account_type === "facebook") {
  //         console.log("hit facebook");
  //         FaceBookTextPost(params);
  //       } else if (selected.value.account_type === "twitter") {
  //         console.log("hit Twitter");
  //         postTwitterMessage(params);
  //       } else if (selected.value.account_type === "medium") {
  //         console.log("hit medium");
  //         postMedium(params);
  //       }
  //     }
  //   }
  //   setPostContent("");
  //   setPaths("");
  //   setImgName("");
  // };

  const handlePostAndDuplicate = () => {
    if (paths?.length > 5) {
      let params = {
        message: postContent,
        token: selected.value.token,
        id: selected.value.id,
        secrate: selected.value.secrate,
        url: paths,
      };
      console.log(params);

      if (selected?.length !== 0 && postContent?.length !== 0) {
        if (selected.value.account_type === "twitter") {
          postTwitterMessage(params);
        }
        FaceBookTextPost(params);
      }
    } else {
      let params = {
        message: postContent,
        token: selected.value.token,
        id: selected.value.id,
        secrate: selected.value.secrate,
      };
      console.log(params);

      if (selected?.length !== 0 && postContent?.length !== 0) {
        if (selected.value.account_type === "twitter") {
          postTwitterMessage(params);
        } else {
          FaceBookTextPost(params);
        }

        setSelected("");
      }
    }
  };

  const handlePostDraft = () => {
    let params = {
      message: postContent,
      // pages: selected,
      user: userInfo._id,
      url: paths,
    };
    console.log(params);
    console.log(postDrafts(params));
    console.log("all ok");
  };

  const handleScheduleDraft = () => {
    console.log(selected);
    let params = {
      message: postContent,
      pages: selected,
      user: userInfo._id,
      url: paths,
      time: selectedDate,
    };
    console.log(params);
    scheduleDrafts(params);
    console.log("all ok");
  };

  async function sendSchedule() {
    let params = {
      time: selectedDate,
      message: postContent,
      token: selected.value.token,
      id: selected.value.id,
    };
    try {
      await facebookScheduleMessages(params).then(() => {
        console.log("Scheduled!!");
      });
    } catch (err) {
      console.log(err);
    }
  }

  const addEmoji = (e) => {
    console.log(e);
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setPostContent(postContent + emoji);
  };

  const [newLinksObj, setNewLinksObj] = useState({});

  // const OnLInkShortner = async () => {
  //   const hell = await postContent.replace(
  //     /(https?:\/\/[^\s]+)/g,
  //     function (url) {
  //  fetch(`https://api.shrtco.de/v2/shorten?url=${url}`)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       if (data) {
  //         setNewLinksObj({...newLinksObj, [url]: data.result.short_link})
  //       } else {
  //         throw new Error('Failed to shorten URL');
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });

  //   }
  //   )
  // }

  useEffect(() => {
    if (eventData?.pageId) {
      pagesDetails.map((data) => {
        if (data.pageId === eventData?.pageId) {
          let dat = [];

          dat.push({
            value: data,

            label: (
              <div>
                {" "}
                {data.account_type === "BUSINESS" ||
                data.account_type === "pinterest" ? (
                  <svg
                    class="gUZ GjR U9O kVc"
                    height="16"
                    width="16"
                    viewBox="0 0 24 24"
                    aria-label="Pinterest"
                    role="img"
                  >
                    <path d="M0 12c0 5.123 3.211 9.497 7.73 11.218-.11-.937-.227-2.482.025-3.566.217-.932 1.401-5.938 1.401-5.938s-.357-.715-.357-1.774c0-1.66.962-2.9 2.161-2.9 1.02 0 1.512.765 1.512 1.682 0 1.025-.653 2.557-.99 3.978-.281 1.189.597 2.159 1.769 2.159 2.123 0 3.756-2.239 3.756-5.471 0-2.861-2.056-4.86-4.991-4.86-3.398 0-5.393 2.549-5.393 5.184 0 1.027.395 2.127.889 2.726a.36.36 0 0 1 .083.343c-.091.378-.293 1.189-.332 1.355-.053.218-.173.265-.4.159-1.492-.694-2.424-2.875-2.424-4.627 0-3.769 2.737-7.229 7.892-7.229 4.144 0 7.365 2.953 7.365 6.899 0 4.117-2.595 7.431-6.199 7.431-1.211 0-2.348-.63-2.738-1.373 0 0-.599 2.282-.744 2.84-.282 1.084-1.064 2.456-1.549 3.235C9.584 23.815 10.77 24 12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12"></path>
                  </svg>
                ) : data.account_type === "linkedin" ? (
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="#2f3638"
                  >
                    <g>
                      <path
                        d="M10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20Z"
                        fill="#0A66C2"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.97347 5.39759C6.97878 5.79554 6.84294 6.1277 6.56703 6.39512C6.29112 6.66254 5.92819 6.79519 5.47825 6.79519H5.46233C5.02299 6.79519 4.66961 6.66254 4.40113 6.39512C4.13371 6.1277 4 5.79554 4 5.39759C4 4.99434 4.13796 4.66112 4.41387 4.39689C4.68978 4.13159 5.05058 4 5.49416 4C5.93987 4 6.29537 4.13265 6.56279 4.39689C6.83127 4.66219 6.96604 4.99434 6.97241 5.39759H6.97347ZM6.80368 7.89883V16H4.15387V7.89883H6.80368ZM10.9126 9.04676C11.0138 8.88166 11.1171 8.7416 11.2236 8.61514C11.336 8.48461 11.4889 8.34241 11.6778 8.19066C11.8688 8.03785 12.1022 7.919 12.3781 7.83516C12.654 7.75027 12.9597 7.70782 13.2971 7.70782C14.2129 7.70782 14.9494 8.01769 15.5065 8.6353C16.0637 9.25398 16.3428 10.1602 16.3428 11.3541V15.9968H13.6983V11.664C13.6983 11.0909 13.59 10.6452 13.3735 10.3194C13.156 9.99576 12.8175 9.83339 12.3569 9.83339C12.0195 9.83339 11.7372 9.92572 11.508 10.1146C11.2809 10.3024 11.1111 10.5348 10.9986 10.8129C10.9402 10.9763 10.9105 11.197 10.9105 11.4751V15.9958H8.26813C8.27874 13.8203 8.28299 12.0587 8.28299 10.7068C8.28299 9.35585 8.28299 8.54935 8.27768 8.28723L8.27025 7.89459H10.9126V9.04676Z"
                        fill="white"
                      ></path>
                    </g>
                  </svg>
                ) : data.account_type === "twitter" ? (
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="#2f3638"
                  >
                    <g>
                      <path
                        d="M20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10Z"
                        fill="#1DA1F2"
                      ></path>
                      <path
                        d="M16.134 6.188C15.806 6.699 15.404 7.133 14.934 7.492C14.943 7.566 14.943 7.676 14.943 7.82C14.9401 9.22326 14.5464 10.598 13.806 11.79C13.4322 12.4001 12.9733 12.9537 12.443 13.434C11.8821 13.9286 11.2346 14.3152 10.533 14.574C9.76895 14.8618 8.95839 15.0062 8.14197 15C6.83072 15.008 5.54876 14.6124 4.46997 13.867C4.64197 13.887 4.83297 13.898 5.04397 13.898C6.13107 13.9074 7.18515 13.5247 8.01297 12.82C7.50642 12.8116 7.01719 12.6342 6.62297 12.316C6.21843 11.999 5.9233 11.5633 5.77897 11.07C5.94297 11.098 6.09097 11.11 6.23197 11.11C6.44197 11.11 6.65397 11.082 6.86097 11.023C6.31332 10.9068 5.82547 10.5981 5.48597 10.153C5.12478 9.69652 4.9329 9.129 4.94297 8.547V8.516C5.27096 8.71203 5.64312 8.8221 6.02497 8.836C5.69559 8.60494 5.42835 8.29614 5.24697 7.937C5.05272 7.56604 4.95343 7.15272 4.95797 6.734C4.95797 6.277 5.06797 5.852 5.28197 5.461C5.8689 6.229 6.61188 6.86394 7.46197 7.324C8.30816 7.78759 9.24833 8.05358 10.212 8.102C10.174 7.91133 10.1545 7.71742 10.154 7.523C10.154 6.824 10.388 6.23 10.853 5.738C11.322 5.246 11.884 5 12.544 5C13.236 5 13.818 5.266 14.29 5.797C14.8295 5.68765 15.3445 5.48094 15.81 5.187C15.6435 5.76458 15.2691 6.26008 14.759 6.578C15.216 6.528 15.677 6.395 16.134 6.188Z"
                        fill="white"
                      ></path>
                    </g>
                  </svg>
                ) : null}
                <span style={{ marginLeft: "5px" }}>{data.name}</span>
              </div>
            ),
          });

          setSelected(dat);
        }
      });
    }
  }, [eventData?.pageId, pagesDetails]);

  console.log(selected);

  // const LimitObj = {
  //   fbLimit: 100,
  //   instaLimit: 200,
  //   twitLimit: 300,
  //   youTLimit: 400,
  // };

  const [limitValue, setLimitValue] = useState(0);

  console.log(limitValue);

  useEffect(() => {
    selected.map((ele) => {
      switch (ele.value.account_type) {
        case "facebook":
          let fbLimit = 33000;
          if (postContent?.length > fbLimit) {
            toast.error("facebook content excceded");
          }
          return setLimitValue(fbLimit);
          break;
        case "instagram":
          let instaLimit = 2200;
          if (postContent?.length > instaLimit) {
            toast.error("insta content excceded");
          }
          return setLimitValue(instaLimit);
          break;
        case "twitter":
          let twitterLimit = 260;
          if (postContent?.length > twitterLimit) {
            toast.error("twitter content excceded limit is 260 characters");
          }
          return setLimitValue(twitterLimit);
          break;
        case "linkedin":
          let linkedinLimit = 3000;
          if (postContent?.length > linkedinLimit) {
            toast.error("linkedin content excceded");
          }
          return setLimitValue(linkedinLimit);
          break;
        case "youtube":
          let youtubeLimit = 5000;
          if (postContent?.length > youtubeLimit) {
            toast.error("youtube content excceded");
          }
          return setLimitValue(youtubeLimit);
          break;
        case "pinterest":
          let pinterestLimit = 500;
          if (postContent?.length > pinterestLimit) {
            toast.error("pinterest content excceded");
          }
          return setLimitValue(pinterestLimit);
          break;
        default:
          return setLimitValue(0);
      }
    });
  }, [postContent, selected]);

  const [urlsLong, setUrlsLong] = useState([]);
  const [urlsShort, setUrlsShort] = useState([]);

  useEffect(() => {
    async function rep() {
      setPostContent(await replaceUrlsWithShortUrls(postContent));
    }
    rep();
  }, [postContent]);

  // creating function to replace urls in postContent with short urls

  const shortURL = async (url) => {
    const shortCode = await axios.post(`https://shtn.in/shortLink`, {
      full: url,
    });
    return `https://shtn.in/${shortCode.data.short}`;
  };

  const replaceUrlsWithShortUrls = async (postContent) => {
    // Use a regular expression to identify URLs in the post content
    const urlRegex = /https?:\/\/[^\s<>"]+|www\.[^\s<>"]+/g;
    let matches = postContent?.match(urlRegex);

    // For each URL, generate a short URL using the shortURL function

    for (let i = 0; i < matches?.length; i++) {
      if (!matches[i]?.includes("https://shtn.in/")) {
        const longUrl = matches[i];
        const shortUrl = await shortURL(longUrl);

        // Replace the long URL with the short URL in the post content
        postContent = postContent?.replace(longUrl, shortUrl);
      }
    }

    return postContent;
  };

  useEffect(() => {
    if (postContent?.length <= 0) {
      setPostContent(" ");
    }
  }, [postContent]);

  function deleteImageFromPaths(image) {
    const index = paths.FileArr.indexOf(image);
    console.log("paths", paths.FileArr);
    console.log("img path", image);
    console.log("path index", paths.FileArr[index]);
    console.log(index);
    if (index > -1) {
      paths.FileArr.splice(index, 1);
      console.log(paths.FileArr);
      setPaths({ ...paths, FileArr: paths.FileArr });
    }
  }

  function deleteInstagramImageFromPaths(image) {
    const index = paths.instagram.indexOf(image);
    console.log("paths", paths.instagram);
    console.log("img path", image);
    console.log("path index", paths.instagram[index]);
    console.log(index);
    if (index > -1) {
      paths.instagram.splice(index, 1);
      console.log(paths.instagram);
      setPaths({ ...paths, instagram: paths.instagram });
    }
  }

  // async function replaceUrlsWithShortUrls(postContent) {
  //   // Use a regular expression to identify URLs in the post content
  //   const urlRegex = /https?:\/\/[^\s<>"]+|www\.[^\s<>"]+/g;
  //   let matches = postContent.match(urlRegex);

  //   // For each URL, generate a short URL using the URL shortening service

  //   for (let i = 0; i < matches?.length; i++) {
  //     if (!matches[i].includes("https://shrtco.de/")) {
  //       const longUrl = matches[i];
  //       const response = await fetch(
  //         `https://api.shrtco.de/v2/shorten?url=${longUrl}`
  //       );
  //       const shortUrl = await response.json();

  //       // Replace the long URL with the short URL in the post content
  //       postContent = postContent.replace(
  //         longUrl,
  //         shortUrl.result.full_short_link
  //       );
  //     }
  //   }

  //   return postContent;
  // }

  const [allvendors, setAllVendors] = useState([]);

  const getAllvendors = async () => {
    const vendors = await axios.post(
      `${process.env.REACT_APP_SERVER_URI}/api/vendors/getVendors`,
      {
        jwToken: sessionStorage.getItem("token"),
      }
    );
    console.log(vendors.data);
    setAllVendors(vendors.data);
  };

  useEffect(() => {
    getAllvendors();
  }, []);

  useEffect(() => {
    if (postType === "image") {
      if (selectedVendor) {
        // remove youtube from options

        let filteredOptions = options.filter((item) => item.key !== "youtube");

        console.log(filteredOptions);

        let keysToMatch = selectedVendor.vendorPages.map((item) => item.pageId);
        console.log(keysToMatch, "keysToMatch");

        const filteredObjects = filteredOptions.filter((obj) =>
          keysToMatch.includes(obj.key)
        );
        console.log(filteredObjects, "filteredObjects");

        setSelected(filteredObjects);
      }
    }
  }, [postType]);

  const handleChangeVendor = (e) => {
    console.log(e.target.value);
    console.log(allvendors[0]._id);

    let vv = allvendors.find((item) => item._id === e.target.value);

    console.log(vv);

    setSelectedVendor(vv);
  };

  // Use useEffect to handle the state update and filtering
  useEffect(() => {
    if (selectedVendor) {
      let keysToMatch = selectedVendor.vendorPages.map((item) => item.pageId);
      console.log(keysToMatch, "keysToMatch");

      const filteredObjects = options.filter((obj) =>
        keysToMatch.includes(obj.key)
      );
      console.log(filteredObjects, "filteredObjects");

      setSelected(filteredObjects);
    }
  }, [selectedVendor]);

  const [UserTagsWords, setUserTagsWords] = useState(null);
  const [userColloboratorsWords, setUserColloboratorsWords] = useState(null);

  const handleselectedUserTags = (e) => {
    console.log(e);

    let selectedUserTagssss = e.map((item) => {
      console.log(item);
      return item?.value?.username;
    });

    setselectedUserTags(selectedUserTagssss);

    console.log(selectedUserTags);
  };

  const handleSelectedcollaborators = (e) => {
    let selectedCollaborators = e.map((item) => item?.value?.username);

    setselectedCollaborators(selectedCollaborators);

  };

  const [cc, setCC] = useState(null);

  const initializeEditor = async () => {
    if (!cc) {
      try {
        const newCC = await window.CCEverywhere.initialize({
          clientId: "8dc3df9d65ec40b7aacb1700f2f7a121",
          container: "#adobe-express-container",
          appName: "Project 1",
          locale: "en-US",
        });
        setCC(newCC);
        return newCC;
      } catch (error) {
        console.error("Failed to initialize CC Everywhere:", error);
        return null;
      }
    }
    return cc;
  };


//  useEffect(() => {
//   if(typeof window !== 'undefined'){
//  // target shadow-root(open) to change the z-index of the editor

//   const shadowRoot = document.querySelector("#shadow-root").shadowRoot;
//   if (shadowRoot) {
//     const style = document.createElement("style");
//     style.textContent = `z-index: 1000000;`;
//     shadowRoot.appendChild(style);
//   }
//   }
//   }, [])


  const handleOpenEditor = async () => {
    const currentCC = await initializeEditor();
    if (currentCC && currentCC.editor) {
      // Instead of isActive(), directly attempt to create or handle an existing editor session
      try {
        currentCC.editor.create({
          docConfig: {
            canvasSize:
              postType === "image" ? {
                width: 1080,
                height: 1080,
              } : postType === "reel" ? {
                width: 1080,
                height: 1920,
              } : postType === "video" ? {
                width: 1920,
                height: 1080,
              } : null

            
          },
          appConfig: {
            selectedCategory: "media",
            templateType: "instagram-reel",
            allowedFileTypes: [
              "image/png",
              "image/jpeg",
              "application/pdf",
              "video/mp4",
            ],
          style:{
    zIndex: "1000000",
          },
            multiPage: false,
            callbacks: {
              // onCancel: () => {},
              // onError: (err) => { console.error("Editor error:", err); },
              // onLoadStart: () => {},
              // onLoad: () => {},
              // onPublishStart: () => {},
              // onPublish: (publishParams) => {
              //   console.log("Publishing completed", publishParams);
              // }
            }
          },
          exportConfig: [
            {
              action: {
                context: "new",
                target: "express",
              },
              id: "editor",
              label: "Open in Adobe Express",
              style: {
                uiType: "button",
              },
            },
            {
              action: {
                target: "publish",
                outputype: "URL",
                closeTargetOnExport: false,
              },
              id: "download-btn",
              // editor-download-button
              label: "Save in App",
              style: {
                uiType: "button",
              },
            },
            {
              action: {
                target: "export",
                outputype: "URL",
                closeTargetOnExport: false,
              },
              id: "export",
              label: "Export",

              style: {
                uiType: "button",
              },
            },
            {
              id: "download",
              label: "Download",
              action: {
                target: "download",
              },
              style: {
                uiType: "button",
              },
            },
            {
              id: "save-modified-asset",
              label: "image to app",
              action: {
                target: "publish",
              },
              style: {
                uiType: "button",
              },
            },
          ],
        });

        // Bind events
        currentCC.editor.onCancel = () => console.log("Cancelled");
        currentCC.editor.onError = (err) =>
          console.error("Error occurred:", err);
        currentCC.editor.onLoadStart = () => console.log("Loading started");
        currentCC.editor.onLoad = () => console.log("Loading completed");
        currentCC.editor.onPublishStart = () =>
          console.log("Publishing started");

        currentCC.editor.onPublish = (publishParams) => {
          console.log("Publishing completed", publishParams);
          console.log(publishParams);
          // Handle the publish action
        };

        currentCC.editor.onExport = (exportParams) => {
          console.log("Exported", exportParams);
          // Handle the export action
        };
        currentCC.editor.onClose = () => console.log("Editor closed");
      } catch (error) {
        toast.error("Editor already opened");
      }
    }
  };

  return (
    <>




      {minimized === false ? (
        <div className="create-post-container">
          <div
id="adobe-express-container"
style={{ zIndex: "1000000", position: "fixed", top: "0", left: "0"}}
></div>
          {/* {postingLoader === true ? (
            <div className="Email_Loader">
              <MoonLoader
                color={"#ff9001"}
                loading={postingLoader}
                size={150}
              />
            </div>
          ) : null} */}

          {/* {postingLoader === true ? (
            <div className="Email_Loader">
              <MoonLoader
                color={"#ff9001"}
                loading={postingLoader}
                size={150}
              />
            </div>
          ) : null} */}

          <div className="createPost_wrapper">
            <div className="form-header">
              <ToastContainer />
              <div className="add_line"></div>
              <div className="form-header-text">
                <h2>Create New Post</h2>
              </div>
              <div className="form-header-rightBtns">
                {eventData?.taskId ? (
                  <div
                    className="minimize-Btn"
                    onClick={() => {
                      DELETEAGENDAPOSTS({
                        unique_name: eventData.unique_name,
                        jwToken: sessionStorage.getItem("token"),
                      });
                      handleClickCreatePost();
                      toast.success("post deleted");
                    }}
                  >
                    <button type="button" height="44" width="auto">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 20 20"
                        fill="#ef6927"
                      >
                        <path d="M15,1.25 L15,3.75 L20,3.75 L20,6.25 L18.75,6.25 L18.75,18.75 C18.75,19.5 18.25,20 17.5,20 L2.5,20 C1.75,20 1.25,19.5 1.25,18.75 L1.25,6.25 L0,6.25 L0,3.75 L5,3.75 L5,1.25 C5,0.5 5.5,0 6.25,0 L13.75,0 C14.5,0 15,0.5 15,1.25 Z M7.5,2.5 L7.5,3.75 L12.5,3.75 L12.5,2.5 L7.5,2.5 Z M16.25,6.25 L3.75,6.25 L3.75,17.5 L16.25,17.5 L16.25,6.25 Z M6.25,8.75 L8.75,8.75 L8.75,16.25 L6.25,16.25 L6.25,8.75 Z M11.25,8.75 L13.75,8.75 L13.75,16.25 L11.25,16.25 L11.25,8.75 Z"></path>
                      </svg>
                    </button>
                  </div>
                ) : null}

                <div
                  className="minimize-Btn"
                  onClick={() => setMinimized(true)}
                >
                  <button type="button" height="44" width="auto">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M17.5,18.75 L7.5,18.75 L7.5,16.25 L14.48,16.25 L1.61,3.38 L3.38,1.61 L16.25,14.48 L16.25,7.5 L18.75,7.5 L18.75,17.5 C18.75,18.19 18.19,18.75 17.5,18.75 Z"></path>
                    </svg>
                  </button>
                </div>
                <div className="minimize-Btn" onClick={handleClickCreatePost}>
                  <button type="button" height="44" width="auto">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M18.37,1.62 C17.87,1.12 17.12,1.12 16.62,1.62 L10,8.25 L3.37,1.62 C2.87,1.12 2.12,1.12 1.62,1.62 C1.12,2.12 1.12,2.87 1.62,3.37 L8.25,10 L1.62,16.62 C1.12,17.12 1.12,17.87 1.62,18.37 C1.87,18.62 2.12,18.75 2.5,18.75 C2.87,18.75 3.12,18.62 3.37,18.37 L10,11.75 L16.62,18.37 C16.87,18.62 17.25,18.75 17.5,18.75 C17.75,18.75 18.12,18.62 18.37,18.37 C18.87,17.87 18.87,17.12 18.37,16.62 L11.75,10 L18.37,3.37 C18.87,2.87 18.87,2.12 18.37,1.62 Z"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="form-input-text-container">
              <div className="form-input-text-container-left">
                <div className="leftMain">
                  <div className="leftMain-container">
                 

                    <h4
                      className="recently-used-container mt-4 select_type__"
                      style={{ alignItems: "left" }}
                    >
                      Select Post Type{" "}
                    </h4>

                    <select
                      className="custom-select drop_cust_"
                      onChange={(e) => {
                        setPostType(e.target.value);
                        // setPostType(events.find((event) => event._id === e.target.value))
                      }}
                    >
                      <option className="optionsSelectPostType" value="image">
                        Image
                      </option>
                      <option className="optionsSelectPostType" value="reel">
                        Reel/shorts
                      </option>
                      <option className="optionsSelectPostType" value="video">
                        Video
                      </option>
                    </select>

                    <div className="socialNetwork-picker">
                      <div className="select-social-account">
                        {/* <div className='select-social-account-container'> */}
                        {/* {console.log(selected)} */}

                        <label className="select-social-account-label select_type__">
                          Select Vendor
                        </label>
                        <select
                          className="custom-select drop_cust_"
                          onChange={(e) => {
                            handleChangeVendor(e);
                          }}
                        >
                          <option className="optionsSelectPostType" value="">
                            Select Vendor
                          </option>
                          {allvendors &&
                            allvendors.map((vendor) => {
                              return (
                                <option
                                  className="optionsSelectPostType"
                                  value={vendor._id}
                                >
                                  {vendor.vendorName}
                                </option>
                              );
                            })}
                        </select>
                        <p className="info_text">
                          {" "}
                          Set up your client as a Vendor and add platforms like
                          Facebook, Instagram, YouTube, LinkedIn, Twitter, and
                          Pinterest. This allows you to swiftly compose and post
                          content without selecting each social account
                          individually. Additionally, you have the flexibility
                          to remove specific social accounts with ease.{" "}
                        </p>

                        {/* 
                        <Select
                          options={allvendors}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={selected}
                          onChange={handleChangeVendor}
                          isMulti={true}
                        /> */}
                      </div>
                      <h6 className="select_type__ mt-4">Publish To</h6>
                      {!selectedVendor ? (
                        <div style={{ color: "red" }}>
                          please select vendor first
                        </div>
                      ) : null}
                      <div className="select-social-account">
                        <Select
                          options={options}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={selected}
                          onChange={(event) => {
                            setSelected(event);
                          }}
                          isMulti={true}
                          isDisabled={selectedVendor ? false : true}
                        />
                      </div>

                      <span>
                        <h4 className="recently-used-container mt-4">
                          Account Holder
                        </h4>
                        <div className="dummy-page-options-block">
                          <div
                            className="dummy-page-options-inner-block"
                            role="button"
                            title={
                              JSON.parse(
                                localStorage.getItem("userProfileInfo")
                              ).fName
                            }
                            tabIndex="0"
                          >
                            <span
                              className="dummy-page-text"
                              // onClick={() => {
                              //   setSelected(selected.push(options[0]));
                              // }}
                            >
                              {
                                JSON.parse(
                                  localStorage.getItem("userProfileInfo")
                                ).fName
                              }
                            </span>
                          </div>
                        </div>
                      </span>
                    </div>
                    {/* {postContent?.length > 2000 ? (
                      <Alert message="Your text exceeds the character limit for Facebook" />
                    ) : null} */}

                    <div className="seprator"></div>
                    <div className="MessageEditContent-container">
                      <div
                        className="mb-4"
                        style={{
                          display: `${
                            isWebsiteRequired === true ? "block" : "none"
                          }`,
                        }}
                      >
                        <div className="upload-Media-header">
                          <div className="left-Text">
                            <h3 className="select_type__ mt-4">
                              Pinterest Web URL
                            </h3>
                          </div>
                        </div>
                        <input
                          className="text_box_inp"
                          placeholder="Enter Web URL for the Pinterest Video"
                          type="text"
                          name="name"
                          id="name"
                          value={PinintrestWebURL}
                          onChange={(e) => {
                            e.preventDefault();
                            setPinintrestWebURL(e.target.value);
                          }}
                        />
                      </div>

                      <div
                        style={{
                          display: `${
                            isWebsiteRequired || titleRequired === true
                              ? "block"
                              : "none"
                          }`,
                        }}
                      >
                        <div className="upload-Media-header">
                          <div className="left-Text">
                            <h3 className="select_type__ mt-4">Post Title</h3>
                          </div>
                        </div>
                        <input
                          className="text_box_inp"
                          placeholder="Enter title for the post"
                          type="text"
                          name="name"
                          id="name"
                          value={postTitle}
                          onChange={(e) => {
                            e.preventDefault();
                            setPostTitle(e.target.value);
                          }}
                        />
                      </div>

                      <div
                        className="mb-4"
                        style={{
                          display: `${
                            titleRequired === true ? "block" : "none"
                          }`,
                        }}
                      >
                        <div className="upload-Media-header">
                          <div className="left-Text">
                            <h3 className="select_type__ mt-4">
                              YouTube Video Privacy Status
                            </h3>
                          </div>
                        </div>
                        <select
                          className="custom-select drop_cust_"
                          defaultValue={"public"}
                          onChange={(e) => {
                            setPrivacyStatus(e.target.value);
                          }}
                        >
                          <option
                            className="optionsSelectPostType"
                            value="public"
                          >
                            Public
                          </option>
                          <option
                            className="optionsSelectPostType"
                            value="private"
                          >
                            Private
                          </option>
                          <option
                            className="optionsSelectPostType"
                            value="unlisted"
                          >
                            Unlisted
                          </option>
                        </select>

                        <div>
                          <div style={{ width: "95%" }}>
                            <div className="upload-Media-header">
                              <div className="left-Text">
                                <h3 className="select_type__ mt-4">
                                  YouTube Video keywords
                                </h3>
                              </div>
                            </div>
                            <CreatableSelect
                              placeholder="Youtube video Keywords"
                              isClearable
                              onChange={(e) => {
                                setYoutubeKeywords(e);
                              }}
                              isMulti={true}
                              styles={{
                                width: "95%",
                                marginTop: "25px",
                                marginBottom: "10px",
                              }}
                            />
                          </div>

                          {/* is youtube Embeddable */}
                          <div className="upload-Media-header">
                            <div className="left-Text">
                              <h3 className="select_type__ mt-4">
                                YouTube Video Embeddable
                              </h3>
                            </div>
                          </div>

                          <Switch
                            onChange={() => {
                              setYoutubeIsEmbaddable(!youtubeIsEmbaddable);
                            }}
                            checked={youtubeIsEmbaddable}
                          />
                        </div>
                        <div>
                          {/* is youtube Notify Subs */}
                          <div className="upload-Media-header">
                            <div className="left-Text">
                              <h3 className="select_type__ mt-4">
                                YouTube Notify subscribers
                              </h3>
                            </div>
                          </div>

                          <Switch
                            onChange={() => {
                              setYoutubeIsNotifySubscribers(
                                !youtubeIsNotifySubscribers
                              );
                            }}
                            checked={youtubeIsNotifySubscribers}
                          />
                        </div>
                      </div>
                      <div className="MessageEditContent-container-header">
                        <div className="MessageEditContent-div">
                          <div>
                            <div
                              role="list"
                              className="MessageEditContent-inner-div"
                            >
                              <div
                                role="listitem"
                                tabIndex="0"
                                className="MessageEditContent-box"
                              >
                                <div className="MessageEditContent-text">
                                  Description
                                </div>
                              </div>

                              {/* <div className="MessageEditContent-text">
                                {selected?.length > 0
                                  ? selected.map((element, key) => {
                                      return (
                                        <>
                                          <div
                                            className="ButtonPreviewPost"
                                            key={key}
                                            onClick={() => {
                                              setSelectedPreview(element.value);
                                            }}
                                          >
                                            {" "}
                                            <svg
                                              width="42"
                                              height="auto"
                                              viewBox="0 0 20 20"
                                              style={{ marginLeft: "0px" }}
                                              fill=  {
                                                socials[element.value.account_type]
                                                  ?.color   || "currentColor"
                                              }
                                            >
                                              <g>
                                                <path
                                                  width={32}

height={"auto"}                                                  d={
                                                    socials[
                                                      element.value.account_type
                                                    ]?.icon
                                                  }
                                                ></path>
                                              </g>
                                            </svg>
                                          </div>{" "}
                                        </>
                                      );
                                    })
                                  : null}
                              </div> */}
                            </div>
                          </div>
                        </div>

                        <div className="sc-hUlgpI hQCMeI">
                          <div className="sc-eUXlCE rc-CharacterCounter bUxVBB">
                            <span className="sc-iqlufy -characterCounterCount kPxjWs">
                              {selected?.length > 0
                                ? postContent?.length + "/" + 5000
                                : //  + limitValue
                                  // : postContent?.length
                                  null}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* <GrammarlyEditorPlugin
                        clientId={process.env.REACT_APP_GRAMMERLY_CLIENT_ID}
                      > */}
                      <MentionsInput
                        style={defaultStyle}
                        value={postContent}
                        onChange={(e) => setPostContent(e.target.value)}
                      >
                        <Mention
                          trigger="#"
                          data={searchHashtag}
                          style={defaultMentionStyle}
                          markup="#__display__"
                          regex={/@(\S+)/}
                        />
                      </MentionsInput>
                      {/* </GrammarlyEditorPlugin> */}
                      <div className="div_info_ mt-2">
                        <p className="info_text">
                          {" "}
                          Compose your message to share across the selected
                          platforms. If you prefer to customize the message for
                          a particular platform, simply click the button below
                          and enter a unique message different from the general
                          one{" "}
                        </p>{" "}
                      </div>
                      <div className={"emojiIconContainer"}>
                        <Button
                          className="custom_msg_btn"
                          onClick={() => setCustomContentSelected(true)}
                        >
                          Want Custom Message?
                        </Button>
                        <CustomContent
                          postContent={postContent}
                          setPostContent={setPostContent}
                          customContentSelected={customContentSelected}
                          setCustomContentSelected={setCustomContentSelected}
                          selected={selected}
                          customText={customText}
                          setCustomText={setCustomText}
                          socials={socials}
                        />
                        <img
                          src={emojiesIcon}
                          style={{ float: "right", cursor: "pointer" }}
                          alt="emojies"
                          title="Select your own emoji"
                          onClick={() => setShowEmojis(!showEmojis)}
                        />
                      </div>

                      {showEmojis && (
                        <div className="emoji_picker__">
                          <Picker
                            data={data}
                            onChange={addEmoji}
                            onEmojiSelect={addEmoji}
                          />
                          {/* <Picker data={data} onEmojiSelect={console.log} /> */}
                        </div>
                      )}
                      {console.log(paths.facebook)}

                      {paths?.facebook?.length > 0 &&
                      paths?.facebook?.includes(".mp4") ? (
                        <VideoDetails
                          paths={paths}
                          imgName={imgName}
                          handleClickDeleteImage={handleClickDeleteImage}
                        />
                      ) : // <ImgDetails
                      //   paths={paths}
                      //   imgName={imgName}
                      //   handleClickDeleteImage={handleClickDeleteImage}
                      // />
                      null}

                      {/*{*/}
                      {/*  paths.facebook  !== null ?*/}
                      {/*    typeof (paths) === "string" ? paths?.includes(".mp4") ? <VideoDetails paths={paths} imgName={imgName} handleClickDeleteImage={handleClickDeleteImage} />*/}
                      {/*            : <ImgDetails*/}
                      {/*      paths={paths}*/}
                      {/*      imgName={imgName}*/}
                      {/*      handleClickDeleteImage={handleClickDeleteImage}*/}
                      {/*    />*/}
                      {/*      : null*/}
                      {/*    :*/}
                      {/*    paths?.facebook?.includes(".mp4") ? <VideoDetails paths={paths.facebook} imgName={imgName} handleClickDeleteImage={handleClickDeleteImage} /> : <ImgDetails*/}
                      {/*      paths={paths}*/}
                      {/*      imgName={imgName}*/}
                      {/*      handleClickDeleteImage={handleClickDeleteImage}*/}
                      {/*    />*/}
                      {/*}*/}

              

                      {(imgName?.length !== 0) & (paths?.length === 0) ? (
                        <ImageUploadSpinner
                          handleClickDeleteImage={handleClickDeleteImage}
                        />
                      ) : null}

                      {}
                      {postType === "video" ? (
                        eventData?.vidurl || VideoUrl ? (
                          <ReelsDetails
                            videoUrl={eventData?.vidurl || VideoUrl}
                            setVideoUrl={setVideoUrl}
                          />
                        ) : !isOnlyInstagram ? (
                          
                          <VideoDropzonearea
                            VideoUrl={VideoUrl}
                            setVideoUrl={setVideoUrl}
                          />
                        ) : null
                      ) : null}

                      <div
                        className="mb-4"
                        style={{
                          display: `${
                            postType === "video"
                              ? titleRequired === true ||
                                selectedSocials.some(
                                  (element) =>
                                    element.value.account_type === "instagram"
                                )
                                ? "block"
                                : "none"
                              : "none"
                          }`,
                        }}
                      >
                        <div className="upload-Media-header">
                          <div className="left-Text">
                            <h3 className="select_type__ mt-4">
                              {" "}
                              Video Thumbnail/cover Image
                            </h3>
                          </div>
                        </div>

                        {paths["facebook"] && (
                          <div
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <img
                              src={paths["facebook"]}
                              alt=" preview"
                              width="40"
                              height="40"
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setPaths({ ...paths, facebook: "" });
                                setFileArr([]);
                              }}
                            >
                              <BsTrash color="red" />
                            </div>
                          </div>
                        )}

                        <DropzoneArea
                          isThumbnail={true}
                          setPaths={setPaths}
                          setImgName={setImgName}
                          setImgUrl={setImgUrl}
                          setFileArr={setFileArr}
                          FileArr={FileArr}
                          selected={selected}
                          paths={paths}
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                        />
                      </div>

                      {/*{*/}
                      {/*  selectedSocials.map(*/}
                      {/*      (element) => {*/}
                      {/*        if (element.value.account_type === "instagram") {*/}

                      {/*          return (*/}
                      {/*              <div style={{ fontSize: "24px", backgroundColor: "brown" }} onClick={() => { reels == true ? setReels(false) : setReels(true) }}>Reels</div>*/}
                      {/*          )*/}
                      {/*        }*/}
                      {/*        else {*/}
                      {/*          return null;*/}
                      {/*        }*/}

                      {/*        })}*/}

                      {/* {selectedSocials.map((element) => {
                        if (element.value.account_type == "instagram") {
                          return ReelUrl ? (
                            <ReelsDetails
                              ReelUrl={ReelUrl}
                              setReelUrl={setReelUrl}
                            />
                          ) : (
                            <ReelsDropzoneArea
                              ReelUrl={setReelUrl}
                              setReelUrl={setReelUrl}
                            />
                          );
                        } else if (element.value.account_type == "youtube") {
                          return youtubeUrl ? (
                            <YoutubeDetails
                              youtubeUrl={youtubeUrl}
                              setYoutubeUrl={setYoutubeUrl}
                            />
                          ) : (
                            <YoutubeDropzoneArea
                              youtubeUrl={youtubeUrl}
                              setYoutubeUrl={setYoutubeUrl}
                              paths={paths}
                              setPaths={setPaths}
                              selected = {selected}
                            
                              
                            />
                          );
                        }
                      })} */}

                      <div
                        style={{
                          display: `${
                            titleRequired === true ? "block" : "none"
                          }`,
                        }}
                      >
                        {/* <YoutubeDropzoneArea
                          youtubeUrl={youtubeUrl}
                          setYoutubeUrl={setYoutubeUrl}
                          paths={paths}
                          setPaths={setPaths}
                          selected={selected}
                         /> */}
                      </div>

                      <div
                        className="video_drp_wrp"
                        style={{
                          width: "70%",
                          height: "auto",
                          marginTop: "20px",
                          display: `${youtubeUrl ? "block" : "none"}`,
                        }}
                      >
                        <video
                          className="video__wrp_"
                          src={youtubeUrl || ""}
                          controls
                        ></video>
                      </div>

                      {selectedSocials &&
                        selectedSocials.some(
                          (element) =>
                            element.value.account_type === "instagram" &&
                            postType === "image"
                        ) && (
                          <div>
                            <div>
                              {paths.instagram &&
                                paths.instagram.map((image) => (
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "inline-block",
                                    }}
                                  >
                                    <img
                                      src={image}
                                      alt=" preview"
                                      width="40"
                                      height="40"
                                    />
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        deleteInstagramImageFromPaths(image)
                                      }
                                    >
                                      <BsTrash color="red" />
                                    </div>
                                  </div>
                                ))}
                            </div>
                            <div>
                              <InstaImageDropzone
                                className="custom_insta_drop_"
                                setPaths={setPaths}
                                setImgName={setImgName}
                                setImgUrl={setImgUrl}
                                setFileArr={setFileArr}
                                FileArr={FileArr}
                                selected={selected}
                                paths={paths}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                              />
                            </div>
                            <div className="div_info_ mt-2">
                              <p className="info_text">
                                Upload only <b>Instagram Post Image</b> here,
                                Sizes are 1080px(Width) - 1080px(Height), Less
                                than 5MB
                              </p>
                            </div>
                          </div>
                        )}
                      <div>
                        <>
                          {postType === "reel" ? (
                            eventData?.vidurl || ReelUrl ? (
                              <ReelsDetails
                                videoUrl={eventData?.vidurl || ReelUrl}
                                setVideoUrl={setReelUrl}
                              />
                            ) : (
                              <ReelsDropzoneArea
                                ReelUrl={setReelUrl}
                                setReelUrl={setReelUrl}
                              />
                            )
                          ) : null}

                          <div>


<div style={{ fontSize:'12px'}}>
  Don't have a design/video/poster yet? Create one with Adobe Express
</div>
                      <div
                      id="adobe-express-container"
                      style={{
                        zIndex: 210,
                        display: "flex",
                        flexDirection: "start",
                        width: "100%",
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor: "#ff9001",
                          color: "white",
                          width: "100%",
                        }}
                        onClick={handleOpenEditor}
                      >
                        <span style={{ marginRight: "5px" }}>
                          <SiAdobe />
                        </span>
                        Create new Design
                      </Button>
                    </div>
                    


                            {/* Add userTags here */}

                            {selectedSocials &&
                            selectedSocials.some(
                              (element) =>
                                element.value.account_type === "instagram"
                            ) ? (
                              <div>
                                <div
                                  style={{ marginTop: "25px", width: "95%" }}
                                >
                                  {/* <CreatableSelect
              placeholder="Tag users in this post"
              options={(() => {
                let allKeywords = [];
                // const allKeywords = instaUsers && instaUsers.map((item, i) => ({
                //   value: item,
                //   label: item,
                // }));




                if (UserTagsWords && UserTagsWords.keywords.length > 0) {
                  allKeywords.push(...UserTagsWords.keywords);
                }
                if (UserTagsWords && UserTagsWords.description.length > 0) {
                  allKeywords.push(...UserTagsWords.description);
                }
                if (UserTagsWords && UserTagsWords.title.length > 0) {
                  allKeywords.push(...UserTagsWords.title);
                }

                return allKeywords.map((item, i) => ({
                  value: item,
                  label: item,
                }));
              })()}
              onChange={handleselectedUserTags}
              isMulti={true}
              styles={{ width: "95%", marginTop: "25px", marginBottom: "10px"}}
            /> */}

                                  <AsyncSelect
                                    isMulti
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={debounce(
                                      (inputValue, callback) => {
                                        if (inputValue.length > 3) {
                                          InstaGramUsersSearch(inputValue).then(
                                            (res) => {
                                              callback(res);
                                            }
                                          );
                                        } else {
                                          callback([]); // return an empty array when inputValue length is less than or equal to 3
                                        }
                                      },
                                      3000 // delay in ms
                                    )}
                                    placeholder="Tag users in this post"
                                    onChange={handleselectedUserTags}
                                  />

                                  <div style={{ marginTop: "10px" }}>
                                    {/* <CreatableSelect
              placeholder="Add collaborators in the post"
              options={(() => {
                let allKeywords = [];

                if (userColloboratorsWords && userColloboratorsWords.keywords.length > 0) {
                  allKeywords.push(...userColloboratorsWords.keywords);
                }
                if (userColloboratorsWords && userColloboratorsWords.description.length > 0) {
                  allKeywords.push(...userColloboratorsWords.description);
                }
                if (userColloboratorsWords && userColloboratorsWords.title.length > 0) {
                  allKeywords.push(...userColloboratorsWords.title);
                }

                return allKeywords.map((item, i) => ({
                  value: item,
                  label: item,
                }));
              })()}
              onChange={handleSelectedcollaborators}
              isMulti={true}
              styles={{ width: "95%", marginTop: "25px" }}
            /> */}
                                    <AsyncSelect
                                      isMulti
                                      cacheOptions
                                      defaultOptions
                                      loadOptions={debounce(
                                        (inputValue, callback) => {
                                          if (inputValue.length > 3) {
                                            InstaGramUsersSearch(
                                              inputValue
                                            ).then((res) => {
                                              callback(res);
                                            });
                                          } else {
                                            callback([]); // return an empty array when inputValue length is less than or equal to 3
                                          }
                                        },
                                        3000 // delay in ms
                                      )}
                                      placeholder="collab with users in this post"
                                      onChange={handleSelectedcollaborators}
                                    />
                                  </div>
                                </div>
                                <p>
                                  Publish to InstaGram Stories :{" "}
                                  <Switch onChange={handleInstaStory} />
                                  {InstaStory ? (
                                    <span style={{ color: "green" }}>ON</span>
                                  ) : (
                                    <span style={{ color: "red" }}>OFF</span>
                                  )}
                                </p>
                              </div>
                            ) : null}
                          </div>
                        </>
                      </div>

                      {postType === "image" && !isOnlyInstagram ? (
                        <div className="upload-Media-container">
                          <div className="upload-Media-header">
                            <div className="left-Text d-flex align-items-center">
                              <h3 className="mb-2 mt-2">Media</h3>
                              <div id="maxLimitTooltip" className="toolTipBtn">
                                <div className="IbuttonBox">i</div>
                              </div>
                            </div>

                            <div
                              className="right-Text"
                              onClick={() => {
                                setGallery(true);
                                console.log(Gallery);
                              }}
                            >
                              <h3
                                style={{
                                  display: "none",
                                  margin: "10px 0",
                                  cursor: "pointer",
                                }}
                              >
                                Open Media Library
                              </h3>
                            </div>
                          </div>

                          {/* // Image Preview with delete button on top left corner */}
                          <div>
                            {paths.FileArr &&
                              paths.FileArr.map((image) => (
                                <div
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                  }}
                                >
                                  <img
                                    src={image}
                                    alt=" preview"
                                    width="40"
                                    height="40"
                                  />
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => deleteImageFromPaths(image)}
                                  >
                                    <BsTrash color="red" />
                                  </div>
                                </div>
                              ))}
                          </div>

                          <div className="uploadMedia-boxContainer">
                            {isLoading ? (
                              "loading...."
                            ) : (
                              <DropzoneArea
                                setPaths={setPaths}
                                setImgName={setImgName}
                                setImgUrl={setImgUrl}
                                setFileArr={setFileArr}
                                FileArr={FileArr}
                                selected={selected}
                                paths={paths}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                              />
                            )}

                            <div
                              className="upload-from-lib media-border"
                              onClick={() => {
                                setGallery(true);
                                console.log(Gallery);
                              }}
                            >
                              <button
                                className="uploadBtn2"
                                width="auto"
                                height="32"
                                type="button"
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 20 20"
                                  fill="#2f3638"
                                >
                                  <path d="M16.25,8.25 C16.25,8.25 16.25,8.12 16.25,8.12 C16.25,4.62 13.5,1.87 10,1.87 C6.87,1.87 4.25,4.12 3.87,7.25 C1.62,8 0,10 0,12.5 C0,15.62 2.5,18.12 5.62,18.12 C7.75,18.12 13,18.12 15,18.12 C17.75,18.12 20,15.87 20,13.12 C20,10.75 18.37,8.87 16.25,8.25 Z M11.25,11.87 L11.25,15.62 L8.75,15.62 L8.75,11.87 L5,11.87 L10,6.87 L15,11.87 L11.25,11.87 Z"></path>
                                </svg>
                              </button>
                              <p className="uploadBtn2-text1">
                                Explore thousands of assets
                              </p>
                              <p className="uploadBtn2-text2">
                                Browse your media
                              </p>
                            </div>
                          </div>
                          <Tooltip
                            isOpen={tooltipToggle}
                            target="maxLimitTooltip"
                            toggle={toggle}
                            className="tooltipMaxLimit"
                          >
                            Max Limit for uploading an image is 5MB.
                          </Tooltip>
                        </div>
                      ) : null}

                      {/* <ImgDetails /> */}
                    </div>
                    {postType === "image" && (
                      <div className="div_info_ ">
                        <p className="info_text">
                          Upload images here for Facebook, LinkedIn & Twitter
                          posts. The provided dimensions are universally
                          compatible with these platforms. Recommended image
                          sizes for upload are 1240px (Width) - 600px (Height),
                          Less than 5MB
                        </p>{" "}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-input-text-container-right">
                <div className="form-input-text-inner-container-right small_model_">
                  {Gallery === true ? (
                    <Pixabay
                      setPaths={setPaths}
                      paths={paths}
                      selected={selected}
                      setGallery={setGallery}
                    />
                  ) : (
                    <>
                      <div className="MessagePreview-area">
                        <div className="MessagePreview-header">
                          <div className="header-left">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="#143059"
                              className="sc-jMGUhp hVdFLT"
                            >
                              <g>
                                <path
                                  fill="#1877F2"
                                  d={
                                    selectedPreview
                                      ? socials[selectedPreview.account_type]
                                          .icon
                                      : null
                                  }
                                ></path>
                              </g>
                            </svg>
                          </div>
                          <div className="header-right">
                            <span
                              style={{
                                paddingBottom: "5px",
                                marginTop: "-5px",
                              }}
                            >
                              {selectedPreview
                                ? socials[selectedPreview.account_type].name
                                : null}
                            </span>
                          </div>
                        </div>

                        {selectedPreview ? (
                          <div className="previewBase-Layout">
                            <div className="previewBase-Layout-innerContainer">
                              <div className="profile-preview-container">
                                <div>
                                  {/* <img
                                    height={40}
                                    width={40}
                                    src={selectedPreview.picture}
                                    style={{
                                      clipPath: "circle(50% at 50% 50%)",
                                    }}
                                  /> */}
                                  <CgProfile
                                    // height={80}
                                    //   width={80}
                                    size={40}
                                    // style={{
                                    //   clipPath: "circle(50% at 50% 50%)",
                                    // }}
                                  />
                                </div>
                                <div
                                  className="profile-preview-name"
                                  width="60px"
                                >
                                  {selectedPreview.name}
                                  <div
                                    style={{
                                      fontWeight: "100",
                                      fontSize: "12px",
                                    }}
                                  >
                                    just Now
                                  </div>
                                </div>
                              </div>
                              <div className="content-preview-container img_leng">
                                {postContent?.length !== 0 ||
                                paths?.length !== 0 ? (
                                  <NewPost
                                    postContent={postContent}
                                    paths={paths}
                                    customText={customText}
                                    selectedPreview={selectedPreview}
                                  />
                                ) : (
                                  <>
                                    <div className="content-preview"></div>
                                    <div className="content-preview2"></div>
                                  </>
                                )}
                              </div>
                              <div className="bottom-preview-container">
                                <div className="like">
                                  <img
                                    width={30}
                                    className="bottom-img"
                                    src={
                                      socials[selectedPreview.account_type]
                                        .buttonBottom.like
                                    }
                                  ></img>{" "}
                                </div>
                                <div className="comment">
                                  <img
                                    width={30}
                                    className="bottom-img"
                                    src={
                                      socials[selectedPreview.account_type]
                                        .buttonBottom.comment
                                    }
                                  ></img>{" "}
                                </div>
                                <div className="share">
                                  <img
                                    width={30}
                                    className="bottom-img"
                                    src={
                                      socials[selectedPreview.account_type]
                                        .buttonBottom.share
                                    }
                                  ></img>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="previewBase-Layout">
                            <div className="previewBase-Layout-innerContainer">
                              <div className="profile-preview-container">
                                <div className="profile-preview-pic">
                                  <div className="profile-blank"></div>
                                </div>
                                <div
                                  className="profile-preview-name"
                                  width="60px"
                                >
                                  <div className="bottom-blank"></div>
                                </div>
                              </div>
                              <div className="content-preview-container img_leng">
                                {postContent?.length !== 0 ||
                                paths?.length !== 0 ? (
                                  <NewPost
                                    postContent={
                                      postContent?.startsWith("https://") ? (
                                        <a
                                          href={postLink}
                                          rel="noreferrer"
                                          target="_blank"
                                        >
                                          {postLink}
                                        </a>
                                      ) : (
                                        postContent
                                      )
                                    }
                                    paths={paths}
                                  />
                                ) : (
                                  <>
                                    <div className="content-preview"></div>
                                    <div className="content-preview2"></div>
                                  </>
                                )}
                              </div>
                              <div className="bottom-blank-container">
                                <div className="like">
                                  <div className="bottom-blank"></div>
                                </div>
                                <div className="comment">
                                  <div className="bottom-blank"></div>
                                </div>
                                <div className="share">
                                  <div className="bottom-blank"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* ------------------------------------POST PREVIEW END------------------------------------------------- */}
                        <div
                          style={{
                            marginTop: "30px",
                            fontSize: "14px",
                            textAlign: "center",
                          }}
                        >
                          Social networks regularly make updates to formatting,
                          so your post may appear slightly different when
                          published. <a href="#">Learn more</a>
                        </div>
                      </div>
                    </>
                  )}

                  <Modal
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    date={date}
                    preDate={
                      ConvertDateFormat(eventData?.date?.slice(0, 10)) || null
                    }
                    setDate={setDate}
                    time={time}
                    setTime={setTime}
                    schedule={schedule}
                    setSchedule={setSchedule}
                  />
                </div>
              </div>
            </div>

            <div className="bottom-Container">
              <div className="scheduleBtn">
                <span className="scheduleBtn-container">
                  {date || time ? (
                    <>
                      {" "}
                      {console.log("isOpen State ", isOpen)}
                      <button
                        onClick={() => setIsOpen(true)}
                        className="scheduleLaterBtn"
                        width="auto"
                        height="44"
                        type="button"
                        name="time"
                      >
                        {selectedDate}{" "}
                        <span
                          style={{ color: "red" }}
                          onClick={() => {
                            setDate(null);
                            setTime(null);
                            setIsOpen(false);
                          }}
                        >
                          <h3>x</h3>
                        </span>
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() => {
                        setIsOpen(true);
                        console.log("Its open");
                      }}
                      className="scheduleLaterBtn"
                      width="auto"
                      height="44"
                      type="button"
                    >
                      Schedule Now
                    </button>
                  )}
                </span>
              </div>

              <div className="postNowBtn-container" type="cta">
                {date || time ? (
                  <>
                    <button
                      className="postNowBtn"
                      width="auto"
                      height="44"
                      cursor="pointer"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        // handleSchedulePost();
                        handleMultiSchedule();
                      }}
                    >
                      Schedule Now
                    </button>
                    {/*<div className="postNowBtn-arrow-container">*/}
                    {/*  <div style={{ position: "relative" }}>*/}
                    {/*    <button*/}
                    {/*      className="postNowBtn-arrow"*/}
                    {/*      aria-label="more"*/}
                    {/*      width="auto"*/}
                    {/*      height="44"*/}
                    {/*      type="button"*/}
                    {/*      onClick={() => {*/}
                    {/*        scheduleOptions == false*/}
                    {/*          ? setScheduleOptions(true)*/}
                    {/*          : setScheduleOptions(false);*/}
                    {/*      }}*/}
                    {/*    >*/}
                    {/*      <svg*/}
                    {/*        width="16"*/}
                    {/*        height="16"*/}
                    {/*        viewBox="0 0 20 20"*/}
                    {/*        fill="#143059"*/}
                    {/*      >*/}
                    {/*        <polygon points="10 14.25 3.25 7.5 5 5.75 10 10.75 15 5.75 16.75 7.5"></polygon>*/}
                    {/*      </svg>*/}
                    {/*    </button>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </>
                ) : (
                  <>
                    {" "}
                    <button
                      className="postNowBtn"
                      width="auto"
                      height="44"
                      cursor="pointer"
                      type="button"
                      onClick={handleMultiPost}
                    >
                      <ScaleLoader
                        width={2}
                        height={20}
                        size={20}
                        color="#ffffff"
                        loading={posting}
                      />
                      Post Now
                    </button>
                    {/*<div className="postNowBtn-arrow-container">*/}
                    {/*  <div style={{ position: "relative" }}>*/}
                    {/*    <button*/}
                    {/*      className="postNowBtn-arrow"*/}
                    {/*      aria-label="more"*/}
                    {/*      width="auto"*/}
                    {/*      height="44"*/}
                    {/*      type="button"*/}
                    {/*      onClick={() =>*/}
                    {/*        postOptions == false*/}
                    {/*          ? setPostOPtions(true)*/}
                    {/*          : setPostOPtions(false)*/}
                    {/*      }*/}
                    {/*    >*/}
                    {/*      <svg*/}
                    {/*        width="16"*/}
                    {/*        height="16"*/}
                    {/*        viewBox="0 0 20 20"*/}
                    {/*        fill="#143059"*/}
                    {/*      >*/}
                    {/*        <polygon points="10 14.25 3.25 7.5 5 5.75 10 10.75 15 5.75 16.75 7.5"></polygon>*/}
                    {/*      </svg>*/}
                    {/*    </button>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </>
                )}

                {postOptions === true ? (
                  <div className="postPopUp">
                    <div className="postPopUpHeader" onClick={handlePostDraft}>
                      Save Draft{" "}
                    </div>
                    <div className="postPopUpHeader" onClick={postAndReuse}>
                      Post and Reuse account{" "}
                    </div>
                    <div
                      className="postPopUpHeader"
                      onClick={handlePostAndDuplicate}
                    >
                      Post and Duplicate{" "}
                    </div>
                  </div>
                ) : null}

                {scheduleOptions === true ? (
                  <div className="postPopUp">
                    <div
                      className="postPopUpHeader"
                      onClick={handleScheduleDraft}
                    >
                      Save Draft{" "}
                    </div>
                    <div className="postPopUpHeader" onClick={scheduleAndReuse}>
                      Schedule and Reuse account{" "}
                    </div>
                    <div
                      className="postPopUpHeader"
                      onClick={handleScheduleAndDuplicate}
                    >
                      Post and Duplicate{" "}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div onClick={() => setMinimized(false)} className="fixed">
          {" "}
          <div className="vk-MinimizedComposerModal sc-fJgHZD kaynPP">
            <div className="vk-MaximizeComposerHeader sc-jwUKeQ jqSPTz">
              <p className="sc-jgpVhK hNkawu">New Post</p>
              <button
                className="sc-jIjYVd iGGRsv"
                width="auto"
                height="28"
                type="button"
              >
                <svg width="16" height="16" viewBox="0 0 20 20" fill="#143059">
                  <path d="M17.5,1.25 L7.5,1.25 L7.5,3.75 L14.48,3.75 L1.61,16.61 L3.38,18.38 L16.25,5.51 L16.25,12.5 L18.75,12.5 L18.75,2.5 C18.75,1.81 18.19,1.25 17.5,1.25 Z"></path>
                </svg>
              </button>
            </div>
            <div className="sc-hcSvYt cuXuNS">
              <div className="sc-gjcKCj fZAwwt">{postContent}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
function fetchHashtags({ queryKey }) {
  const [_, query] = queryKey;
  return axios.get(
    `${process.env.REACT_APP_SERVER_URI}/api/hashtags/autoComplete/${query}`
  );
}
export default CreateNewPost;
