import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { useNavigate, useParams } from "react-router";
import Avatar from "../../Components/Avtar/Avtar";
import {
  getPaymentsListAPI,
  getProfileUrl,
  getUserProfileInfo,
  updateUserProfileInfo,
  DeteteUser,
} from "../../Utils/api";
import { packs } from "../../Utils/Constants";
import easyinvoice from 'easyinvoice';
import tooweLarge from "../../assets/2406x437.png";
import {
  GetConvertDateFromUnix,
  getSubcribstionEndDate,
} from "../../Utils/GetCurrentDate";
import "./profile.css";
import { useDispatch, useSelector } from "react-redux";
import CreateNewPost from "../Dashboard/CreateNewPost";
import CreatePin from "../Dashboard/CreatePin";
import CreateBoard from "../Dashboard/CreateBoard";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import logoutIcon from "../../assets/logoutIconClient.png";
import { logo } from "../../assets/logoBase64";

const Profile = ({ pagesDetails }) => {
  const dispatch = useDispatch();
  const [selectedForm, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const isCreateNewPostMenu = useSelector(
    (state) => state.createNewPost.showCreatePostMenu
  );

  const isCreateNewPinMenu = useSelector(
    (state) => state.createNewPin.showCreatePinMenu
  );

  const isCreateNewBoardMenu = useSelector(
    (state) => state.createNewBoard.showCreateBoardMenu
  );

  const { id } = useParams();
  const [UserProfileDetails, setUserProfileDetails] = useState();
  const [lName, setLName] = useState("");
  const [firstName, setfirstName] = useState("");
  const [imgFile, setImgFile] = useState("");
  const [subscriptionList, setSubscriptionList] = useState([]);

  const formData = new FormData();

  const userDetails = localStorage.getItem("userProfileInfo");
  const plan = JSON.parse(userDetails).subscription.split(" ")[0];
  const endd = JSON.parse(userDetails)?.planExpiry
  const EndDate = new Date(parseInt(endd)).toDateString();

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchInfo(id);
      fetchSubcriptionInfo({ id });
    }
  }, [id]);

  const handleClickUpgrade = () => {
    navigate("/upgrade");
  };

  const uploadProfilePic = async (data) => {
    const res = await getProfileUrl(data);
    setImgFile(res.data.imgurl);

    const updatedInfo = { ...UserProfileDetails, profileUrl: res.data.imgurl };
    updateInfo(updatedInfo);
  };

  const fetchSubcriptionInfo = async (id) => {
    try {
      const res = await getPaymentsListAPI(id);

      setSubscriptionList(res.data.orderList);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchInfo = async (id) => {
    try {
      const res = await getUserProfileInfo(id);
      setUserProfileDetails(res.data);

      if (res.data.fName.split(" ")[1]) {
        setLName(res.data.fName.split(" ")[1]);
        setfirstName(res.data.fName.split(" ")[0]);
        setImgFile(res.data.profileUrl);
      } else {
        setfirstName(res.data.fName);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateInfo = async (info) => {
    try {
      const res = await updateUserProfileInfo(info);
      setUserProfileDetails(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickUpdate = () => {
    let name = firstName;
    if (firstName.length === 0) {
      window.alert("First Name is required");
      return;
    }
    if (lName.length !== 0) {
      name = `${firstName} ${lName}`;

      setUserProfileDetails((current) => {
        return {
          ...current,
          fName: name,
        };
      });
    }

    const updatedInfo = { ...UserProfileDetails, fName: name };

    updateInfo(updatedInfo);
  };

  const handleChangeFirstName = (value) => {
    setUserProfileDetails((current) => {
      return {
        ...current,
        fName: value,
      };
    });
    setfirstName(value);
  };

  const handleChangeLastName = (value) => {
    if (value.trim() !== "") {
      setLName(value);
    } else {
      setLName("");
    }
  };

  const handleChangecompanyName = (value) => {
    setUserProfileDetails((current) => {
      return {
        ...current,
        company: value,
      };
    });
  };

  // const handleChangetimeZone = (value) => {
  //   setUserProfileDetails((current) => {

  //     return {
  //       ...current,
  //       timeZone : value

  //     }
  //   })
  // }

  const handleChangeEmail = (value) => {
    setUserProfileDetails((current) => {
      return {
        ...current,
        email: value,
      };
    });
  };

  const handleChangePassword = (value) => {
    setUserProfileDetails((current) => {
      return {
        ...current,
        password: value,
      };
    });
  };

  const handleChangePasswordOld = (value) => {
    setUserProfileDetails((current) => {
      return {
        ...current,
        passwordOld: value,
      };
    });
  };

  const handleClickSaveEmail = () => {
    updateInfo(UserProfileDetails);
  };

  const handleClickSavePassword = () => {
    if (
      !(
        UserProfileDetails.passwordOld &&
        UserProfileDetails.passwordOld.length !== 0
      )
    ) {
      window.alert("Please enter the old password");
      return;
    }

    if (
      !(UserProfileDetails.password && UserProfileDetails.password.length === 0)
    ) {
      window.alert("Please enter the New password");
      return;
    }
    updateInfo(UserProfileDetails);
  };

  const handleChangeProfile = (e) => {
    const data = e.target.files[0];
    formData.append("file", data);
    uploadProfilePic(formData);
  };

  // const handleClickInvoiceDownload = (item, date) => {
  //   var reportData = [];
  //   reportData.push(item);

  //   const unit = "pt";
  //   const size = "a4"; // Use A1, A2, A3 or A4
  //   const orientation = "portrait"; // portrait or landscape
  //   const marginLeft = 40;
  //   const doc = new jsPDF(orientation, unit, size);
  //   doc.setFontSize(15);
  //   const title = `${date} Subscription`;

  //   const headers = [Object.keys(item)];
  //   const data = reportData.map((elt) => [
  //     elt.id,
  //     elt.entity,
  //     elt.amount,
  //     elt.currency,
  //     elt.base_amount,
  //     elt.base_currency,
  //     elt.status,
  //     elt.order_id,
  //     elt.invoice_id,
  //     elt.nternational,
  //     elt.method,
  //     elt.amount_refunded,
  //     elt.refund_status,
  //     elt.captured,
  //     elt.description,
  //     elt.card_id,
  //     elt.card,
  //     elt.bank,
  //     elt.wallet,
  //     elt.vpa,
  //     elt.email,
  //     elt.contact,
  //     elt.notes,
  //     elt.fee,
  //     elt.tax,
  //     elt.error_code,
  //     elt.error_description,
  //     elt.error_source,
  //     elt.error_step,
  //     elt.error_reason,
  //     elt.acquirer_data,
  //     elt.created_at,
  //   ]);

  //   let xAxisHead = 60;
  //   let yAxisHead = 60;
  //   let xAxisVal = 200;
  //   let yAxisVal = 60;
  //   headers[0].map((head) => {
  //     yAxisHead += 20;
  //     return doc.text(xAxisHead, yAxisHead, head);
  //   });

  //   data[0].map((dat) => {
  //     yAxisVal += 20;
  //     return doc.text(xAxisVal, yAxisVal, String(dat));
  //   });

  //   doc.text(title, marginLeft, 40);

  //   doc.save(`${date} Subscription.pdf`);
  // };

  const handleClickInvoiceDownload = (item, date) => {
    console.log(item);
    const subData = item;
    const doc = new jsPDF();
    const title = `${date} Subscription`;
    const imgData = logo;

    doc.addImage(imgData, "PNG", 20, 5, 30, 15);
    doc.text(title, 100, 10);

    doc.autoTable({
      theme: "striped",
      margin: { top: 30 },
      styles: {
        fontSize: 16,
        cellPadding: 0.5,
        rowHeight: 10,
        marginTop: 10,
        valign: "middle",
      },
      title: "Subscription Details",

      head: [[" ", "INVOICE"]],
      body: [
        ["Id", subData?.payment.id],
        ["Amount", subData?.payment.amount / 100],
        ["Date", new Date(subData?.payment?.created * 1000).toDateString()],
        ["Currency", subData?.payment.currency],
        // ["Description", subData.order_note + " Plan"],
        ["Email", subData?.payment.charges.data[0].billing_details.email],
        // ["Contact", subData.customer_details.customer_phone],
        // ["Entity", subData.entity],
        ["Order Id", subData?.payment.id],

        // ['David', 'david@example.com', 'Sweden'],
        // ['Castille', 'castille@example.com', 'Norway'],
      ],
    });

    doc.save(new Date(subData?.payment?.created * 1000).toDateString() + ".pdf");
  };

const handleDownloadInvoice = (item, date) => {
  const subData = item;
 async function getSampleData() {
    return {
   
        images: {
            logo: 'https://marketifyfiles.blob.core.windows.net/files/2406x437.png'
        },
        sender: {
            company: 'Crib Store',
            address: 'Sample Street 123',
            zip: '1234 AB',
            city: 'Hydrabad',
            country: 'India'
        },
        client: {
        },
        information: {
          //  OrderId: subData?.payment.id,
            date: new Date(subData?.payment?.created * 1000).toDateString()  || new Date(subData?.created_at * 1000).toDateString(),
        },
        products: [
            {
                quantity: 1,
                description:   'Toowe Subscription',
                'tax-rate': 0,
                price: subData?.payment.amount / 100 || subData?.amount / 100,
            },
        ],
        'bottom-notice': '',
        settings: {
            currency: 'USD'
        },    
        "translate": {         
          'vat': 'other taxes', 
          'subtotal': 'Sub-total(including GST)'    ,
 
        },
     };
} 
  async function createInvoice() {
    // See documentation for all data properties
    const data = getSampleData();
    const result = await easyinvoice.createInvoice(data);
    this.setState({
        invoiceBase64: result.pdf
    });
}

async function downloadInvoice() {
    // See documentation for all data properties
    const data = await getSampleData();
    const result = await easyinvoice.createInvoice(data);
    easyinvoice.download('myInvoice.pdf', result.pdf);
    //	you can download like this as well:
    //	easyinvoice.download();                                      
    //	easyinvoice.download('myInvoice.pdf');    
}

downloadInvoice();

}









  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  };

const DeleteAccount = () => {
  if (window.confirm('Are you sure you want to delete your account?')) {
    DeteteUser({ jwToken: localStorage.getItem("token") });
    logout();
  }
};

  return (
    <div className="profileOuterLayout">
      <div className="profileHeadLayout regular">
        <div className="btnSection">
          <button
            onClick={() => {
              navigate("/link-account");
            }}
          >
            Add Social Network
          </button>
        </div>
      </div>
      <div className="ProfileSectionBody">
        {/* ----------------------------First Section------------------------------ */}
        <div className="profileSubSection regular">
          <div className="profileSectionHead">
            <p className="regular profileHeadingText">My Profile</p>
          </div>
          {/* <div className="profileContainer">
            <img src={require("../../assets/myprofile_icon.png")} alt="profile" />
          </div> */}

          <Avatar imageUrl={imgFile} />

          {/* <a
            style={{ display: "block", marginBottom: "40px" }}
            onClick={() => {
              document.getElementById("getFile").click();
            }}
          >
            Upload Profile Pic
          </a> */}
          <div className="uploadBtn" onChange={handleChangeProfile}>
            <input type="file" id="getFile" />
            <p>Upload Profile Pic</p>
          </div>
          <div className="inputBlock">
            <label>First Name</label>
            <br />
            <input
              type="text"
              value={
                UserProfileDetails && UserProfileDetails.fName
                  ? UserProfileDetails.fName.split(" ")[0]
                  : ""
              }
              onChange={(e) => handleChangeFirstName(e.target.value)}
            />
          </div>
          <div className="inputBlock">
            <label>Last Name</label>
            <br />
            <input
              type="text"
              value={lName}
              onChange={(e) => handleChangeLastName(e.target.value)}
            />
          </div>
          <div className="inputBlock">
            <label>Company Name</label>
            <br />
            <input
              type="text"
              value={UserProfileDetails ? UserProfileDetails.company : ""}
              onChange={(e) => handleChangecompanyName(e.target.value)}
            />
          </div>
          {/* <div className="inputBlock">
            <label>Time Zone</label>
            <br />
            <select>
              <option>(GMT+5.00) Asia / Kolkata</option>
              <option>(GMT+5.00) Asia / Kolkata</option>
              <option>(GMT+5.00) Asia / Kolkata</option>
              <option>(GMT+5.00) Asia / Kolkata</option>
            </select>
          </div> */}
          <div className="inputBlock">
            <button onClick={handleClickUpdate}>UPDATE</button>
          </div>
        </div>

        {/* ---------------------------------------------------------- */}
        <div className="profileSubSection regular">
          <div className="profileSubSectionTwo">
            <div className="sectionOne">
              <p className="sectionTwoPara">Email Address</p>
              <p>Current Email Id</p>
              <p className="emailTxt">
                {UserProfileDetails && UserProfileDetails.email}
              </p>
              <div className="inputBlock">
                <label>Change Email Id</label>
                <br />
                <input
                  type="text"
                  onChange={(e) => handleChangeEmail(e.target.value)}
                />
              </div>
              <div className="inputBlock">
                <button onClick={handleClickSaveEmail}>SAVE</button>
              </div>
            </div>
            <div className="sectionTwo">
              <p className="sectionTwoPara">Change Password</p>
              <div className="inputBlock">
                <label>Old Password</label>
                <br />
                <input
                  type="text"
                  onChange={(e) => handleChangePasswordOld(e.target.value)}
                />
              </div>
              <div className="inputBlock">
                <label>New Password</label>
                <br />     
                <input
                  type="text"
                  onChange={(e) => handleChangePassword(e.target.value)}
                />
              </div>
              <div className="inputBlock">
                <button onClick={handleClickSavePassword}>SAVE</button>
              </div>
            </div>
          </div>
        </div>
        <div className="profileSubSection regular">
          <div className="paymentInfoSection">
            <p className="paymentPrimaryText">Payment Info</p>
            <p>Current Plan</p>
            <p>
              <span style={{ color: "#ff8b00" }}>
                {plan} - {packs[plan]}
              </span>
              <br />
              <span style={{ color: "#979797", fontSize: "14px" }}>
                Subscription ends on {EndDate}
              </span>
            </p>
            <div className="upreBtns">
              <button
                style={{ backgroundColor: " #ff8b00" }}
                className="bold"
                onClick={handleClickUpgrade}
              >
                UPGRADE
              </button>
              <button
                style={{ backgroundColor: "#08365e" }}
                className="bold"
                onClick={handleClickUpgrade}
              >
                RENEW
              </button>
            </div>
          </div>
          <div className="subscriptionInfoSection">
            <p>Subscription Info</p>
            <div className="invoiceSection">
              {subscriptionList.map((element) => {
                console.log("Hi", element);
                return (
                  <>
                    <div className="record">
                      <div
                        style={{
                          padding: "5px 10px 5px 10px",
                          fontSize: "13px",
                        }}
                      >
                        <span style={{ fontSize: "12px", color: "#979797" }}>
                          Paid on
                        </span>
                        <br />
                        {
                          new Date(
                            element.order.payment?.created * 1000
                          ).toDateString()
                          // Date.parse(element.order.payment.created * 1000)
                        }{" "}
                      </div>
                      <div
                        style={{
                          padding: "15px 10px 5px 0px",
                          color: "#ff8b00",
                          fontSize: "13px",
                          cursor: "pointer",
                        }}
                        className="bold"
                        onClick={() =>
                          handleDownloadInvoice(
                            element.order,
                            element.order?.created_at
                          )
                        }
                      >
                        Download Invoice
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
        <div className="profileSubSection regular">
          <button className="deletebtn bold" onClick={DeleteAccount}>
            Delete Account
          </button>
        </div>

        {/* <button
          className="bold"
          style={{
            backgroundColor: " #ff8b00",
            paddingLeft: "10px",
            paddingRight: "10px",
            height: "40px",
            color: "#fff",
          }}
          onClick={logout}
        >
          Log out{" "}
        </button> */}
      </div>
      <div className="profilebottom"></div>
      {isCreateNewPostMenu && (
        <CreateNewPost
          pagesDetails={pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {isCreateNewPinMenu && (
        <CreatePin
          pagesDetails={pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {isCreateNewBoardMenu && (
        <CreateBoard
          pagesDetails={pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </div>
  );
};

export default Profile;
