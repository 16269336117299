

import axios from "axios";
import { useState, useEffect } from "react";








const Timeline = ({userInfo}) => {
    const [timelineData, setTimelineData] = useState([]);
    const [loading, setLoading] = useState(false);


    const getTimeLineData = async (userId) => {


        const response = await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/crud/getUserTimeLine`,{
      
          "userId": userId,
       
        },{
            headers: {
                "Content-Type": "application/json",
                //  userInfo: JSON.parse(localStorage.getItem("userInfo")).token,
                 token: JSON.parse(localStorage.getItem("userProfileInfo")).token,

            },
        });
    
        // const response = await fetch(`${process.env.REACT_APP_SERVER_URI}/api/crud/${userId}`);
        // const data = await response.json();



        return response.data;
    }
    


    useEffect(() => {
        setLoading(true);
        getTimeLineData(userInfo._id)
            .then((data) => {
                setTimelineData(data);
                setLoading(false);
            })
          
    }, [userInfo._id]);

    if (loading) {
        return <p>Loading...</p>;
    }


    return (
        <div style={{alignItems:'flex-start', textAlign:'start', height:'100vh', width:'100%', overflow:'scroll'}}>
            <h1>Timeline</h1>
         
            <ul>
                {timelineData.map((item) => (
                    <li key={item._id}>
                      
                        <p >{JSON.stringify(item.result)}</p>
                        <p>{JSON.stringify(item.message)}</p>
                        <p>{JSON.stringify(item.createdAt)}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};


export default Timeline;