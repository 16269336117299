import React from "react";
import { useState } from "react";
// import "./styles.css";
import { Line } from "react-chartjs-2";


export default function PinintrestAccountLineChart({ date, impressions, engagements}) {
  const [lineData, setLineData] = useState({
    data: {
      engagements: engagements,
      impressions: impressions,
    },
    categories: date,

  });



  const datas = {
    labels:
      lineData.categories,
    //  [9,8,7,6,5,4,3,2,1] ,
    datasets: [
      {
        label: "impressions",
        data: lineData.data.impressions,
        //  [3, 9, 23, 8, 12, 42, 2, 43],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        grid: {
          display: false
        },
        tension: 0.5,
      },
      {
        label: "Engagement",
        data: lineData.data.engagements,
        // [3, 56, 23, 44, 12, 32, 12, 34],
        fill: true,
        borderColor: "#742774",
        tension: 0.5,
      },

    ],

    tension: 1
  };
  const options = {
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        }
      }
    },
    maintainAspectRatio: false

  }


  return (
    <centre>
      <div style={{ margin: 'auto', position: "relative", "height": "400px", width: "100%" }} >
        <Line data={datas} options={options} />

      </div>
    </centre>
  );
}
