import React, { useEffect } from "react";
import CustomDropDown from "../../../Components/CustomDropDown/CustomDropDown";
import "./NewAnalyticsPage.css";
import refreshIcon from "../../../assets/refreshIcon.png";
import faceBookAnalytics from "./FaceBookAnalytics";
import calendarIcon from "../../../assets/calendarIcon.png";
import { useState } from "react";
import FbAnalytics from "./FbAnalytics/FbAnalytics";

import "flatpickr/dist/themes/light.css";
import Flatpickr from "react-flatpickr";

import FaceBookAnalytics from "./FaceBookAnalytics";
import InstagramAnalytics from "./Instagram/InstagramAnalytics";
import YoutubeAnalytics from "./Youtube/YoutubeAnalytics";
import PinintrestAnalytics from "./PinintrestAnalytics";
import { Props } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import CreateNewPost from "../CreateNewPost";
import CreatePin from "../CreatePin";
import CreateBoard from "../CreateBoard";
import { json, useNavigate } from "react-router";
import AnaPage from "../../../Components/RabbitAnalytics/page";
import axios from "axios";

function NewAnalyticsPageV2(props) {
  const [selectedForm, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log("props", props);
  const isCreateNewPostMenu = useSelector(
    (state) => state.createNewPost.showCreatePostMenu
  );

  const isCreateNewPinMenu = useSelector(
    (state) => state.createNewPin.showCreatePinMenu
  );

  const isCreateNewBoardMenu = useSelector(
    (state) => state.createNewBoard.showCreateBoardMenu
  );

  const [isActive, setIsActive] = useState(false);
  const [isActiveSocials, setIsActiveSocials] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState("All Networks");

  const [selected, setIsSelected] = useState("All Networks");
  const [list, setList] = useState(["one", "two", "three"]);
  
  const [allVendors, setAllVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedVendorAccount, setSelectedVendorAccount] = useState([]);
  const [ThreeTimesAnalyticsData, setThreeTimesAnalyticsData] = useState([]);
  const [analyticsStartDate, setAnalyticsStartDate] = useState({
    date: new Date(new Date() - 7 * 24 * 60 * 60 * 1000).toISOString(),
    pindate: new Date(new Date() - 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0],
  });
  const [analyticsEndDate, setAnalyticsEndDate] = useState({
    date: new Date().toISOString(),
    pindate: new Date().toISOString().split("T")[0],
  });

  const handleClickReload = () => {
    window.location.reload();
  };

  const handleClickAddNetwork = () => {
    // code to add account
    navigate("/link-account");
  };

  const getAllVendors = async () => {
    try {
        let data = await axios.post(process.env.REACT_APP_SERVER_URI + "/api/vendors/getVendors", {
            vendorUserId:JSON.parse(localStorage.getItem('userProfileInfo'))._id ,
            jwToken:JSON.parse(localStorage.getItem('userProfileInfo')).token
          });
          console.log("vendors.........." ,data.data);
          setAllVendors(data.data);
            setSelectedVendor(data.data[0].vendorName);
    
    } catch (error) {
      console.log(error);
    }
  };

  const getThreeTimesAnalyticsData = async () => {
    try {
        let data = await axios.post(process.env.REACT_APP_SERVER_URI + "/api/crud/GetThreeTimesAnalyticsData", {
            vendorID:  selectedVendorAccount._id,
            // date: customDate,
            timeFrame:'day'
            },{
              headers: {
                token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
                userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
              }
            });
            console.log("ThreeTimesAnalyticsData.........." ,data.data);
            setThreeTimesAnalyticsData(data.data);

    } catch (error) {
        console.log(error);
    }
    };

    useEffect(() => {
        getThreeTimesAnalyticsData();
        }, [selectedVendorAccount, analyticsStartDate, analyticsEndDate]);


  useState(() => {
    getAllVendors();
  }, []);

  useEffect(() => {
    setSelectedVendorAccount([]);
    if (selectedVendor) {
      let selectedVendorAccounts = allVendors
      .filter(
        (item) => item.vendorName === selectedVendor
      )[0];
      console.log("selectedVendorAccounts", selectedVendorAccounts);
      setSelectedVendorAccount(selectedVendorAccounts);
    }
    }, [allVendors, selectedVendor]);


   
    


  return (
    <div className="analyticsMainPage">
      <div className="analyticsHeader">
        <div className="leftHeaderFilter">
        
                <CustomDropDown
            isActive={isActiveSocials}
            setIsActive={setIsActiveSocials}
            selected={selectedVendor}
            setIsSelected={setSelectedVendor}
            list={allVendors?.map((item) => item.vendorName) }
            type="Customdropdown"
          />
          <div className="dateFilterBlock">
            <div className="fromFilter">
              <Flatpickr
                options={{
                  theme: "light",
                  dateFormat: "Y-m-d",
                  defaultDate: new Date(
                    new Date() - 7 * 24 * 60 * 60 * 1000
                  ).toISOString(),

                  onChange: function (selectedDates, dateStr, instance) {
                    console.log(dateStr);
                    setAnalyticsStartDate({
                      pindate: dateStr.split("T")[0],
                      date: dateStr,
                      epoch: Math.floor(Date.parse(dateStr) / 1000),
                    });

                    console.log(analyticsStartDate, analyticsEndDate);
                    // selectedDates.forEach(dat => { let epoch = Date.parse(dat); console.log(epoch) })
                  },
                  // console.log(dateStr)
                  // console.log(instance)
                }}
              />
              <img src={calendarIcon} alt="calendar icon" />
            </div>
            <div className="toFilter">
              <p>TO</p>
              <div className="fromFilter">
                <Flatpickr
                  options={{
                    dateFormat: "Y-m-d",
                    defaultDate: new Date().toISOString(),

                    onChange: function (selectedDate, dateStr, instance) {
                      if (
                        new Date(dateStr) < new Date(analyticsStartDate.date)
                      ) {
                        alert("Please select a date greater than start date");
                      } else {
                        console.log(dateStr);
                        setAnalyticsEndDate({
                          pindate: dateStr.split("T")[0],
                          date: dateStr,
                          epoch: Math.floor(Date.parse(dateStr) / 1000),
                        });
                      }
                      console.log(analyticsStartDate, analyticsEndDate);
                      // selectedDates.forEach(dat => { let epoch = Date.parse(dat); console.log(epoch) })
                    },
                  }}
                />{" "}
                <img src={calendarIcon} alt="calendar icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="rightHeaderFilter">
          <div className="refreshBtn" onClick={handleClickReload}>
            <img src={refreshIcon} alt="refresh" /> <p>Refresh</p>
          </div>
          <div className="addNetworkHeaderBtn" onClick={handleClickAddNetwork}>
            <p>Add Social Network</p>
          </div>
        </div>
      </div>
     
      <div className="analyticsContentArea"> 


{
    JSON.stringify(ThreeTimesAnalyticsData)
}
     


     <div style={{ display:'flex',  justifyContent:'flex-start'}}>
        <h2>Analytics</h2>
     </div>

     <div style={{display:'flex', width:'90vw', justifyContent:'flex-start'}}>
        <div >
            <h6>
                GA traffic
            </h6>
        

        </div>
        <div >
            <h6>
                Facebook
            </h6>

        </div>
        <div >
            <h6>
                Instagram
            </h6>

        </div>

     </div>

      
       </div>
      {isCreateNewPostMenu && (
        <CreateNewPost
          pagesDetails={props.pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}

      {isCreateNewPinMenu && (
        <CreatePin
          pagesDetails={props.pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {isCreateNewBoardMenu && (
        <CreateBoard
          pagesDetails={props.pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </div>
  );
}

export default NewAnalyticsPageV2;
