import React, { Component, useState } from "react";
import FormInput from "../../../Components/FormInput/FormInput";
import "./modal.css";

const Modal = ({ open, onClose, setDate, preDate, setTime, setSchedule }) => {
  console.log(date, time);

  if (!open) return null;

  // let preDAte = "2023-12-11"

  return (
    <>
      <div className="modal-main">
        <div className="modal-wrapper">
          <div className="modal-header">
            <h5 className="modal-header-title">Schedule Post</h5>
          </div>

          <div className="modal-input-date">
            <input
              type="date"
              name="date"
              defaultValue={preDate}
              onChange={(date) => setDate(date.target.value)}
              setState={setSchedule}
            />
          </div>
          <div className="modal-title">
            <p className="modal-title-text">Manually Set time</p>
            <p className="modal-title-desc">(Gmt +5.30)Asia/Kolkata</p>
          </div>

          <div className="modal-time-container">
            <input
              type="time"
              name="time"
              value={time}
              onChange={(time) => setTime(time.target.value)}
              setState={setSchedule}
            />
          </div>

          <button className="modal-button" onClick={onClose}>
            Done
          </button>
        </div>
      </div>
    </>
  );
};

export default Modal;
export const { date, time } = Component;
