import { OPEN_STREAM } from "../Constants";

const streamInitialState = {
  showSteam: false,
};

export const openStream = (state = streamInitialState, action) => {
  switch (action.type) {
    case OPEN_STREAM:
      return {
        ...state,
        showSteam: !state.showSteam,
      };

    default:
      return state;
  }
};
