import React, { useEffect, useState } from "react";
import ListCard from "../../Components/Listcard/Listcard";
import { createNewListAPI, DeleteEmail } from "../../Utils/api";
import "./CreateNewList.css";
import Delete from "../../assets/icons8-trash-30.png";

const CreateNewList = ({
  userInfo,
  setListArray,
  listArray,
  fetchMyList,
  listRecord,
  setListRecord,
}) => {
  const [list, setList] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [temp, setTemp] = useState([]);

  // console.log(listArray);
  // console.log(emailList);
  console.log(listRecord);

  const listHandle = () => {
    const newList = {
      _id: userInfo._id,
      listName: list,
    };

    createMyNewList(newList);
  };

  useEffect(() => {
    if (userInfo._id) {
      fetchMyList(userInfo._id);
    }
  }, [userInfo]);

  useEffect(() => {
    if (listArray) {
      setEmailList(Object.values(listArray));
      if (Object.keys(listArray).length > 0) {
        setTemp(Object.values(listArray)[0]);
      }
    }
  }, [listArray]);

  // console.log(Object.keys(listArray)[0]);

  useEffect(() => {
    emailList.map((emails) => {
      console.log(emails);

      if (listRecord.length === emails.length) {
        return setTemp(emails);
      }
    });
  }, [listRecord]);

  const createMyNewList = async (values) => {
    const res = await createNewListAPI(values);
    console.log("addedList", res.data);
    setListArray(res.data.emailDataLists);
    setList("");
  };

  // console.log(listRecord.ele);

  const deleteEmailFromList = async (email, index) => {
    const result = await DeleteEmail({
      id: userInfo._id,
      list: listRecord.ele || Object.keys(listArray)[0],
      email: email,
    });

    if (result.data.modifiedCount === 1) {
      setTemp(temp.filter((el, i) => i !== index));
      console.log(listArray);
    }
    console.log("email deleted...", result.data.modifiedCount);
  };

  return (
    <div className="ListOuterLayout">
      <div className="ListHeadLayout regular">
        <button>My Emails List</button>
        <button>Create New List</button>
      </div>
      <div className="ListmainContent">
        <div className="listSection regular">
          <div className="headText">
            <p>Create New List</p>
          </div>
          {/* <div className="secondText">Add New List</div> */}
          <div
            className="inputArea"
            onChange={(e) => {
              setList(e.target.value);
            }}
          >
            <input
              type="text"
              placeholder="Ex: Sep.Marketing List"
              value={list}
            />
            <button onClick={listHandle}>Add New List</button>
          </div>
          {listArray && (
            <div className="addedListSection">
              <ListCard listArray={listArray} setListRecord={setListRecord} />
            </div>
          )}
        </div>
        <div className="jumboTextSection regular">
          <div className="jumboText">
            <div className="listsMain">
              {temp.map((email, id) => {
                return (
                  <div className="listEmailItem">
                    {email}{" "}
                    <img
                      src={Delete}
                      alt="trash"
                      onClick={() => {
                        deleteEmailFromList(email, id);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewList;
