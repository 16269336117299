import React, { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import {
  saveACcountsLinked,
  savePageDetails,
  getPageDetailsApi,
} from "../../Utils/api";
import "./link.css";
import "./LinkAccount.css";
import axios from "axios";
import TwitterLogin from "react-twitter-login";
import { async } from "emoji-mart";
import { useState } from "react";
import Cancel from "../../assets/icons8-cancel-64.png";
import Super from "../../assets/superman.png";
import trash from "../../assets/icons8-trash-30.png";
import { toast, ToastContainer } from "react-toastify";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
// You can use provided image shipped by this package or using your own
// import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
console.log("hello");

const baseURL = `${process.env.REACT_APP_SERVER_URI}/api/`;
const toastSetting = {
  position: "top-right",
  // autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
function LinkAccount({
  setPagesDetails,
  allFbPagesDetails,
  setAllFbPagesDetails,
  userInfo,
  fetchPagesInfo,
}) {
  const location = useLocation();
  const [externalPopup, setExternalPopup] = useState("");
  const [twitterData, setTwitterData] = useState({
    oauth_token: "",
    oauth_token_secret: "",
    oauth_verifier: "",
  });

  const [selected, setSelected] = useState("");
  const [consentScreen, setConsentScreen] = useState(false);

  const [linkedinPages, setLinkedinPages] = useState([]);
  const [GoogleAnalyticsPages, setGoogleAnalyticsPages] = useState([]);
  const [openPinterest, setOpenPinterest] = useState(false);
  const [PinterestPages, setPinterestPages] = useState([]);
  const [GoogleAnalyticsConnectModal, setGoogleAnalyticsConnectModal] = useState(false);
  const {
    data: socialData,
    isLoading: socialIsLoading,
    isError: socialIsError,
    error: socialError,
    refetch,
  } = useQuery("fetchSocials", getSocials);
  const { mutate, isLoading, isError, error } = useMutation(deletePage, {
    onSuccess: (successData) => {
      refetch();
      toast.error("Account Disconnected");
    },
    onError: (error) => {
      toast.error(error.message, toastSetting);
    },
  });

  // ----------------------------------------Pinterest validation starts------------------------------------//
  const pinterestValidation = (code, state) => {
    const redirectURI = `${process.env.REACT_APP_REDIRECT_URI}/dashboard`;
    if (state === "pinterest" && code !== null) {
      axios
        .post(`${baseURL}crud/token`, {
          code,
          redirectURI,
        },{
         
            headers: {
              "Content-Type": "application/json",
               "token": JSON.parse(localStorage.getItem("userProfileInfo")).token,
               "userId": JSON.parse(localStorage.getItem("userProfileInfo"))._id,
            },
        })
        .then((response) => {
          axios
            .post(`${baseURL}crud/user`, {
              token: response.data.access_token,
              refresh_token: response.data.refresh_token,
              jwToken: sessionStorage.getItem("token"),
            })
            .then(() => {
              axios.post(`${baseURL}crud/boards`, {
                token: response.data.access_token,
                refresh_token: response.data.refresh_token,
                jwToken: sessionStorage.getItem("token"),
              },{
                
            headers: {
              "Content-Type": "application/json",
               "token": JSON.parse(localStorage.getItem("userProfileInfo")).token,
               "userId": JSON.parse(localStorage.getItem("userProfileInfo"))._id,
            },
                
              }).then((res) => {
                console.log(res.data);
                setPinterestPages(res.data);
                setOpenPinterest(true);
              });
            });
        });
    }
  };
  const saveAnalyticsPageDetails = async (page) => {

    await axios.post(`${baseURL}crud/saveAnalyticsPages`, {
      jwToken: localStorage.getItem("token"),
      page: page,
      user: userInfo,
      // id: tempId,
    },{
      headers: {
        token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
        userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
      }
    }).then((res) => {
      console.log(res);
        setGoogleAnalyticsPages(prevPages => prevPages.filter(p => p !== page));

      toast.success(`The ${res.data.name} get connected successfully`, toastSetting);
      // setGoogleAnalyticsConnectModal(false);
      getSocials();
    }).catch((err) => {
      console.log(err);
    })
  }

  const linkedinValidation = (params) => {
    if (params !== null) {
      axios
        .post(`${baseURL}crud/linkedin`, {
          jwToken: sessionStorage.getItem("token"),
          code: params,
        },{
          headers: {
            token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
            userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
          }
        })
        .then((res) => {
          console.log(res.data);
          let linkpages = [];
          res.data[2].elements.forEach((element) => {
            linkpages.push({
              name: element["organizationalTarget~"]?.localizedName,
              id: element?.organizationalTarget,
              token: res.data[1]?.access_token,
              refresh_token: res.data[1]?.refresh_token,
              token_expiry: res.data[1]?.expires_in,
            });
          });
          setLinkedinPages(linkpages);
          console.log(linkedinPages);
          console.log(linkpages);
        });
      setOpenLinkedin(true);
    }
  };
  //------------------------------------------Pinterest Validation Ends------------------------------------//

  const getCode = (params) => {
    if (params) {
  

      axios
        .post(`${baseURL}crud/googleAccessToken`, {
          code: params,
          token: sessionStorage.getItem("token"),
        },{
          headers: {
            token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
            userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
          }
        })
        .catch((err) => console.log(err))
        .then((res) => {
          console.log(res);
          // setGoogleAnalyticsPages(res.data.properties);
          // setGoogleAnalyticsConnectModal(true);
          window.location.reload();

        }
        );
    }
  };
  const AnalyticsGetCode = (params) => {
    if (params) {
  

      axios
        .post(`${baseURL}crud/googleAnalyticsAccessToken`, {
          code: params,
          token: sessionStorage.getItem("token"),
        }, {
          headers: {
            token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
            userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
          }  })
        .catch((err) => console.log(err))
        .then((res) => {
          console.log(res);
          setGoogleAnalyticsPages(res?.data?.data);
          setGoogleAnalyticsConnectModal(true);
        //   {
        //     "properties": [
        //         {
        //             "name": "properties/262139460",
        //             "parent": "accounts/169213943",
        //             "createTime": "2021-02-12T14:02:23.569Z",
        //             "updateTime": "2021-02-12T14:02:23.569Z",
        //             "displayName": "aadil-1fa63",
        //             "timeZone": "Asia/Calcutta",
        //             "currencyCode": "USD",
        //             "serviceLevel": "GOOGLE_ANALYTICS_STANDARD",
        //             "account": "accounts/169213943",
        //             "propertyType": "PROPERTY_TYPE_ORDINARY"
        //         }
        //     ]
        // }
          // window.location.reload();
        }
        );
    }
  };


  let navigate = useNavigate();

  async function saveAccountsVerified(params) {
    await saveACcountsLinked(params);
  }
                             
  async function savePagesInfo(params) {
    console.log("Calling savePagesInfo");
    const res = await savePageDetails(params);
    // console.log(res.data);
    setAllFbPagesDetails(res.data);
    // setPagesDetails(res.data);
  }

  const getNewData = async () => {
    const getNewPagesData = await getPageDetailsApi({
      jwToken: localStorage.getItem("token"),
    });
    console.log(getNewPagesData.data);
    setPagesDetails(getNewPagesData.data);
  };

  const handleClickContinue = () => {
    navigate("/dashboard");
    getNewData();
  };

  //--=-===================================LinkedIn Login---------------------------------------//

  const handleLinkedin = () => {
    setSelected("linkedin");
    const linkedinORGloginUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&scope=r_liteprofile,w_member_social,rw_organization_admin,r_emailaddress,r_organization_admin,w_organization_social,r_organization_social,&state=123456&redirect_uri=${process.env.REACT_APP_REDIRECT_URI || 'https://app.toowe.io'}/link-account`;
    // const re_url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&scope=r_liteprofile,r_emailaddress,w_member_social,r_organization_admin&state=123456&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}/link-account`;
    window.location.href = linkedinORGloginUrl;
  };

  const handlePinterestLogin = () => {
    // window.location.href = `https://www.pinterest.com/oauth/?client_id=${process.env.REACT_APP_PINTEREST_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}/link-account&response_type=code&scope=boards:read,pins:read,user_accounts:read,boards:write,pins:write&state=pinterest`;
    setSelected("pinterest");
    const re_url = `https://in.pinterest.com/oauth/?client_id=${process.env.REACT_APP_PINTEREST_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}/link-account&response_type=code&scope=boards:read,pins:read,user_accounts:read,boards:write,pins:write&state=pinterest`;
    window.location.href = re_url;
  };
  //------------------------------------------Pinterest Ends---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

  //------------------------------------------Youtube Starts---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//
  // const handleYoutubeLogin = async () => {
  //   const url = await axios.get(`${baseURL}crud/googleAuth`)
  //   if (url.data) {
  //     const re_url = url.data;
  //     window.location.href = re_url
  //   }
  // };

  const handleYoutubeInitiator = () => {


    setConsentScreen(true);

  }


  const handleYoutubeLogin = async (event) => {
    event.preventDefault();

    const url = await axios.get(`${baseURL}crud/googleAuth`,{
      headers: {
        token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
        userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
      }
    });

  
    setSelected("google");
    if (url.data) {
      const width = 500;
      const height = 400;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2.5;
      const title = `WINDOW TITLE`;
      const re_url = url.data;
      // window.location.href=url.data
      const popup = window.open(
        re_url,
        title,
        `width=${width},height=${height},left=${left},top=${top}`
      );
      setExternalPopup(popup);
    }
  };

  const handleGoogleAnalyticsLogin = async () => {
    const url = await axios.get(`${baseURL}crud/googleAnalyticsAuth`, {
      headers: {
        token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
        userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
      },
    });
    setSelected("googleAnalytics");
    if (url.data) {
      const width = 500;
      const height = 400;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2.5;
      const title = `WINDOW TITLE`;
      const re_url = url.data;
      // window.location.href=url.data
      const popup = window.open(
        re_url,
        title,
        `width=${width},height=${height},left=${left},top=${top}`
      );
      setExternalPopup(popup);
    }
  };

  useEffect(() => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: process.env.REACT_APP_FB_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v15.0",
      });
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  //------------------------------------------Youtube End---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

  //------------------------------------------Facebook Starts---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//
  const onFacebookLoginClick = () => {
    window.FB.login(
      function (response) {
        console.log("auth", response);
        let token = response.authResponse.accessToken;
        let id = response.authResponse.userID;
        if (response.authResponse) {
          console.log("Welcome!  Fetching your information.... ");
          window.FB.api("/me", function (response) {
            const AccountsData = {
              accounts: ["facebook"],
              user: userInfo._id,
            };
            const PagesCredentials = {
              token,
              id,
              user: userInfo._id,
              jwToken: sessionStorage.getItem("token"),
            };
            console.log(response);
            saveAccountsVerified(AccountsData);
            savePagesInfo(PagesCredentials);
            idFun(id);
            setOpen(true);
          });
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        scope:
          "email,public_profile,pages_show_list,pages_read_engagement,pages_read_user_content,pages_manage_posts,pages_manage_engagement,read_insights",
      }
    );
  };

  //------------------------------------------Facebook Ends---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//
  //-----------------------------------------INSTAGRAM STARTS---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//
  


  

  
  const onInstaLoginClick = async () => {
    window.FB.login(
      function (response) {
        console.log("auth", response);
        let token = response.authResponse.accessToken;
        let id = response.authResponse.userID;
        if (response.authResponse) {
          console.log("Welcome!  Fetching your information.... ");
          window.FB.api("/me", function (response) {
            const AccountsData = {
              accounts: ["facebook"],
              user: userInfo._id,
            };
            const PagesCredentials = {
              token,
              id,
              user: userInfo._id,
            };
            console.log(response);
            console.log(PagesCredentials);
            const pag = axios
              .post(`${baseURL}crud/instaAccounts`, {
                id: id,
                token: token,
              },{
                headers: {
                  "Content-Type": "application/json",
                   "token": JSON.parse(localStorage.getItem("userProfileInfo")).token,
                },
              })
              .then((pag) => {
                // console.log(pag.data.data);
                // const pagData = pag.data.data;
                // pagData.forEach((element) => {
                //   const isInstagram = axios
                //     .post(
                //       `${process.env.REACT_APP_SERVER_URI}/api/crud/isinsta`,
                //       {
                //         id: element.id,
                //         token: element.access_token,
                //         jwToken: sessionStorage.getItem("token"),
                //       }
                //     )
                //     .then((isInstagram) => {
                //       if (isInstagram.data.instagram_business_account) {
                //         axios.get(
                //           `https://graph.facebook.com/v14.0/${isInstagram.data.instagram_business_account.id}?fields=name,profile_picture_url,username,id`
                //         );
                //       }
                //       console.log(isInstagram.data);
                //     })
                //     .catch((err) => {
                //       console.log(err);
                //     });
                // });

                setInstagramPages(pag.data);
                setOpenInstagram(true);

              })
              .catch((err) => {
                console.log(err);
              });

            // saveAccountsVerified(AccountsData);
            // savePagesInfo(PagesCredentials);
          });
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        scope:
          "publish_to_groups,pages_manage_posts,instagram_basic,pages_show_list,instagram_content_publish,business_management,instagram_manage_insights",
      }
    );
  };
   
  //-----------------------------------------INSTAGRAM ENDS---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

  //------------------------------------------Twitter Starts---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

  async function twitterLogin() {
    setSelected("twitter");
    const res = await axios.post(`${baseURL}crud/getTwitter`,{
    
    },{
      headers: {
        token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
        userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
      }
    });
    twitterData.oauth_token = res.data.oauth_token;
    twitterData.oauth_token_secret = res.data.oauth_token_secret;
    setTwitterData({ ...twitterData });
    if (res.data.url) {
      const re_url = res.data.url;
      window.location.href = re_url;
    }
  }

  const getTwitterAccess = async () => {
    const accessToken = await axios.post(`${baseURL}crud/accessToken`, {
      oauth_token: twitterData.oauth_token,
      oauth_verifier: twitterData.oauth_verifier,
    }, {
      headers: {
        token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
        userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
      }
    }
      
      
      );

    twitterData.oauth_token = accessToken.data.split("&")[0].split("=")[1];
    twitterData.oauth_token_secret = accessToken.data
      .split("&")[1]
      .split("=")[1];
    setTwitterData({ ...twitterData });

    const saveData = await axios.post(`${baseURL}crud/twitterMe`, {
      oauth_token: twitterData.oauth_token,
      oauth_token_secret: twitterData.oauth_token_secret,
      jwToken: localStorage.getItem("token"),
    },{
      headers: {
        token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
        userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
      }
    });
  };

  useEffect(() => {
    console.log("Current Location");
    console.log(location);
    if (location.search !== "") {
      if (location?.search?.split("&")[1]?.split("=")[0] === "oauth_verifier") {
        console.log(location?.search?.split("&")[0]?.split("=")[1]);
        twitterData.oauth_token = location?.search
          ?.split("&")[0]
          ?.split("=")[1];
        twitterData.oauth_verifier = location?.search
          ?.split("&")[1]
          ?.split("=")[1];
        setTwitterData({ ...twitterData });

        getTwitterAccess();
      }

      // Dublicate code check

      // else if (location.search.split("&")[1].split("=")[1] === "pinterest") {
      //   const code = location.search.split("&")[0].split("=")[1];
      //   const state = location.search.split("&")[1].split("=")[1];
      //
      //   getTwitterAccess();
      // }
      else if (location.search.split("&")[1].split("=")[1] === "pinterest") {
        const code = location.search.split("&")[0].split("=")[1];
        const state = location.search.split("&")[1].split("=")[1];

        console.log("Code ", code);
        console.log("State ", state);

        pinterestValidation(code, state);
      } else if (location.search.split("&")[1].split("=")[1] === "123456") {
        const code = location.search.split("&")[0].split("=")[1];

        linkedinValidation(code);
      }
    }
  }, [location]);
  const [hasCalledAnalyticsGetCode, setHasCalledAnalyticsGetCode] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }
    

      switch (selected) {
        case "google":
          const currentUrl = externalPopup.location.href;
          const searchParamsGoogle = new URL(currentUrl).searchParams || "";
          let codeGoogle = searchParamsGoogle.get("code");
          getCode(codeGoogle);
          setSelected(null);
          externalPopup.close();
          navigate("/link-account");
          break;
          case "googleAnalytics":
            const currentUrlGA = externalPopup.location.href;
            const searchParamsGoogleAnalytics = new URL(currentUrlGA).searchParams || "";
            let codeGoogleAnalytics = searchParamsGoogleAnalytics.get("code");
            if(codeGoogleAnalytics && !hasCalledAnalyticsGetCode){
              AnalyticsGetCode(codeGoogleAnalytics);
              setHasCalledAnalyticsGetCode(true);
              setSelected(null);
            }
    
            externalPopup.close();
            break;
        default:
          externalPopup.close();
      }
    }, 5000);
    
    return () => {
      clearInterval(timer);
    };
  }, [externalPopup, navigate, selected]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      } else{
        console.log("External Popup", externalPopup.location);
      }
    }, 5000);
  }, [externalPopup]);

  //------------------------------------------Twitter Ends---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//
  // const [connectedSocials, setConnectedSocials] = useState([]);
  const [open, setOpen] = useState(false);
  const [btnclick, setBtnclick] = useState(false);
  const [openLinkedin, setOpenLinkedin] = useState(false);
  let connectedSocials = [];
  function getSocials() {
    return axios.post(
      `${process.env.REACT_APP_SERVER_URI}/api/crud/getPagesDetail`,
      { jwToken: localStorage.getItem("token") },{
        headers: {
          token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
          userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
        }
      }
    );

    // console.log(res.data);

    // const result = res.data.filter(tempFun);
    // const result = res.data;
    // function tempFun(account) {
    //   return account.account_type != "pinterest";
    // }
    // console.log("Filtered Accounts :", result);
    // setConnectedSocials(result);
  }

  if (!socialIsLoading && !socialIsError) {
    connectedSocials = socialData.data;
  }
  useEffect(() => {
    refetch();
  }, [open]);

  // socials object
  let socials = {
    facebook: {
      name: "Facebook",
      icon: require("../../assets/icons8-facebook-64.png"),
    },
    instagram: {
      name: "Instagram",
      icon: require("../../assets/icons8-instagram-48.png"),
    },
    twitter: {
      name: "Twitter",
      icon: require("../../assets/icons8-twitter-48.png"),
    },
    youtube: {
      name: "Youtube",
      icon: require("../../assets/icons8-youtube-48.png"),
    },
    linkedin: {
      name: "Linkedin",
      icon: require("../../assets/icons8-linkedin-circled-48.png"),
    },
    pinterest: {
      name: "Pinterest",
      icon: require("../../assets/pinterestLogo.png")
    },
    BUSINESS: {
      name: "Business",
      icon: require("../../assets/pinterestLogo.png"),
    },
    GoogleAnalytics :{
      name: "GoogleAnalytics",
      icon: require("../../assets/ga4.png"),
    }
  };

  const [tempId, setTempId] = useState("");

  // console.log(btnclick);

  const HandleClose = () => {
    setOpen(false);
    setCheck("");
  };

  async function idFun(id) {
    return setTempId(id);
  }

  const [checkLinkedIn, setCheckLinkedIn] = useState();

  const pageAddLinkedIn = async (page) => {
    setBtnclick(true);
    // console.log(page);
    // console.log(userInfo._id);
    // console.log(tempId);

    await axios
      // .post(`${process.env.REACT_APP_SERVER_URI}/api/crud/saveLinkedInPages`, {
      //   jwToken: localStorage.getItem("token"),
      //   page: page,
      //   user: userInfo._id,
      //   // id: tempId,
      // })
      .post(`${process.env.REACT_APP_SERVER_URI}/api/crud/savePagesAny`, {
        jwToken: localStorage.getItem("token"),
        page: page,
        refresh_token: page.refresh_token,
        user: userInfo._id,
        account_type: 'linkedin',
      })
      .then((res) => {
        // setOpen(false);
        getSocials();
        setCheck("");

        console.log(`The ${res.data.name} get connected successfully`);
// remove the saved page from the list of pages
        const newPages = linkedinPages.filter((item) => item.id !== page.id);
        setLinkedinPages(newPages);

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const pageAdd = async (page) => {
    setBtnclick(true);
    // console.log(page);
    // console.log(userInfo._id);
    // console.log(tempId);

    await axios
      .post(`${process.env.REACT_APP_SERVER_URI}/api/crud/savePages`, {
        jwToken: localStorage.getItem("token"),
        page: page,
        user: userInfo._id,
        id: tempId,
      })
      .then((res) => {
        // setOpen(false);
        getSocials();
        // setCheck("");

        console.log(`The ${res.data.name} get connected successfully`);

        // remove the saved page from the list of pages
        const newPages = allFbPagesDetails.filter((item) => item.id !== page.id);
        setAllFbPagesDetails(newPages);

      })
      .catch((err) => {
        console.log(err);
      });
  };


  const ConnectPage = async ({page,account_type}) => {
    // setBtnclick(true);
    // console.log(page);
    // console.log(userInfo._id);
    // console.log(tempId);

    await axios
      .post(`${process.env.REACT_APP_SERVER_URI}/api/crud/savePagesAny`, {
        jwToken: localStorage.getItem("token"),
        page: page,
        user: userInfo._id,
        account_type: account_type,
      })
      .then((res) => {
        // setOpen(false);
        toast.success(`The ${res.data.name} get connected successfully`, toastSetting);
        getSocials();

        // setCheck("");

        console.log(`The ${res.data.name} get connected successfully`);

        // remove the saved page from the list of pages
        const newPages = allFbPagesDetails?.filter((item) => item.id !== page.id);
        setAllFbPagesDetails(newPages);

        const newPagesInsta = instagramPages?.filter((item) => item.id !== page.id);
        setInstagramPages(newPagesInsta);

        const newPagesPin = PinterestPages?.filter((item) => item.id !== page.id);
        setPinterestPages(newPagesPin);

      })
      .catch((err) => {
        console.log(err);
      });
  };




  const pageRemove = async (page) => {
    setBtnclick(false);
    await axios
      .post(`${process.env.REACT_APP_SERVER_URI}/api/crud/removePages`, {
        jwToken: localStorage.getItem("token"),
        userid: userInfo._id,
        page: page,
      })
      .then((res) => {
        getSocials();
        setCheck("");
        console.log(res.data);
        // setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [check, setCheck] = useState([]);

  useEffect(() => {
    connectedSocials.map((connect_account) => {
      allFbPagesDetails.map((map_account) => {
        if (connect_account.pageId === map_account.id) {
          console.log(map_account);

          setCheck((prev) => [...prev, map_account]);
        }
      });
    });
  }, [connectedSocials, allFbPagesDetails]);

  const Facebook_Page_Pop = () => {
    const [filteredList, setFilteredList] = new useState(allFbPagesDetails);

    console.log(filteredList);

    const filterBySearch = (event) => {
      const query = event.target.value;

      var updatedList = [...allFbPagesDetails];

      updatedList = updatedList.filter((item) => {
        return item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });

      setFilteredList(updatedList);
    };


    const saveAnalyticsPageDetails = async (page) => {

      await axios.post(`${baseURL}crud/saveAnalyticsPages`, {
        jwToken: localStorage.getItem("token"),
        page: page,
        user: userInfo,
        // id: tempId,
      },{
        headers: {
          token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
          userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
        }
      }).then((res) => {
        console.log(res);
        setGoogleAnalyticsConnectModal(false);
        getSocials();
      }).catch((err) => {
        console.log(err);
      })
    }


    return (
      <div className="PopBody regular" style={{ backgroundColor: "black" }}>
        <div
          className="popOuterLayout"
          style={{ width: "45%", height: "500px" }}
        >
          <div
            className="popHead"
            style={{
              fontSize: "22px",
              height: "30px",
              padding: "1px 0px 5px 10px",
              borderLeft: "4px solid #ff8e00",
            }}
          >
            Facebook pages
            <img src={Cancel} alt="close" onClick={HandleClose} />
          </div>
          <div className="search_section">
            <label>Search by page name or keyword</label>
            <input type="text" onChange={filterBySearch} />
          </div>
          <div className="page_section">
            <label>Facebook Pages</label>
            <div className="pages_list">
              {filteredList.length > 0 ? (
                filteredList.map((page, id) => {
                  const isPageConnected = connectedSocials.some(account => account.pageId === page.id);
                  return (
                    <div className="page_card">
                      <img src={page.picture.data.url} alt="profile" />
                      <p>{page.name}</p>
                      <div className="btn_sec">
                        <button
                          // className={}
                          className={isPageConnected ? "button_update" : "button_add"}
                          onClick={() => {
                            pageAdd(page);
                          }}
                          style={{width:'80px'}}
                        >
                          {isPageConnected ? "update" : "Add"}
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="page_card">No pages found</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };


  const [instagramPages, setInstagramPages] = useState([]);
  const [openInstagram, setOpenInstagram] = useState(false);


  const HandleCloseInsta = () => {
    setOpenInstagram(false);
    setInstagramPages([]);
  };

  const HandleClosePin = () => {
    setOpenPinterest(false);
    setPinterestPages([]);
  };


  const Instagram_Page_Pop = () => {
    const [filteredList, setFilteredList] = new useState(instagramPages);

    console.log(filteredList);

    const filterBySearch = (event) => {
      const query = event.target.value;

      var updatedList = [...instagramPages];

      updatedList = updatedList.filter((item) => {
        return item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });

      setFilteredList(updatedList);

      console.log(updatedList);
    };

    return (
      <div className="PopBody regular" style={{ backgroundColor: "black" }}>
        <div
          className="popOuterLayout"
          style={{ width: "45%", height: "500px" }}
        >
          <div

            className="popHead"
            style={{
              fontSize: "22px",
              height: "30px",
              padding: "1px 0px 5px 10px",
              borderLeft: "4px solid #ff8e00",
            }}
          >
            Instagram pages
            <img src={Cancel} alt="close" onClick={HandleCloseInsta} />
          </div>
          <div className="search_section">
            <label>Search by page name or keyword</label>
            <input type="text" onChange={filterBySearch} />
          </div>
          <div className="page_section">
            <label>Instagram Pages</label>
            <div className="pages_list">
              {filteredList?.length > 0 ? (
                filteredList?.map((page, id) => {
                  return (
                    <div className="page_card">
                      <img src={page?.picture} alt="profile" />
                      <p>{page?.name}</p>
                      <div className="btn_sec">
                        <button
                          className={
                            check.includes(page)
                              ? "button_remove"
                              : "button_add"
                          }
                          onClick={() => {
                            ConnectPage({page,account_type:'instagram'});
                          }}
                        >
                          {check.length > 0
                            ? "ADD"
                            : "ADD"}
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="page_card">No pages found</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Pintrest_page_pop = () => {
    const [filteredList, setFilteredList] = new useState(PinterestPages);

    console.log(filteredList);

    const filterBySearch = (event) => {
      const query = event.target.value;

      var updatedList = [...PinterestPages];

      updatedList = updatedList.filter((item) => {
        return item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });

      setFilteredList(updatedList);

      console.log(updatedList);
    };

    return (
      <div className="PopBody regular" style={{ backgroundColor: "black" }}>
        <div
          className="popOuterLayout"
          style={{ width: "45%", height: "500px" }}
        >
          <div

            className="popHead"
            style={{
              fontSize: "22px",
              height: "30px",
              padding: "1px 0px 5px 10px",
              borderLeft: "4px solid #ff8e00",
            }}
          >
            Pinterest pages
            <img src={Cancel} alt="close" onClick={HandleClosePin} />
          </div>
          <div className="search_section">
            <label>Search by page name or keyword</label>
            <input type="text" onChange={filterBySearch} />
          </div>
          <div className="page_section">
            <label>Pinterest Pages</label>
            <div className="pages_list">
              {filteredList?.length > 0 ? (
                filteredList?.map((page, id) => {
                  return (
                    <div className="page_card">
                      {/* <img src={page?.picture} alt="profile" /> */}
                      <p>{page?.name}</p>
                      <div className="btn_sec">
                        <button
                          className={
                            check.includes(page)
                              ? "button_remove"
                              : "button_add"
                          }
                          onClick={() => {
                            ConnectPage({page,account_type:'pinterest'});
                          }}
                        >
                          {check.length > 0
                            ? "ADD"
                            : "ADD"}
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="page_card">No pages found</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };













  const HandleCloseLinkedIn = () => {
    setOpenLinkedin(false);
    window.location = "/link-account";
  };

  const Linkedin_Page_Pop = () => {
    const [filteredList, setFilteredList] = new useState(linkedinPages);
    return (
      <div className="PopBody regular" style={{ backgroundColor: "black" }}>
        <div
          className="popOuterLayout"
          style={{ width: "45%", height: "500px" }}
        >
          <div
            className="popHead"
            style={{
              fontSize: "22px",
              height: "30px",
              padding: "1px 0px 5px 10px",
              borderLeft: "4px solid #ff8e00",
            }}
          >
            Linkedin pages
            <img src={Cancel} alt="close" onClick={HandleCloseLinkedIn} />
          </div>
          {/* <div className="search_section">
            <label>Search by page name or keyword</label>
            <input type="text" onChange={filterBySearch} />
          </div> */}
          <div className="page_section">
            <label>Linked pages</label>
            <div className="pages_list">
              {linkedinPages.map((page, id) => {
                return (
                  <div className="page_card">
                    <img src={page?.picture?.data?.url} alt="profile" />
                    <p>{page?.name}</p>
                    <div className="btn_sec">
                      <button
                        className={
                          check.includes(page) ? "button_remove" : "button_add"
                        }
                        onClick={() => {
                          
                            pageAddLinkedIn(page);
                          
                          // HandleCloseLinkedIn();
                        }}
                      >
                        {check.length > 0
                          ? check.includes(page)
                            ? "REMOVE"
                            : "ADD"
                          : "ADD"}
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div style={{ margin: "auto" }} className="mainLayout">

{
  GoogleAnalyticsConnectModal && (
    <Modal isOpen={GoogleAnalyticsConnectModal} style={{ display:'flex', backgroundColor:'white'}}>
    <ModalHeader>
      Connect Google Analytics
    </ModalHeader>
    <ModalBody>
      <ModalBody>

        {
          GoogleAnalyticsPages?.map((page, index) => {
            return (
              <div className="row_youtube_selection">
                <p style={{margin:'0'}}>{page.name}</p>
                <Button onClick={()=>{saveAnalyticsPageDetails(page)}} type="submit" className="btn_cntc"   >
                Connect
              </Button>
              </div>
            )
          })
        }

        
   



         <div style={{display:'flex', justifyContent:'space-between'}}>
         <Button type="submit" className="cancel_btn__" style={{width:'20%',background:'red', display:'flex', borderRadius:'0'}} onClick={()=>{setGoogleAnalyticsConnectModal(false)}} >
                Cancel
              </Button>
         <Button onClick={() => {setGoogleAnalyticsConnectModal(false)}} type="submit" className="continue_btn__" style={{width:'70%', background:'green', display:'flex', borderRadius:'0'}} >
                Done
              </Button>
  
            
         </div>
          
  
  
  
      </ModalBody>
  
    </ModalBody>
     </Modal>
  )
}




{
  consentScreen && (
   <Modal isOpen={consentScreen} style={{ display:'flex', backgroundColor:'white'}}>
  <ModalHeader>
    YouTube Data Usage
  </ModalHeader>
  <ModalBody>
    <ModalBody>
      <p>
        By proceeding, you are consenting to allow this application to have the following access to your YouTube account:
      </p>
      <ul>
        <li>
          <strong>https://www.googleapis.com/auth/youtube:</strong> This allows the application to view your YouTube account data.
        </li>
        <li>
          <strong>https://www.googleapis.com/auth/youtube.force-ssl:</strong> This allows the application to view and manage your YouTube videos, playlists, and live broadcasts, in a secure SSL encrypted environment.
        </li>
        <li>
          <strong>https://www.googleapis.com/auth/youtube.upload:</strong> This allows the application to upload videos to your YouTube channel.
        </li>
      </ul>
      <p>
        You can revoke these permissions at any time from your Google account settings.
      </p>
      <div >
        <form onSubmit={handleYoutubeLogin}>
        <p style={{display:'flex', alignItems:'center'}} ><input type="checkbox" required id="consentCheckbox" size={12} style={{width:'10%', height:'20px'}} /> I consent to these permissions 
</p>
       <div style={{display:'flex', justifyContent:'space-between'}}>
      
       <Button type="submit" className="cancel_btn__" onClick={()=>{setConsentScreen(false)}} >
              Cancel
            </Button>
             <Button type="submit" className="continue_btn__" >
              Continue
            </Button>

         
       </div>
        



</form>
  </div>
    </ModalBody>

  </ModalBody>
   </Modal>
  )
}


      <ToastContainer />
      {openLinkedin && <Linkedin_Page_Pop />}
      {open && <Facebook_Page_Pop />}
      {openInstagram && <Instagram_Page_Pop />}
      {openPinterest && <Pintrest_page_pop />}
      <div className="layout1">
        <div className="leftLayout">
          <img src={require("../../assets/logo1.png")} alt="logo" />
          <p className="regular text">
            Get one stop solution for all
            <br />
            your <span>Digital Marketing</span> needs
          </p>
        </div>
        <div className="leftJumboImg">
          <img
            src={require("../../assets/add_social_page_left_img.png")}
            alt="login"
          />
        </div>
      </div>
      <div className="rightLayout">
        <h2 className="bold">Connect your social accounts to start</h2>
        <p className="regular secondaryText">
          Login to your social accounts and map to toowe
        </p>

        <div className="subsection">
          <div className="leftsubsection">
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className=" Imagepointer" onClick={onFacebookLoginClick}>
                <img
                  src={require("../../assets/fb_social_ic.png")}
                  alt="facebook"
                  width="160px"
                />
              </div>
              <div className=" Imagepointer" onClick={twitterLogin}>
                <img
                  src={require("../../assets/tw_social_ic.png")}
                  alt="twitter"
                  width="160px"
                />
              </div>
              <div className=" Imagepointer" onClick={onInstaLoginClick}>
                <img
                  src={require("../../assets/insta_social_ic.png")}
                  alt="instagram"
                  width="160px"
                />
              </div>

              <div className=" Imagepointer" onClick={handlePinterestLogin}>
                <img
                  src={require("../../assets/pinterest_social_ic.png")}
                  alt="pinterest"
                  width="160px"
                />
              </div>
              <div className=" Imagepointer" onClick={handleLinkedin}>
                <mg
                  src={require("../../assets/ln_social_ic.png")}
                  alt="linkedin"
                  width="160px"
                />
              </div>
              <div className=" Imagepointer" onClick={handleYoutubeInitiator}>
                <img
                  src={require("../../assets/youtube_social_ic.png")}
                  alt="youtube"
                  width="160px"
                />
              </div>
              <div className=" Imagepointer" onClick={handleLinkedin}>
                <img
                  src={require("../../assets/ln_social_ic.png")}
                  alt="linkedin"
                  width="160px"
                />
              </div>
              <div className=" Imagepointer" onClick={handleGoogleAnalyticsLogin}>
                <img
                  src={require("../../assets/GA_Icon.png")}
                  alt="GoogleAnalytics"
                  width="160px"
                />
              </div>
              
            </div>

            <button className="ctnBtn" onClick={handleClickContinue}>
              <p className="regular">CONTINUE</p>
              <img src={require("../../assets/icons8-more-than-50.png")} />
            </button>
          </div>

          {connectedSocials.length > 0 ? (
            // <div className="rightsubsection pb_pt">
            <div className="rightsubsection">
              {connectedSocials.map((account) => {
                return (
                  <div
                    key={account._id}
                    className={`accounts ${
                      account.account_type === "facebook"
                        ? "facebook"
                        : account.account_type === "twitter"
                        ? "twitter"
                        : account.account_type === "instagram"
                        ? "instagram"
                        : account.account_type === "youtube"
                        ? "youtube"
                        : account.account_type === "pinterest"
                        ? "BUSINESS"
                        : account.account_type === "linkedin"
                        ? "linkedin"
                        : "facebook"
                    }`}
                  >
                    <div>
                      <img
                      height={'40px'}
                      width={'40px'}
                      style={{
                        height: '40px',
                        width: '40px',
                      }}

                        src={
                          account.account_type === "facebook"
                            ? socials.facebook.icon
                            : account.account_type === "twitter"
                            ? socials.twitter.icon
                            : account.account_type === "instagram"
                            ? socials.instagram.icon
                            : account.account_type === "youtube"
                            ? socials.youtube.icon
                            : account.account_type === "pinterest"
                            ? socials.pinterest.icon
                            : account.account_type === "linkedin"
                            ? socials.linkedin?.icon
                            :  account.account_type === "googleAnalytics"
                            ? socials.GoogleAnalytics?.icon : ""
                        }
                        alt="logo"
                      />
                      <span className="regular secondaryText"  >
                        {account.name} 
                      {/* <img
                        src={require("../../assets/social_check.png")}
                        alt="logo"
                      />  */}
                      </span>
                    </div>
                    <label htmlFor={account._id} style={{ cursor: "pointer" }}>
                      <img src={trash} alt="delete" style={{ width: "16px" }} />
                    </label>
                    <button
                      id={account._id}
                      onClick={(e) =>
                        mutate({
                          userid: userInfo._id,
                          jwToken: localStorage.getItem("token"),
                          page: {
                            id: account.pageId,
                            name: account.name,
                            account_type: account.account_type,
                          },
                        })
                      }
                      hidden
                    />
                  </div>
                );
              })}
              {/* <div className="accounts">
              <img
                src={require("../../assets/icons8-facebook-64.png")}
                alt="fb"
              />
              <p className="regular secondaryText">Added facebook</p>
              <img src={require("../../assets/social_check.png")} alt="fb" />
            </div>
            <div className="accounts">
              <img
                src={require("../../assets/icons8-twitter-48.png")}
                alt="fb"
              />
              <p className="regular secondaryText">Added twitter</p>
              <img src={require("../../assets/social_check.png")} alt="fb" />
            </div>
            <div className="accounts">
              <img
                src={require("../../assets/icons8-instagram-48.png")}
                alt="fb"
              />
              <p className="regular secondaryText">Added instagram</p>
              <img src={require("../../assets/social_check.png")} alt="fb" />
            </div>
            <div className="accounts">
              <img
                src={require("../../assets/icons8-linkedin-circled-48.png")}
                alt="fb"
              />
              <p className="regular secondaryText">Added linkedin</p>
              <img src={require("../../assets/social_check.png")} alt="fb" />
            </div>
            <div className="accounts">
              <img
                src={require("../../assets/pinterestLogo")}
                alt="fb"
              />
              <p className="regular secondaryText">Added linkedin</p>
              <img src={require("../../assets/social_check.png")} alt="fb" />
            </div>
            <div className="accounts">
              <img
                src={require("../../assets/icons8-youtube-48.png")}
                alt="fb"
              />
              <p className="regular secondaryText">Added linkedin</p>
              <img src={require("../../assets/social_check.png")} alt="fb" />
            </div>
            <div className="accounts">
              <img
                src={require("../../assets/icons8-youtube-48.png")}
                alt="fb"
              />
              <p className="regular secondaryText">Added linkedin</p>
              <img src={require("../../assets/social_check.png")} alt="fb" />
            </div>
            <div className="accounts">
              <img
                src={require("../../assets/icons8-youtube-48.png")}
                alt="fb"
              />
              <p className="regular secondaryText">Added linkedin</p>
              <img src={require("../../assets/social_check.png")} alt="fb" />
            </div> */}
            </div>
          ) : (
            <> No Linked Accounts Found...</>
          )}
        </div>

        {/*<div className="displayAccountsName">*/}
        {/*  <button*/}
        {/*    type="button"*/}
        {/*    className="btn btn-primary w-100"*/}
        {/*    onClick={onFacebookLoginClick}*/}
        {/*  >*/}
        {/*    Facebook*/}
        {/*  </button>*/}

        {/*  <button type="button" className="btn-insta w-100" onClick={onInstaLoginClick}>*/}
        {/*    Instagram*/}
        {/*  </button>*/}

        {/* <button
          type="button"
          className="btn-insta w-100"
          onClick={onInstaLoginClick}
        >
          Instagram
        </button> */}

        {/*  <button className="btn btn-primary w-100" onClick={handleLinkedin}>*/}
        {/*    LinkedIn*/}
        {/*  </button>*/}

        {/*  <button className="btn btn-danger w-100" onClick={handlePinterestLogin}>*/}
        {/*    Pinterest*/}
        {/*  </button>*/}

        {/*  <button className=" btn-red w-100" onClick={handleYoutubeLogin}>*/}
        {/*    Youtube*/}
        {/*  </button>*/}
        {/*</div>*/}

        {/*<button*/}
        {/*  className="selectAccountbtn"*/}
        {/*  type="submit"*/}
        {/*  onClick={handleClickContinue}*/}
        {/*>*/}
        {/*  Continue*/}
        {/*</button>*/}
      </div>
    </div>
  );
}
//remove page handler
function deletePage(user) {
  console.log("INSIDE", user);
  const data = {
    userid: user.userid,
    jwToken: user.jwToken,
    page: user.page,
  };
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URI}/api/crud/removePages`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  };
  const response = axios(config);
  return response;
}
export default LinkAccount;
