import axios from "axios";
import { Drawer, TextareaAutosize } from "@mui/material";
import { useState, useEffect } from "react"
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Button, Row, Col, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import CustomDropDown from "../../../Components/CustomDropDown/CustomDropDown";
import { Modal, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";  
import CreateNewPost from "../CreateNewPost";



import Select from "react-select";
import DropzoneArea from "../../../Components/Dropzone/Dropzone";








import {
  showcreatePostMenu,
  showCreatePinMenu,
  showCreateBoardMenu,
} from "../../../Redux/Actions/CreateNewPostAction";
import { SelectField } from "material-ui";
import SelectInput from "@material-ui/core/Select/SelectInput";
import ReelsDropzoneArea from "../../../Components/Dropzone/ReelsDropzone";

export default function PostsGenerator({pagesDetails}) {
 
  const dispatch = useDispatch();

  const handleClickCreatePost = () => {
    dispatch(showcreatePostMenu());
  };

  
const isCreateNewPostMenu = useSelector(
  (state) => state.createNewPost.showCreatePostMenu
);



  const socials = {
    facebook: {
      name: "Facebook",
      icon: `
       M20 10.0001C19.9997 8.08916 19.4518 6.21827 18.4213 4.60898C17.3908 2.99968 15.9207 1.71936 14.1851 0.919594C12.4495 0.119828 10.5211 -0.165895 8.62821 0.0962525C6.73529 0.3584 4.95712 1.15744 3.50421 2.39877C2.0513 3.64011 0.984494 5.27176 0.430087 7.10055C-0.124321 8.92935 -0.143113 10.8787 0.375935 12.7178C0.894983 14.557 1.93014 16.2089 3.35884 17.478C4.78755 18.7471 6.54999 19.5803 8.4375 19.8789V12.8908H5.89875V10.0001H8.4375V7.79702C8.4375 5.29077 9.93062 3.90639 12.2144 3.90639C12.9644 3.91679 13.7126 3.98197 14.4531 4.10139V6.56264H13.1919C12.9769 6.5341 12.7584 6.55428 12.5523 6.6217C12.3462 6.68912 12.158 6.80205 12.0015 6.95211C11.845 7.10218 11.7243 7.28553 11.6483 7.48859C11.5723 7.69165 11.543 7.90921 11.5625 8.12514V10.0001H14.3362L13.8925 12.8908H11.5625V19.8789C13.9149 19.5068 16.0572 18.3073 17.604 16.4963C19.1507 14.6853 20.0004 12.3818 20 10.0001V10.0001Z
     `,
    },
    instagram: {
      name: "Instagram",
      icon: `M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z`,
    },
    twitter: {
      name: "Twitter",
      icon: `
       M16.134 6.188C15.806 6.699 15.404 7.133 14.934 7.492C14.943 7.566 14.943 7.676 14.943 7.82C14.9401 9.22326 14.5464 10.598 13.806 11.79C13.4322 12.4001 12.9733 12.9537 12.443 13.434C11.8821 13.9286 11.2346 14.3152 10.533 14.574C9.76895 14.8618 8.95839 15.0062 8.14197 15C6.83072 15.008 5.54876 14.6124 4.46997 13.867C4.64197 13.887 4.83297 13.898 5.04397 13.898C6.13107 13.9074 7.18515 13.5247 8.01297 12.82C7.50642 12.8116 7.01719 12.6342 6.62297 12.316C6.21843 11.999 5.9233 11.5633 5.77897 11.07C5.94297 11.098 6.09097 11.11 6.23197 11.11C6.44197 11.11 6.65397 11.082 6.86097 11.023C6.31332 10.9068 5.82547 10.5981 5.48597 10.153C5.12478 9.69652 4.9329 9.129 4.94297 8.547V8.516C5.27096 8.71203 5.64312 8.8221 6.02497 8.836C5.69559 8.60494 5.42835 8.29614 5.24697 7.937C5.05272 7.56604 4.95343 7.15272 4.95797 6.734C4.95797 6.277 5.06797 5.852 5.28197 5.461C5.8689 6.229 6.61188 6.86394 7.46197 7.324C8.30816 7.78759 9.24833 8.05358 10.212 8.102C10.174 7.91133 10.1545 7.71742 10.154 7.523C10.154 6.824 10.388 6.23 10.853 5.738C11.322 5.246 11.884 5 12.544 5C13.236 5 13.818 5.266 14.29 5.797C14.8295 5.68765 15.3445 5.48094 15.81 5.187C15.6435 5.76458 15.2691 6.26008 14.759 6.578C15.216 6.528 15.677 6.395 16.134 6.188Z"
         `,
    },
    linkedin: {
      name: "Linkedin",
      icon: `  
      M6.97347 5.39759C6.97878 5.79554 6.84294 6.1277 6.56703 6.39512C6.29112 6.66254 5.92819 6.79519 5.47825 6.79519H5.46233C5.02299 6.79519 4.66961 6.66254 4.40113 6.39512C4.13371 6.1277 4 5.79554 4 5.39759C4 4.99434 4.13796 4.66112 4.41387 4.39689C4.68978 4.13159 5.05058 4 5.49416 4C5.93987 4 6.29537 4.13265 6.56279 4.39689C6.83127 4.66219 6.96604 4.99434 6.97241 5.39759H6.97347ZM6.80368 7.89883V16H4.15387V7.89883H6.80368ZM10.9126 9.04676C11.0138 8.88166 11.1171 8.7416 11.2236 8.61514C11.336 8.48461 11.4889 8.34241 11.6778 8.19066C11.8688 8.03785 12.1022 7.919 12.3781 7.83516C12.654 7.75027 12.9597 7.70782 13.2971 7.70782C14.2129 7.70782 14.9494 8.01769 15.5065 8.6353C16.0637 9.25398 16.3428 10.1602 16.3428 11.3541V15.9968H13.6983V11.664C13.6983 11.0909 13.59 10.6452 13.3735 10.3194C13.156 9.99576 12.8175 9.83339 12.3569 9.83339C12.0195 9.83339 11.7372 9.92572 11.508 10.1146C11.2809 10.3024 11.1111 10.5348 10.9986 10.8129C10.9402 10.9763 10.9105 11.197 10.9105 11.4751V15.9958H8.26813C8.27874 13.8203 8.28299 12.0587 8.28299 10.7068C8.28299 9.35585 8.28299 8.54935 8.27768 8.28723L8.27025 7.89459H10.9126V9.04676Z"
      `
    },
    youtube: {
      name: "Youtube",
      icon: `M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z`,
    },

  }




    const [aiPost, setAiPost] = useState({
        title: '',
        description: '',
        tags: '',
        image: '',
        content: '',
        category: '',
        status: '',
    })

    const [GeneratedPosts, setGeneratedPosts] = useState([
        {
          "date": "01-12-2023",
          "message": "Introducing Toowe, the ultimate social media marketing automation software! Schedule and analyze your posts effortlessly."
        },
        {
          "date": "02-12-2023",
          "message": "Boost your social media presence with Toowe! Engage with your audience and watch your brand grow."
        },
        {
          "date": "03-12-2023",
          "message": "Save time and streamline your social media campaigns with Toowe. Try it today!"
        },
        {
          "date": "04-12-2023",
          "message": "Toowe makes social media marketing a breeze. Automate your posts and focus on what truly matters."
        },
        {
          "date": "05-12-2023",
          "message": "Take control of your social media strategy with Toowe. Plan, schedule, and analyze your content with ease."
        },
        {
          "date": "06-12-2023",
          "message": "Toowe helps you optimize your social media efforts. Maximize your reach and drive more engagement."
        },
        {
          "date": "07-12-2023",
          "message": "Streamline your social media activities with Toowe. Simplify your workflow and achieve better results."
        }
      ]
    )
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [vendors, setVendors] = useState(undefined)
    const [allVendors, setAllVendors] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [selectedVendorAccounts, setSelectedVendorAccounts] = useState([]);
    const [isGenerating, setIsGenerating] = useState(false)

    const [selected, setSelected] = useState(null);

    const [isActiveSocials, setIsActiveSocials] = useState(false);

    const [brandName, setBrandName] = useState('');
const [AboutBrand, setAboutBrand] = useState('');
const [extra, setExtra] = useState('');
const [days, setDays] = useState(7);

const [selectedGeneratedPost, setSelectedGeneratedPost] = useState({})
const [isLoading, setIsLoading] = useState(false);

const [postType, setPostType] = useState("image");
const [postImage, setPostImage] = useState(null);
const [postVideo, setPostVideo] = useState(null);

const [FileArr, setFileArr] = useState([]);

const [paths, setPaths] = useState(  {
  facebook:"",
  youtube: "",
  linkedin: "",
  twitter: "",
  pinterest: "",
  FileArr: [],
  instagram: []
}
);


const [imgName, setImgName] = useState("");
const [imgUrl, setImgUrl] = useState("");




const handleBrandNameChange = (event) => {
  setBrandName(event.target.value);
};

const handleAboutBrandChange = (event) => {
  setAboutBrand(event.target.value);
};

const handleExtraChange = (event) => {
  setExtra(event.target.value);
};

const handleDaysChange = (event) => {
  setDays(event.target.value);
};


const GeneratePosts = async (event) => {
 
  // isGenerating(true)
   event.preventDefault();

  
  let data = await axios.post(process.env.REACT_APP_SERVER_URI + "/api/openAI/getsocialcontent", {
    brandName: brandName,
    AboutBrand: AboutBrand,
    extra: extra,
    days: days
  },{
    headers: {
      token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
      userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
    }
  });



  setGeneratedPosts(data.data.content);

  data.data.content.forEach(async(item) => {
    console.log(item.message)

    axios.post(process.env.REACT_APP_SERVER_URI + "/api/openAI/saveGeneratedContent", {
      userId:JSON.parse(localStorage.getItem('userProfileInfo'))._id ,
      VendorId:allVendors.filter((item) => item.vendorName === selectedVendor)[0]._id,
      jwToken:JSON.parse(localStorage.getItem('userProfileInfo')).token,
      postMessage:item.message,
      postDate:item.date,
      PostImages: [],
      PostVideos: [],
      postType: 'image',
    },{
      headers: {
        token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
        userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
      }
    }).then((res) => {
      console.log(res.data);
    }).catch((err) => {
      console.log(err);
    })



  })


  // isGenerating(false)
};


const getAllGeneratedPosts = async ()=>{
  try {
    let data = await axios.post(process.env.REACT_APP_SERVER_URI + "/api/openAI/getGeneratedContent", {
      userId:JSON.parse(localStorage.getItem('userProfileInfo'))._id ,
      VendorId:allVendors.filter((item) => item.vendorName === selectedVendor)[0]._id,
      jwToken:JSON.parse(localStorage.getItem('userProfileInfo')).token
    },{
      headers: {
        token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
        userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
      }
    });
    console.log("generated posts.........." ,data.data);
    setGeneratedPosts(data.data.aiGeneratedPosts);
    setSelectedGeneratedPost(data.data.aiGeneratedPosts[0].message);
    // setAllVendors(data.data);

  } catch(err) {
    console.log(err);

  }
  }

const getAllVendors = async ()=>{
  try {
    let data = await axios.post(process.env.REACT_APP_SERVER_URI + "/api/vendors/getVendors", {
      vendorUserId:JSON.parse(localStorage.getItem('userProfileInfo'))._id ,
      jwToken:JSON.parse(localStorage.getItem('userProfileInfo')).token
    });
    console.log("vendors.........." ,data.data);
    setAllVendors(data.data);
    setSelectedVendor(data.data[0].vendorName);
    // setAllVendors(data.data);

  } catch(err) {
    console.log(err);

  }
  }
  let options = [];

  useEffect(() => {
    if(selectedVendor){
      let selectedVendorAccounts = allVendors.filter((item) => item.vendorName === selectedVendor);
      setSelectedVendorAccounts(selectedVendorAccounts);



  


    }

  }, [selectedVendor]);


  useEffect(() => {
    getAllGeneratedPosts()
  }, [selectedVendor]);



  // console.log(selectedVendorAccounts[0].vendorPages && selectedVendorAccounts[0].vendorPages)

  selectedVendorAccounts[0]?.vendorPages &&  selectedVendorAccounts[0]?.vendorPages.forEach((element, index) => {
    // console.log(element)
    if (
      element.account_type !== "BUSINESS" &&
      element.account_type !== "pinterest"
    ) {
      options.push({
        value: {
          id: element.pageId,
          // token: element.token,
          // picture: element.picture,
          account_type: element.account_type,
          // secrate: element.auth || element.token_secrate,
          name: element.name,
        },
        key: index,

        label: (
          <>
            <div>
              {" "}
              <svg
                width="25"
                height="25"
                viewBox="0 0 20 20"
                fill="#143059"
                className="sc-jMGUhp hVdFLT"
              >
                <g>
                  <path d={socials[element.account_type]?.icon}></path>
                </g>
              </svg>
              <span style={{ marginLeft: "5px" }}>{element?.name}</span>
            </div>
          </>
        ),
      });


    }

   

  });

  // setSelected(options);




  // useEffect(() => {
  //   setSelected(options);
  // }, [options]);

useEffect(() => {
  getAllVendors();
}, []);




    const handleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen)
    }

    const handleChaneType = (e) => {

      setPostType(e.target.value);

    }

    const handleChangeSelectedPost = (message) => {
      setSelectedGeneratedPost(message);
      setFileArr([]);
      setPaths({
        facebook:"",
        youtube: "",
        linkedin: "",
        twitter: "",
        pinterest: "",
        FileArr: [],
        instagram: []
      })



    }

    useEffect(() => {
      if (selectedVendor) {
        let keysToMatch = selectedVendor?.vendorPages?.map((item) => item.pageId);
        console.log(keysToMatch, 'keysToMatch');
    
        const filteredObjects = options?.filter(obj => keysToMatch?.includes(obj.key));
        console.log(filteredObjects, 'filteredObjects');
    
        setSelected(filteredObjects);
      }
    }, [selectedVendor]);
      

    return (
        <Card className="calenderModuleCard">
        <CardBody className="calendarBody">
          {/*Drop down start*/}
          <div className="calender_top">
          <CustomDropDown
            isActive={isActiveSocials}
            setIsActive={setIsActiveSocials}
            selected={selectedVendor}
            setIsSelected={setSelectedVendor}
            list={allVendors?.map((item) => item.vendorName) }
            type="Customdropdown"
          />

            <Button className="calender_btn" style={{marginLeft:'10px'}} onClick={handleDrawer}>Generate Post</Button>

            <Drawer open={isDrawerOpen}  onClose={handleDrawer}>
                
    <form style={{padding:'20px'}} onSubmit={GeneratePosts}>
        <div style={{display:'flex', flexDirection:'column'}}>

      <label>
        Brand Name:
        <Input value={brandName} onChange={handleBrandNameChange} type="text" name="title" />
      </label>
      <label>
        Brand Detail:
        <TextareaAutosize value={AboutBrand} onChange={handleAboutBrandChange} name="content" placeholder="Eg. what your brand is about?, what it does?"  style={{width:'100%', minHeight:'100px'}}  />
      </label>
      <label>
        No of Days:
        <Input type="number"  value={days} onChange={handleDaysChange} defaultValue={7} name="days"  style={{width:'100%'}}  />
      </label>
      </div>
      <Input  style={{marginTop:'10px', background:'green', color:'white', fontWeight:'bold'}} type="submit" value="Submit" />
    </form>
  </Drawer>


  <div>
   
  
  

  
 
  </div>
 
     
     </div>
     
     
     
     <div style={{ display: 'flex', marginTop: '20px', width: '90vw' }}>
      <div style={{ width: '30vw', background: '#f4f4f4', height: '73vh', overflow: 'scroll' }}>
        {GeneratedPosts.length > 1 &&
          GeneratedPosts?.map((item, index) => (
            <div
              key={index}
              style={{
                padding: '15px',
                background: selectedGeneratedPost === item.postMessage ? '#e1e1e1' : 'white',
                margin: '10px',
                cursor: 'pointer',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                transition: 'background 0.3s',
                textAlign: 'left',
              }}
              onClick={() => handleChangeSelectedPost(item)}
            >
              <h3 style={{ marginBottom: '8px', fontSize: '14px', color: '#555' }}>{item?.postDate?.slice(0,10)}</h3>
              <p style={{ fontSize: '16px', lineHeight: '1.4', color: '#333' }}>{item.postMessage}</p>
            </div>
          ))}
      </div>

      <div style={{ width: '60vw', background: '#f4f4f4', height: '73vh', overflow: 'scroll' }}>
        <div style={{ padding: '20px', background: 'white', margin: '10px', height:'70vh', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
         
         <div>
          {/* <h3 style={{ marginBottom: '10px', color: '#333', fontSize: '18px', width:'100%' }}>Selected Message</h3> */}
         </div>

        <div className="image-gallery">
        

    

   
        </div>
      



    {/* <Select
                          options={options}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={selected}
                          onChange={(event) => {
                            setSelected(event);
                          }}
                          isMulti={true}
                        /> */}



       <select style={{width:'100%', marginTop:'10px', marginBottom:'10px'}} onChange={(e)=> {handleChaneType(e)}}>
        <option value="image">Image</option>
        <option value="video">Video</option>
       </select>

       {
          postType === "image" ? (
            <DropzoneArea 
            setFileArr={setFileArr}
            FileArr={FileArr}
            paths={paths}
            setPaths={setPaths}

            setImgName={setImgName}
            setImgUrl={setImgUrl}
             />
          ) : (
            <ReelsDropzoneArea 
            setFileArr={setFileArr}
            FileArr={FileArr}
            paths={paths}
            setPaths={setPaths}
            
            setImgName={setImgName}
            setImgUrl={setImgUrl}
              />
          )
       }


                     
         
          <p style={{ fontSize: '16px', lineHeight: '1.4', color: '#555' }}>{selectedGeneratedPost?.postMessage || 'No message selected'}</p>
      
      
      
          <Button style={{marginTop:'20px', background:'green', color:'white', fontWeight:'bold', width:'100%', borderRadius:'0px'}} onClick={handleClickCreatePost} >Schedule</Button>
     
        </div>
      </div>
    </div>

    
     
     </CardBody>
     
     
     
     {isCreateNewPostMenu && (
        <CreateNewPost
          pagesDetails={pagesDetails}
          eventData={{
          
              desc:  selectedGeneratedPost?.postMessage,
              accountsSelectedDefault: selected,
              postType: postType,
              url: paths,
              start: new Date(selectedGeneratedPost?.postDate).toString() 
          
          }}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
     
     </Card>
    )

}

