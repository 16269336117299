import React, { useState, useEffect } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap";
import InstagramLineChart from "./InstagramLineChart";
import InstagramSmallCharts from "./InstagramSmallCharts";
import PostAnalytics from "./PostAnalytics";
import Posts from "./posts/Posts";
import "../analytics.css";
import "../FbAnalytics/FbAnalytics.css";

import axios from "axios";
import CustomDropDown from "../../../../Components/CustomDropDown/CustomDropDown";

const InstagramAnalytics = (props) => {
  const [GraphdropdownOpen, setGraphdropdownOpen] = useState(false);
  const [pageData, setPageData] = useState(
    (props?.pagesDetails.pagesDetails?.filter(
      (item) => item.account_type === "instagram"
    ))[0]
  );
  const [smallGraphs, setSmallGraphs] = useState([
    "follower_count",
    "get_directions_clicks",
    "impressions",
    "phone_call_clicks",
  ]);
  const [instaPosts, setInstaPosts] = useState();
  const metrics = [
    "email_contacts",
    "follower_count",
    "get_directions_clicks",
    "impressions",
    "phone_call_clicks",
    "profile_views",
    "reach",
    "text_message_clicks",
    "website_clicks",
  ];
  const [selectedPost, setSelectedPost] = useState();
  const handleSelectPost = (e) => {
    setSelectedPost(e);
    console.log(selectedPost);
  };

  const handleAdd = (e) => {
    // check if the value is already in the array
    if (smallGraphs.includes(e)) {
      // if it is, remove it
      setSmallGraphs(smallGraphs.filter((item) => item !== e));
    } else {
      // if it isn't, add it
      setSmallGraphs([...smallGraphs, e]);
    }
    // setSmallGraphs([...smallGraphs, e])
    console.log(smallGraphs);
  };
  const getPostData = async () => {
    const postIds =  await axios.get(
      `https://graph.facebook.com/v11.0/${pageData.pageId}/media?pretty=0&limit=100&access_token=${pageData.token}&fields=id&limit=100`
    );
    setInstaPosts(postIds.data);
    console.log(instaPosts);
  };
  const [AllPages, setAllPages] = useState(props.pagesDetails);
  const [IsPageSelectedInsta, setIsPageSelectedInsta] = useState(false);
  const [SelectedPage, setSelectedPage] = useState("Select Page");
  const [networkPages, setNetworkPages] = useState([]);
  useEffect(() => {
    pageData?.pageId && getPostData();
    console.log(instaPosts);
  }, []);

  useEffect(() => {
    const Networkpages = [];
    props.pagesDetails.pagesDetails?.map((item) => {
      if (item.account_type === "instagram") {
        Networkpages.push(item.name);
      }
    });
    setNetworkPages(Networkpages);
    setSelectedPage(Networkpages[0]);
    console.log("Network----------->", Networkpages);
  }, [props.pagesDetails]);

  useEffect(() => {
    console.log(SelectedPage);
    setPageData(
      props.pagesDetails.pagesDetails.filter(
        (item) => item.name === SelectedPage
      )[0]
    );
  }, [SelectedPage]);

  return (
    <div className="fbAnalyticBlock">
      <div className="mediaFilterBlock">
        <p>Instagram</p>
        <CustomDropDown
          isActive={IsPageSelectedInsta}
          setIsActive={setIsPageSelectedInsta}
          selected={SelectedPage}
          setIsSelected={setSelectedPage}
          list={networkPages}
          type="mediaFilter"
        />
      </div>

      {/*<div  style={{ margin: '10px', display: 'flex', flexDirection: 'start' }}>*/}
      {/*    <ButtonDropdown toggle={() => { setGraphdropdownOpen(!GraphdropdownOpen) }}*/}
      {/*        isOpen={GraphdropdownOpen} >*/}
      {/*        <DropdownToggle caret color="transparent" style={{ boxShadow: '0 4px 24px 0 rgb(34 41 47 / 31%)' }}>*/}
      {/*            Add Graph*/}
      {/*        </DropdownToggle>*/}
      {/*        <DropdownMenu>*/}
      {/*            <DropdownItem header>Numeric Characters*/}
      {/*            </DropdownItem>*/}
      {/*            {metrics.map((filter) => {*/}
      {/*                return (*/}
      {/*                    <DropdownItem onClick={() => { handleAdd(filter) }}>{filter}</DropdownItem>*/}
      {/*                )*/}
      {/*            })*/}
      {/*            }*/}

      {/*        </DropdownMenu>*/}
      {/*    </ButtonDropdown>*/}
      {/*</div>*/}

      <div className="mediaKPIBlock">
        {smallGraphs.map((metric) => {
          return (
            <InstagramSmallCharts
              metric={metric}
              filters={metrics}
              PageData={pageData}
              startDate={props.startDate}
              endDate={props.endDate}
            />
          );
        })}
      </div>
      <div className="mediaLineChart">
        <InstagramLineChart
          PageData={pageData}
          startDate={props.startDate}
          endDate={props.endDate}
        />
      </div>

      {/*<div className="containere">*/}

      {/*         {instaPosts !== undefined && instaPosts.data.length > 1 ? instaPosts.data.map((post) => {*/}
      {/*             return (<div onClick={()=> handleSelectPost(post.id)}  >*/}
      {/*                 <Posts pageData={props.PageData}  postId={post.id}  pageDataToken={props.PageData.token}/>*/}

      {/*             </div>*/}
      {/*             )*/}

      {/*         }) : null}*/}

      {/*     </div>  */}
      {/*     <div className="instagram-Posts-Analytics" style={{ width: "85vw" }}>*/}
      {/*         <PostAnalytics PostId={selectedPost} PageData={props.PageData} />*/}
      {/*         */}
      {/*         </div>  */}
    </div>
  );
};
export default InstagramAnalytics;
