import React, { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Message.css";
// import profile_pic from "../../assets/profile_pic.png";
import fbIcon from "../../assets/icons8-facebook-64.png";
import TwitterIcon from "../../assets/icons8-twitter-48.png";
import { ScaleLoader } from "react-spinners";
import {
  FacebookAllPosts,
  FacebookAllCommentsForPost,
  FacebookPostCommentReply,
  TwitterAllTweets,
  TwitterAllComments,
  TwitterReplyToComment,
  TwitterMoreTweetResults,
} from "../../Utils/api";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import CreateNewPost from "../Dashboard/CreateNewPost";
import CreatePin from "../Dashboard/CreatePin";
import CreateBoard from "../Dashboard/CreateBoard";
import { useNavigate } from "react-router";
const Message = ({ pagesDetails }) => {
  const [selectedForm, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isCreateNewPostMenu = useSelector(
    (state) => state.createNewPost.showCreatePostMenu
  );

  const isCreateNewPinMenu = useSelector(
    (state) => state.createNewPin.showCreatePinMenu
  );

  const isCreateNewBoardMenu = useSelector(
    (state) => state.createNewBoard.showCreateBoardMenu
  );
  const [selectedFilter, setSelectedFilter] = useState("All Networks");
  const [socialAccountDetails, setSocialAccountDetails] = useState([]);
  const [facebookAccountDetails, setFacebookAccountDetails] = useState([]);
  const [twitterAccountDetails, setTwitterAccountDetails] = useState([]);
  const [allNetworkAccountDetails, setAllNetworkAccountDetails] = useState([]);
  const [comments, setComments] = useState([]);
  const [replyDetails, setReplyDetails] = useState("");
  const [replyText, setReplyText] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [tweetComments, setTweetComments] = useState([]);
  const [tweets, setTweets] = useState([]);
  const [tweetData, setTweetData] = useState([]);
  const [tweetMeta, setTweetMeta] = useState([]);
  const [selectedTweetData, setSelectedTweetData] = useState([]);


  const [loadingData, setLoadingData] = useState(false);

  // const [tweetUser, setTweetUser] = useState([]);

  // console.log(tweets);
  // console.log(tweetComments);
  // console.log(tweetMeta);
  // console.log(tweetData);
  // console.log(tweetUser);
  // console.log(replyText);
  // console.log(comments);
  // console.log(error);
  // console.log(twitterAccountDetails);
  // console.log(replyDetails);
  console.log(selectedTweetData);
  console.log(allNetworkAccountDetails);

  const checkAccount = (detail) => {
    return (
      detail.account_type == "facebook" || detail.account_type == "twitter"
    );
  };
  const checkFacebook = (detail) => {
    return detail.account_type == "facebook";
  };
  const checkTwitter = (detail) => {
    return detail.account_type == "twitter";
  };








  useEffect(() => {
    const allResult = pagesDetails.filter(checkAccount);
    const facebookResult = pagesDetails.filter(checkFacebook);
    const twiterresult = pagesDetails.filter(checkTwitter);
    setSocialAccountDetails(allResult);
    setFacebookAccountDetails(facebookResult);
    setTwitterAccountDetails(twiterresult);
  }, [pagesDetails]);

  const token = sessionStorage.getItem("token");

  useEffect(() => {
    facebookAccountDetails.forEach(async (account, id) => {
      let pageid = account.pageId;
      let pageToken = account.token;

      const userData = async () => {
        return await axios
          .get(
            `https://graph.facebook.com/v11.0/${pageid}?fields=picture,name&access_token=${pageToken}`
          )
          .then((res) => {
            return res.data?.picture.data?.url;
          });
      };

      let profileAvatar = await userData();

      const fetches = async () => {

        setLoadingData(true);

        const posts = await FacebookAllPosts({ pageid, pageToken, token });

        const fetchComments = async (postid, pageToken, token, profileAvatar) => {
          const allComments = await FacebookAllCommentsForPost({
            postid,
            pageToken,
            token,
          });

          return allComments.data?.data?.map((comment, id) => ({
            comment,
            pageToken,
            profileAvatar,
          }));
        };

        Promise.all(
          posts.data?.data?.map((ele, idx) =>
            fetchComments(ele.id, pageToken, token, profileAvatar)
          )
        )
          .then((commentsArray) => {
            const flattenedComments = commentsArray.flat();
            setComments((prev) => [...prev, ...flattenedComments]);
            setLoadingData(false);
          })
          .catch((error) => {
            // Handle error
          });

      
      };

      fetches();
    });
  }, [facebookAccountDetails]);

  useEffect(() => {
    twitterAccountDetails.forEach((account) => {
      console.log(account);

      const fetchTwitterData = async () => {
        const userid = account.pageId;
        // const { data } = await TwitterAllTweets({ userid });

        const data = []

        // console.log(data);

        setTweets(data);
        // setTweetData(data?.includes.tweets);
        // setTweetUser(data?.includes.users);

        setTweetMeta(data?.meta);

        data?.includes?.users &&     data?.includes?.users?.forEach((user, id) => {
          data?.includes?.tweets?.map((tweet, id) => {
            if (user.id === tweet.author_id) {
              setTweetData((prev) => [
                ...prev,
                {
                  name: user?.name,
                  profile_image_url: user.profile_image_url,
                  username: user.username,
                  id: user.id,
                  conversation_id: tweet.conversation_id,
                  created_at: tweet.created_at,
                  text: tweet.text,
                  tweetid: tweet.id,
                },
              ]);
            }
          });
        });

        // data?.data?.map(async (tweet, id) => {
        //   const conversationid = tweet.conversation_id;

        //   const { data } = await TwitterAllComments({ conversationid });

        //   // const filterCheck = (comm) => {
        //   //   return comm.meta.result_count === 0;
        //   // };

        //   data?.data?.map((comm, id) => {
        //     setTweetComments((prev) => [...prev, comm]);
        //   });
        // });
      };

      fetchTwitterData();
    });
  }, [twitterAccountDetails]);

  // useEffect(() => {
  //   setSelectedTweetData("");
  // }, [selectedFilter]);

  // useEffect(async () => {
  //   if (tweetMeta.next_token != "") {
  //     const { data } = await TwitterMoreTweetResults();
  //   }
  // }, [tweetMeta]);

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  useEffect(() => {
    setAllNetworkAccountDetails(shuffle(comments.concat(tweetData)));
  }, [comments, tweetData]);

  const selectCommentForReply = (
    commentid,
    pageToken,
    name,
    profileAvatar,
    text,
    temp
  ) => {
    console.log(name);
    console.log(profileAvatar);
    console.log(text);
    console.log(commentid);
    console.log(pageToken);
    console.log(temp);
    toast.info(`${name}'s comment selected for reply `, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

    setReplyDetails({ commentid, pageToken, name, profileAvatar, text, temp });
    setSelectedTweetData(() => [{ comment: text }]);
  };

  const replyTwitterComment = async (e) => {
    if (replyText === "") {
      return toast.error("Enter the Reply for the comment you have selected", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (replyDetails === "") {
      return toast.error("please select a comment to reply", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      setLoading(true);
      console.log("reply initiated");
      e.preventDefault();

      let conversationid = replyDetails.commentid;
      let message = replyText;
      // let id = replyDetails.pageToken;

      try {
        const afterReplyResult = await TwitterReplyToComment({
          conversationid,
          message,
        });

        // console.log(afterReplyResult.data?.data?.id);

        if (afterReplyResult.data?.data?.id) {
          toast.success("reply has been sucessful", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setReplyText("");
          setLoading(false);
          setSelectedTweetData((prev) => [
            ...prev,
            { reply: afterReplyResult.data?.data?.text },
          ]);
        }
      } catch (error) {
        console.log(error);
        setError(error.message);
        toast.error("Something went wrong with request", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setReplyText("");
        setLoading(false);
      }
    }
  };




  const replyComment = async (e) => {




    if (replyText === "") {
      return toast.error("Enter the Reply for the comment you have selected", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (replyDetails === "") {
      return toast.error("please select a comment to reply", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      setLoading(true);
      console.log("reply initiated");
      e.preventDefault();

      let commentid = replyDetails.commentid;
      let pageToken = selectedFacebookReplyAccount?.token;
      let message = replyText;

      try {
        const afterReplyResult = await FacebookPostCommentReply({
          commentid,
          pageToken,
          message,
          token,
        });

        console.log(afterReplyResult);
        if (afterReplyResult.data?.id) {
          toast.success("reply has been sucessful", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setReplyText("");
          setLoading(false);
          setSelectedTweetData((prev) => [...prev, { reply: replyText }]);
        }
      } catch (error) {
        console.log(error);
        setError(error.message);
        toast.error("Something went wrong with request", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setReplyText("");
        setLoading(false);
      }
    }
  };

  const options = [
    { value: "All Networks", label: "All Networks" },
    { value: "Facebook", label: "Facebook" },
    // { value: "Twitter", label: "Twitter" },
  ];

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#f9f8f4",
      borderRadius: "0px",
      border: "1px solid #d5d3d3",
      height: "20px",
      textAlign: "left",
    }),
    input: (styles) => ({
      ...styles,
      marginTop: "-8px",
      paddingTop: "3px",
      //   display: "none",
    }),
    placeholder: (styles) => ({ ...styles }),
  };


  const [selectedFacebookReplyAccount, setSelectedFacebookReplyAccount] = useState(
    facebookAccountDetails?.[0]
  )


  const handleAccountChange = (event) => {
    console.log(JSON.stringify(event.target.value))
   setSelectedFacebookReplyAccount(JSON.parse(event.target.value))
  };

  useEffect(() => {
    setSelectedFacebookReplyAccount(facebookAccountDetails?.[0])
  }, [facebookAccountDetails])


  return (
    <div className="MessageOuterLayout regular">
      <div className="MessageHeadLayout ">
        <button
          onClick={() => {
            navigate("/link-account");
          }}
        >
          Add Social Network
        </button>
      </div>
      <div className="MessageMainContent">
        <div className="listSection regular">
          <div
            className="messageTopHeadText  "
            style={{ marginLeft: "20px", marginTop: "30px" }}
          >
            All Messages
          </div>
          <div>
            <Select
              options={options}
              styles={colourStyles}
              defaultValue={options[0]}
              className="messageSelect"
              onChange={(e) => {
                setSelectedFilter(e.value);
              }}
            />
          </div>


          {
              loadingData && (
                <div className="loadingData">
                  <ScaleLoader
                    width={2}
                    height={20}
                    size={20}
                    color="#ff6d01"
                    loading={loadingData}
                  />
                </div>
              )
            }
          <div className="FilteredDataMainArea">
            {selectedFilter === "All Networks"
              ? allNetworkAccountDetails.map((data, id) => {
                  const months = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ];
                  const days = [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                  ];

                  let d = new Date(
                    data?.created_at || data?.comment.created_time
                  );
                  // let time = d.getTime();
                  let month = months[d.getMonth()];
                  let day = days[d.getDay()];
                  let date = d.getDate();
                  let yr = d.getFullYear();

                  // let time = hr;

                  let created_Date =
                    date + " " + month + " " + yr + " " + "," + day;
                  let temp;
                  return (
                    <>
                      <div
                        className="NetworkData"
                        key={id}
                        style={{
                          backgroundColor: data?.profile_image_url ? "#fff" : "",
                        }}
                        onClick={(e) => {
                          data?.profile_image_url
                            ? selectCommentForReply(
                                data?.conversation_id,
                                data?.id,
                                data?.name,
                                data?.profile_image_url,
                                data?.text,
                                (temp = "Twitter")
                              )
                            : selectCommentForReply(
                                data?.comment.id,
                                data?.pageToken,
                                data?.comment.from?.name,
                                data?.profileAvatar,
                                data?.comment.message,
                                (temp = "Facebook")
                              );
                        }}
                      >
                        <div className="avatar">
                          <img
                            src={data?.profile_image_url || data?.profileAvatar}
                            alt=""
                            height="35px"
                            width="35px"
                          />
                        </div>
                        <div className="textArea">
                          <span>
                            <img
                              src={
                                data?.profile_image_url ? TwitterIcon : fbIcon
                              }
                              alt="facebook"
                              height="15px"
                              weight="15px"
                            />
                            {/* Private Message ... */}
                            <span style={{ color: "#ff6d01" }}>
                              {data?.name || data?.comment?.from?.name}
                            </span>
                          </span>
                          <br />
                          <span>{data?.text || data?.comment.message}</span>
                          <br />
                          <span>{created_Date}</span>
                        </div>
                      </div>
                    </>
                  );
                })
              : selectedFilter === "Twitter"
              ? tweetData.map((record, id) => {
                  const months = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ];
                  const days = [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                  ];

                  let d = new Date(record.created_at);
                  // let time = d.getTime();
                  let month = months[d.getMonth()];
                  let day = days[d.getDay()];
                  let date = d.getDate();
                  let yr = d.getFullYear();

                  // let time = hr;

                  let created_Date =
                    date + " " + month + " " + yr + " " + "," + day;
                  let temp;
                  return (
                    <>
                      <div
                        className="NetworkData"
                        key={id}
                        style={{
                          backgroundColor: "#fff",
                        }}
                        onClick={(e) => {
                          selectCommentForReply(
                            record.conversation_id,
                            record.id,
                            record?.name,
                            record.profile_image_url,
                            record.text,
                            (temp = "Twitter")
                          );
                        }}
                      >
                        <div className="avatar">
                          <img
                            src={record.profile_image_url}
                            alt=""
                            height="35px"
                            width="35px"
                          />
                        </div>
                        <div className="textArea">
                          <span>
                            <img
                              src={TwitterIcon}
                              alt="facebook"
                              height="15px"
                              weight="15px"
                            />
                            Private Message ...
                            <span style={{ color: "#ff6d01" }}>
                              {record?.name}
                            </span>
                          </span>
                          <br />
                          <span>{record.text}</span>
                          <br />
                          <span>{created_Date}</span>
                        </div>
                      </div>
                    </>
                  );
                })
              : selectedFilter === "Facebook"
              ? comments.map((comment, id) => {
                  const months = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ];
                  const days = [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                  ];

                  let d = new Date(comment.comment.created_time);
                  // let time = d.getTime();
                  let month = months[d.getMonth()];
                  let day = days[d.getDay()];
                  let date = d.getDate();
                  let yr = d.getFullYear();

                  // let time = hr;

                  let created_Date =
                    date + " " + month + " " + yr + " " + "," + day;
                  let temp;

                  return (
                    <>
                      <div
                        className="NetworkData"
                        key={id}
                        style={{
                          backgroundColor: "#f4f9fd",
                        }}
                        onClick={(e) => {
                          selectCommentForReply(
                            comment.comment.id,
                            comment.pageToken,
                            comment.comment.from?.name,
                            comment.profileAvatar,
                            comment.comment.message,
                            (temp = "Facebook")
                          );
                        }}
                      >
                        <div className="avatar">
                          <img
                            src={comment.profileAvatar}
                            alt=""
                            height="35px"
                            width="35px"
                          />
                        </div>
                        <div className="textArea">
                          <span>
                            <img
                              src={fbIcon}
                              alt="facebook"
                              height="15px"
                              weight="15px"
                            />
                            Private Message ...
                            <span style={{ color: "#ff6d01" }}>
                              {comment.comment.from?.name}
                            </span>
                          </span>
                          <br />
                          <span>{comment.comment.message}</span>
                          <br />
                          <span>{created_Date}</span>
                        </div>
                      </div>
                    </>
                  );
                })
              : ""}
          </div>
        </div>
        <div className="jumboTextSection regular">
          <div className="MessageContainer">
            {replyDetails != "" ? (
              <>
                {" "}
                {facebookAccountDetails && (
        <div>
          {
            JSON.stringify(selectedFacebookReplyAccount) === "{}" ? (
              <p>No account selected</p>
            ) : (
              <p>Reply with: {selectedFacebookReplyAccount?.name}</p>
            )
          }
          <select value={selectedFacebookReplyAccount} style={{width:'100%'}} onChange={handleAccountChange} >
            { facebookAccountDetails && facebookAccountDetails?.map((account) => (
              <option key={account?.token} value={JSON.stringify(account)}>
                {account?.name}
              </option>
            ))}
          </select>
          {/* <button onClick={handleReplyConfirm}>Confirm</button> */}
        </div>
      )}
                <div className="comment_profile">
                  <img src={replyDetails.profileAvatar} alt="profile" />
                  <div className="profile_text">{replyDetails?.name}</div>
                </div>
                <div className="map_comment_reply">
                  {selectedTweetData.map((obj) => {
                    return (
                      <div className={obj.comment ? "mapComment" : "mapReply"}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: (obj.comment || obj.reply).replace(
                              /(https?:\/\/[^\s]+)/g,
                              function (url) {
                                return (
                                  '<a target="_blank" href=' +
                                  url +
                                  ">" +
                                  url +
                                  "</a>"
                                );
                              }
                            ),
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              ""
            )}




            <input
              placeholder="start reply here..."
              className="textarea"
              value={replyText}
              onChange={(e) => {
                setReplyText(e.target.value);
              }}
            />
            <button
              onClick={
                replyDetails.temp === "Facebook"
                  ? replyComment
                  : replyTwitterComment
              }
            >
              REPLY
              <ScaleLoader
                width={2}
                height={20}
                size={20}
                color="#ffffff"
                loading={loading}
              />
            </button>
    
          </div>
        </div>
      </div>
      <ToastContainer />
      {isCreateNewPostMenu && (
        <CreateNewPost
          pagesDetails={pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {isCreateNewPinMenu && (
        <CreatePin
          pagesDetails={pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {isCreateNewBoardMenu && (
        <CreateBoard
          pagesDetails={pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </div>
  );
};

export default Message;
