import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Await, json } from "react-router";
import { Alert } from "reactstrap";
import SpinnerReact from "../Spinner/Spinner";
import axios from "axios";

function DropzoneArea({ setPaths, setImgName, setImgUrl, imgUrl, selected, paths,isThumbnail, isLoading, setIsLoading, FileArr, setFileArr }) {
 


  const onDrop = useCallback(
    async (acceptedFiles) => {
      console.log("Accepted Files: ", acceptedFiles);


      for(let i=0; i<acceptedFiles.length; i++){

        let ff = new FormData();
        ff.append("file", acceptedFiles[i]);
        await fetch(`${process.env.REACT_APP_SERVER_URI}/api/crud/upload`, {
          method: "POST",
          body: ff,
          headers: {
            token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
            userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
          }
        },{
          headers: {
            token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
            userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
          }
        }).then((response) => response.json()).then((data) => {
          console.log(data.imgurl);

          paths.FileArr = [...paths.FileArr, data.imgurl]

          setPaths({ ...paths })
          
          setIsLoading(false)
        }).catch(err => console.log(err));




      }



  
console.log(acceptedFiles[0].type, "type")
      if (acceptedFiles[0].type ===
        "image/jpeg" || acceptedFiles[0].type === "image/png" || acceptedFiles[0].type === "image/jpg" ) {
        if (acceptedFiles[0].size / 1048576 <= 5) {
          const formData = new FormData();
               // console.log(formData)
          const [file] = acceptedFiles;

          const uploadFunctions = {                                                  
            facebook: async (body) => {
              setIsLoading(true)
              console.log(body, "body")
              await fetch(`${process.env.REACT_APP_SERVER_URI}/api/crud/upload`, {
                    method: "POST",
                body: body, headers: {
            token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
            userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
          } 
              },{
                headers: {
                  token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
                  userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
                }
              }).then((response) => response.json()).then((data) => {
                console.log(data.imgurl);
                paths.facebook = data.imgurl
              
    
                setPaths({ ...paths })
                
                setIsLoading(false)
              }).catch(err => console.log(err));
            },
            instagram: async (body) => {
              setIsLoading(true)
              await fetch(`${process.env.REACT_APP_SERVER_URI}/api/crud/upload`, {
                  method: "POST",
                body: body, headers: {
            token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
            userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
          }
              },{
                headers: {
                  token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
                  userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
                }
              }).then((response) => response.json()).then((data) => {
                console.log(data.imgurl);
                paths.instagram = data.imgurl
                setPaths({ ...paths })
                setIsLoading(false)
              }).catch(err => console.log(err));
            },
            youtube: async (body) => {
              setIsLoading(true);
              await fetch(`${process.env.REACT_APP_SERVER_URI}/api/crud/upload`, {
                method: "POST",
                body: body.formData,
              },{
                headers: {
                  token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
                  userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
                }
              }).then((response) => response.json()).then((data) => {
                paths.facebook = data.imgurl
                setPaths({ ...paths })
              }).then(() => {
                console.log(paths.facebook)
                fetch(`${process.env.REACT_APP_SERVER_URI}/api/crud/uploadVideo`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
                    userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
                  },
                  body: JSON.stringify({
                    mediaURL: paths.facebook,
                    secrate: body.secrate
                  })
                }).then((res) => res.json()).then((data) => {
                  paths.youtube = data.link
                  setPaths({ ...paths })
                  setIsLoading(false)
                })     
              }).catch(err => console.log(err));
            },
            linkedin: async (body) => {
              setIsLoading(true)
              await fetch(`${process.env.REACT_APP_SERVER_URI}/api/crud/upload`, {
                  method: "POST",
                body: body, headers: {
            token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
            userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
          }
              },{
                headers: {
                  token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
                  userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
                }
              }).then((response) => response.json()).then((data) => {
                paths.facebook = data.imgurl
                paths.linkedin = data.imgurl
                setPaths({ ...paths })
            //     selected.filter(acc => acc.value.account_type === "linkedin").map( async acc =>{
            // let dd = await axios.post(process.env.REACT_APP_SERVER_URI + '/api/crud/uploadLinkedInFromURL', {
            //         url:   { imgurl: data.imgurl},
            //         id: acc.value.id,
            //        token: acc.value.token,
            //        jwToken: localStorage.getItem('token')
            //     }).then(res => res.data).then(dd => {
            //     console.log("LinkedIn Upload: ", dd.imgurl)
            //     paths.linkedin = [...paths.linkedin, dd.imgurl]
            //     setPaths({ ...paths })
            //     setIsLoading(false)   
            //     }).catch(err => console.log(err))                 
                            
            //   }).catch(err => console.log(err))
            })
            },
            twitter: async (body) => {
              setIsLoading(true)
              await fetch(`${process.env.REACT_APP_SERVER_URI}/api/crud/upload`, {
                  method: "POST",
                body: body, headers: {
            token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
            userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
          }

              },{
      headers: {
        token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
        userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
      }
    }).then((response) => response.json()).then((data) => {
                paths.facebook = data.imgurl
                setPaths({ ...paths })
              }).then(async () =>
                await fetch(`${process.env.REACT_APP_SERVER_URI}/api/crud/uploadtwitter`, {
                  method: "POST",
                  body: body
                },{
                  headers: {
                    token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
                    userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
                  }
                }).then(response => response.json()).then(data => {
                  console.log("Twitter Data: ", data.imgurl)
                  paths.twitter = data.imgurl
                  setPaths({ ...paths })
                  setIsLoading(false)
                }).catch(err => console.log(err)))
            },
            pinterest: async (body) => {
              // setIsLoading(true)
              await fetch(`${process.env.REACT_APP_SERVER_URI}/api/crud/upload`, {
                  method: "POST",
                body: body, headers: {
            token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
            userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
          }
              },{
                headers: {
                  token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
                  userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
                }
              }).then((response) => response.json()).then((data) => {
                console.log(data.imgurl);
                paths.pinterest = data.imgurl
                setImgUrl(data.imgurl)
                setPaths({ ...paths })
                setIsLoading(false)
              }).catch(err => console.log(err));
            }
          }
        

          formData.append("file", file);
          formData.append("jwToken", localStorage.getItem('token'))
          
          // formData.append("secrate", ` ${selected.value.secrate}`);
          setImgName(acceptedFiles.map((file) => file.name));
          console.log(selected)
          uploadFunctions.facebook(formData)
          // selected.map((acc, key) => {
          //   switch (acc.value.account_type) {
          //     case "facebook":
          //       uploadFunctions.facebook(formData)
                
          //     case "instagram":
          //       uploadFunctions.instagram(formData)
          //     case "youtube":
          //       uploadFunctions.youtube({
          //         secrate: acc.value.secrate,
          //         formData
          //       })
          //     case "linkedin":
          //       formData.append("token", `${acc?.value?.token}`);

          //       formData.append("id", `${acc?.value?.id}`);
          //       uploadFunctions.linkedin(formData)

          //     case "twitter":
          //       formData.append("token", `${acc?.value?.token}`);
                
          //       formData.append("secrate", `${acc?.value?.secrate}`);
          //       uploadFunctions.twitter(formData)
          //     case "pinterest":
          //       uploadFunctions.pinterest(formData)
          //     default:
          //       console.log("")
          //   }
          // })
          console.log("Selected in Dropzone", selected)
        } else {
          window.alert("Max Size limit increased. The File size is greater than 5 MB")
        }
      }
      else {
        window.alert("Invalid Format of Image.")
      }

   


  


    },
    [paths, selected]
  );        
  const { getRootProps, getInputProps } = useDropzone({ onDrop });




  return (
    <div className="upload-from-device media-border" {...getRootProps()}>
 
      <input {...getInputProps()} />
      <svg width="16" height="16" viewBox="0 0 20 20" fill="#2f3638">
        <path d="M16.25,8.25 C16.25,8.25 16.25,8.12 16.25,8.12 C16.25,4.62 13.5,1.87 10,1.87 C6.87,1.87 4.25,4.12 3.87,7.25 C1.62,8 0,10 0,12.5 C0,15.62 2.5,18.12 5.62,18.12 C7.75,18.12 13,18.12 15,18.12 C17.75,18.12 20,15.87 20,13.12 C20,10.75 18.37,8.87 16.25,8.25 Z M11.25,11.87 L11.25,15.62 L8.75,15.62 L8.75,11.87 L5,11.87 L10,6.87 L15,11.87 L11.25,11.87 Z"></path>
      </svg>
   
{
  isThumbnail ? ' Upload the thumbnail here' : 'Upload the Images for Facebook, twitter, linkedin here'
}
      {/* <p className="uploadBtn2-text1">upload the Images for Facebook, twitter, linkedin here</p> */}
      <ul>
        {/* {   paths.FileArr &&    paths.FileArr.map((image) => (
         
            <img height='20px' width='20px' alt="img" src={image} />
        ))} */}
      </ul>
    </div>
  )
}

export default DropzoneArea;
