import React, { useState, useEffect } from "react";
import FormInput from "../../../Components/FormInput/FormInput";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import warning from "../../../assets/warning.png";
import { loginApi, ResetForgottenPassword } from "../../../Utils/api";
import { ScaleLoader } from "react-spinners";
import "./verifyPassword.css";
import AuthAPI from "../../../Utils/AuthAPI";
import Logo from "../../../assets/logo1.png";
import login_left_img from "../../../assets/login_left_img.png";
import { ChevronRight } from "react-feather";
import { toast, ToastContainer } from "react-toastify";

const VerifyPassword = ({
  loginState,
  setLoginState,
  setUserInfo,
  userInfo,
}) => {
  const [searchParam] = useSearchParams();
  // const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassError, setConfirmPassError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disable, setDisable] = useState(false);
  const [offError, setOFFError] = useState("");

  console.log(newPassword);
  console.log(confirmPassword);
  console.log(offError);
  console.log(passwordError);

  const navigate = useNavigate();

  const handleClickReset = async () => {
    setIsLoading(true);
    if (newPassword === "" || confirmPassword === "") {
      setOFFError("Please fill all the Input");
      return;
    }

    if (newPassword.password != confirmPassword.password2) {
      console.log("Not equal");
      setConfirmPassError("Password do not match");
      return;
    }

    ResetForgottenPassword({ password: newPassword, token: searchParam.get("token") }).then((result) => {
      toast.success("Password Reset Success!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        navigate("/");
      }, 3000)
      setIsLoading(false);
    }).catch((err) => {
      toast.error("Could not process this request!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsLoading(false);
    })
  };

  return (
    <div>
      <ToastContainer />
      <div className="mainLayout">
        <div className="layout1">
          <div className="leftLayout">
            <img src={Logo} alt="logo" />
            <p className="regular text">
              Get one stop solution for all
              <br />
              your <span>Digital Marketing</span> needs
            </p>
          </div>
          <div>
            <img src={login_left_img} alt="login" className="img3" />
          </div>
        </div>
        <div className="layout2">
          <section className="mainBlock">
            <div className="boldText">
              <h3>Set your new Password</h3>

              {offError.length !== 0 && (
                <p className="errorcase">
                  {offError}
                  <img src={warning} alt="error" />
                </p>
              )}
              <div className="field">
                <p className="regular">New Password</p>
                <FormInput
                  inputType="password"
                  name="password"
                  setState={setNewPassword}
                  setError={setPasswordError}
                />
              </div>
              {passwordError.length !== 0 && (
                <p className="errorcase">
                  {passwordError} <img src={warning} alt="error" />
                </p>
              )}
              <div className="field">
                <p className="regular">Confirm Password</p>
                <FormInput
                  inputType="password"
                  name="password2"
                  setState={setConfirmPassword}
                  setError={setPasswordError}
                />
              </div>
              {confirmPassError.length !== 0 && (
                <p className="errorcase">
                  {confirmPassError} <img src={warning} alt="error" />
                </p>
              )}
              <button
                type="submit"
                className="login-btn"
                onClick={handleClickReset}
              >
                <div className="loginBtn-Container">
                  <p className="logintxt">
                    RESET PASSWORD &nbsp;&nbsp;&nbsp;
                    <ChevronRight className="arrow" />
                  </p>
                  <ScaleLoader
                    style={{ paddingRight: "20px" }}
                    width={2}
                    height={20}
                    size={20}
                    color="#ffffff"
                    loading={isLoading}
                  />
                  {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                </div>
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default VerifyPassword;
