import React, { useEffect } from 'react';
import'./SubcriptionNotification.css';
import alert from '../../assets/alert.png';
import { json, useNavigate } from 'react-router';

function SubcriptionNotification({subscriptionType,renewalDaysLeft}) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/upgrade')
    }

const [daysLeft, setDaysLeft] = React.useState(0);



    // const getRemaningDays = () => {
    //     const today = new Date();
    //     const expiryDate = new Date( Date.parse(JSON.parse(localStorage.getItem('userProfileInfo')).createdAt) + 604800000);
    //     const diffTime =   expiryDate - today;
    //     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    //     console.log('iyueap gwifcgwexigwquig-================================================9213907444444444449877777777777777777777777777777777777777')
    //     console.log(diffDays)
    //     setDaysLeft(diffDays)
    //     return diffDays;
    // }


    const expiryDate = JSON.parse(localStorage.getItem('userProfileInfo')).planExpiry;

    const RemaningDays=() =>{
        const currentDateInSeconds = Math.floor(Date.now() / 1000);
        const remainingSeconds = expiryDate - currentDateInSeconds;
        const days = Math.floor(remainingSeconds / (24 * 60 * 60));
// let days = Math.floor(( expiryDate - Date.now()) / (24*60*60*1000))
        
setDaysLeft(days)

if (days <= 0) {
    navigate('/upgrade')
}

    }
    

    const checkExpired = () => {
       if (daysLeft() <= 0) {
              return true

    } else{
        return false
    }}


    useEffect(() => {
        RemaningDays()
                                                                             
    }, [window.location.pathname])
 
  const subcription = JSON.parse(localStorage.getItem('userProfileInfo')).subscription;

    return (
    daysLeft > 5 || subcription === 'free '  &&    <div className={`paymentReminderBlock ${subcription === 'free' ? 'freeReminderColor' : 'paidReminderColor' } `}>
            <img src={alert} alt="alert" />
            <div className='alertText'>
                   
                {
                    subcription === 'free' && daysLeft > 0 ? 
                    <p> Your free trial will expire in {daysLeft} days. Renew now to continue enjoying all the benefits of our paid plan</p> : null
                }
                {
                    subcription === 'free' && daysLeft <= 0 ?
                    <p> Your free trial has expired. Renew now to continue enjoying all the benefits of our paid plan</p> : null


                }

                {
                    subcription  !== 'free' && daysLeft <= 0 ?
                    <p> Your subscription has expired. Renew now to continue enjoying all the benefits of our paid plan</p> : null
                }





            </div>
            <button onClick={handleClick}>{subcription === 'free' ? 'UPGRADE':'RENEW NOW'}</button>
            
        </div>
    );
}

export default SubcriptionNotification;