import React from 'react';
import { useState } from 'react';
import { Card, CardHeader, CardBody, Table, } from 'reactstrap';
import DataVisualizer from './TreeStructure';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';


const BacklinksDataComponent = ({ backlinksData }) => {

    const [currentPage, setCurrentPage] = useState(0);
  
    const pageSize = 10; // Change this to the number of items you want per page
    const pagesToShow = 5; // Change this to the number of page links you want to show at a time

    const pagesCount = Math?.ceil(backlinksData?.backlinks?.length / pageSize);
    const startPage = Math?.max(currentPage - Math?.floor(pagesToShow / 2), 0);
    const endPage = Math?.min(startPage + pagesToShow, pagesCount);
  
    const handleClick = (e, index) => {
      e.preventDefault();
      setCurrentPage(index);
    }
    
  return (
    <div>
      <Card>
       
        <CardBody>
        
 <DataVisualizer data={backlinksData?.counts} /> 

        </CardBody>
      </Card>
      <Card style={{marginTop: '30px'}}>
       
        <CardBody>
          <Table responsive striped className='tblcustom'>
            <thead>
              <tr>
                <th>Source URL</th>
                <th>Target URL</th>
                <th>Title</th>
                <th>Anchor</th>
                <th>Nofollow</th>
                <th>First Seen</th>
                <th>Last Visited</th>
              </tr>
            </thead>
            <tbody>
            {backlinksData.backlinks
            ?.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
            ?.map((link, index) => (
                <tr style={{textDecoration:'none'}} key={index}>
                  <td style={{textDecoration:'none'}}><a href={link?.url_from} target="_blank" rel="noopener noreferrer">{link?.url_from}</a></td>
                  <td><a href={link?.url_to} target="_blank" rel="noopener noreferrer">{link?.url_to}</a></td>
                  <td>{link?.title}</td>
                  <td>{link?.anchor}</td>
                  <td>{link?.nofollow ? 'Yes' : 'No'}</td>
                  <td>{link?.first_seen}</td>
                  <td>{link?.last_visited}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination className="custom-pagination">
        <PaginationItem disabled={currentPage <= 0}>
          <PaginationLink onClick={e => handleClick(e, currentPage - 1)} previous href="#" />
        </PaginationItem>

        {[...Array(endPage - startPage)].map((page, i) => 
          <PaginationItem active={i === currentPage - startPage} key={i}>
            <PaginationLink onClick={e => handleClick(e, i + startPage)} href="#">
              {i + startPage + 1}
            </PaginationLink>
          </PaginationItem>
        )}

        <PaginationItem disabled={currentPage >= pagesCount - 1}>
          <PaginationLink onClick={e => handleClick(e, currentPage + 1)} next href="#" />
        </PaginationItem>
      </Pagination>
        </CardBody>
      </Card>
    </div>
  );
};

export default BacklinksDataComponent;