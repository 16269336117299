
const ApexAreaChartsFacebookPage = (props) => {
  

  return (

    <div className='mediaKPI cardSpace'>
      <p>{props.metric}</p>
      <p className='kpiValue'>
       
        {props.analyticsData}
      </p>
    </div>
  )
}
export default ApexAreaChartsFacebookPage
