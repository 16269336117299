export const GetCurrentDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;

    return today
}



export const GetDateDiffofPaid = (dateInital) => {
    var diff = '';
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;

    var initalDate = new Date(parseInt(dateInital) * 1000);
    var Initaldd = String(initalDate.getDate()).padStart(2, '0');
    var Initalmm = String(initalDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var Initalyyyy = initalDate.getFullYear();

    initalDate = Initalyyyy + '-' + Initalmm + '-' + Initaldd;

    console.log(initalDate, 'new Date', today);

    diff = (new Date(today) - new Date(initalDate)) / (1000 * 3600 * 24)

    return diff;
}


export const GetDateDiffofFree = (dateInital) => {
    var diff = '';
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;

    var initalDate = new Date(parseInt(dateInital));
    var Initaldd = String(initalDate.getDate()).padStart(2, '0');
    var Initalmm = String(initalDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var Initalyyyy = initalDate.getFullYear();

    initalDate = Initalyyyy + '-' + Initalmm + '-' + Initaldd;

    console.log(initalDate, 'new Date', today);

    diff = (new Date(today) - new Date(initalDate)) / (1000 * 3600 * 24)

    return diff;
}


export const GetConvertDateFromUnix = (dateInital) => {
    var convertedDate = '';

    var initalDate = new Date(parseInt(dateInital) * 1000);
    var Initaldd = String(initalDate.getDate()).padStart(2, '0');
    var Initalmm = String(initalDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var Initalyyyy = initalDate.getFullYear();

    convertedDate = Initaldd + '-' + Initalmm + '-' + Initalyyyy;


    return convertedDate;
}



export const getSubcribstionEndDate = (plan, latestPayDate) => {
    var subEndDate = '';


    var initalDate = new Date(latestPayDate * 1000);
    var Initaldd = String(initalDate.getDate()).padStart(2, '0');
    var Initalmm = String(initalDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var Initalyyyy = initalDate.getFullYear();



    if (plan === 'Beginner') {

        Initalmm = parseInt(Initalmm);
        Initalmm = Initalmm + 1
        Initalmm = Initalmm.toString();

    } else if (plan === 'Intermediate') {

        Initalmm = parseInt(Initalmm);
        Initalmm = Initalmm + 3
        Initalmm = Initalmm.toString();


    } else if (plan === 'Advanced') {

        Initalyyyy = parseInt(Initalyyyy);
        console.log(Initalyyyy);
        Initalyyyy = Initalyyyy + 1;
        Initalyyyy = Initalyyyy.toString();




    }


    subEndDate = Initaldd + '-' + Initalmm + '-' + Initalyyyy;





    return subEndDate;
}