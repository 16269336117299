import React, { useEffect, useState } from "react";
import "./Admindashboard.css";
import Logout from "../../assets/logoutIcon.png";
import { getAllUserApi, updateUser } from "../../Utils/api";
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [nameQuery, setNameQuery] = useState("");
  const [emailQuery, setEmailQuery] = useState("");

  const ActionHandleSuspend = async (e) => {
    setSelectedUser(userData[e.target.id]);
    await updateUser(userData[e.target.id]._id, { status: "suspended" });
  };

  const ActionHandleActive = async (e) => {
    setSelectedUser(userData[e.target.id]);
    await updateUser(userData[e.target.id]._id, { status: "active" });
  };

  const fetchUsersToDisplay = async () => {
    try {
      const res = await getAllUserApi();
      console.log("All Users", res.data);
      setUserData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const filteredUsers = userData?.filter((user) => {
    try {
      const nameMatch = user?.fName
        .toLowerCase()
        .includes(nameQuery?.toLowerCase());
      const emailMatch = user?.email
        .toLowerCase()
        .includes(emailQuery?.toLowerCase());
      return nameMatch && emailMatch;
    } catch (err) {
      return "" && "";
    }
  });
  console.log("filtered ", filteredUsers);
  useEffect(() => {
    fetchUsersToDisplay();
    setSelectedUser(null);
  }, [selectedUser]);

  return (
    <div className="userOuterLayout">
      <div className="alluserMainsection">
        <div className="headSection regular">
          <span>All Users</span>

          <img
            src={Logout}
            alt="logout"
            onClick={() => {
              localStorage.clear();
              sessionStorage.clear();
              return navigate("/admin-login");
            }}
          />
        </div>

        <div className="searchSection">
          <input
            type="text"
            placeholder="Search by name"
            value={nameQuery}
            onChange={(e) => setNameQuery(e.target.value)}
          />
          <input
            type="text"
            placeholder="Search by email"
            value={emailQuery}
            onChange={(e) => setEmailQuery(e.target.value)}
          />
        </div>
        <div className="dataSection">
          <table>
            <tr
              style={{
                backgroundColor: "#cfe0f1",
                padding: "40px",
              }}
            >
              <th>S.No.</th>
              <th>Name</th>
              <th>Email Id</th>
              <th>Status</th>
              <th>Subscription</th>
              <th>Recent Activity On</th>
              <th>Action</th>
            </tr>
            {nameQuery || emailQuery
              ? filteredUsers.map((user, idx) => {
                  return (
                    <>
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{user.fName}</td>
                        <td>{user.email}</td>
                        <td>
                          <div
                            className={
                              user.status === "suspended"
                                ? "suspended"
                                : user.status === "active"
                                ? "active"
                                : "inactive"
                            }
                          >
                            {user.status?.toUpperCase()}
                          </div>
                        </td>
                        <td>
                          <div
                            className={
                              user.subscription === "free" ? "free" : "beginner"
                            }
                          >
                            {user.subscription?.toUpperCase()}
                          </div>
                        </td>
                        <td>
                          {user.lastUpdated ||
                            new Date(user.createdAt).toLocaleString()}
                        </td>
                        <td>
                          {/* {
                        user.status === "active" || user.status === "inactive" ? <button
                          id={idx}
                          className="suspend"
                          onClick={ActionHandle}
                        >
                          {"suspend".toUpperCase()}
                        </button>
                          :
                          <button
                            id={idx}
                            className="activate"
                            onClick={ActionHandle}
                          >
                            {"active".toUpperCase()}
                          </button>
                      } */}
                          <button
                            id={idx}
                            className={
                              user.status === "active" ||
                              user.status === "inactive"
                                ? "suspend"
                                : "activate"
                            }
                            onClick={
                              user.status === "active" ||
                              user.status === "inactive"
                                ? ActionHandleSuspend
                                : ActionHandleActive
                            }
                          >
                            {user.status === "active" ||
                            user.status === "inactive"
                              ? "suspend"?.toUpperCase()
                              : "activate"?.toUpperCase()}
                          </button>
                        </td>
                      </tr>
                    </>
                  );
                })
              : userData.map((user, idx) => {
                  return (
                    <>
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{user.fName}</td>
                        <td>{user.email}</td>
                        <td>
                          <div
                            className={
                              user.status === "suspended"
                                ? "suspended"
                                : user.status === "active"
                                ? "active"
                                : "inactive"
                            }
                          >
                            {user.status?.toUpperCase()}
                          </div>
                        </td>
                        <td>
                          <div
                            className={
                              user.subscription === "free" ? "free" : "beginner"
                            }
                          >
                            {user.subscription?.toUpperCase()}
                          </div>
                        </td>
                        <td>
                          {user.lastUpdated ||
                            new Date(user.createdAt).toLocaleString()}
                        </td>
                        <td>
                          {/* {
                        user.status === "active" || user.status === "inactive" ? <button
                          id={idx}
                          className="suspend"
                          onClick={ActionHandle}
                        >
                          {"suspend".toUpperCase()}
                        </button>
                          :
                          <button
                            id={idx}
                            className="activate"
                            onClick={ActionHandle}
                          >
                            {"active".toUpperCase()}
                          </button>
                      } */}
                          <button
                            id={idx}
                            className={
                              user.status === "active" ||
                              user.status === "inactive"
                                ? "suspend"
                                : "activate"
                            }
                            onClick={
                              user.status === "active" ||
                              user.status === "inactive"
                                ? ActionHandleSuspend
                                : ActionHandleActive
                            }
                          >
                            {user.status === "active" ||
                            user.status === "inactive"
                              ? "suspend"?.toUpperCase()
                              : "activate"?.toUpperCase()}
                          </button>
                        </td>
                      </tr>
                    </>
                  );
                })}

            {/* <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr> */}
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
