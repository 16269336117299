import { useState, useEffect } from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import "./csvindex.css";
import {
    TabContent, TabPane, Nav,
    NavItem, NavLink, Row, Col
} from 'reactstrap';
import classnames from 'classnames';
import axios from "axios";
import { Card, CardBody } from "reactstrap";
import {BsFilter} from "react-icons/bs"

import DataTable from 'react-data-table-component';




export default function CSVindex() {
    const [currentActiveTab, setCurrentActiveTab] = useState('1');
    

const [data, setData] = useState([])
const [dataToDisplay, setDataToDisplay] = useState([])
const tabDataObj ={
    1: 'backlinks',
    2: 'articles',
    3: 'directories'
}

    useEffect(() => {
     
    axios.post(process.env.REACT_APP_SERVER_URI + '/api/crud/getbacklinksdata', { type:tabDataObj?.[currentActiveTab] },{
        headers: {
          token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
          userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
        }
      }).then(res =>{
        setData(res.data.data)
        setDataToDisplay(res.data.data)
        return null

        
    })}, [currentActiveTab])
  
    // Toggle active state for Tab
    const toggle = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    }








    return(
        <div>
              <Card className="calenderModuleCard">
        <CardBody className="calendarBody">
          {/*Drop down start*/}
          <div className="calender_top">
            <div className="margin-left"></div>
            <div className="calender_info">
              <h5>Organic SEO Activities</h5>
            </div>
            </div>
        <div class="orgseo_tabs" >

            <Nav tabs className="tabs_orgseo">
                <NavItem>
                    <NavLink
                        className={classnames({
                            active:
                                currentActiveTab === '1'
                        })}
                        onClick={() => { toggle('1'); }}
                    >
                      Backlinks
                    </NavLink>
                </NavItem>                                                                                                                                  
                <NavItem>
                    <NavLink
                        className={classnames({
                            active:
                                currentActiveTab === '2'
                        })}
                        onClick={() => { toggle('2'); }}
                    >
                        Articles
                    </NavLink>
                </NavItem>
                <NavItem >
                    <NavLink
                        className={classnames({
                            active:
                                currentActiveTab === '3'
                        })}
                        onClick={() => { toggle('3'); }}
                    >
                        Directories
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={currentActiveTab}>
                <TabPane tabId="1">
                    <Row>
                        <Col sm="12">
                        <h5 classNames="text_left_"  style={{padding: '24px 0', color: '#e68c19', fontWeight:'400'}}>List of best Backlinks websites</h5>

                        <DataTable
            columns={[
              {
                name: 'serial',
                selector: row => row.Serial,
                sortable: true,
            },
            {
                name: 'Domain',
                selector: row => row.Domain,
                sortable: true,
            },
            {
               name: 'DA',
               selector: row => row.DA,
               sortable: true,
            },{
              name: 'SS',
              selector: row => row.SS,
              sortable: true,
            },
            ]}
            data={data}
            direction="auto"
            fixedHeaderScrollHeight="300px"
            pagination
            responsive
            subHeaderAlign="right"
            subHeaderWrap
        />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                <h5  classNames="text_left_"  style={{padding: '24px 0', color: '#e68c19', fontWeight:'400'}}>List of best Articles websites</h5>

                    <Row>
                        <Col sm="12">

                        <DataTable
            columns={[
              {
                name: 'serial',
                selector: row => row.Serial,
                sortable: true,
            },
            {
                name: 'Domain',
                selector: row => row.Domain,
                sortable: true,
            },
            {
               name: 'DA',
               selector: row => row.DA,
               sortable: true,
            },{
              name: 'SS',
              selector: row => row.SS,
              sortable: true,
            },
            ]}
            data={data}
            direction="auto"
            fixedHeaderScrollHeight="300px"
            pagination
            responsive
            subHeaderAlign="right"
            subHeaderWrap
        />

                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="3">
                    <Row>
                        <Col sm="12 ">


                            <h5 classNames="text_left_" style={{padding: '24px 0', color: '#e68c19', fontWeight:'400'}}>List of best  Directories websites</h5>
{/* <input type="text" placeholder="Search" onChange={(e)=>setquery(e.target.value)} style={{width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', marginBottom: '10px'}} /> */}



<DataTable
            columns={[
              {
                name: 'serial',
                selector: row => row.Serial,
                sortable: true,
            },
            {
                name: 'Domain',
                selector: row => row.Domain,
                sortable: true,
            },
            {
               name: 'DA',
               selector: row => row.DA,
               sortable: true,
            },{
              name: 'SS',
              selector: row => row.SS,
              sortable: true,
            },
            ]}
            data={data}
            direction="auto"
            fixedHeaderScrollHeight="300px"
            pagination
            responsive
            subHeaderAlign="right"
            subHeaderWrap
        />


                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
        </div >
        </CardBody>
        </Card>
            </div>
    )
}