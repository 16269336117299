import React from 'react'
import { Spinner } from 'reactstrap'
import './Spinner.css'

const SpinnerReact = () => {
  return (
    <div className='container spinnerContainer'>
        <Spinner className='loader'/>
    </div>
  )
}

export default SpinnerReact