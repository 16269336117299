import React, { Component, useEffect, useState } from 'react'
import axios from 'axios'

import './Pixabay.css'



export default function Pixabay({ setGallery, setPaths, selected, paths }) {

  const [search, setSearch] = useState("hello")

  const [res, setRes] = useState([]);


  useEffect(() => {
    axios.get(`https://pixabay.com/api/?key=28928939-3f41b7fc45917590d3a5077c9&per_page=200&q=${search}`)
      .then(res => {
        setRes(res.data.hits)

      })
      .catch(err => {
        console.log(err)
      })

  }, [search]);


  //   useEffect(() => {
  //     axios.get(`https://api.giphy.com/v1/gifs/search?api_key=0revKRQ9pSSOL1fWayhA1U4FRT2EA4xk&q=${search}&limit=10&offset=0&rating=g&lang=en`)
  //     .then(res=>{
  //       setRes(res.data.data)
  // console.log(res.data.data)
  //     })
  //     .catch(err=>{
  //       console.log(err)
  //     })

  //   }, [search]);



  useEffect(() => {
    console.log(search)
  }, [search]);

  const [linkedINImages, setLinkedINImages] = useState()



  return (
    <>
      <div className='pixabayContainer'>
        <span style={{ fontSize: '25px', fontWeight: '700', margin: '30px', marginTop: '20px', marginRight: '30px' }}>Pixabay Gallery</span><span style={{ float: 'right' }} onClick={() => { setGallery(false) }}><button type="button" className='btttt' height="44" width="auto" style={{marginRight: '25px', borderRadius: '25px'}}><svg width="16" height="16" viewBox="0 0 20 20" fill="currentColor"><path d="M18.37,1.62 C17.87,1.12 17.12,1.12 16.62,1.62 L10,8.25 L3.37,1.62 C2.87,1.12 2.12,1.12 1.62,1.62 C1.12,2.12 1.12,2.87 1.62,3.37 L8.25,10 L1.62,16.62 C1.12,17.12 1.12,17.87 1.62,18.37 C1.87,18.62 2.12,18.75 2.5,18.75 C2.87,18.75 3.12,18.62 3.37,18.37 L10,11.75 L16.62,18.37 C16.87,18.62 17.25,18.75 17.5,18.75 C17.75,18.75 18.12,18.62 18.37,18.37 C18.87,17.87 18.87,17.12 18.37,16.62 L11.75,10 L18.37,3.37 C18.87,2.87 18.87,2.12 18.37,1.62 Z"></path></svg></button></span>

        <div className='search'>
          <input onChange={e => { setSearch(e.target.value); console.log(search) }} type="search" placeholder="Search..." value={search} />
        </div>
        <div className='ImageGallery'>


          {res.map(image => (
            <img src={image.previewURL} onClick={async() => {
    
    
              paths.FileArr = [...paths.FileArr, image.largeImageURL]
    
    //           await   selected.forEach(async (item) => {
    //             if (item.value.account_type === 'linkedin') {
    //           await  axios.post(process.env.REACT_APP_SERVER_URI + '/api/crud/uploadLinkedInFromURL',{
    //                 url: { imgurl: image.largeImageURL},
    //                 id: item.value.id,
    //                 token: item.value.token,
    //                 jwToken: localStorage.getItem('token')
    // }).then(res => {
    //   console.log(res)
    //   // setLinkedINImages(res.data.imgurl)
    //   setPaths({
    //     facebook: image.largeImageURL,
    //       twitter: '',
    //       instagram: image.largeImageURL,
    //       pinterest: image.largeImageURL,
    //       linkedin: res.data.imgurl,
    //     youtube: '',
    //   })
    // }).catch(err => {
    //   console.log(err)
    // })
    //             }else{
    //               setPaths({
    //                 facebook: image.largeImageURL,
    //                   twitter: '',
    //                   instagram: image.largeImageURL,
    //                   pinterest: image.largeImageURL,
    //                   linkedin: '',
    //                 youtube: '',
    //               })
    //             }
              
              
              
    //           })
                                                                                                                                                    
         
              
              
              }} 
              
           
            
            
            
            alt='' />
          ))}


          {/* {res.map(image =>(
// {console.log(image)},
            <img src={image.url} onClick={()=>{ setPaths(image.images.downsized.url)}} alt='' />
          ))} */}

        </div>
      </div>

    </>
  )
}
