import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const ReactionChart = ({ data }) => {
  return (
    <LineChart width={600} height={400} data={data} margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
      {/* <CartesianGrid strokeDasharray="" aria-hidden={true} /> */}
      <XAxis dataKey="dateData" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="likeData" name="Total Likes👍" stroke="#8884d8" dot={''} strokeWidth={3} activeDot={{ r: 18 }} width={3}/>
        <Line type="monotone" dataKey="loveData" name="Total Loves🥰" stroke="#82ca9d" dot={''}  strokeWidth={3}/>
        <Line type="monotone" dataKey="hahaData" name="Total Hahas😆" stroke="#ffc658" dot={''} strokeWidth={3} />
        <Line type="monotone" dataKey="wowData" name="Total Wows😲" stroke="#00ff00"dot={''} strokeWidth={3} />
        <Line type="monotone" dataKey="sadData" name="Total Sads😔" stroke="#0000ff" dot={''} strokeWidth={3} />
        <Line type="monotone" dataKey="angryData" name="Total Angrys😡" stroke="#ff0000" dot={''} strokeWidth={3} />

    </LineChart>
  );
};

export default ReactionChart;
