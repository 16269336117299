import axios from "axios";


export const  InstaGramUsersSearch = async (searchText) => {
    const options = {
        method: 'GET',
        url: 'https://instagram-data1.p.rapidapi.com/user/search',
        params: {keyword: searchText},
        headers: {
          'X-RapidAPI-Key': '73d81628c0msh54f30c07479fde0p141da4jsnf5e9e44d9b0a',
          'X-RapidAPI-Host': 'instagram-data1.p.rapidapi.com'
        }
      };
      
      try {
          const response = await axios.request(options);
          console.log("search ----------------------------------------->>>>> ",response);
          let refinedResponse = response.data.users.map((user) => {
            console.log("user",user);
            return {
            label: user.user.username,
             value: {
              id: user.user.pk,
              username: user.user.username,
              fullName: user.user.full_name,
              profilePic: user.user.profile_pic_url
             }
            }
          });
          console.log("refinedsearch",refinedResponse);

          return  refinedResponse;

      } catch (error) {
          console.error(error);
          return [];
      }
}