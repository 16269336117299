import React, { useEffect } from "react";
import { useState } from "react";
import CustomDropDown from "../../Components/CustomDropDown/CustomDropDown";
import "./streamScreen.css";
import refreshIcon from "../../assets/refreshIcon.png";
import addSocialMediaAccounts from "../../assets/add_new_social.png";
import addNewVendors from "../../assets/addNewVendor.png"; 
import manageVendors from "../../assets/icon_manage_vendors.png";
import dropDownArrow from "../../assets/dropDownArrow.png";
import streamFilterInstaIcon from "../../assets/streamFilterInstaIcon.png";
import streamFilterFaceBookIcon from "../../assets/icons8-facebook-64.png";
import MediaPostCard from "../../Components/MediaPostCard/MediaPostCard";
import Facebookstreams from "./Facebook/Facebook";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import Instagramstreams from "./Instagram/Instagramstreams";
import TwitterStreams from "./Twitter/TwitterStream";
import YoutubeStream from "./Youtube/Youtube";
import Pinintrest from "./Pinintrest/Pinintrest";
import { updateActivity } from "../../Utils/api";
import { useDispatch, useSelector } from "react-redux";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import {  IoNotifications, IoCloseCircleOutline } from "react-icons/io5";


import CreateNewPost from "../Dashboard/CreateNewPost";
import CreatePin from "../Dashboard/CreatePin";
import CreateBoard from "../Dashboard/CreateBoard";
import { Link } from "react-router-dom";
import Drawer from "react-modern-drawer";
import { Button, Modal, Spinner } from "reactstrap";
import Select, { StylesConfig } from "react-select";
import { useNavigate } from "react-router-dom";
import arrow_Left from '../../assets/arrow_left.png'
import arrow_Right from '../../assets/arrow_right.png'

import { IconButton } from '@material-ui/core';

//import styles 👇
import "react-modern-drawer/dist/index.css";
import { Input, Label, Spinner as ReactstrapSpinner } from "reactstrap";
import StreamCard from "./Streamcard/Stream";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import { set } from "react-ga";
import { Chip, TextField } from "material-ui";
import Notification from "../../Components/Notification/Notification";


const socials = {
  facebook: {
    name: "Facebook",
    icon: `
     M20 10.0001C19.9997 8.08916 19.4518 6.21827 18.4213 4.60898C17.3908 2.99968 15.9207 1.71936 14.1851 0.919594C12.4495 0.119828 10.5211 -0.165895 8.62821 0.0962525C6.73529 0.3584 4.95712 1.15744 3.50421 2.39877C2.0513 3.64011 0.984494 5.27176 0.430087 7.10055C-0.124321 8.92935 -0.143113 10.8787 0.375935 12.7178C0.894983 14.557 1.93014 16.2089 3.35884 17.478C4.78755 18.7471 6.54999 19.5803 8.4375 19.8789V12.8908H5.89875V10.0001H8.4375V7.79702C8.4375 5.29077 9.93062 3.90639 12.2144 3.90639C12.9644 3.91679 13.7126 3.98197 14.4531 4.10139V6.56264H13.1919C12.9769 6.5341 12.7584 6.55428 12.5523 6.6217C12.3462 6.68912 12.158 6.80205 12.0015 6.95211C11.845 7.10218 11.7243 7.28553 11.6483 7.48859C11.5723 7.69165 11.543 7.90921 11.5625 8.12514V10.0001H14.3362L13.8925 12.8908H11.5625V19.8789C13.9149 19.5068 16.0572 18.3073 17.604 16.4963C19.1507 14.6853 20.0004 12.3818 20 10.0001V10.0001Z
   `,
  
    buttonBottom: {
      like: `https://i.hootsuite.com/assets/plancreate/fb-like.svg`,
      share: `https://i.hootsuite.com/assets/plancreate/fb-share.svg`,
      comment: `https://i.hootsuite.com/assets/plancreate/fb-comment.svg`,
    },
  },
  instagram: {
    name: "Instagram",
    icon: `M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z
     `,


  },
  twitter: {
    name: "Twitter",
    icon: `
     M16.134 6.188C15.806 6.699 15.404 7.133 14.934 7.492C14.943 7.566 14.943 7.676 14.943 7.82C14.9401 9.22326 14.5464 10.598 13.806 11.79C13.4322 12.4001 12.9733 12.9537 12.443 13.434C11.8821 13.9286 11.2346 14.3152 10.533 14.574C9.76895 14.8618 8.95839 15.0062 8.14197 15C6.83072 15.008 5.54876 14.6124 4.46997 13.867C4.64197 13.887 4.83297 13.898 5.04397 13.898C6.13107 13.9074 7.18515 13.5247 8.01297 12.82C7.50642 12.8116 7.01719 12.6342 6.62297 12.316C6.21843 11.999 5.9233 11.5633 5.77897 11.07C5.94297 11.098 6.09097 11.11 6.23197 11.11C6.44197 11.11 6.65397 11.082 6.86097 11.023C6.31332 10.9068 5.82547 10.5981 5.48597 10.153C5.12478 9.69652 4.9329 9.129 4.94297 8.547V8.516C5.27096 8.71203 5.64312 8.8221 6.02497 8.836C5.69559 8.60494 5.42835 8.29614 5.24697 7.937C5.05272 7.56604 4.95343 7.15272 4.95797 6.734C4.95797 6.277 5.06797 5.852 5.28197 5.461C5.8689 6.229 6.61188 6.86394 7.46197 7.324C8.30816 7.78759 9.24833 8.05358 10.212 8.102C10.174 7.91133 10.1545 7.71742 10.154 7.523C10.154 6.824 10.388 6.23 10.853 5.738C11.322 5.246 11.884 5 12.544 5C13.236 5 13.818 5.266 14.29 5.797C14.8295 5.68765 15.3445 5.48094 15.81 5.187C15.6435 5.76458 15.2691 6.26008 14.759 6.578C15.216 6.528 15.677 6.395 16.134 6.188Z"
       `,


  },
  linkedin: {
    name: "Linkedin",
    icon: `  
   M11 19H12C12.5523 19 13 18.5523 13 18V13.5C13 12 16 11 16 13V18.0004C16 18.5527 16.4477 19 17 19H18C18.5523 19 19 18.5523 19 18V12C19 10 17.5 9 15.5 9C13.5 9 13 10.5 13 10.5V10C13 9.44771 12.5523 9 12 9H11C10.4477 9 10 9.44772 10 10V18C10 18.5523 10.4477 19 11 19Z
  `,
  },
  // medium: {
  //   name: "Medium",
  //   icon: `M2.846 6.887c.03-.295-.083-.586-.303-.784l-2.24-2.7v-.403h6.958l5.378 11.795 4.728-11.795h6.633v.403l-1.916 1.837c-.165.126-.247.333-.213.538v13.498c-.034.204.048.411.213.537l1.871 1.837v.403h-9.412v-.403l1.939-1.882c.19-.19.19-.246.19-.537v-10.91l-5.389 13.688h-.728l-6.275-13.688v9.174c-.052.385.076.774.347 1.052l2.521 3.058v.404h-7.148v-.404l2.521-3.058c.27-.279.39-.67.325-1.052v-10.608z`,
  //   buttonBottom: {
  //     like: `https://i.hootsuite.com/assets/plancreate/fb-like.svg`,
  //     share: `https://i.hootsuite.com/assets/plancreate/fb-share.svg`,
  //     comment: `https://i.hootsuite.com/assets/plancreate/fb-comment.svg`,
  //   },
  // },
  youtube: {
    name: "Youtube",
    icon: `M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z`,
   
  },

  pinterest: {
    name: "Pinterest",
    icon: `M0 12c0 5.123 3.211 9.497 7.73 11.218-.11-.937-.227-2.482.025-3.566.217-.932 1.401-5.938 1.401-5.938s-.357-.715-.357-1.774c0-1.66.962-2.9 2.161-2.9 1.02 0 1.512.765 1.512 1.682 0 1.025-.653 2.557-.99 3.978-.281 1.189.597 2.159 1.769 2.159 2.123 0 3.756-2.239 3.756-5.471 0-2.861-2.056-4.86-4.991-4.86-3.398 0-5.393 2.549-5.393 5.184 0 1.027.395 2.127.889 2.726a.36.36 0 0 1 .083.343c-.091.378-.293 1.189-.332 1.355-.053.218-.173.265-.4.159-1.492-.694-2.424-2.875-2.424-4.627 0-3.769 2.737-7.229 7.892-7.229 4.144 0 7.365 2.953 7.365 6.899 0 4.117-2.595 7.431-6.199 7.431-1.211 0-2.348-.63-2.738-1.373 0 0-.599 2.282-.744 2.84-.282 1.084-1.064 2.456-1.549 3.235C9.584 23.815 10.77 24 12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12`
  }
  ,
  googleAnalytics: {
    name: "Google Analytics",
    icon: `m 13,11.940296 c 0,0.58262 -0.47654,1.05916 -1.05916,1.05916 l -9.8805925,0 c -0.5826193,0 -1.0591595,-0.47654 -1.0591595,-1.05916 l 0,-9.880592 c 0,-0.58262 0.4765402,-1.05916 1.0591595,-1.05916 l 9.8805925,0 c 0.58262,0 1.05916,0.47654 1.05916,1.05916 l 0,9.880592 z M 13,4.770434 11.916361,4.040392 c 0.02176,-0.09357 0.03482,-0.190943 0.03482,-0.291582 0,-0.70393 -0.570651,-1.275126 -1.275125,-1.275126 -0.704475,0 -1.275126,0.570652 -1.275126,1.275126 0,0.276894 0.08921,0.532572 0.239358,0.74201 L 7.297299,8.073575 c -0.0952,-0.02285 -0.194751,-0.03645 -0.297022,-0.03645 -0.313885,0 -0.600571,0.113695 -0.823065,0.301917 L 4.6088671,7.436557 c 0.00816,-0.05766 0.0136,-0.115872 0.0136,-0.175711 0,-0.70393 -0.5706514,-1.275126 -1.2751258,-1.275126 -0.7044743,0 -1.2751258,0.570652 -1.2751258,1.275126 0,0.174623 0.03536,0.341085 0.099007,0.49286 L 1,8.840065 1,9.996056 2.7396981,8.382021 c 0.1806065,0.09792 0.3873249,0.153407 0.6070991,0.153407 0.3372773,0 0.6430029,-0.131647 0.8709371,-0.345982 L 5.747994,9.070175 C 5.732762,9.148515 5.724602,9.229022 5.724602,9.31171 c 0,0.70393 0.570652,1.274581 1.275126,1.274581 0.704474,0 1.275126,-0.570651 1.275126,-1.274581 0,-0.293214 -0.100095,-0.562492 -0.266558,-0.777914 l 2.326669,-3.557732 c 0.108799,0.02992 0.222495,0.04733 0.340542,0.04733 0.295389,0 0.566299,-0.101183 0.782809,-0.269822 l 1.54114,1.038488 0,-1.021624 z`
  }


};
function StreamScreen(props) {
  const dispatch = useDispatch();
  const [selectedForm, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const isCreateNewPostMenu = useSelector(
    (state) => state.createNewPost.showCreatePostMenu
  );

  const isCreateNewPinMenu = useSelector(
    (state) => state.createNewPin.showCreatePinMenu
  );

  const isCreateNewBoardMenu = useSelector(
    (state) => state.createNewBoard.showCreateBoardMenu
  );
  const [savedStreams, setsavedStreams] = useState([]);

  const [isActive, setIsActive] = useState(false);
  const [isActiveAccounts, setIsActiveAccounts] = useState(false);
  const [hashSearchActive, setHashSearchActive] = useState(false);
  const [selected, setIsSelected] = useState("All content");
  const [list, setList] = useState(["one", "two", "three"]);
  const [connectedAccount, setconnectedAccount] = useState([]);
  const [mediaPages, setmediaPages] = useState(["fb", "insta"]);
  const [allPages, setallpages] = useState();
  const [allNetworks, setallNetworks] = useState();
  const [searchStream, setsearchStream] = useState("");
  const [searchStreams, setsearchStreams] = useState([]);
  const [searchType, setsearchType] = useState("Select");
  const [SubOptionselected, setIsSubOptionSelected] = useState("All content");

  const [isNewVendorDrawerOpen, setIsNewVendorDrawerOpen] = useState(false);

  const [isManageVendorsDrawerOpen, setIsManageVendorsDrawerOpen] =
    useState(false);

  const handleNewVendorDrawer = () => {
    setIsNewVendorDrawerOpen(!isNewVendorDrawerOpen);
    setSelectedAccountsForVendor([]);
    setVendorNameToManage('');
    setSelectedVendorsKeyword([]);
    setVendorsURL('');

  };
  const handleManageVendorsDrawer = () => {
    setIsManageVendorsDrawerOpen(!isManageVendorsDrawerOpen);

    setSelectedVendorToManage('');
    setSelectedAccountsForVendorToManage([]);
    setVendorNameToManage('');

  };

  const [currentPage, setCurrentPage] = useState(0); // Current page/position of streams
  // const streamsPerPage = 3; // Number of streams to display per page

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
    console.log("prev", currentPage);
  };

  // Function to handle moving to the next page
  const goToNextPage = () => {
    const totalStreams = savedStreams.length;
    if (currentPage < Math.ceil(totalStreams / streamsPerPage) - 1) {
      setCurrentPage(currentPage + 1);
    }
    console.log("next", currentPage);
  };

  const [isCreateStreamDrawerOpen, setIsCreateStreamDrawerOpen] =
    useState(false);
  const [networkSelectedForStream, setNetworkSelectedForStream] =
    useState(null);
  const [accountSelectedForStream, setAccountSelectedForStream] =
    useState(null);
  const [streamTypeSelectedForStream, setStreamTypeSelectedForStream] =
    useState(null);

  const [visibleStreams, setVisibleStreams] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [streamsPerPage] = useState(4); // Adjust this based on your screen size
  const [allStreams] = useState(savedStreams); // Assuming savedStreams is your data source

  const [vendors, setVendors] = useState([]);
  const [vendorsURL, setVendorsURL] = useState("");
  const [metaKeywords, setMetaKeywords] = useState(null);

  const handleChangeNetwork = (e) => {
    setNetworkSelectedForStream(e.target.value);
    console.log(networkSelectedForStream);
  };

  const handleChangeAccount = (e) => {
    setAccountSelectedForStream(e.target.value);
  };

  const toggelDrawer = () => {
    setIsCreateStreamDrawerOpen(!isCreateStreamDrawerOpen);
    setNetworkSelectedForStream(null);
    setAccountSelectedForStream(null);
    setStreamTypeSelectedForStream(null);

  };

  const getVendorsURL = (event) => {
    setVendorsURL(event.target.value);
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      const data = {
        jwToken: localStorage.getItem("token"),
        updatedTime: new Date().toLocaleString(),
      };
      updateActivity(data);
    }
  }, []);
  const validateURL = (url) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(url);
  };

  const getVendorsKeywords = async () => {
    setLoader(true);
    if (validateURL(vendorsURL) == false) {
      toast.error("Please enter a valid URL");
      setLoader(false);
      return;
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URI}/api/crud/GetMetaKeywords`,
          { url: vendorsURL },{
            
 headers: {
  "Content-Type": "application/json",
   "token": JSON.parse(localStorage.getItem("userProfileInfo")).token,
},
          }
        );
        console.log("Response######******:", response.data);
        console.log("MEtakeywords%$$##:", metaKeywords);
        setMetaKeywords(response.data);
        // Handle the response data here
        // setLoading(false);
        setLoader(false);
      } catch (error) {
        console.error("Error fetching data##########@@@@@@:", error);
        setLoader(false);
        setMetaKeywords(null);
        // Handle the error here
        // setLoading(false);
      }
    }
  };

  const subOptions = {
    facebook: ["tagged", "posts", "scheduled_posts"],
    twitter: [
      // 'tagged', 'Home Timeline', 'scheduled' ,
      "tagged",
      "posts",
    ],
    instagram: ["posts"],
    youtube: ["posts"],
    pinterest: ["posts"],
  };

  //-----------Extra Streams------------------

  const getAllvendors = async () => {
    const vendors = await axios.post(
      `${process.env.REACT_APP_SERVER_URI}/api/vendors/getVendors`,
      {
        vendorUserId: JSON.parse(localStorage.getItem("userProfileInfo"))._id,
        jwToken: localStorage.getItem("token"),
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("token"),
        },
      }
    );
    console.log(vendors);


    setVendors(vendors.data);



    // handleManageVendorChange(vendors.data[0]._id);

  };

  useEffect(() => {
    setallpages(props.pagesDetails);
    let allNetworksn = props.pagesDetails.map((page) => page.account_type);

    // remove linkedin ,business, googleAnalytics from allNetworks

    let allNetworksFiltered = allNetworksn.filter(
      (item) => item !== "BUSINESS" 
    );
    setallNetworks(allNetworksFiltered.filter((v, i, a) => a.indexOf(v) === i));

    getAllvendors();



    console.log(allPages);
    pageInf();
  }, [props.pagesDetails]);

  const Network = {
    instagram: {
      icon: streamFilterInstaIcon,
      name: "FaceBook",
      postIDs: async function (pageID, token) {
        const postIds = await axios.get(
          `https://graph.facebook.com/v11.0/${pageID}/posts?pretty=0&limit=100&access_token=${token}&fields=id&limit=100`
        );
        return postIds.data.data;
      },
    },
    facebook: {
      icon: streamFilterInstaIcon,
      name: "Facebook",
      postIDs: async function (pageID, token) {
        const postIds = await axios.get(
          `https://graph.facebook.com/v11.0/${pageID}/posts?pretty=0&limit=100&access_token=${token}&fields=id&limit=100`
        );
        return postIds.data.data;
      },
    },
    twitter: {
      icon: streamFilterInstaIcon,
      name: "Twitter",
      postIDs: async function (id, token, secret) {
        let data = await axios.post(
          `${process.env.REACT_APP_SERVER_URI}/api/crud/getTwitterPosts`,
          {
            pageid: id,
            pageToken: token,
            verifier: secret,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              token: localStorage.getItem("token"),
            },
          }
        );

        let dataJson = await JSON.stringify(data.data);

        // console.log(dataJson)
        return dataJson;
      },

      postData: async function (id, token, secret) {
        let data = await axios.post(
          `${process.env.REACT_APP_SERVER_URI}/api/crud/getTwitterPostsById`,
          {
            tweetid: id,
            pageToken: token,
            verifier: secret,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              token: localStorage.getItem("token"),
            },
          }
        );

        let dataJson = await JSON.stringify(data.data);

        // console.log(dataJson)
        return dataJson;
      },
    },
    youtube: {
      icon: streamFilterInstaIcon,
      name: "Youtube",
    },
    BUSINESS: {
      icon: streamFilterInstaIcon,
      name: "Pinterest",
    },
  };

  const GetSavedStreams = async () => {
    const streams = await axios.get(
      `${process.env.REACT_APP_SERVER_URI}/api/crud/getStreamBoards`,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("token"),
        },
      }
    );
    console.log(streams.data);
    setsavedStreams(streams.data);
  };

  const createSearchStream = async () => {
    console.log("CLICKED!!!");
    if (searchStreams.length >= 0) {
      setsearchStreams([
        ...searchStreams,
        {
          type: searchType,
          pageId: props.pagesDetails.filter(
            (page) => page.account_type === searchType
          )[0].pageId,
          token: props.pagesDetails.filter(
            (page) => page.account_type === searchType
          )[0].token,
          secrate:
            searchType === "twitter"
              ? props.pagesDetails.filter(
                  (page) => page.account_type === searchType
                )[0].token_secrate
              : null,
          searchTerm: searchStream.replaceAll(" ", "_"),
          searchType: "hashtag",
        },
      ]);
    } else {
      setsearchStreams([
        {
          type: searchType,
          pageId: props.pagesDetails.filter(
            (page) => page.account_type === searchType
          )[0].pageId,
          token: props.pagesDetails.filter(
            (page) => page.account_type === searchType
          )[0].pageId,
          secrate:
            searchType === "twitter"
              ? props.pagesDetails.filter(
                  (page) => page.account_type === searchStream
                )[0].token_secrate
              : null,
          searchTerm: searchStream,
          searchType: "hashtag",
        },
      ]);
    }

    console.log(searchStreams);
  };

  const handleCreateNewStream = async () => {
    console.log("clicked");
    console.log(networkSelectedForStream, accountSelectedForStream);
    if (networkSelectedForStream && accountSelectedForStream) {
      const data = {
        pageId: accountSelectedForStream,
        NetworkName: networkSelectedForStream,
        StreamType: streamTypeSelectedForStream,
        AccountName: props.pagesDetails.filter(
          (page) => page.pageId === accountSelectedForStream
        )[0].name,
        userId: JSON.parse(localStorage.getItem("userProfileInfo"))._id,
        token: props.pagesDetails.filter(
          (page) => page.pageId === accountSelectedForStream
        )[0].token,
        secrate: props.pagesDetails.filter(
          (page) => page.pageId === accountSelectedForStream
        )[0].token_secrate,
      };
      console.log(data);
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URI}/api/crud/saveStreamBoard`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            token: localStorage.getItem("token"),
          },
        }
      );
      console.log(res.data);

      if(res.data.status === false){
        toast.error(`Stream already exists`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        return 0;
      }

      if (res.status === 200) {
        GetSavedStreams();
        toast.success(`stream Created`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setIsCreateStreamDrawerOpen(false);
        setAccountSelectedForStream(null);
        setNetworkSelectedForStream(null);
        setStreamTypeSelectedForStream(null);
      }
    }
  };

  const handleChangeStreamtype = (e) => {
    setStreamTypeSelectedForStream(e.target.value);
  };




  const [CreateVendorName, setCreateVendorName] = useState("");




  const handleCreateNewVendor = async () => {
    console.log(selectedAccountsForVendor);


    if (vendors.filter((vendor) => vendor.vendorName === CreateVendorName).length > 0) {
      toast.error('Vendor already exists')
      return
    }

    if(selectedVendorsKeyword == '' || selectedVendorsKeyword == null || selectedVendorsKeyword == undefined){
      toast.error('please select any keyword or enter custom keyword')
      return
    }
    if (selectedAccountsForVendor.length > 0) {
      const data = {
        vendorName: CreateVendorName,
        jwToken: localStorage.getItem("token"),
        vendorPages: selectedAccountsForVendor.map((item) => {
          return {
            name: item.label,
            pageId: item.value,
            account_type: props.pagesDetails.filter(
              (page) => page.pageId === item.value
            )[0].account_type,
          };
        }),
        vendorKeywords: selectedVendorsKeyword.map((item) => {
          return item
        }),
        vendorUrl: vendorsURL,
        vendorUserId: JSON.parse(localStorage.getItem("userProfileInfo"))._id,
        vendorLogo:
          "https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png",
      };
      console.log(data);
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URI}/api/vendors/saveVendor`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            token: localStorage.getItem("token"),
          },
        }
      );
      console.log(res.data);

      if (res.status === 200) {
        props.fetchPages();


        setSelectedAccountsForVendor([]);
        setVendorNameToManage('');
        setSelectedVendorsKeyword([]);
      
        toast.success(`Vendor Created`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        window.location.reload();
        setIsNewVendorDrawerOpen(false);

        // setIsNewVendorDrawerOpen(false)
      }
    }
  };

  const pageInf = function () {
    const networks = [];
    if (props?.pagesDetails !== null && props.pagesDetails.length > 0) {
      props?.pagesDetails?.map((page) => {
        if (networks.includes(page?.account_type)) {
        } else {
          networks.push(page?.account_type);
        }
      });
      console.log("networks", networks);
      setconnectedAccount(networks);

      const pages = [];
      props?.pagesDetails?.map((page) => {
        pages?.push(page.page_name);
      });
      setmediaPages(pages);
    }
  };

  const handleSearch = (e) => {
    setsearchType(e.target.value);
  };

  const handleDeleteStream = (id)=>{
    if (window.confirm('Are you sure you want to delete This stream?')) {
    axios.post(`${process.env.REACT_APP_SERVER_URI}/api/Streams/deleteStream`,{
      streamId:id,
      jwToken:localStorage.getItem("token")
    },{
      headers: {
        token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
        userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
      }
    }).then((res)=>{


      console.log(res.data);
      toast.success("Stream Deleted Successfully");

      allStreams.filter((item, i) => {
        if (item._id === id) {
          allStreams.splice(i, 1);
        }
      });

      setsavedStreams(allStreams);

      


      GetSavedStreams();
      // window.location.reload();
    }).catch((err)=>{
      console.log(err);
    })

  }

  }


  useEffect(() => {
    GetSavedStreams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pagesDetails]);

  const [selectedAccountsForVendor, setSelectedAccountsForVendor] = useState(
    []
  );
  const [selectedVendorsKeyword, setSelectedVendorsKeyword] = useState([]);

  const [SelectedVendorToManage, setSelectedVendorToManage] = useState(null);

  const [
    selectedAccountsForVendorToManage,
    setSelectedAccountsForVendorToManage,
  ] = useState([]);

  const [vendorNameToManage, setVendorNameToManage] = useState(null);
  const handleManageVendorChange = (e) => {
    setVendorNameToManage(
      vendors?.filter((vendor) => vendor._id === e.target.value)[0].vendorName
    );
    setSelectedVendorToManage(e.target.value);

    console.log('______________________VENDORS_________________________')
    console.log(allPages?.filter(page => page.VendorId === e.target.value).map((item, i) => ({ value: item.pageId, label: item.name, name: item.name, account_type: item.account_type })))

    setSelectedAccountsForVendorToManage(
      // vendors
      //   ?.filter((vendor) => vendor._id === e.target.value)[0]
      //   .vendorPages.map((item, i) => {
      //     return { value: item.id,   label: item.value, name: item?.value, account_type: item?.account_type };
      //   })

      allPages?.filter(page => page.VendorId === e.target.value).map((item, i) => ({ value: item.pageId, label: item.name, name: item.name, account_type: item.account_type }))



      
    
    );


    

  };

  const handleChangeAccountVendor = (e) => {
    console.log(e);
    setSelectedAccountsForVendor(e);
  };
  const handleSelectedVendorsKeywords = (e) => {
    setSelectedVendorsKeyword(e);
  };

  const handleChangeManageAccountVendor = (selectedOptions) => {

    console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')
    console.log(selectedOptions)

    console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')

    setSelectedAccountsForVendorToManage(selectedOptions);
  };

  const handleUpdateVendor = async () => {
    console.log(selectedAccountsForVendorToManage);

console.log('--------------------------------------')
    selectedAccountsForVendorToManage.map((item) => {
      console.log(item, item.account_type)
      return item.account_type
    })
console.log('--------------------------------------')


    if (selectedAccountsForVendorToManage.length > 0) {
      const data = {
        vendorName: vendorNameToManage,
        vendorId: SelectedVendorToManage,
        vendorPages: selectedAccountsForVendorToManage.map((item) => {


        
       return     { pageId: item.value, name: item.name, account_type: item.account_type }
        
          // return {
          //   name: item.label.name,
          //   pageId: item.value,
          //   account_type: props.pagesDetails.filter(
          //     (page) => page.pageId === item.value
          //   )[0].account_type,
          // };
        }),
        vendorUserId: JSON.parse(localStorage.getItem("userProfileInfo"))._id,
        vendorLogo:
          "https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png",
      };
      console.log(data);
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URI}/api/vendors/updateVendor`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            token: localStorage.getItem("token"),
          },
        }
      );
      console.log(res.data);

      if (res.status === 200) {
        props.fetchPages();

        setIsManageVendorsDrawerOpen(false);
        toast.success(`Vendor Updated`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // setIsNewVendorDrawerOpen(false)

        getAllvendors();
      }
    }

  }

  const handleDeleteVendor = async () => {

    console.log(selectedAccountsForVendorToManage)

    if (selectedAccountsForVendorToManage.length > 0) {
      
      await  axios.post(`${process.env.REACT_APP_SERVER_URI}/api/vendors/DeleteVendor`,{
        vendorId: SelectedVendorToManage,
        jwToken: localStorage.getItem("token"),
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("token"),
        },
      }).then((res) => {
        console.log(res.data)
        if (res.status === 200) {
          toast.success(`Vendor Deleted`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });


     setSelectedVendorToManage('')
      setSelectedAccountsForVendorToManage([])
          setIsNewVendorDrawerOpen(false)
          setVendorNameToManage('')
          setVendorsURL('')
          setSelectedVendorsKeyword([])


          getAllvendors()
        }
      }).catch((err) => {
        console.log(err)
      
      })

    }

  }

  const handleAddKeyword = (event) => {
    if (event.key === 'Enter' && metaKeywords) {
      setMetaKeywords([...metaKeywords, metaKeywords]);
      setMetaKeywords('');
    }
  };

  const handleDeleteKeyword = (keywordToDelete) => () => {
    setMetaKeywords((keywords) => keywords.filter((keyword) => keyword !== keywordToDelete));
  };





  const handleUpdateVendorName = (e) => {
    console.log(e.target.value);
    setVendorNameToManage(e.target.value);
  };

  useEffect(() => {
    console.log(selectedAccountsForVendorToManage);
  }, [selectedAccountsForVendorToManage]);



  const [IsNotificationsOpen, setIsNotificationsOpen] = useState(false);

  const handleOpenNitifications = () => {
    setIsNotificationsOpen(!IsNotificationsOpen);
  }


  return (
    <MuiThemeProvider>

    <div className="streamMainPage">
      <ToastContainer />
<Notification  isopen={IsNotificationsOpen} toggelDrawer={handleOpenNitifications} />

      <Drawer
                open={isCreateStreamDrawerOpen}
                onClose={toggelDrawer}
                direction='right'
                size={400}
                className='bla bla bla'
            >

              <h2 className="title_homepage popup_heading__ ">Create New Stream</h2>

<div className="form_styles__" style={{marginTop:'50px', display:'flex', alignItems:'start', flexDirection:'column', margin:'25px'}}>


<Label for="selectNetwork" style={{marginTop:'25px'}}>Select Network</Label>
  <Input type="select" className="drop_dwn_" onChange={handleChangeNetwork} defaultValue={'select network'} style={{borderRadius:'0', width:'95%', height:'45px', }} name="select" id="selectNetwork">
<option>Select Network</option>
  
{
    allNetworks?.map((item, i) => {
      if (item === 'googleAnalytics' || item === 'twitter') {
        return null; // exclude option for LinkedIn and Twitter
      }
      return (
        <option value={item} key={i}>{item}</option>
      )
    })
  }
  </Input>



  {
    networkSelectedForStream && ( 
      <>
      
      <Label for="selectAccount" style={{marginTop:'25px'}} >Select Account</Label>
      <Input type="select" className="drop_dwn_" onChange={handleChangeAccount} defaultValue={'Select account'}  style={{borderRadius:'0', width:'95%', height:'45px', }} name="select" id="selectAccount">
<option >Select Account</option>
{
  allPages?.filter((page) => page.account_type === networkSelectedForStream).map((item, i) => {
    return (
        <option className="item" value={item.pageId} key={i}>{item.name}</option>
    )
  })

}
      </Input>


{
  accountSelectedForStream && (
    <>
    <Label for="selectStreamType" style={{marginTop:'25px'}} >Select Stream Type</Label>
    <Input type="select" className="drop_dwn_" onChange={handleChangeStreamtype} style={{borderRadius:'0', width:'95%', height:'45px', }} name="select" id="selectStreamType">
     <option >Select Stream Type</option>
      <option value="posts">POSTS</option>
    {
      accountSelectedForStream && networkSelectedForStream === 'facebook' && (
      <>
        <option value="scheduled">SCHEDULED</option>
        <option value="tagged">TAGGED</option></>
      )
    }
    </Input>
    </>
  )
}


<div style={{width:'100%'}}>
<Button onClick={ handleCreateNewStream} className="ctnBtn_">Create Stream</Button>
</div>


      </>
    )
  }
 

  </div>


                
    
            </Drawer>

    <Drawer
                open={isNewVendorDrawerOpen}
                onClose={handleNewVendorDrawer}
                direction='right'
                size={400}
                className='bla bla bla'
                enableOverlay = {false}
            >

                 <h2 className="title_homepage popup_heading__ ">
    <span>
Create New Vendor    </span>
  <IconButton onClick={handleNewVendorDrawer}>
  <IoCloseCircleOutline />

  </IconButton>
</h2>

<form>
<div className="form_styles__" style={{marginTop:'50px', display:'flex', alignItems:'start', flexDirection:'column', margin:'25px'}}>




<Label for="vendorName" style={{marginTop:'25px'}}>Vendor Name</Label>
<Input type="text" className="drop_dwn_" name="vendorName" id="vendorName" placeholder="Vendor Name" value={CreateVendorName} onChange={(e)=>{
  setCreateVendorName(e.target.value)
}} />





{/* <Label for="selectNetwork" style={{marginTop:'25px'}}>Select Network</Label> */}
  {/* <Input className="drop_dwn_ form-select" type="select" onChange={handleChangeNetwork} defaultValue={'select network'} style={{borderRadius:'0', width:'95%', height:'45px', }} name="select" id="selectNetwork">

<option>Select Network</option>

            {allNetworks?.map((item, i) => {
              return (
                <option value={item} key={i}>
                  {item}
                </option>
              );
            })}
          </Input> */}

<div style={{marginTop:'25px', width:'95%'}}>

<Select 
  className="drop_dwn_" 
  placeholder="Select Accounts"
  options={allPages?.map((item, i) => ({ value: item.pageId, label: item.name, account_type: item.account_type }))}
  formatOptionLabel={( option ) => (
    <div style={{display:'flex'}}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 20 20"
        fill="#143059"
        className="sc-jMGUhp hVdFLT"
      >
        <g>
          <path d={socials[option.account_type]?.icon}></path>
        </g>
      </svg>
      <span style={{ marginLeft: "5px" }}>{option.label}</span>
    </div>
  )}
  onChange={handleChangeAccountVendor}
  isMulti={true}
  styles={{width:'95%', marginTop:'25px'}}
/>

</div>

{/* <Label for="websiteURL" style={{marginTop:'25px'}}>Website URL</Label> */}


{/* <div style={{display:'flex', width:'100%'}}>

<Input type="text"  style={{width:'70%', height:'50px', borderRadius:'0px'}} name="websiteURL" id="WebsiteURL" placeholder="Website URL"  onChange={
(e)=>{
  setVendorsURL(e.target.value)

}

}/>
<Button onClick={getVendorsKeywords} style={{width:'40%', borderRadius:'0px', height:'50px'}} className="">Get Keywords</Button>

</div> */}


<div style={{ marginTop: "25px", width: "95%" }}>
            <CreatableSelect
              placeholder="Select or Enter Keywords"
              options={(() => {
                const allKeywords = [];

                if (metaKeywords && metaKeywords.keywords.length > 0) {
                  allKeywords.push(...metaKeywords.keywords);
                }
                if (metaKeywords && metaKeywords.description.length > 0) {
                  allKeywords.push(...metaKeywords.description);
                }
                if (metaKeywords && metaKeywords.title.length > 0) {
                  allKeywords.push(...metaKeywords.title);
                }

                return allKeywords.map((item, i) => ({
                  value: item,
                  label: item,
                }));
              })()}
              onChange={handleSelectedVendorsKeywords}
              isMulti={true}
              styles={{ width: "95%", marginTop: "25px" }}
            />
          </div>




<div style={{width:'95%'}}>
<Button onClick={handleCreateNewVendor} className="ctnBtn_  ">Create New Vendor</Button>
</div>

  </div>
  </form>
  </Drawer>

  <Drawer
    open={isManageVendorsDrawerOpen}
    onClose={handleManageVendorsDrawer}
    direction='right'
    size={400}
    className='bla bla bla'
    enableOverlay = {false}
  >


   <h2 className="title_homepage popup_heading__ ">
    <span>
    Manage Vendors
    </span>
  <IconButton onClick={handleManageVendorsDrawer}>
  <IoCloseCircleOutline />

  </IconButton>
</h2>

<div className="form_styles__" style={{marginTop:'50px', display:'flex', alignItems:'start', flexDirection:'column', margin:'25px'}}>
   

    <label for="vendorName" style={{marginTop:'25px'}}>Select Vendor</label>
  
  <select className="drop_dwn_ form-select form-control" value={vendorNameToManage} onChange={handleManageVendorChange} style={{height:'45px'}} >
    <option>Select Vendor</option>
    {
      vendors &&
      vendors?.map((item, i) => {
        return (
          <option value={item._id} key={i}>{item.vendorName}</option>
        )
      })
    }
  </select>

  <label for="vendorName" style={{marginTop:'25px'}}>Vendor Name</label>
  <input className="drop_dwn_" value={vendorNameToManage} type="text" onChange={(e)=>{handleUpdateVendorName(e)}} style={{borderRadius:'0', width:'100%', height:'45px', }} name="vendorName" id="vendorName" placeholder="Vendor Name" defaultValue={vendors?.filter((vendor) => vendor._id === SelectedVendorToManage)[0]?.vendorName} />

            <div style={{ marginTop: "25px", width: "100%" }}>
              {SelectedVendorToManage && (
                <Select
                  placeholder="Select Accounts"
                 options={allPages?.map((item, i) => ({ value: item.pageId, label: item.name, name: item.name, account_type: item.account_type }))}
  formatOptionLabel={( option ) => (
    <div style={{display:'flex'}}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 20 20"
        fill="#143059"
        className="sc-jMGUhp hVdFLT"
      >
        <g>
          <path d={socials[option.account_type]?.icon}></path>
        </g>
      </svg>
      <span style={{ marginLeft: "5px" }}>{option.label}</span>
    </div>
  )}
                  value={selectedAccountsForVendorToManage || []}
                  onChange={handleChangeManageAccountVendor}
                  isMulti={true}
                  styles={{ width: "95%", marginTop: "25px" }}
                />
              )}
            </div>


{
  selectedAccountsForVendorToManage && (
<div style={{width:'95%'}}>
<Button onClick={handleUpdateVendor} className="ctnBtnUpdate_">Update Vendor</Button>
<Button onClick={handleDeleteVendor} style={{background:'red'}} className="ctnBtn_">Delete Vendor</Button>
</div>
  )
}


 
    </div>

  </Drawer>



      <div className="streamHeader">
        <div className="streamHeaderOptions">
          {/* <div className="headerMargin">
            <CustomDropDown
              isActive={isActiveAccounts}
              setIsActive={setIsActiveAccounts}
              selected={selected}
              setIsSelected={setIsSelected}
              list={[
                "All content",
                ...connectedAccount.filter(
                  (item) => item !== "BUSINESS" && item !== "linkedin"
                ),
              ]}
              type="streamFilter"
            />
          </div>

          <div className="headerMargin">
            <CustomDropDown
              isActive={isActive}
              setIsActive={setIsActive}
              selected={SubOptionselected}
              setIsSelected={setIsSubOptionSelected}
              list={
                selected !== "All content"
                  ? ["All content", ...subOptions[selected]]
                  : ["All content", "tagged", "posts"]
              }
              type="streamFilter"
            />
          </div> */}

          <div
            className="refreshBtn"
            onClick={() => {
              window.location.reload(true);
            }}
          > <img src={refreshIcon} alt="refresh" /> <p>Refresh</p>
          </div>
           
            
              <Link className="addNetworkHeaderBtn"
                to="/link-account"
                style={{ textDecoration: "none"}}
              >
                <img src={addSocialMediaAccounts} className="addsocial_icon_"/> Add Social Network
              </Link> 

          <div className="managevendorBtn" style={{marginLeft:'6px'}} onClick={handleManageVendorsDrawer}>
            <img src={manageVendors} className="addsocial_icon_"/> Manage Vendors  </div>
<div className="addvendornewbtn" style={{marginLeft:'6px'}} onClick={handleNewVendorDrawer}>
  <img src= {addNewVendors} className="addsocial_icon_" /> Add New Vendor </div>

       {/* <div className="addvendornewbtn" style={{marginLeft:'6px'}} onClick={handleOpenNitifications}>
           <IoNotifications style={{fontSize:'20px', color:'#143059'}} />
          </div> */}

        </div>
      </div>
      <div className="streamContentArea">
        <div className="streamContentAreaHeader">
          {/* <p>All Networks</p> */}

         <h2 className="title_homepage">Dashboard 
          <button className="createnewstream_btn_hompage" onClick={toggelDrawer}>
              Create New Stream
            </button></h2>

          {/* <div className="streamSearchArea">
            <CustomDropDown
              isActive={hashSearchActive}
              setIsActive={setHashSearchActive}
              selected={searchType}
              setIsSelected={setsearchType}
              list={connectedAccount.filter(
                (network) =>  network === "instagram"
              )   }
              type="streamFilter"
            />
       
            <input
              type="search"
              className="streamSearchAreaInput"
              onInput={(e) => {
                setsearchStream(e.target.value);
              }}
              placeholder="Search here"
            />
            <button
              className={`streamSearchAreaBtn ${
                connectedAccount.includes("instagram")
                  ? ""
                  : "disabled"
              }`}
              onClick={createSearchStream}
            >
              Create
            </button>
          </div> */}
        </div>

        <div className="streamsCardsArea">


          <div style={{display:'flex', alignItems:'center'}} onClick={goToPreviousPage}  >

            {currentPage !== 0 && (
              <img src={arrow_Left} style={{fontSize:'25px', cursor:'pointer'}} />
            )}
          </div> 



          <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'flex-start', minWidth: '90%' }}>
          {
savedStreams?.length > 0 && savedStreams
.slice(currentPage * streamsPerPage, (currentPage + 1) * streamsPerPage)
.map((stream, index) => (
  <StreamCard key={index} stream={stream} delete={handleDeleteStream} />
))

}
  
          </div> 

<div style={{display:'flex', alignItems:'center'}} onClick={goToNextPage}>
{currentPage < savedStreams.length / streamsPerPage - 1 && (
  <img src={arrow_Right} style={{fontSize:'25px', cursor:'pointer'}}  />
)}
          </div>

        

         
        </div>

<div className="footer_wrp">
©  2023-2024 Cribstore Inc. All Rights Reserved. 
</div>
     



      </div>
      {isCreateNewPostMenu && (
        <CreateNewPost
          pagesDetails={props.pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {isCreateNewPinMenu && (
        <CreatePin
          pagesDetails={props.pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {isCreateNewBoardMenu && (
        <CreateBoard
          pagesDetails={props.pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </div>
    </MuiThemeProvider>
  );
}

export default StreamScreen;
