import React, { useEffect, useState } from "react";
import "./adminLogin.css";
import Logo from "../../../assets/logo1.png";
import FormInput from "../../../Components/FormInput/FormInput";
import warning from "../../../assets/warning.png";
import { ScaleLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { loginApi } from "../../../Utils/api";

const AdminLogin = ({ adminLoginState, setAdminLoginState }) => {
  const navigate = useNavigate();
  const [adminEmailError, setAdminEmailError] = useState("");
  const [adminPasswordError, setAdminPasswordError] = useState("");
  const [adminLoginError, setAdminLoginError] = useState("");
  const [adminIsLoading, setAdminIsLoading] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      JSON.parse(localStorage.getItem("userProfileInfo")).role === "admin"
    ) {
      navigate("/admin-dashboard");
    } else if (
      localStorage.getItem("token") &&
      JSON.parse(localStorage.getItem("userProfileInfo")).role === "user"
    ) {
      navigate("/dashboard");
    }
  }, []);

  const AdminloginUser = async () => {
    // API Integration pending
    try {
      const res = await loginApi({
        email: adminLoginState.adminEmail,
        password: adminLoginState.adminPassword,
      });
      console.log(res.data);
      if (res.status === 200 && res.data.role === "admin") {
        setAdminIsLoading(false);
        sessionStorage.setItem("token", res.data.token);
        localStorage.setItem("token", res.data.token);
        sessionStorage.setItem("userProfileInfo", JSON.stringify(res.data));
        localStorage.setItem("userProfileInfo", JSON.stringify(res.data));
        navigate("/admin-dashboard");
      } else if (res.status === 200 && res.data.role !== "admin") {
        setAdminLoginError("Invalid Admin Credentials");
        setAdminIsLoading(false);
      }
    } catch (err) {
      setAdminLoginError(err.response.data.message);
      setAdminIsLoading(false);
    }
  };

  const handleAdminLogin = () => {
    if (
      adminLoginState.adminEmail.length === 0 &&
      adminLoginState.adminPassword.length === 0
    ) {
      setAdminLoginError("Please fill all the Input");
      return;
    }

    if (adminLoginError.length !== 0) {
      setAdminLoginError("");
    }
    if (adminEmailError == "" && adminPasswordError == "") {
      setAdminIsLoading(true);
      AdminloginUser();
      return;
    }
  };

  return (
    <div className="OuterAreaLayout regular">
      <div className="mainLoginArea">
        <img src={Logo} alt="logo" />
        <p>Admin Login</p>

        <div className="inputAreaSection">
          {adminLoginError.length !== 0 && (
            <p className="adminErrorCase">
              {adminLoginError}
              <img src={warning} alt="error" />
            </p>
          )}
          {/* <label>User Name</label> */}
          {/* <input type="text" /> */}
          <p className="regular label">User Name</p>
          <FormInput
            className={"adminInput"}
            inputType="email"
            name="adminEmail"
            setState={setAdminLoginState}
            setError={setAdminEmailError}
          />
          {adminEmailError.length !== 0 && (
            <p className="adminErrorCase">
              {adminEmailError} <img src={warning} alt="error" />
            </p>
          )}

          <p className="regular label">Password</p>
          <FormInput
            inputType="password"
            name="adminPassword"
            setState={setAdminLoginState}
            setError={setAdminPasswordError}
          />
          {adminPasswordError.length !== 0 && (
            <p className="adminErrorCase">
              {adminPasswordError} <img src={warning} alt="error" />
            </p>
          )}

          {/* <label>Password</label>
          <input type="text" /> */}

          <button onClick={handleAdminLogin}>
            <div className="loginBtn-Container">
              <p className="logintxt">LOGIN</p>{" "}
              <ScaleLoader
                width={2}
                height={20}
                size={20}
                color="#ffffff"
                loading={adminIsLoading}
              />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
