import {CREATE_PIN}  from "../Constants"

const createPinInitialState = {
    showCreatePinMenu:false,
}


export const createNewPinReducer = (state=createPinInitialState,action) =>{
    switch(action.type){
        case CREATE_PIN:
            return{
                ...state,
                showCreatePinMenu:!state.showCreatePinMenu,
            }
 
        
        default:
            return state;
    }
}