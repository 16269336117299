import React, { useEffect } from "react";
import CustomDropDown from "../../../Components/CustomDropDown/CustomDropDown";
import "./NewAnalyticsPage.css";
import refreshIcon from "../../../assets/refreshIcon.png";
import faceBookAnalytics from "./FaceBookAnalytics";
import calendarIcon from "../../../assets/calendarIcon.png";
import { useState } from "react";
import FbAnalytics from "../Analytics/FbAnalytics/FbAnalytics";

import "flatpickr/dist/themes/light.css";
import Flatpickr from "react-flatpickr";

import FaceBookAnalytics from "./FaceBookAnalytics";
// import InstagramAnalytics from "./Instagram/InstagramAnalytics";
// import YoutubeAnalytics from "./Youtube/YoutubeAnalytics";
// import PinintrestAnalytics from "./PinintrestAnalytics";
import { Props } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import CreateNewPost from "../CreateNewPost";
import CreatePin from "../CreatePin";
import CreateBoard from "../CreateBoard";
import { useNavigate } from "react-router";
import InstagramAnalytics from "./InstagramAnalytics";
import YoutubeAnalytics from "./YoutubeAnalytics";
import GoogleAnalytics from "../GoogleAnalytics/googleAnalytics";

function DeepAnalyticsPage(props) {
  const [selectedForm, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log("props", props);
  const isCreateNewPostMenu = useSelector(
    (state) => state.createNewPost.showCreatePostMenu
  );

  const isCreateNewPinMenu = useSelector(
    (state) => state.createNewPin.showCreatePinMenu
  );

  const isCreateNewBoardMenu = useSelector(
    (state) => state.createNewBoard.showCreateBoardMenu
  );

  const [isActive, setIsActive] = useState(false);
  const [isActiveSocials, setIsActiveSocials] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState("All Networks");

  const [selected, setIsSelected] = useState("All Networks");
  const [list, setList] = useState(["one", "two", "three"]);
  const [analyticsStartDate, setAnalyticsStartDate] = useState({
    date: new Date(new Date() - 7 * 24 * 60 * 60 * 1000).toISOString(),
    pindate: new Date(new Date() - 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0],
  });
  const [analyticsEndDate, setAnalyticsEndDate] = useState({
    date: new Date().toISOString(),
    pindate: new Date().toISOString().split("T")[0],
  });

  let distinctPages = [];
  for (let i = 0; i < props?.vendor?.vendorPages?.length; i++) {
    if (distinctPages.includes(props.vendor?.vendorPages[i].account_type)) {
      console.log("already exists");
    } else {
      distinctPages.push(props.vendor?.vendorPages[i].account_type);
      console.log(distinctPages);
    }
  }

  useEffect(() => {
    console.log(selectedAccount);
  }, [selectedAccount]);
  console.log("distinct", distinctPages);
  console.log("list", list);

  return (
    <div className="analyticsMainPage">
      {/* <div className="analyticsHeader">
        <div className="leftHeaderFilter">
          <CustomDropDown
            isActive={isActiveSocials}
            setIsActive={setIsActiveSocials}
            selected={selectedAccount}
            setIsSelected={setSelectedAccount}
            list={[
              "All Networks",
              ...distinctPages.filter((item) => item === "facebook" || item === "instagram" || item === "youtube" ),
            ]}
            type="Customdropdown"
          />
          <div className="dateFilterBlock">
            <div className="fromFilter">
              <Flatpickr
                options={{
                  theme: "light",                                              
                  dateFormat: "Y-m-d",
                  defaultDate: new Date(
                    new Date() - 7 * 24 * 60 * 60 * 1000
                  ).toISOString(),

                  onChange: function (selectedDates, dateStr, instance) {
                    console.log(dateStr);
                    setAnalyticsStartDate({             
                      pindate: dateStr.split("T")[0],
                      date: dateStr,
                      epoch: Math.floor(Date.parse(dateStr) / 1000),
                    });

                    console.log(analyticsStartDate, analyticsEndDate);
                    // selectedDates.forEach(dat => { let epoch = Date.parse(dat); console.log(epoch) })
                  },
                  // console.log(dateStr)
                  // console.log(instance)
                }}
              />
              <img src={calendarIcon} alt="calendar icon" />
            </div>
            <div className="toFilter">
              <p>TO</p>
              <div className="fromFilter">
                <Flatpickr
                  options={{
                    dateFormat: "Y-m-d",
                    defaultDate: new Date().toISOString(),

                    onChange: function (selectedDate, dateStr, instance) {
                      if (
                        new Date(dateStr) < new Date(analyticsStartDate.date)
                      ) {
                        alert("Please select a date greater than start date");
                      } else {
                        console.log(dateStr);
                        setAnalyticsEndDate({
                          pindate: dateStr.split("T")[0],
                          date: dateStr,
                          epoch: Math.floor(Date.parse(dateStr) / 1000),
                        });
                      }
                      console.log(analyticsStartDate, analyticsEndDate);
                      // selectedDates.forEach(dat => { let epoch = Date.parse(dat); console.log(epoch) })
                    },
                  }}
                />{" "}
                <img src={calendarIcon} alt="calendar icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="rightHeaderFilter">
          <div className="refreshBtn" onClick={handleClickReload}>
            <img src={refreshIcon} alt="refresh" /> <p>Refresh</p>
          </div>
          <div className="addNetworkHeaderBtn" onClick={handleClickAddNetwork}>
            <p>Add Social Network</p>
          </div>
        </div>
      </div> */}

      <div className="analyticsContentArea fb_wrp__ width__99" >
        {/* <div className="alert alert-warning mb-5">
        <p>Choose your favourite page from dropdown and analyse quickly</p>
       </div> */}

       
        {selectedAccount === "All Networks" && props.vendor.vendorPages  && (
          <> 
            <div className="mediaSection">


{
                props.vendor.vendorPages && props.vendor.vendorPages.some(obj => obj.account_type === 'facebook') ? (

                  <FaceBookAnalytics
                  PageData={null}
                  pagesDetails={{
                    pagesDetails: props.vendor.vendorPages && props.vendor.vendorPages,
                  }}
                  startDate={props.startDate}
                  endDate={props.endDate}
                />
                ) : (
                  <div className="click_notify_wrp" >
                   <p>Stay updated with your audience! <br/>Connect your Facebook account and choose your target 
                   page to get and anlyse the traffic and many Insights</p>
               
                  <h2>Please Connect Facebook Account</h2>
                </div>
                )
}
</div>
     
         <div className="mediaSection">
         {
                props.vendor.vendorPages && props.vendor.vendorPages.some(obj => obj.account_type === 'instagram') ? (

            <InstagramAnalytics
            
              PageData={null}
              pagesDetails={{
                pagesDetails: props.vendor.vendorPages && props.vendor.vendorPages,
              }}
              startDate={props.startDate}
              endDate={props.endDate}
            />

                ) : (
                  <div className="click_notify_wrp" >
                  <p>Stay updated with your audience! <br/>Connect your Instagram account and choose your target 
                  business profile to get and anlyse the traffic and many Insights</p> 
                  <h2>Please Connect Instagram account</h2>


                  </div>
                )
}

 
          </div> 
        {/* ) : selectedAccount === "youtube" && props.vendor.vendorPages ? ( */}
          <div className="mediaSection mb-3">

          {
                props.vendor.vendorPages && props.vendor.vendorPages.some(obj => obj.account_type === 'youtube') ? (


         <YoutubeAnalytics

            PageData={null}
            pagesDetails={{
              pagesDetails: props.vendor.vendorPages && props.vendor.vendorPages,
            }}
            startDate={props.startDate}
            endDate={props.endDate}
          />


                ) : (
                  <div className="click_notify_wrp">
                    <p>Stay updated with your audience! <br/>Connect your YouTube Account and choose your target 
                  channel to get and anlyse the traffic and many Insights</p>
              
                  
                  <h2>Please Connect YouTube Account</h2>


                  </div>

                )
}



        </div>


        {
                props.vendor.vendorPages && props.vendor.vendorPages.some(obj => obj.account_type === 'googleAnalytics') ? (

<GoogleAnalytics  pagesDetails={{
              pagesDetails: props.vendor.vendorPages && props.vendor.vendorPages,
            }}
            startDate={props.startDate}
            endDate={props.endDate}
            />

                ) : (

                  <div className="click_notify_wrp"
                  >
                       <p>Stay updated with your audience! <br/>Connect your GA4 Account and choose your target 
                  project to get and anlyse the traffic and many Insights</p>
                  <h2>Please Connect Google Analytics Account</h2>

                  </div>

                )
}

 </>
        )}
        {/* ) 
        : null
          } */}

        {/*<div className='mediaSection'>*/}
        {/*    <FbAnalytics*/}

        {/*        isActive={isActive}*/}
        {/*        setIsActive={setIsActive}*/}
        {/*        selected={selected}*/}
        {/*        setIsSelected={setIsSelected}*/}
        {/*        list={list}*/}
        {/*        type="mediaFilter"*/}

        {/*    />*/}

        {/*</div>*/}
      </div>
      {isCreateNewPostMenu && (
        <CreateNewPost
          pagesDetails={props.pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {isCreateNewPinMenu && (
        <CreatePin
          pagesDetails={props.pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {isCreateNewBoardMenu && (
        <CreateBoard
          pagesDetails={props.pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </div>
  );
}

export default DeepAnalyticsPage;
