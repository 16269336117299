import React, { useState, useEffect } from "react";
import "./RadioOption.css";
import Delete from "../../assets/icons8-trash-30.png";

const RadioOption = ({
  update,
  radioOption,
  setRadioOption,
  radioOptionRecord,
  setRadioOptionRecord,
}) => {
  console.log(update);
  console.log(radioOption);
  console.log(radioOptionRecord);

  const Scroll = () => {
    const scrollContainer = document.getElementById("RadioOptionMainOuterArea");
    scrollContainer.scrollTo({
      top: scrollContainer.scrollHeight * 500,
      bottom: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const AddOption = () => {
    if (radioOption === "") {
      return alert("Radio Option are not filled");
    }
    setRadioOptionRecord((prev) => [...prev, radioOption]);
    setRadioOption("");
    // document.getElementById("RadioOptionMainOuterArea").className =
    //   "AfterActive_RadioOptionMainOuterArea";
    Scroll();
  };

  const DeleteOption = (obj, id) => {
    setRadioOptionRecord(radioOptionRecord.filter((el, i) => i !== id));
    // document.getElementById("RadioOptionMainOuterArea").className =
    //   "RadioOptionMainOuterArea";
  };

  return (
    <div className="radioOptionLayout">
      <div className="radioInputSection">
        <input
          value={radioOption}
          type="text"
          onChange={(e) => {
            setRadioOption(e.target.value);
            Scroll();
          }}
        />
        <button onClick={AddOption}>ADD</button>
      </div>
      <div
        className={
          radioOptionRecord <= 0
            ? "RadioOptionMainOuterArea"
            : "AfterActive_RadioOptionMainOuterArea"
        }
        id="RadioOptionMainOuterArea"
      >
        {radioOptionRecord?.map((obj, id) => {
          return (
            <div
              className="radioOptionResults"
              key={id}
              id="radioOptionResults"
            >
              <h6>{obj}</h6>
              <img
                src={Delete}
                alt="delete"
                height="20px"
                width="20px"
                onClick={() => {
                  DeleteOption(obj, id);
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RadioOption;
