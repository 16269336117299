import React, { useEffect, useState } from "react";
import "./verifyEmail.css";
import languageEncoding from "detect-file-encoding-and-language";
import { CSVLink } from "react-csv";
import TagsInput from "../../Components/TagsInput/TagsInput";
import Papa from "papaparse";
import validator from "validator";
import warning from "../../assets/warning.png";
import check from "../../assets/check.png";
import plus from "../../assets/icons8-plus-50.png";
import cross from "../../assets/x-button.png";
import download from "../../assets/icons8-downloading-updates-48.png";
import { getverifiedEmail, getverifiedEmailsList } from "../../Utils/api";
import EmailResultCard from "../../Components/EmailResultCard/EmailResultCard";
import { useNavigate } from "react-router";
import EmailListPop from "../../Components/NewListPopUp/NewlistPop";
import { useDispatch, useSelector } from "react-redux";
// import {
//   showcreatePostMenu,
//   showCreatePinMenu,
//   showCreateBoardMenu,
// } from "../../../Redux/Actions/CreateNewPostAction";
import CreateNewPost from "../Dashboard/CreateNewPost";
import CreatePin from "../Dashboard/CreatePin";
import CreateBoard from "../Dashboard/CreateBoard";
import { MoonLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
const EmailVerify = ({ setListArray, listArray, userInfo, pagesDetails }) => {
  const [selectedForm, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const isCreateNewPostMenu = useSelector(
    (state) => state.createNewPost.showCreatePostMenu
  );

  const isCreateNewPinMenu = useSelector(
    (state) => state.createNewPin.showCreatePinMenu
  );

  const isCreateNewBoardMenu = useSelector(
    (state) => state.createNewBoard.showCreateBoardMenu
  );
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [emailArray, setEmailArray] = useState([]);
  const [disable, setDisable] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [cleanEmailData, setCleanEmailData] = useState([]);
  const [cleanEmailListData, setCleanEmailListData] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [isAddToListPopup, setisAddToListPopup] = useState(false);
  const [processingEmails, setProcessingEmails] = useState(false);
  const [sampleCsv, setSampleCsv] = useState([
    {
      Name: "Saurav",
      Email: "saurav@hellosaurav.com",
    },

    {
      Name: "hello",
      Email: "hello@noHello.in",
    },
  ]);

  const formData = new FormData();

  useEffect(() => {
    if (emailArray.length > 9) {
      setDisable(true);
      setEmailError("only 10 email is allowed please check it");
    }
  }, [emailArray]);

  const handleClickRemoveEmail = (mail) => {
    const filteredMailList = cleanEmailData.filter(
      (item) => item.Email !== mail
    );
    console.log(filteredMailList);
    setCleanEmailData(filteredMailList);
  };

  const handleClickVerifyBtn = async () => {
    console.log("verify");
    const res = await getverifiedEmailsList({ emails: emailArray, accountEmail: userInfo.email, pack:JSON.parse(localStorage.getItem('userProfileInfo')).subscription }).catch((err) => {
      console.log(err);
      if(err.response.status === 429){
      toast.error("You have reached your limit of 3 emails. Please upgrade your plan to verify more emails.");
      }
    });
    console.log(...res.data.cleanedEmails);
    setEmailArray([]);
    setCleanEmailData([...res.data.cleanedEmails]);
  };

  const checkHeaders = (str, delim = ",") => {
    console.log(str);
    const headers = str.slice(0, str.indexOf("\n")).split(delim);
    console.log(headers);
    if (headers.includes(`"Email"`) || headers.includes(`Email`) || headers.includes(`"Email\r"`) || headers.includes(`Email\r`) || headers.includes(`email\r`)) {
      if (!headers.includes("")) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const checkFile = async (fileData) => {
    // check for file header if correct headers send to the API
console.log(fileData)
    const reader = new FileReader();

    reader.onload = async function (e) {

      const text = e.target.result;
      const hasHeader = checkHeaders(text);
      console.log( text);
      if (hasHeader) {
        //call API
        setProcessingEmails(true);
        const res = await getverifiedEmail(formData).then((res, err) => {
          if (res.data.message) {
            console.log(err);
            setProcessingEmails(false);
            toast.error(res.data.message);
            return err;
            } else{
              return res;
            }
            });
        setProcessingEmails(false);
        if (res.status === 200) {
          console.log("email", res.data);
          setCleanEmailListData(res.data);
          setProcessingEmails(false);

        } else{
          toast.error(res.err.message)
          setProcessingEmails(false);
          setEmailError(res.err.message);

        }
      } else {
        console.log("Valid Headers not present in the file");
        setEmailError("Valid Headers not present in the file");
        setProcessingEmails(false);
 
      }
      setProcessingEmails(false);
    };

    setProcessingEmails(false);

    reader.readAsText(fileData);
  };

  const handleUploadEmailFile = (e) => {
    setProcessingEmails(true);
    setCleanEmailListData([]);
    const data = e.target.files[0];
    setSelectedFile(data);
    formData.append("file", data);

    if (data.type === "text/csv") {
      languageEncoding(data).then((fileInfo) => {
        if (fileInfo.encoding === "CP1252" || fileInfo.encoding === "UTF-8") {
          checkFile(data);
        } else {
          setEmailError("Can not read the file");
        }
      });
    } else {
      setEmailError("Please upload a csv file");
    }

    setProcessingEmails(false);
  };

  const handleClickCreateNewList = () => {
    navigate("/create-new-list");
  };

  const handleClickMyEmailList = () => {
    navigate("/create-new-list");
  };

  return (
    <>
      {isAddToListPopup && (
        <EmailListPop
          userInfo={userInfo}
          selectedEmail={selectedEmail}
          setSelectedEmail={setSelectedEmail}
          setisAddToListPopup={setisAddToListPopup}
          setListArray={setListArray}
          listArray={listArray}
        />
      )}
      <div className="emailOuterLayout">
      <ToastContainer />

        <div className="HeadLayout regular">
          {/* <div className="btnSection"> */}
          <button className="mailList_btn" onClick={handleClickMyEmailList}>
            My Emails List
          </button>
          <button className="createList_btn" onClick={handleClickCreateNewList}>
            Create New List
          </button>
          {/* </div> */}
        </div>
        <div className="mainContent">
          <div className="headText">
            <p>Verify Emails</p>
          </div>
          <div className="headTwoText">
            <p>Find professional vaild email addresses in seconds</p>
          </div>
          <div className="verfiySectionArea">
            <div className="verifySection">
              <p>Enter 10 emails only</p>

              <div className="inputSection" id="inputSection">
                <TagsInput
                  TagData={emailArray}
                  setEmail={setEmail}
                  email={email}
                  setArray={setEmailArray}
                />

                <button
                  className="verifyBtn"
                  id="verifyBtn"
                  onClick={handleClickVerifyBtn}
                  disabled={disable}
                >
                  VERIFY
                </button>
              </div>
              <div>
                <p style={{ color: "#282828", textAlign: "left" }}>
                  <span style={{ color: "#858685" }}>example:</span>{" "}
                  emailid1@email.com, emailid2@email.com
                </p>
                {emailError !== "" ? (
                  <p
                    className="errorcase"
                    style={{ width: "750px", textAlign: "left" }}
                  >
                    {emailError}
                    <img src={warning} alt="warning" />
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            {
              
            }
            <div className="orArea">
              <p className="orText regular">(OR)</p>
            </div>
            <div className="uploadAreaContent">
              <div
                className="uploadEmailListBtn"
                onChange={(e)=>{
                  if(JSON.parse(localStorage.getItem("userProfileInfo")).subscription === "free"){
                            toast.error("Please upgrade your plan to use this feature")


                  } else{
                    console.log(JSON.parse(localStorage.getItem("userProfileInfo")).subscription)
                    handleUploadEmailFile(e)
                  }
                }}
              >
                <input type="file" id="getFile" />
                <p>UPLOAD</p>
              </div>

              <p
                style={{ color: "#858685", float: "left", marginBottom: "5px" }}
              >
                Upload .CSV file to upload bulk email Id's to verify(Max Limit 5K emails)
              </p>
              <p style={{ color: "#858685", marginTop: "5px" }}>
                <CSVLink
                  data={sampleCsv}
                  filename="sample.csv"
                  className="downloadFileBtn"
                  target="_blank"
                >
                  Download sample csv file
                </CSVLink>
              </p>
            </div>
          </div>

          {processingEmails === true ? (
            <div className="Email_Loader">
              <MoonLoader
                color={"#ff9001"}
                loading={processingEmails}
                size={150}
              />
            </div>
          ) : null}

          {(cleanEmailListData.length !== 0 || cleanEmailData.length !== 0) && (
            <>
              <div className="sectionHeadText">Find the below Results</div>
              <div className="downloadAreaSection">
                {cleanEmailListData.length !== 0 && (
                  <div className="downloadDocSection">
                    verifed email list doc
                    <CSVLink
                      data={cleanEmailListData ? cleanEmailListData : []}
                      filename={
                        selectedFile
                          ? `verified ${selectedFile.name}`
                          : "verified.csv"
                      }
                      className="downloadFileBtn"
                      target="_blank"
                    >
                      <img
                        src={download}
                        height="15px"
                        width="15px"
                        style={{ marginTop: "3px" }}
                        alt="Downnload csv"
                      />
                      Download
                    </CSVLink>
                  </div>
                )}
                <div className="inputEmailArea">
                  {cleanEmailData.length !== 0 &&
                    cleanEmailData.map((element, i) => {
                      return (
                        <EmailResultCard
                          handleClickRemoveEmail={handleClickRemoveEmail}
                          Email={element.Email}
                          Valid={element.Valid}
                          Reason={element.Reason ? element.Reason : ""}
                          setisAddToListPopup={setisAddToListPopup}
                          setSelectedEmail={setSelectedEmail}
                        />
                      );
                    })}
                </div>
       
              </div>
            </>
          )}
        </div>
      </div>
      {isCreateNewPostMenu && (
        <CreateNewPost
          pagesDetails={pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {isCreateNewPinMenu && (
        <CreatePin
          pagesDetails={pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {isCreateNewBoardMenu && (
        <CreateBoard
          pagesDetails={pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
};

export default EmailVerify;
