import React from "react";
import { addeNewEmailAPI } from "../../Utils/api";
import "./Listcard.css";

const ListCard = ({
  userInfo,
  HandleClose,
  selectedEmail,
  listArray,
  setListRecord,
  addToList = false,
}) => {
  const updateEmailList = async (value) => {
    const params = {
      _id: userInfo._id,
      listName: value,
      email: selectedEmail,
    };
    const res = await addeNewEmailAPI(params);
    HandleClose();
    console.log(res.data);
  };

  const handleClickAddToList = (value) => {
    if (addToList) {
      updateEmailList(value);
    }
  };

  const listData = (data) => {
    setListRecord(data);
  };
  return (
    <>
      {Object.keys(listArray).map((ele, idx) => {
        // setListRecord({ length, ele });
        return (
          <div
            className="MainLayout"
            key={idx}
            onClick={(e) => {
              let length = listArray[ele].length;
              handleClickAddToList(ele);
              listData({ length, ele });
            }}
          >
            <div className="MainText">{ele.replaceAll("_", " ")}</div>
            <div className="numBox">{listArray[ele].length}</div>
          </div>
        );
      })}
    </>
  );
};

export default ListCard;
