import React from "react";
import "./AdminSidebar.css";
import Logo from "../../assets/app_logo.png";
import Dot from "../../assets/icons8-360.png";
import Arrow from "../../assets/greaterArrow.png";
import { useNavigate, useLocation } from "react-router-dom";

const AdminSideBar = (props) => {
  const navigate = useNavigate();

  return (
    <div className="adminlayoutMainContainer">
      <div className="adminSideBarContainer">
        <div className="adminSideBarMainArea">
          <div className="adminlogoContainer">
            <img src={Logo} alt="logo" />
          </div>
          <div
            className="compSection regular"
            onClick={(e) => {
              e.preventDefault();
              navigate("/admin-dashboard");
            }}
          >
            <img src={Dot} alt="circle" className="img1" />
            <span>Users</span>
            <img src={Arrow} alt="arrow" className="img2" />
          </div>
          <div
            className="compSection regular"
            onClick={(e) => {
              e.preventDefault();
              navigate("/admin-question-setup");
            }}
          >
            <img src={Dot} alt="circle" className="img1" />
            <span>Create Questions</span>
            <img src={Arrow} alt="arrow" className="img2" />
          </div>
          <div
            className="compSection regular"
            onClick={(e) => {
              e.preventDefault();
              navigate("/admin-package-setup");
            }}
          >
            <img src={Dot} alt="circle" className="img1" />
            <span>Premium Packages</span>
            <img src={Arrow} alt="arrow" className="img2" />
          </div>
          <div
            className="compSection regular"
            onClick={(e) => {
              e.preventDefault();
              navigate("/admin-view-users-questions");
            }}
          >
            <img src={Dot} alt="circle" className="img1" />
            <span>View Users Questions</span>
            <img src={Arrow} alt="arrow" className="img2" />
          </div>
          <div
            className="compSection regular"
            onClick={(e) => {
              e.preventDefault();
              navigate("/admin-upgrade-subscription");
            }}
          >
            <img src={Dot} alt="circle" className="img1" />
            <span>Upgrade Subscription</span>
            <img src={Arrow} alt="arrow" className="img2" />
          </div>

{/* //----------------------------------------------- */}
<div
            className="compSection regular"
            onClick={(e) => {
              e.preventDefault();
              navigate("/admin-upload-backlinks");
            }}
          >
            <img src={Dot} alt="circle" className="img1" />
            <span>Backlinks Master Page</span>
            <img src={Arrow} alt="arrow" className="img2" />
          </div>


          <div
            className="compSection regular"
            onClick={(e) => {
              e.preventDefault();
              navigate("/admin-upload-articles");
            }}
          >
            <img src={Dot} alt="circle" className="img1" />
            <span>Articles Master Page</span>
            <img src={Arrow} alt="arrow" className="img2" />
          </div>

          <div
            className="compSection regular"
            onClick={(e) => {
              e.preventDefault();
              navigate("/admin-upload-directories");
            }}
          >
            <img src={Dot} alt="circle" className="img1" />
            <span>Directories Master Page</span>
            <img src={Arrow} alt="arrow" className="img2" />
          </div>


{/* //--------------------------------------------- */}

        </div>
      </div>
      {props.children}
    </div>
  );
};

export default AdminSideBar;
