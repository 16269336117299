import React, { useState, useEffect } from "react";
import "./packages.css";
import Select, { StylesConfig } from "react-select";
import Logout from "../../../assets/logoutIcon.png";
import Edit from "../../../assets/icons8-edit-64.png";
import Delete from "../../../assets/icons8-trash-30.png";
import {
  getPackageApi,
  createPackages,
  updatePackages,
  deletePackageAPI,
} from "../../../Utils/api";
import { useNavigate } from "react-router-dom";

const Packages = () => {
  const navigate = useNavigate();
  const [packageName, setPackageName] = useState("");
  const [days, setDays] = useState("");
  const [amount, setAmount] = useState("");
  const [packageRecord, setPackageRecord] = useState([]);
  const [editClicked, setEditClicked] = useState(false);
  const [beforeUpdateValue, setBeforeUpdateValue] = useState();
  const [planId, setPlanId] = useState("");
  const [dayValue, setDayValue] = useState("");
  const [amountValue, setAmountValue] = useState("");

  // console.log(planId);
  // console.log(packageName);
  // console.log(days);
  console.log(amount);
  console.log(amountValue);
  console.log(packageRecord);
  // console.log(beforeUpdateValue);

  const options = [
    // { value: "Free", label: "Free", idx: 0 },
    { value: "Beginner", label: "Beginner", idx: 0 },
    { value: "Intermediate", label: "Intermediate", idx: 1 },
    { value: "Advanced", label: "Advanced", idx: 2 },
  ];

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#f7f8fa",
      borderRadius: "0px",
      border: "1px solid #919192",
    }),
    // input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles }),
  };

  const editHandle = (record, idx) => {
    console.log(record);
    setEditClicked(true);
    setBeforeUpdateValue({ record: record, idx: idx });
    setDayValue(record.days);
    setAmountValue(parseInt(record.amount));
  };

  const deleteHandle = async (record, index) => {
    console.log("Record deleted...", record);
    await deletePackageAPI(record.pId);
    setPackageRecord(packageRecord.filter((el, i) => i !== index));
  };

  const createPackage = async () => {
    if (packageName === "" || days === "") {
      return alert("All fields are mandatory to create a package");
    } else {
      const result = await createPackages({
        packageText: packageName,
        packageDays: days,
        packageAmount: amount === "" ? "N/A" : amount,
        packagePlanId: planId,
      });

      console.log(result);

      setPackageRecord((prev) => [
        ...prev,
        {
          pId: result.data.id,
          packageName: packageName,
          days: days,
          amount: amount === "" ? "N/A" : amount,
          planId: planId,
        },
      ]);
      setAmount("");
      setDays("");
    }
  };

  const updatePackage = async () => {
    if (packageName === "" || dayValue === "") {
      return alert("All fields are mandatory to create a package");
    } else {
      const data = {
        packageText: packageName,
        packageDays: dayValue,
        packageAmount: amountValue + "$",
        packagePlanId: planId,
      };

      console.log(data);
      setPackageRecord((current) =>
        current.map((obj) => {
          if (obj.planId === beforeUpdateValue.record.planId) {
            return {
              ...obj,
              packageName: packageName,
              days: dayValue,
              amount: amountValue === "" ? "N/A" : amountValue + "$",
              planId: planId,
            };
          }

          return obj;
        })
      );

      await updatePackages(beforeUpdateValue.record.pId, data);

      setEditClicked(false);
    }
  };

  const getPackagesAPI = async () => {
    const data = await getPackageApi();
    console.log(data.data);
    const reqTypeData = data.data.map((data) => {
      return {
        pId: data._id,
        packageName: data.packageText,
        days: data.packageDays,
        amount: data.packageAmount,
        planId: data.packagePlanId,
      };
    });

    console.log("from get package api...", reqTypeData);
    setPackageRecord(reqTypeData);
  };

  useEffect(() => {
    getPackagesAPI();
  }, []);

  return (
    <div className="userOuterLayout">
      <div className="headSection regular">
        <span>Premium Packages</span>

        <img
          src={Logout}
          alt="logout"
          onClick={() => {
            localStorage.clear();
            sessionStorage.clear();
            return navigate("/admin-login");
          }}
        />
      </div>
      <div className="packageFunctionArea regular">
        {editClicked ? (
          <>
            {" "}
            <div className="topFunctionArea">
              <div className="itemSection-package">
                <label>Package Name</label>
                <br />
                <Select
                  className="select"
                  options={options}
                  styles={colourStyles}
                  defaultValue={options[beforeUpdateValue.record.planId]}
                  onChange={(e) => {
                    setPlanId(e.idx);
                    setPackageName(e.value.trim());
                  }}
                />
              </div>
              <div className="itemSection-days">
                <label>Days</label> <br />
                <input
                  type="number"
                  // value={beforeUpdateValue.record.days}
                  value={dayValue}
                  // placeholder={beforeUpdateValue.record.days}
                  onChange={(e) => {
                    // setDays(e.target.value.trim());
                    setDayValue(e.target.value.trim());
                  }}
                />
              </div>
              <div className="itemSection-amount">
                <label>Amount($)</label> <br />
                <input
                  type="number"
                  // value={parseInt(beforeUpdateValue.record.amount)}
                  value={amountValue}
                  // placeholder={
                  //   parseInt(beforeUpdateValue.record.amount) || "N/A"
                  // }
                  placeholder="N/A"
                  onChange={(e) => {
                    // setAmount(e.target.value.trim() + "$");
                    setAmountValue(parseInt(e.target.value.trim() + "$"));
                  }}
                />
              </div>
            </div>
            <div className="createBtn">
              <button onClick={updatePackage}>Update</button>
            </div>
          </>
        ) : (
          <>
            <div className="topFunctionArea">
              <div className="itemSection-package">
                <label>Package Name</label>
                <br />
                <Select
                  className="select"
                  options={options}
                  styles={colourStyles}
                  onChange={(e) => {
                    setPlanId(e.idx);
                    setPackageName(e.value.trim());
                  }}
                />
              </div>
              <div className="itemSection-days">
                <label>Days</label> <br />
                <input
                  type="number"
                  value={days}
                  onChange={(e) => {
                    setDays(e.target.value.trim());
                  }}
                />
              </div>
              <div className="itemSection-amount">
                <label>Amount($)</label> <br />
                <input
                  type="number"
                  value={parseInt(amount)}
                  onChange={(e) => {
                    setAmount(e.target.value.trim() + "$");
                  }}
                  placeholder="N/A"
                />
              </div>
            </div>
            <div className="createBtn">
              <button onClick={createPackage}>Create</button>
            </div>
          </>
        )}

        <div className="bottomFunctionArea">
          <table>
            <tr>
              <th>S.No.</th>
              <th>Package Name</th>
              <th>Days</th>
              <th>Amount ($)</th>
              <th>Action</th>
            </tr>

            {packageRecord.map((record, idx) => {
              return (
                <>
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{record.packageName}</td>
                    <td>{record.days}</td>
                    <td>{record.amount}</td>
                    <td>
                      <span>
                        <img
                          src={Edit}
                          alt="edit"
                          height="25px"
                          width="25px"
                          onClick={() => {
                            editHandle(record, idx);
                          }}
                        />
                        <img
                          src={Delete}
                          alt="delete"
                          height="20px"
                          width="20px"
                          onClick={() => {
                            deleteHandle(record, idx);
                          }}
                        />
                      </span>
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
          {packageRecord.length === 0 ? (
            <div className="noFound">No Data Available</div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Packages;
