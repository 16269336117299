import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import {Button, Skeleton} from '@mui/material';
import { Card } from '@mui/material';
import { CardMedia, CardContent, Typography, CardActions } from "@mui/material";
import axios from 'axios';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';






export default function YoutubePostAnalytics(props) {

const [post_analytics, setPost_analytics] = useState([])

  const [loading, setLoading] = useState(false);



const getPostAnalytics = async () => {

  setLoading(true)
    const data = await axios.post(process.env.REACT_APP_SERVER_URI + '/api/deepAnalytics/YoutubeVideoAnalytics', {
        postId: props.selectedPostAnalyticsId,
        videoId: props.selectedPostAnalyticsId,
        pageId: props.SelectedPageId,
        startDate:props?.startDate,
        endDate:props?.endDate,
        jwToken: sessionStorage?.getItem("token"),

    })

    setLoading(false)
    console.log(data?.data)

    setPost_analytics(data?.data)

}

useEffect(() => {
  props.selectedPostAnalyticsId &&  getPostAnalytics()
}, [props.selectedPostAnalyticsId])

// useEffect(() => {
//   setPost_analytics([
//  {
//  title: "likes",
//     value: props.post?.statistics?.likeCount
//     },
//     {
//         title: "dislikes",
//         value: props.post?.statistics?.dislikeCount
//         },
//         {
//             title: "comments",
//             value: props.post?.statistics?.commentCount
//             },
//             {
//               title: "views",
//               value: props.post?.statistics?.viewCount
//             },
//             {
//               title: "avg sentiment score",
//               value: props.post?.avg_sentiment_score
//             },{
//               title: "duration",
//               value: props.post?.contentDetails?.duration
//             }
 
//   ])
// }, [props.post])



    const [modal, setModal] = useState(props.isPostAnalytics);
    useEffect(() => {
        setModal(props.isPostAnalytics)
    }, [props.isPostAnalytics])

    const toggle = () =>{ 
        setModal(!modal) 
         props.setIsPostAnalytics(false)
        };








    return(
        <div>
         {/* <Button color="danger" onClick={toggle}>
        Click Me
      </Button> */}
      <Modal isOpen={modal} toggle={toggle} fullscreen >
        <ModalHeader toggle={toggle}>Post Analytics [{props.selectedPostAnalyticsId}] <Button size="small"  href={`https://youtube.com/watch?v=`+ props.selectedPostAnalyticsId}  /* `target='_blank'` is an attribute used in HTML to open a link in a new browser tab or window when clicked. In this code, it is used in the `Button` component to open the Facebook post in a new tab when the user clicks on it. */
        target='_blank'>view post</Button>
 </ModalHeader>
        <ModalBody>


        {/* <Card className="fbAnalyticsCard" style={{marginTop:'20px', padding:'30px', display:'flex', flexDirection:'row', flexWrap:'wrap'}}> */}

        {/* <Card sx={{ maxWidth: 450 }} style={{width:'350px', margin:'10px'}}>
        <Pie data={data} />
        {
           post_analytics[0]?.value+ post_analytics[1]?.value+ post_analytics[2]?.value == 0 ?
                 <div><b> No data available</b></div> : null
            

        }
        </Card> */}

       

        {/* </Card> */}

            {/* {
                props.selectedPostAnalyticsId
            } */}



{/* {
    post_analytics.map((item, index) => {
        return <div> {item?.name}</div>

    })
} */}




<div className="fbAnalyticsCard" style={{marginTop:'20px', padding:'10px', display:'flex', flexDirection:'row', flexWrap:'wrap'}}>

{
    post_analytics.length > 0 ?
    post_analytics?.map((item, index) => {
return(
  <div className='tiles__ text-center'>

         
      <p> 
         {item?.name}</p>

       <h2 className="val_ues">{ item.value }</h2>
          
      {/* <CardActions style={{display:'flex', 'justifyContent':'space-between'}}>

<Button size="small" href={`https://facebook.com/`+ Most_positive_and_negative_post?.most_negative_post?.post_data?.id}>view post</Button>
<Button size="small" onClick={()=>openPostAnalyticsModal(Most_positive_and_negative_post?.most_negative_post?.post_data?.id)}>See analytics</Button>
</CardActions> */}
    </div>
)


    }) : (

      <Card  style={{marginTop:'10px', padding:'10px', display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
      {
Array.from({ length: 5 }).map((_, index) => (
  <div style={{margin:'5px'}}>
  <Skeleton key={index} variant="rectangular" width={410} height={218} />

  </div>
))
}
          </Card>
    )
}







</div>



       
       

       
       
       
       
       </ModalBody>
        {/* <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Do Something
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter> */}
      </Modal>
        </div>
    )}