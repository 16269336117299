import React from "react";
import "./FormInput.css";

function FormInput({
  lableText,
  inputType = "text",
  name,
  setState,
  setError,
  setValidationError,
}) {
  const handleInputChange = (e) => {
    setState((current) => {
      return {
        ...current,
        [`${name}`]: e.target.value.trim(),
      };
    });

    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (name === "fName" && !e.target.value.match(/^[a-zA-Z ]*$/)) {
      setError("Please Enter at least 2 characters");
    } else if (
      (name === "email" && !pattern.test(e.target.value)) ||
      (name === "adminEmail" && !pattern.test(e.target.value))
    ) {
      setError("Please enter a valid email format");
    } else if (
      (name === "password" &&
        !e.target.value.match(
          /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
        )) ||
      (name === "adminPassword" &&
        !e.target.value.match(
          /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
        ))
    ) {
      setError(
        "Password must be at least 8 characters, At least 1 special character from @#$%&, At least 1 number, 1 lowercase, 1 uppercase letter"
      );
    } else {
      setError("");
    }
  };
  return (
    <>
      <p className="lableText">{lableText}</p>
      <input
        type={inputType}
        placeholder={lableText}
        // className="input"
        className={
          name === "fName" ||
          name === "email" ||
          name === "password" ||
          name === "password2"
            ? "input"
            : "adminInput"
        }
        onChange={(e) => handleInputChange(e)}
        onClick={() => setValidationError("")}
      />
    </>
  );
}

export default FormInput;
