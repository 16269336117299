import {CREATE_BOARD}  from "../Constants"

const createBoardInitialState = {
    showCreateBoardMenu:false,
}


export const createNewBoardReducer = (state=createBoardInitialState,action) =>{
    switch(action.type){
        case CREATE_BOARD:
            return{
                ...state,
                showCreateBoardMenu:!state.showCreateBoardMenu,
            }
 
        
        default:
            return state;
    }
}