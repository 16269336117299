import React from 'react';
import InstaIconNobg from '../../assets/InstaIconNobg.png';
import dummyImage from '../../assets/dummyImage.png';
import instaLike from '../../assets/instaLike.png';
import instaComment from '../../assets/instaComment.png';
import instaShare from '../../assets/instaShare.png';


function MediaPostCard(props) {
    return (
        <div className='postCard'>
        <div className='postHeader'>
            <div className='postMediaIcon'>
                <img src={InstaIconNobg} alt="insta" />
            </div>
            <div className='postUserInfo'>
                <p className='postUserName'>User Name</p>
                <p className='postDate'>Sep 12 2022</p>
            </div>

        </div>
        <div className='postText'>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
        </div>
        <div className='postImg'>
            <img src={dummyImage} alt='post_image' />

        </div>
        <div className='postLikeShareSection'>
            <img className='postLike' src={instaLike} alt='like' />
            <img className='postLike' src={instaComment} alt='Comment' />
            <img className='postLike' src={instaShare} alt='Share' />

        </div>
    </div>
    );
}

export default MediaPostCard;