import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer, Views  } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
// import getDay from "date-fns/getDate";
import "./calender.css";
import {
  Card,
  CardBody,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import illustration from "../../../assets/calendar-illustration.png";
import { getSchedulePosts, GETALLAGENDAPOSTS } from "../../../Utils/api";
import { useDispatch, useSelector } from "react-redux";
import {
  showcreatePostMenu,
  showCreatePinMenu,
  showCreateBoardMenu,
} from "../../../Redux/Actions/CreateNewPostAction";
import CreateNewPost from "../CreateNewPost";
import CreatePin from "../CreatePin";
import CreateBoard from "../CreateBoard";
// import Stream from "../Stream/Stream";
import moment from "moment";
import { BsFacebook, BsInstagram, BsYoutube, BsLinkedin, BsTwitter } from "react-icons/bs";
import { PageIdToName } from "../../../Utils/api";
const scoialMediaIcons = {
  facebook: <BsFacebook size={18} color="#3b5998" />,
  instagram: <BsInstagram size={18} color="#e1306c" />,
  youtube: <BsYoutube size={18} color="#c4302b" />,
  linkedin: <BsLinkedin size={18} color="#0e76a8" />,
  twitter: <BsTwitter size={18} color="#1da1f2" />,
};

// const locales = {
//   "en-US": require("date-fns/locale/en-US"),
// };

// const localizer = dateFnsLocalizer({
//   format,
//   parse,
//   startOfWeek,
//   getDay,
//   locales
// });

const localizer = momentLocalizer(moment);

const Calenders = ({ pagesDetails, userInfo }) => {
  const [postData, setPostData] = useState([]);
  const [post, setPost] = useState([]);
  const dispatch = useDispatch();
  const [dropDown, setDropDown] = useState(false);
  const [selected, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const isCreateNewPostMenu = useSelector(
    (state) => state.createNewPost.showCreatePostMenu
  );

  const isCreateNewPinMenu = useSelector(
    (state) => state.createNewPin.showCreatePinMenu
  );

  const isCreateNewBoardMenu = useSelector(
    (state) => state.createNewBoard.showCreateBoardMenu
  ); 

  const isStream = useSelector((state) => state.getStream.showStream);



const func1 = async () => {
  const { data } = await  GETALLAGENDAPOSTS({userId : userInfo._id, jwToken : localStorage.getItem('token')});

 const dd =  []
  data.forEach((element, key) => {
    dd.push({
      ids: key ,
      title: element.data.account_type,
      id: element.data.dat.id,
      start: new Date(element.data.dat.timeToPost),   
      TimeToPost: element.data.dat.timeToPost,
      account_type: element.data.account_type,
      unique_name: element.data.unique_name,
      end: new Date(element.data.dat.timeToPost + 60000),
      allDay: true,
      pageId: element.data.pageId || "No Page Id",
      url: element.data.dat.url || "No URL",
      postTitle: element.data.message || "No Title",
      imgUrl: element.data.dat.url || "No Image URL",
      taskId: element?._id || "No Id",
      desc: element.data.dat.message || "No Title",
      privacy: element.data.dat.privacy || "No Privacy",
      vidurl : element.data.dat.vidurl ? element.data.dat.vidurl : null,
      image : element.data.dat.url ? element.data.dat.url : null,
      pageName : element.data.pageName || "Na"
    })
  });


setPostData(dd)
console.log(dd)







  // data.data.map((val, key) => {
  //   let startTime = val.Time.split(" ")[1];
  //   let startDate = val.Time.split(" ")[0];
  //   let formatDate = {
  //     title: "",
  //     start: "",
  //     end: "",
  //     allDay: false,
  //     pageId: "",
  //     url: "",
  //     postTitle: "",
  //     imgUrl: "",
  //     taskId: "",
  //     desc: "",
  //     privacy: "",
  //     vidurl : ""

  //   };
  //   if (val.account_type !== "youtube") {
  //     formatDate.title = `${
  //       val.account_type[0].toUpperCase() + val.account_type.slice(1)
  //     } - ${val.desc}`;
  //     formatDate.start = new Date(
  //       startDate.split("-")[0],
  //       startDate.split("-")[1] - 1,
  //       startDate.split("-")[2],
  //       startTime.split(":")[0],
  //       startTime.split(":")[1],
  //       0,
  //       0
  //     );
  //     formatDate.end = new Date(
  //       startDate.split("-")[0],
  //       startDate.split("-")[1] - 1,
  //       startDate.split("-")[2],
  //       startTime.split(":")[0],
  //       startTime.split(":")[1],
  //       30,
  //       0
  //     );
  //     formatDate.pageId = val.pageId;
  //     formatDate.url = val?.extra?.url ? val.extra.url : "No URL";
  //     formatDate.imgUrl = val?.extra?.imgAltUrl || "Not Image URL";
  //     formatDate.taskId = val?._id || "No Id";
  //     formatDate.desc = val?.extra?.title || "No Title";
  //     formatDate.vidurl = val?.extra?.vidurl ? val.extra.vidurl : "No URL";

  //     formatDate.privacy = val?.extra?.privacy;
  //   } else {
  //     formatDate.title = `${
  //       val.account_type[0].toUpperCase() + val.account_type.slice(1)
  //     } - ${val.desc}`;
  //     formatDate.start = new Date(
  //       startDate.split("-")[0],
  //       startDate.split("-")[1] - 1,
  //       startDate.split("-")[2],
  //       startTime.split(":")[0],
  //       startTime.split(":")[1],
  //       0,
  //       0
  //     );
  //     formatDate.end = new Date(
  //       startDate.split("-")[0],
  //       startDate.split("-")[1] - 1,
  //       startDate.split("-")[2],
  //       startTime.split(":")[0],
  //       startTime.split(":")[1],   
  //       30,
  //       0
  //     );
  //     formatDate.pageId = val.pageId;
  //     formatDate.imgUrl = val?.extra?.imgAltUrl;
  //     formatDate.postTitle = val?.extra?.title ? val.extra.title : "No Title";
  //     formatDate.vidurl = val?.extra?.vidurl ? val.extra.vidurl : "No URL";
  //   }

  //   post.push(formatDate);
  //   setPostData(post);
  //   setPost([]);
  // });
}


  const func = async () => {
    const { data } = await getSchedulePosts();

   



    data.data.map((val, key) => {
      let startTime = val.Time.split(" ")[1];
      let startDate = val.Time.split(" ")[0];
      let formatDate = {
        title: "",
        start: "",
        end: "",
        allDay: false,
        pageId: "",
        url: "",
        postTitle: "",
        imgUrl: "",
        taskId: "",
        desc: "",
        privacy: "",
        vidurl : ""

      };
      if (val.account_type !== "youtube") {
        formatDate.title = `${
          val.account_type[0].toUpperCase() + val.account_type.slice(1)
        } - ${val.desc}`;
        formatDate.start = new Date(
          startDate.split("-")[0],
          startDate.split("-")[1] - 1,
          startDate.split("-")[2],
          startTime.split(":")[0],
          startTime.split(":")[1],
          0,
          0
        );
        formatDate.end = new Date(
          startDate.split("-")[0],
          startDate.split("-")[1] - 1,
          startDate.split("-")[2],
          startTime.split(":")[0],
          startTime.split(":")[1],
          30,
          0
        );
        formatDate.pageId = val.pageId;
        formatDate.url = val?.extra?.url ? val.extra.url : "No URL";
        formatDate.imgUrl = val?.extra?.imgAltUrl || "Not Image URL";
        formatDate.taskId = val?._id || "No Id";
        formatDate.desc = val?.extra?.title || "No Title";
        formatDate.vidurl = val?.extra?.vidurl ? val.extra.vidurl : "No URL";

        formatDate.privacy = val?.extra?.privacy;
      } else {
        formatDate.title = `${
          val.account_type[0].toUpperCase() + val.account_type.slice(1)
        } - ${val.desc}`;
        formatDate.start = new Date(
          startDate.split("-")[0],
          startDate.split("-")[1] - 1,
          startDate.split("-")[2],
          startTime.split(":")[0],
          startTime.split(":")[1],
          0,
          0
        );
        formatDate.end = new Date(
          startDate.split("-")[0],
          startDate.split("-")[1] - 1,
          startDate.split("-")[2],
          startTime.split(":")[0],
          startTime.split(":")[1],   
          30,
          0
        );
        formatDate.pageId = val.pageId;
        formatDate.imgUrl = val?.extra?.imgAltUrl;
        formatDate.postTitle = val?.extra?.title ? val.extra.title : "No Title";
        formatDate.vidurl = val?.extra?.vidurl ? val.extra.vidurl : "No URL";
        formatDate.image = val?.extra?.imgAltUrl ? val.extra.imgAltUrl : "No URL";
        formatDate.allDay = true;
        formatDate.pageName = val.pageName;
        formatDate.postContent = val?.extra?.data?.message ? val.extra.data.message : "No Title";
      }
      post.push(formatDate);
      setPostData(post);
      setPost([]);
    });
  };
 
  useEffect(() => {
    // func();
    func1();
  }, [isCreateNewPostMenu, isCreateNewPinMenu, isCreateNewBoardMenu]);

  const handleClickCreatePost = () => {
    dispatch(showcreatePostMenu());
  };

  const handleClickCreateBoard = () => {
    dispatch(showCreateBoardMenu());
  };

  const handleClickCreatePin = () => {
    dispatch(showCreatePinMenu());
  };

  const toggleDD = () => {
    setDropDown(!dropDown);
  };
  const eventStyleGetter = (event, start, end, isSelected) => {
  // Customize the event rendering properties here
  const style = {
    backgroundColor: 'blue',
    // color: 'white',
    borderLeft: '1px solid red',
    height: '25px',
    // width: '70px'
  };

  return {
    style,
  };
};
console.log(postData)

const EventComponent = ({ event, view}) => (
  <div className="cal_ev_wrp" style={{display:'flex',flexWrap: 'wrap',justifyContent: 'space-between',flexDirection: 'row', lineHeight:'1', fontSize:'8px'}}>
<span className="social_ic_wrp" style={{fontSize:'11px'}}>{event.account_type && scoialMediaIcons[event.account_type]} {event.desc.slice(0,12)+ '...'} </span> 
    {/* <span className="title_post_ev_wrp"><b>{event.pageName.slice(0,7)}</b></span> */}
    {/* <span className="desc_post_ev_wrp">{event.desc.slice(0,7)}</span> */}
  </div>

);


  return (
    <>
      <Card className="calenderModuleCard">
        <CardBody className="calendarBody">
          {/*Drop down start*/}
          <div className="calender_top">
            <div className="margin-left"></div>
            <div className="calender_info">
              <h5>Calendar</h5>                                   
            </div>
            <div className="calender_addSchedule">
              <CardBody>
                <ButtonDropdown
                  isOpen={dropDown}
                  toggle={toggleDD}
                  onClick={(e) => {
                    setSelected(null);
                  }}
                  block
                >
                  <DropdownToggle caret className="addEventButton">
                    New Schedule Post
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={handleClickCreatePost}>
                      Schedule Post
                    </DropdownItem>
                    <DropdownItem onClick={handleClickCreateBoard}>
                      Schedule Board
                    </DropdownItem>
                    <DropdownItem onClick={handleClickCreatePin}>
                      Schedule Pin
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </CardBody>
            </div>
          </div>

          {/*Drop down ends*/}



          <Calendar
            localizer={localizer}
            events={postData}
            startAccessor="start"
             eventPropGetter={eventStyleGetter}
             components={{
              event: EventComponent,
            }}
            
            endAccessor="end"
            onSelectEvent={(e) => {
              setSelected(e);
              console.log(e)

              e.title.startsWith("Pinterest") && e.title.includes("(Pin)")
                ? handleClickCreatePin()
                : e.title.startsWith("Pinterest")
                ? handleClickCreateBoard()
                : handleClickCreatePost();
            }}
            className="calendarModule"
            style={{
              height: "100%",
              borderColor: "#ebe9f1",
              color: "#b9b9c3",
              fontSize: "0.8rem",
              width: "80%",
            }}
            popup={true}
          />
        </CardBody>
      </Card>
      {isCreateNewPostMenu && (
        <CreateNewPost
          pagesDetails={pagesDetails}
          eventData={selected}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {isCreateNewPinMenu && (
        <CreatePin
          pagesDetails={pagesDetails}
          eventData={selected}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {isCreateNewBoardMenu && (
        <CreateBoard
          pagesDetails={pagesDetails}
          eventData={selected}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {/* {isStream && <Stream pagesDetails={pagesDetails} />} */}
    </>
  );
};

export default Calenders;
