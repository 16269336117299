import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

                







export default function InstagramAccountChart2(props) {
    const [structuredData, setStructuredData] = useState([
        {
            "dateData": "2021-05-01",
            "impression": 0
        }
    
    ])
    
    
    console.log(props)
    
    
    useEffect(() => {
    
    
    
    
     props.data &&   setStructuredData(props?.data[0]?.values?.map((data, index) => ({
            dateData: data.end_time.slice(0, 10),
            reach: props?.data[1]?.values[index]?.value,
            // page_impressions: props?.data[1]?.values[index]?.value,
            // page_consumptions: props?.data[2]?.values[index]?.value,
            // page_engaged_users: props?.data[3]?.values[index]?.value
          })))
        
    
    }, [props])
    
        
    

    return(
        <div>
            <LineChart width={1200} height={400} data={structuredData} margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
                {/* <CartesianGrid strokeDasharray="" aria-hidden={true} /> */}
                <XAxis dataKey="dateData" />
                <YAxis />
                <Tooltip />
                <Legend />
                {/* <Line type="monotone" dataKey="impression" name="Page views" stroke="#8884d8"  strokeWidth={3}  width={3}/> */}
                <Line type="monotone" dataKey="reach" name="account reach" stroke="#82ca9d"   strokeWidth={3}/>
                {/* <Line type="monotone" dataKey="page_consumptions" name="Page Consumptions" stroke="#ffc658"  strokeWidth={3} />
                <Line type="monotone" dataKey="page_engaged_users" name="Users engaged" stroke="#00ff00" strokeWidth={3} /> */}
            </LineChart>

        </div>  )

}