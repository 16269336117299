import React, { useState, useEffect } from "react";
import FormInput from "../../Components/FormInput/FormInput";
import { useNavigate } from "react-router-dom";
import "./404.css";
import warning from "../../assets/warning.png";
import Logo from "../../../src/assets/logo1.png";
import Digi from "../../../src/assets/digi.png";
import login_left_img from "../../../src/assets/login_left_img.png";
import { ChevronLeft } from "react-feather";

function Page_404() {
  let navigate = useNavigate();

  const handleClickRedirectToHome = () => {
    navigate("/");
  };

  return (
    <div>
      <div className="mainLayout">
        {/* <div className="layout1">
          <div className="leftLayout">
            <img src={Logo} alt="logo" />
            <p className="regular text">
              Get one stop solution for all
              <br />
              your <span>Digital Marketing</span> needs
            </p>
          </div>
          <div>
            <img src={login_left_img} alt="login" className="img3" />
          </div>
        </div> */}
        {/* <div className="layout2 container"> */}
          <div className="innerLayout">
            <h1
              style={{ fontSize: "150px", fontWeight: "600", color: "#0e3e6d" }}
            >
              404
            </h1>
            <h3 style={{ fontWeight: "400" }}>OOPS! PAGE NOT FOUND</h3>
            <h5 style={{ color: "rgb(154, 156, 157)" }}>
              Sorry, the page you're looking for doesn't exist.
            </h5>
            <button
              style={{
                backgroundColor: " rgb(242, 139, 21)",
                color: "#FFFFFF",
                borderRadius: "10px",
                padding: "15px 30px 15px 20px",
                height: "50px",
                fontSize: "16px",
              }}
              onClick={handleClickRedirectToHome}
            >
              <ChevronLeft /> Back to Home
            </button>
          </div>
        {/* </div> */}
      </div>
    </div>
  );
}

export default Page_404;
