import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { createNewPostReducer } from "./Reducer/CreateNewPostReducer";
import { createNewPinReducer } from './Reducer/CreateNewPinReducer'
import { createNewBoardReducer } from './Reducer/CreateBoardReducer'
import { openStream } from "./Reducer/OpenStreamReducer";

//combininng all reducers
const reducer = combineReducers({
  createNewPost: createNewPostReducer,
  createNewPin:createNewPinReducer,
  createNewBoard:createNewBoardReducer,
  getStream: openStream,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  reducer,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
