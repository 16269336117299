
import "../FbAnalytics/FbAnalytics.css"



const ApexAreaChartsPintrest = ({ data }) => {
 

  return (

    <div className='mediaKPI cardSpace'>
      <p>{data.title}</p>
      <p className='kpiValue'><p className='kpiValue'>
  {Number(data.value) === parseInt(data.value) ? data.value : Number(data.value).toFixed(2)}
</p></p>

    </div>

    //   <Card style={{ border: 'none', marginTop: '50px', margin: '10px', boxShadow: '0 4px 24px 0 rgb(34 41 47 / 31%)', height: '18rem' }}>
    //
    //     <CardBody >
    //
    //
    //       <div style={{ marginBottom: '50px' }}> <h1 style={{ float: 'left' }}>  {(dataset.data && dataset.data[dataset.data.length - 1])?.toFixed(0)}</h1>
    //         <ButtonDropdown toggle={() => { setOpen(!dropdownOpen) }}
    //           isOpen={dropdownOpen} style={{ float: 'right' }}>
    //           <DropdownToggle className="bg-transparent" style={{ border: 'none' }} >
    //
    //             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.479 2l-7.479 12.543v5.924l-1-.6v-5.324l-7.479-12.543h15.958zm3.521-2h-23l9 15.094v5.906l5 3v-8.906l9-15.094z" /></svg>
    //           </DropdownToggle>
    //           <DropdownMenu>
    //             <DropdownItem header>Filters
    //             </DropdownItem>
    //             {filters.map((filter) => {
    //               return (
    //                 <DropdownItem onClick={() => { setMetricF(filter) }}>{filter}</DropdownItem>
    //               )
    //             })
    //             }
    //
    //           </DropdownMenu>
    //         </ButtonDropdown>
    //       </div>
    //       {/* {PageData.PageData.pageId} */}
    //
    //       <Chart options={options} series={series} type='area' height={200} width={220} />
    //     </CardBody>
    //   </Card>
  )
}
export default ApexAreaChartsPintrest
