import React from 'react';
import { MapContainer, TileLayer, Circle, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import L from 'leaflet';
import AllcountryCordinates from './Countrycordinates.json';
// import './InteractiveMap.css'; // You can create a CSS file to style the map if needed
console.log(AllcountryCordinates);


const InteractiveMap = (props) => {
  const countriesData = props.countriesData;
  // Custom icon options to style the markers
  const customIconOptions = {
    iconSize: [250, 410],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png'
  };

  const circles = Object.entries(countriesData).map(([country, count], index) => {
    //get the country coordinates
    const countryCordinates = AllcountryCordinates.find((countryCordinates) => countryCordinates.country === country); // Find the country code
    if (!countryCordinates) return null; // If the country code is not found, return null

    const { latitude, longitude } = countryCordinates; // Get the latitude and longitude
    const center = L.latLng(latitude, longitude); // Random positions
    
// Raduis of the circle gets too big if the data is too high and the map becomes too crowded and also if data is too low the circle becomes too small to see create a scale for the radius of the circle

 // auto adjust scale according to data
    const scale = 2000;



 const radius = count * scale; // Adjust the scale of the circle based on data count

    // const radius = count * 2000; // Adjust the scale of the circle based on data count

    return (
      <Circle
        key={index}
        center={center}
        radius={radius}
        pathOptions={{ color: 'blue', fillColor: 'blue' }}
      >
        <Popup>{`${country}: ${count}`}</Popup>
      </Circle>
    );
  });

  return (
    <MapContainer 
    doubleClickZoom={false}
    scrollWheelZoom={false}
    attributionControl={false}
    zoomControl={false}
    center={[0, 0]} zoom={2} style={{ height: '600px' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {circles}
    </MapContainer>
  );
};

export default InteractiveMap;
