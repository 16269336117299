import React from 'react';
import plus from '../../assets/icons8-plus-50.png';
import check from '../../assets/check.png';
import cross from '../../assets/x-button.png';


const ValidEmailCard = ({Email,Valid,setisAddToListPopup,setSelectedEmail}) => {

  const handleClickAddToList = () =>{
    setisAddToListPopup(true)
    setSelectedEmail(Email)
  } 
    return(
        <div className="addToListSection">
            {Email}
            <span style={{ color: "#161a67" }}>
              <span onClick={handleClickAddToList} >
                <img
                  src={plus}
                  height="15px"
                  width="15px"
                  style={{ marginTop: "2px" }}
                  alt="add to list"
                />
                Add to list
              </span>

              <img
                src={check}
                height="20px"
                width="20px"
                style={{ marginLeft: "20px", marginTop: "1px" }}
                alt="check icon"
              />
            </span>
          </div>
    )
}

const InValidEmailCard = ({handleClickRemoveEmail,Email,Valid,Reason}) => {
    return(
        <div className="invalidSection">
            {Email} <br />
            <small> Invalid email id - {Reason}</small>
            <img
              src={cross}
              height="20px"
              width="20px"
              style={{
                marginLeft: "20px",
                float: "right",
                marginTop: "-10px",
                cursor:"pointer"
              }}
              onClick={() => handleClickRemoveEmail(Email)}
              alt="close icon"
            />
          </div>
    )
}

function EmailResultCard({handleClickRemoveEmail,Email,Valid,Reason,setisAddToListPopup,setSelectedEmail}) {
    return (
        
            Valid ?  <ValidEmailCard setSelectedEmail={setSelectedEmail} Email={Email} Valid={Valid}setisAddToListPopup={setisAddToListPopup}  />: <InValidEmailCard handleClickRemoveEmail={handleClickRemoveEmail} Email={Email} Valid={Valid}  Reason={Reason} />
        
    );
   
}

export default EmailResultCard;