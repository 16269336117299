import { CREATE_MENU } from "../Constants";

const createMenuInitialState = {
    showCreatePostMenu:false,

}


export const createNewPostReducer = (state=createMenuInitialState,action) =>{
    switch(action.type){
        case CREATE_MENU:
            return{
                ...state,
                showCreatePostMenu:!state.showCreatePostMenu,
            }
 
        
        default:
            return state;
    }
}